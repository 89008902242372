import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GroupsScreen.module.scss';
import Button from '../../common/components/button/Button';
import Panel from '../../common/components/panel/Panel';
import Input from '../../common/components/input/Input';
import Label from '../../common/components/label/Label';
import InputError from '../../common/components/inputError/InputError';
import FormItem from '../../common/components/formItem/FormItem';
import { useForm } from 'react-hook-form';
import Loading from '../../common/services/Loading';
import { useToasts } from 'react-toast-notifications';

import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import { GroupViewModel } from '../../api/groups/models/GroupViewModel';
import GroupService from '../../api/groups/GroupService';
import GroupParamsInfo from '../parameters/components/groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../Config';

enum ScreenType {
    NEW,
    EDIT,
    DETAILS
}

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    onGoList?: () => void;
    afterRemove?: () => void;
    showPanel: boolean;
};

const GroupScreen: React.FC<Props> = ({ onGoList, afterRemove, groupId, showPanel, userCanRead, userCanWrite }: Props) => {

    const { t } = useTranslation();

    const { addToast } = useToasts();

    const { register: registerGroup, handleSubmit: handleSubmitGroup, errors: errorsGroup, setValue } = useForm<GroupViewModel>();

    const [titlePanel, setTitlePanel] = useState(t('parameters.group.new_group'));

    const [screenMode, setScreenMode] = useState<ScreenType>(ScreenType.DETAILS);

    const [itemToRemove, setItemToRemove] = useState<GroupViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const onSubmitGroup = async ({ name }: GroupViewModel) => {
        try {
            if (!name) {
                return;
            }

            Loading.show();
            await GroupService.save({ id: groupId, name: name });
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });

            onGoList && onGoList();
            setScreenMode(ScreenType.DETAILS);

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getGroup = async () => {
        try {
            Loading.show();
            const result = await GroupService.get(groupId);
            if (result) {
                setValue('name', result?.name || '');
            }
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const showRemoveItemDialog = (item: GroupViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await GroupService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                afterRemove && afterRemove();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        if (userCanRead) {
            if (groupId) {
                getGroup();
                setScreenMode(ScreenType.DETAILS);
            } else {
                setScreenMode(ScreenType.NEW);
                setTitlePanel(t('parameters.group.new_group'));
            }
        }
    }, [groupId]);

    return (
        <div >
        { screenMode  !==  ScreenType.NEW && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.DETAILS}/> }

        
            <QuestionYesNo message={t('common.messages.remove_record')}
                isVisible={dialogDeleteItemIsOpen}
                onYes={() => removeItem()}
                onNo={() => setDialogDeleteItemIsOpen(false)} />

            { screenMode === ScreenType.NEW && showPanel && <Panel title={titlePanel} isOptions={false} onGoBackClick={() => onGoList && onGoList()} >
                <form onSubmit={handleSubmitGroup(onSubmitGroup)} className={styles.formContent}>
                    <div className={styles.formLine}>
                        <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                            <Label className={styles.bold}>{t('common.name')} *</Label>
                            <Input maxLength={255} name="name" placeholder={t('common.name')} ref={registerGroup({ required: true, maxLength: 255 })} />
                            <InputError error={errorsGroup.name} />
                        </FormItem>
                    </div>
                    <div className={styles.buttonsFooter}>
                        <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} className={styles.button}
                            onClick={() => onGoList && onGoList()} />
                        <Button type="submit" text={t('common.save')} size={'small'} className={styles.button} />
                    </div>
                </form>
            </Panel>}

            
            { screenMode !== ScreenType.NEW && !showPanel && <form onSubmit={handleSubmitGroup(onSubmitGroup)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
                <div className={styles.formLine}>
                    <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                        <Label className={styles.bold}>{t('common.name')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                        <Input maxLength={255} disabled={screenMode === ScreenType.DETAILS} name="name" placeholder={t('common.name')} ref={registerGroup({ required: true, maxLength: 255 })} />
                        {screenMode === ScreenType.EDIT && <InputError error={errorsGroup.name} />}
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                    {screenMode === ScreenType.EDIT && <div>
                        <Button type="button" text={t('common.cancel')} preset={'secondary'} className={styles.button} size={'small'} onClick={() => { setScreenMode(ScreenType.DETAILS); getGroup(); }} />
                        {userCanWrite && <Button type="submit" text={t('common.save')} className={styles.button} size={'small'} />}
                    </div>}
                    {screenMode === ScreenType.DETAILS && <div>
                        {userCanWrite && <Button type="button" text={t('common.remove')} preset={'danger'} className={styles.button} size={'small'} onClick={() => showRemoveItemDialog({ id: groupId } as GroupViewModel)} />}
                        {userCanWrite && <Button type="button" text={t('common.edit')} className={styles.button} size={'small'} onClick={() => setScreenMode(ScreenType.EDIT)} />}
                    </div>}
                </div>

            </form>
            }
        </div>
    );
}

export default GroupScreen;