import React, { memo } from 'react';
import MoneyFormat from '../../../common/components/moneyFormat/MoneyFormat';
import styles from './TimesPlanningTableValueColumn.module.scss';

type Props = {
    executedValue?: number;
    plannedValue?: number;
    color: string;
    isSubRow?: boolean;
    draw?: boolean;
};

const TimesPlanningTableValueColumn = ({ plannedValue, executedValue, color, isSubRow, draw }: Props) => {
    return (
        <div className={styles.item} style={{ background: draw ? `linear-gradient(to right bottom, #fff calc(50% - 1px), #e9eaea, ${color} calc(50% + 1px))` : undefined }}>
            {!!draw && <div className={`${styles.diagonalTop} ${isSubRow ? styles.diagonalIsSubRow : ''}`}>
                {plannedValue != null && plannedValue != undefined ? <MoneyFormat value={plannedValue} suffix={'h'} /> : '-'}
            </div>}
            {!!draw && <div className={`${styles.diagonalBottom} ${isSubRow ? styles.diagonalIsSubRow : ''}`}>
                {executedValue != null && executedValue != undefined ? <MoneyFormat value={executedValue} suffix={'h'} /> : '-'}
            </div>}
        </div>
    );
}

export default memo(TimesPlanningTableValueColumn);
