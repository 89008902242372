import { AES, enc } from 'crypto-js';
import { STORAGE_KEY } from '../../Config';

class Storage {

    private encrypt(value: string): string {
        return AES.encrypt(value, STORAGE_KEY).toString();
    }

    private decrypt(value: string): string {
        return AES.decrypt(value, STORAGE_KEY).toString(enc.Utf8);
    }

    public set(key: string, value: string) {
        try {
            const encrypted = this.encrypt(value);
            localStorage.setItem(key, encrypted);
            return true;
        } catch (_) {
            return false;
        }
    }

    public setObject(key: string, value: any) {
        try {
            const encrypted = this.encrypt(JSON.stringify(value));
            return this.set(key, encrypted);
        } catch (_) {
            return false;
        }
    }

    public get(key: string) {
        try {
            const value = localStorage.getItem(key);
            if (!value) {
                return null;
            }
            const decrypted: string = this.decrypt(value);
            return decrypted;
        } catch (_) {
            return null;
        }
    }

    public getObject(key: string) {
        try {
            const obj = this.get(key);
            if (!obj) {
                return null;
            }
            const decrypted: string = this.decrypt(obj);
            const result = JSON.parse(decrypted);
            return result;
        } catch (_) {
            return null;
        }
    }

    public remove(key: string) {
        try {
            localStorage.removeItem(key);
            return true;
        } catch (_) {
            return false;
        }
    }
}

export default new Storage();
