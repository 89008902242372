import React from 'react';
import styles from './Label.module.scss';

type Props = {
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

const Label: React.FC<Props> = ({ children, ...props }: Props) => {

    return (
        <label
            {...props}
            className={`${styles.label} ${props.className || ''}`}
        >
            {children}
        </label>
    );
}

export default Label;
