import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

type Props = {
    renderAsText?: boolean;
} & NumberFormatProps;

const PercentageFormat: React.FC<Props> = ({ value, renderAsText = false, ...props }: Props) => {

    return (
        <NumberFormat
            value={value}
            displayType={props.displayType || 'text'}
            thousandSeparator={' '}
            decimalSeparator={','}
            decimalScale={2}
            suffix={'%'}
            {...props}
            renderText={renderAsText ? e => e : undefined}
        />
    );
}

export default PercentageFormat;
