import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiEye } from 'react-icons/hi';
import { IoMoveOutline } from 'react-icons/io5';
import Sidebar from '../../../common/components/sidebar/Sidebar';
import styles from './ProjectsSidebar.module.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Button from '../../../common/components/button/Button';
import { SEARCH_HISTORY_PAGES } from '../../../Config';
import { UserSettingsListingViewModel } from '../../../api/userSettings/models/UserSettingsListingViewModel';
import { ColumnField } from '../../../api/projects/models/ProjectsSearchCriteria';
import update from 'immutability-helper';

type Props = {
    columns: UserSettingsListingViewModel[];
    applyColumnsSettings: (settings?: UserSettingsListingViewModel[]) => void;
    removeColumnsSettings: () => UserSettingsListingViewModel[];
    saveSettings: (settings: UserSettingsListingViewModel[], notification: boolean) => void;
}

const ProjectsSidebar: React.FC<Props> = ({ columns, applyColumnsSettings, removeColumnsSettings, saveSettings }: Props) => {
    const { t } = useTranslation();

    const [myColumns, setMyColumns] = useState<UserSettingsListingViewModel[]>([]);

    const onColumnClick = (column: UserSettingsListingViewModel) => {
        setMyColumns(
            update(myColumns, {
                [myColumns.findIndex(x => x.field == column.field)]: {
                    $apply: c => ({
                        ...c,
                        visible: !c.visible,
                    })
                }
            })
        );
    }

    const reorder = (list: UserSettingsListingViewModel[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: 'none',
        padding: '0.5rem',
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver: boolean) => ({

    });

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            [...myColumns],
            result.source.index,
            result.destination.index
        );
        setMyColumns(items);
    }

    const applySettings = async () => {
        const settings = myColumns.map((x, i) => {
            return {
                ...x,
                width: x.field == ColumnField.DESIGNATION ? undefined : x.width,
                index: i,
                page: SEARCH_HISTORY_PAGES.LIST_PROJECTS.toString()
            }
        });
        saveSettings(settings, true);
        applyColumnsSettings(settings);
    }

    const removeSettings = () => {
        setMyColumns(removeColumnsSettings());
    }

    return (
        <Sidebar
            onClose={() => {
                const arrayAux: UserSettingsListingViewModel[] = [];
                columns.forEach(x => {
                    const aux = columns.find(z => z.field == x.field);
                    if (aux) {
                        arrayAux.push({
                            ...aux,
                            visible: x.visible
                        })
                    }
                });
                setMyColumns(arrayAux);
            }}
            onOpen={() => {
                setMyColumns([...columns]);
            }}
        >
            <div className={styles.sideBarContent}>
                <h2 className={styles.sidebarTitle}>{t('projects.visible_columns')}</h2>
                <div className={styles.dragContainer}>
                    <div>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" >
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {myColumns.map((column, index) => (
                                            <Draggable key={column.field} draggableId={column.field} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <div key={column.field} className={`${styles.columnConfig} ${!column.visible ? styles.columnConfigNotVisible : undefined}`}>
                                                            <div className={styles.columnConfigContent}>
                                                                <HiEye className={`${styles.columnConfigEye} ${column.visible ? styles.columnConfigEyeVisible : undefined}`}
                                                                    onClick={() => onColumnClick(column)}
                                                                />
                                                                {column.label}
                                                            </div>
                                                            <div>
                                                                <IoMoveOutline className={styles.columnConfigCursor} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                <div className={styles.buttonsFooter}>
                    <Button type="button" text={t('common.remove')} preset={'secondary'} size={'small'} onClick={() => { removeSettings(); }} />
                    <Button type="button" text={t('common.apply')} size={'small'} onClick={() => { applySettings(); }} />
                </div>
            </div>
        </Sidebar >
    );
}

export default memo(ProjectsSidebar);