import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { ProjectTypeViewModel } from './models/ProjectTypeViewModel';
import { ProjectTypeSearchCriteria } from './models/ProjectTypeSearchCriteria';

class ProjectTypesService {

    public getList(criteria: ProjectTypeSearchCriteria) {
        return Http.get<Page<ProjectTypeViewModel>>('projectTypes', criteria);
    }

    public get(id: string) {
        return Http.get<ProjectTypeViewModel>('projectTypes/' + id);
    }

    public getByName(name: string) {
        return Http.get<ProjectTypeViewModel>('projectTypes/get-by-name/' + name);
    }

}

export default new ProjectTypesService();