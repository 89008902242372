import React, { useState } from 'react';

import ListQualityQuestionsScreen from '../../qualityQuestions/list/ListQualityQuestionsScreen';
import QualityQuestionsScreen from '../../qualityQuestions/QualityQuestionsScreen';
import { QualityQuestionViewModel } from '../../../api/qualityQuestions/models/QualityQuestionViewModel';
import { GROUP_PARAMS_TYPE } from '../../../Config';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_QUALITY_QUESTIONS,
    QUALITY_QUESTION
}

const ParametersQualityQuestionsScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_QUALITY_QUESTIONS);
    const [currentQualityQuestion, setCurrentQualityQuestion] = useState<QualityQuestionViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_QUALITY_QUESTIONS && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.QUALITY_QUESTIONS}/> }
            { screen === ScreenType.LIST_QUALITY_QUESTIONS && <ListQualityQuestionsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewQualityQuestion={() => {
                    setIsDetails(false);
                    setCurrentQualityQuestion(null);
                    setScreen(ScreenType.QUALITY_QUESTION)
                }}
                onClickItem={(item: QualityQuestionViewModel, isDetails: boolean) => {
                    if(item.active)
                    {
                        setIsDetails(isDetails);
                        setCurrentQualityQuestion(item);
                        setScreen(ScreenType.QUALITY_QUESTION);
                    }
                }}
            ></ListQualityQuestionsScreen>}
            { screen === ScreenType.QUALITY_QUESTION && <QualityQuestionsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                qualityQuestionId={currentQualityQuestion?.id}
                afterSave={() => {
                    setCurrentQualityQuestion(null);
                    setScreen(ScreenType.LIST_QUALITY_QUESTIONS);
                }}></QualityQuestionsScreen>}
        </div>

    );
}

export default ParametersQualityQuestionsScreen;
