import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleOutline } from 'react-icons/io5';
import styles from './ScreenNavbar.module.scss';

export interface ScreenNavbarItem {
    key: string;
    text: string;
    url?: string;
    onClick?: (item: ScreenNavbarItem) => void;
    name?: string;
}

type Props = {
    items: ScreenNavbarItem[];
    activeItem: ScreenNavbarItem;
    onChange: (item: ScreenNavbarItem) => void;
    onExitClick?: () => void;
};

const ScreenNavbar: React.FC<Props> = ({ items, activeItem, onChange, onExitClick }: Props) => {
    const { t } = useTranslation();
    const [scrolled, setScrolled] = useState(false)

    useEffect(() => {
        document.addEventListener('scroll', () => {
            const scrolled = document.scrollingElement?.scrollTop || 0;
            if (scrolled >= 5) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        });
    }, [])

    return (
        <div className={`${styles.container} ${scrolled ? styles.sticky : ''}`}>
            <div className={styles.innerContainer}>
                <div className={styles.items}>
                    {items.map(item => (
                        <div
                            key={`screen-navbar-item-${item.key}`}
                            className={`${styles.item} ${Boolean(item.key === activeItem?.key) && styles.active}`}
                            onClick={() => onChange(item)}
                        >
                            <span>{item.text}</span>
                        </div>
                    ))}
                </div>
                {Boolean(onExitClick) && <button className={styles.closeButton} onClick={onExitClick}>
                    <span className={styles.closeButtonText}>{t('common.close')}</span>
                    <IoCloseCircleOutline className={styles.closeButtonIcon} size={24} />
                </button>}
            </div>
        </div>
    );
}

export default ScreenNavbar;
