import React from 'react';
import styles from './CheckBox.module.scss';
import { FaCheck } from 'react-icons/fa';

type Props = {
    label?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const CheckBox = ({ label, children, ...props }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {

    return (
        <label className={styles.container}>
            {Boolean(label) && <span className={styles.label}>{label}</span>}
            {children}
            <input
                {...props}
                ref={ref}
                type="checkbox"
                className={`${styles.input} ${props.className || ''}`}
            />
            <span className={`${styles.checkMark} ${props.disabled ? styles.disabled : ''}`}>
                <FaCheck className={styles.icon} />
            </span>
        </label>
    );
};

export default React.forwardRef<HTMLInputElement, Props>(CheckBox);
