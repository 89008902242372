import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import LoginLogsService from '../../api/loginLogs/LoginLogsService';
import { LoginLogSearchCriteria } from '../../api/loginLogs/models/LoginLogSearchCriteria';
import { LoginLogViewModel } from '../../api/loginLogs/models/LoginLogViewModel';
import Loading from '../../common/services/Loading';
import ListingTable, { ListingTableCell, ListingTableColumn } from '../../common/components/listingTable/ListingTable';
import PaginationTextInfo from '../../common/components/pagination/PaginationTextInfo';
import Pagination, { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from '../../common/components/pagination/Pagination';
import styles from './ListLoginLogsScreen.module.scss';
import DateFormat from '../../common/components/dateFormat/dateFormat';
import { DATE_TIME_FORMAT_DEFAUT } from '../../Config';
import Popover from '../../common/components/popover/Popover';
import Button from '../../common/components/button/Button';
import ListLoginLogsFilters, { Filters } from './listLoginLogsFilters/ListLoginLogsFilters';
import { Reducers } from '../../store/types';
import { UserProfile } from '../../api/account/models/UserProfile';
import { useSelector } from 'react-redux';

type Props = {
    userCanRead: boolean;
};

const ListLoginLogsScreen: React.FC<Props> = ({ userCanRead, ...props }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [criteria, setCriteria] = useState<LoginLogSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'll.created_date',
        orderBy: 'desc',
        groupId: loggedUser && loggedUser.groupId ? loggedUser.groupId : undefined,
        userHasGroupId: Boolean(loggedUser && loggedUser.groupId)
    });
    const [loginLogs, setLoginLogs] = useState<LoginLogViewModel[]>([]);
    const [totalItems, setTotalItems] = useState(0);

    const [filtersTotal, setFiltersTotal] = useState(0);
    const [filters, setFilters] = useState<Filters>({ groupId: loggedUser && loggedUser.groupId ? loggedUser.groupId : undefined });

    const getLoginLogs = async () => {
        try {
            Loading.show();

            const result = await LoginLogsService.getList(criteria);
            setLoginLogs(result.items);
            setTotalItems(result.totalItems);

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const renderTableCreatedDateCell = (cell: ListingTableCell) => {
        const createdDate = (cell.row as LoginLogViewModel).createdDate;

        return (
            <div>
                {createdDate &&<DateFormat value={createdDate} format={DATE_TIME_FORMAT_DEFAUT} />}
            </div>
        );
    }

    const renderTableUser = (cell: ListingTableCell) => {
        return (
            <div>
                {(cell.row as LoginLogViewModel).username}
            </div>
        );
    }

    const renderTableLogType = (cell: ListingTableCell) => {
        const logType = (cell.row as LoginLogViewModel).logType;
        return (
            <div>
                {logType ? t('parameters.login_log_types.' + logType as any) : '-'}
            </div>
        );
    }

    const tableColumns: ListingTableColumn[] = [
        {
            field: 'createdDate',
            name: t('common.date'),
            width: 'fill',
            renderCell: renderTableCreatedDateCell
        },
        {
            field: 'username',
            name: t('common.user'),
            width: 'fill',
            renderCell: renderTableUser
        },         
        {
            field: 'logType',
            name: t('common.type'),
            width: 'fill',
            renderCell: renderTableLogType
        }
    ];

    const onPageChange = (page: number) => {
        setCriteria({ ...criteria, page });
    }

    const onChangeFilters = (f: Filters) => {
        setFilters(f);
        updateTotalFilters(f);
        criteria.startDate = f.startDate;
        criteria.endDate = f.endDate;
        criteria.groupId = f.groupId;
        criteria.userId = f.userId;
        criteria.logType = f.logType;

        setCriteria({ ...criteria });
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.userId) {
            total++;
        }
        if (f.groupId) {
            total++;
        }
        if (f.startDate || f.endDate) {
            total++;
        }
        if (f.logType) {
            total++;
        }
        
        setFiltersTotal(total);
    }

    useEffect(() => {
        if (userCanRead) {
            getLoginLogs();
        }
    }, [criteria]);

    return (
        <div style={{ minHeight: '511px', position: 'relative' }}>
            <div className={styles.filterContainer}>
                <Popover
                    positions={['bottom']}
                    align={'end'}
                    onClickOutside={() => updateTotalFilters(filters)}
                    content={setIsPopoverOpen => <ListLoginLogsFilters
                        filters={filters}
                        onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                        onChange={f => updateTotalFilters(f)}
                    />}>
                    {(isPopoverOpen, setIsPopoverOpen) => (
                        <Button preset="secondary"
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                            className={styles.btnFilters}>
                            {t('common.filters')}
                            {filtersTotal > 0 &&
                                <div className={styles.counter}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                            }
                        </Button>
                    )}
                </Popover>
            </div>
            <ListingTable columns={tableColumns} rows={loginLogs} allowHover={true} />            
            <br />
            { totalItems > 0 && <div className={styles.paginationContainer} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <PaginationTextInfo className={styles.paginationInfo} itemName={t('parameters.login_logs_connections')} items={loginLogs.length} totalItems={totalItems} />
                <Pagination currentPage={criteria.page ?? 1} onChange={onPageChange} totalItems={totalItems} />
            </div>}
        </div>
    );
}

export default ListLoginLogsScreen;
