import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';
import { ProjectTimesViewModel } from '../../../../api/projectsTimes/models/ProjectTimesViewModel';
import ProjectTimesService from '../../../../api/projectsTimes/ProjectTimesService';
import Button from '../../../../common/components/button/Button';
import DateTimePicker from '../../../../common/components/dateTimePicker/DateTimePicker';
import FormItem from '../../../../common/components/formItem/FormItem';
import InputError from '../../../../common/components/inputError/InputError';
import InputGroup from '../../../../common/components/inputGroup/InputGroup';
import InputGroupController from '../../../../common/components/inputGroup/InputGroupController';
import Label from '../../../../common/components/label/Label';
import Loading from '../../../../common/services/Loading';
import styles from './ProjectTimePopoverForm.module.scss';

type Form = {
    id?: string;
    projectPhaseGuid: string;
    projectResponsibleDsiId: string;
    date: Date,
    hours: number
}

type Props = {
    mode: 'NEW' | 'EDIT' | 'DETAILS',
    onCancel: (refresh: boolean) => void,
    item?: ProjectTimesViewModel,
    projectResponsibleDsiId: string,
    projectResponsibleDsiName: string,
    projectPhaseGuid: string,
};

const ProjectTimePopoverForm: React.FC<Props> = ({ mode, onCancel, item, projectResponsibleDsiId, projectResponsibleDsiName, projectPhaseGuid }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const form = useForm<Form>({
        shouldUnregister: false, shouldFocusError: true, defaultValues: !!item ? item : {
            projectResponsibleDsiId,
            projectPhaseGuid,
            date: new Date
        }
    });
    const [isDetails, setIsDetails] = useState<boolean>(mode === 'DETAILS');

    const onSubmit = async (form: Form) => {
        try {

            Loading.show();

            await ProjectTimesService.save(form);

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            onCancel(true);

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    }

    return (
        <form className={styles.formContent} onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('projectstimes.popover.responsable_dsi')}</Label>
                    <Label className={styles.colabName}>{projectResponsibleDsiName}</Label>
                </FormItem>
            </div>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('projectstimes.popover.day')} {isDetails ? '' : '*'}</Label>
                    <DateTimePicker
                        selected={form.watch('date')}
                        name={'date'}
                        dateFormat="dd/MM/yyyy"
                        locale='fr'
                        autoComplete='off'
                        placeholderText={t('projectstimes.popover.day')}
                        customInput={<InputGroup noBorder={false} icon={<FaCalendarAlt />} />}
                        onChange={(date: Date) => {
                            form.setValue('date', date);
                        }}
                        disabled={isDetails}
                        required
                    />
                    <InputError error={form.errors.date} />
                </FormItem>
            </div>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('projectstimes.popover.hours')} {isDetails ? '' : '*'}</Label>
                    <InputGroupController
                                        type="number"
                                        name={'hours'}
                                        form={form}
                                        disabled={isDetails}
                                        format="money"
                                        required={true}
                                    />
                    <InputError error={form.errors.hours} />
                </FormItem>
            </div>
            <div className={styles.buttonsFooter}>
                {mode == 'EDIT' && <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={() => onCancel(false)} />}
                {!isDetails && <Button type="submit" text={t('common.save')} size={'small'} />}
                {isDetails && <Button type="button" text={t('common.edit')} onClick={() => { setIsDetails(false) }} size={'small'} />}
            </div>
        </form >
    );
}

export default ProjectTimePopoverForm;
