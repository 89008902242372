import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { ProjectManagerSearchCriteria } from './models/ProjectManagerSearchCriteria';
import { ProjectManagerViewModel } from './models/ProjectManagerViewModel';

class ProjectManagersService {
    
    public getList(criteria: ProjectManagerSearchCriteria) {
        return Http.get<Page<ProjectManagerViewModel>>('projectManagers', criteria);
    }

    public getProjectManagerById(id: string) {
        return Http.get<ProjectManagerViewModel>('projectManagers/'+ id);
    }

    public save(model: ProjectManagerViewModel) {
        return !!model.id ? Http.put('projectManagers', model) : Http.post('projectManagers', model);
    }

    public remove(model: ProjectManagerViewModel) {
        return  Http.put('projectManagers/deactivate', model);
    }
}

export default new ProjectManagersService();