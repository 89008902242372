import Http from '../../common/services/Http';
import { PolicyRoleItem, PolicyViewModel } from './models/PolicyViewModel';

class PoliciesService {
    
    public getPoliciesByGroup(groupId?: string) {
        return Http.get<PolicyRoleItem[]>('policies/get-policies-by-group/' + groupId);
    }

    public update(model: PolicyViewModel) {
        return Http.put('policies', model);
    }
}

export default new PoliciesService();



