import Http from '../../common/services/Http';
import { UserSettingsListingViewModel } from './models/UserSettingsListingViewModel';
import { UserSettingsViewModel } from './models/UserSettingsViewModel';

class UserSettingsService {

    public getListingSettings(page: string) {
        return Http.get<UserSettingsListingViewModel[]>('usersettings/get-all-user-settings-listing-for-page/' + page);
    }

    public saveListingSettings(model: UserSettingsViewModel) {
        return Http.post('usersettings/save-listing-settings', model);
    }

}

export default new UserSettingsService();