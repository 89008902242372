import React from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';

const NotFoundScreen: React.FC = () => {

    const { t } = useTranslation();

    return (
        <ScreenTitle title={t('common.errors.page_not_found')}>
            <h1>404 - {t('common.errors.page_not_found')}</h1>
        </ScreenTitle>
    );
}

export default NotFoundScreen;
