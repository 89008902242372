import React, { memo, useState } from 'react';
import styles from './CheckBoxGroup.module.scss';
import CheckBox from '../../../../common/components/checkBox/CheckBox';
import { useTranslation } from 'react-i18next';
import { Controller, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../models/ProjectForm';
import Label from '../../../../common/components/label/Label';

export interface CheckBoxGroupItem {
    id: string;
    label: string;
    active?: boolean;
    checked?: boolean;
    children?: CheckBoxGroupItem[];
}

type Props = {
    items: CheckBoxGroupItem[];
    name: string;
    childrenName?: string;
    form: UseFormMethods<ProjectForm>;
    hasChildren?: boolean;
    disabled?: boolean;
    onChange?: () => void;
};

const CheckBoxGroup: React.FC<Props> = ({ items, name, childrenName, form, hasChildren, disabled, onChange }: Props) => {
    const { t } = useTranslation();

    const [selectedGroupIndex, setselectedGroupIndex] = useState(0);
    const selectedGroupItem = items[selectedGroupIndex];

    if (items.length <= 0) {
        return null;
    }

    const getParentChildrenChecked = (parentIndex: number) => {
        return form.watch<string, { checked: boolean }[]>(`${name}[${parentIndex}].${childrenName}`)?.filter(x => x.checked).length || 0;
    }

    const setParentChecked = () => {
        const parentChildrenAnyChecked = form.watch<string, { checked: boolean }[]>(`${name}[${selectedGroupIndex}].${childrenName}`).filter(x => x.checked);
        const parentChecked = form.watch<string, { checked: boolean }>(`${name}[${selectedGroupIndex}].checked`);
        
        if (parentChildrenAnyChecked && !parentChecked) {
            form.setValue(`${name}[${selectedGroupIndex}].checked`, parentChildrenAnyChecked);
        }
    }

    const setParentChildrenUnchecked = (parentIndex: number) => {
        const parentChildrenName = `${name}[${parentIndex}].${childrenName}`;
        const children = form.watch<string, { checked: boolean }[]>(parentChildrenName);
        
        children && children.forEach((_, i) => {
            form.setValue(`${parentChildrenName}[${i}].checked`, false)
        });
    }

    return (
        <div>
            <div className={styles.groups}>
                {items.map((item, i) => {
                    const itemFormKey = `${name}[${i}].checked`;
                    const defaultValue = form.getValues(itemFormKey) || item.checked || name == 'concernedEstablishments' && form.getValues('selectAllConcernedEstablishments');
                    return (
                        <div key={`item-grp-${i}`} className={`${styles.groupItem} ${!item.active ? styles.itemDesactive : ''} ${i === selectedGroupIndex && hasChildren ? styles.active : ''}`} onClick={() => setselectedGroupIndex(i)}>
                            <Controller
                                control={form.control}
                                render={({ value }) => {
                                    return (
                                        <CheckBox
                                            onChange={e => {
                                                form.setValue(itemFormKey, e.target.checked);

                                                if (!e.target.checked){
                                                    setParentChildrenUnchecked(i);
                                                }

                                                onChange && onChange();
                                            }}
                                            checked={value ? true : false}
                                            disabled={disabled}
                                        >
                                            <div className={styles.groupItemText} onClick={e => hasChildren && e.preventDefault()}>
                                                <span className={`${styles.groupItemLabelText} ${!hasChildren ? styles.groupItemLabelTextSingle : ''}`}>{item.label}</span>
                                                {hasChildren && <span className={styles.groupItemSubLabelText}>{t('common.n_of_n_selected', { value1: getParentChildrenChecked(i), value2: item.children?.length || 0 })}</span>}
                                            </div>
                                        </CheckBox>
                                    );
                                }}
                                name={itemFormKey}
                                defaultValue={defaultValue}
                            />
                        </div>
                    );
                })}
            </div>
            {hasChildren && <div className={styles.children}>

                {!selectedGroupItem?.children?.length && <Label>{t('common.without_elements')}</Label>}

                {selectedGroupItem?.children?.length && selectedGroupItem.children.map((item, i) => {
                    const defaultValue = form.getValues(`${name}[${selectedGroupIndex}].${childrenName}[${i}].checked`) || item.checked || name == 'concernedEstablishments' && form.getValues('selectAllConcernedEstablishments');
                    return (
                        <div key={`item-ch-${selectedGroupIndex}-${i}`} className={styles.childrenItem}>
                            <Controller
                                control={form.control}
                                render={({ value }) => {
                                    return (
                                        <CheckBox
                                            onChange={e => {
                                                form.setValue(`${name}[${selectedGroupIndex}].${childrenName}[${i}].checked`, e.target.checked);
                                                setParentChecked();
                                                onChange && onChange();
                                            }}
                                            label={item.label}
                                            checked={value ? true : false}
                                            disabled={disabled}
                                        />
                                    );
                                }}
                                name={`${name}[${selectedGroupIndex}].${childrenName}[${i}].checked`}
                                defaultValue={defaultValue}
                            />
                        </div>
                    );
                })}
                
            </div>}
        </div>
    );
}

export default memo(CheckBoxGroup);
