import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NumberPerPage.module.scss';
import Select from '../../select/Select';

export type ItemsPerPage = 0 | 10 | 25 | 50 | 100;

interface SelectNumberLabel {
    value: ItemsPerPage;
    label: string;
}

type Props = {
    itemsPerPage?: ItemsPerPage;
    onChangeItemsPerPage: (items: ItemsPerPage) => void;
}

const NumberPerPage: React.FunctionComponent<Props> = ({ itemsPerPage, onChangeItemsPerPage }: Props) => {
    const { t } = useTranslation();

    const PAGINATION_ITEMS_PER_PAGE = [
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
        { value: Number.MAX_SAFE_INTEGER, label: t('pagination.all') }
    ];
    
    return (
        <div className={styles.container}>
            <a className={styles.label}>{t('pagination.show')}</a>
            <div className={styles.input}>
                <Select
                    options={PAGINATION_ITEMS_PER_PAGE}
                    onChange={(data: SelectNumberLabel) => {
                        onChangeItemsPerPage(data.value);
                    }}
                    defaultValue={PAGINATION_ITEMS_PER_PAGE.find(x => x.value === (itemsPerPage??10))}
                    divider={false}
                    height={35}
                />
            </div>
            <a className={styles.label}>{t('pagination.items')}</a>
        </div>
    );
}

export default NumberPerPage;