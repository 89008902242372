import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../common/components/box/Box';
import SubBox from '../../../../common/components/subBox/SubBox';
import { VscListSelection } from 'react-icons/vsc';
import FormItem from '../../../../common/components/formItem/FormItem';
import Label from '../../../../common/components/label/Label';
import InputError from '../../../../common/components/inputError/InputError';
import { Controller, UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectForm, ProjectFormConcernedEstablishment, ProjectFormConcernedPartner, ProjectFormDepartmentsInvolved, ProjectScreenMode } from '../../models/ProjectForm';
import { Col, Row } from 'react-flexbox-grid';
import Select from '../../../../common/components/select/Select';
import styles from './ProjectIdentification.module.scss';
import BoxContent from '../../../../common/components/box/BoxContent';
import CheckBoxGroup, { CheckBoxGroupItem } from '../checkBoxGroup/CheckBoxGroup';
import CheckBox from '../../../../common/components/checkBox/CheckBox';
import { DomainViewModel } from '../../../../api/domains/models/DomainViewModel';
import { PriorityLevelViewModel } from '../../../../api/priorityLevels/models/PriorityLevelViewModel';
import { UserViewModel } from '../../../../api/users/models/UserViewModel';
import { StrategicAxesViewModel } from '../../../../api/strategicAxes/models/StrategicAxesViewModel';
import { SelectValueLabel } from '../../../../common/types/SelectValueLabel';
import { ProductViewModel } from '../../../../api/products/models/ProductViewModel';
import { ProjectProductViewModel, ProjectStrategicAxesViewModel } from '../../../../api/projects/models/ProjectViewModel';
import SelectController from '../../../../common/components/select/SelectController';
import InputResizable from '../../../../common/components/input/InputResizable';
import DateTimePicker from '../../../../common/components/dateTimePicker/DateTimePicker';
import InputGroup from '../../../../common/components/inputGroup/InputGroup';
import { DATE_TIME_FORMAT_DEFAUT } from '../../../../Config';
import DateFormat from '../../../../common/components/dateFormat/dateFormat';

type Props = {
    form: UseFormMethods<ProjectForm>;
    concernedEstablishments: UseFieldArrayMethods<ProjectFormConcernedEstablishment, 'id'>;
    departmentsInvolved: UseFieldArrayMethods<ProjectFormDepartmentsInvolved, 'id'>;
    domains: DomainViewModel[];
    priorityLevels: PriorityLevelViewModel[];
    authors: UserViewModel[];
    strategicAxes: StrategicAxesViewModel[];
    products: ProductViewModel[];
    isDetails: boolean;
    onChangeDomaine: (domainProjectId: string) => void;
    buildInvestmentsTypesAndTotalsByDates: () => void;
    buildInternalAllocationTypesByDates: () => void;
    concernedPartners: UseFieldArrayMethods<ProjectFormConcernedPartner, 'id'>;
    changeProjectDesignation: (designation: string) => void;
    mode: ProjectScreenMode;
};

const ProjectIdentification: React.FC<Props> = ({
    form, concernedEstablishments, departmentsInvolved, domains, priorityLevels, authors, strategicAxes,
    products, isDetails, onChangeDomaine, buildInvestmentsTypesAndTotalsByDates, buildInternalAllocationTypesByDates, concernedPartners, changeProjectDesignation,
    mode
}: Props) => {

    const { t } = useTranslation();
    const { register, errors, control, watch } = form;

    const concernedEstablishmentsItems: CheckBoxGroupItem[] = concernedEstablishments.fields.filter(x => (x.active || (!x.active && x.checked))).map(ce => ({
        id: ce.establishmentId!,
        label: ce.name!,
        active: ce.active,
        checked: ce.checked,
        children: ce.poles?.map(p => ({
            id: p.poleId,
            label: p.name,
            active: p.active,
            checked: p.checked,
        })),
    }));

    const departmentsInvolvedItems: CheckBoxGroupItem[] = departmentsInvolved.fields.filter(x => (x.active || (!x.active && x.checked))).map(ce => ({
        id: ce.id!,
        label: ce.name!,
        active: ce.active!,
        checked: ce.checked,
    }));

    const toggleSelectAllConcernedEstablishments = () => {
        const checkValue = watch<string, boolean>('selectAllConcernedEstablishments');

        for (let i = 0; i < concernedEstablishmentsItems.length; i++) {
            form.setValue(`concernedEstablishments[${i}].checked`, checkValue);
            for (let ii = 0; ii < (concernedEstablishmentsItems[i].children || []).length; ii++) {
                form.setValue(`concernedEstablishments[${i}].poles[${ii}].checked`, checkValue);
            }
        }

        buildInvestmentsTypesAndTotalsByDates();
        buildInternalAllocationTypesByDates();
    }

    const toggleSelectAllDepartmentsInvolved = () => {
        const checkValue = watch<string, boolean>('selectAllDepartmentsInvolved');

        for (let i = 0; i < departmentsInvolvedItems.length; i++) {
            form.setValue(`departmentsInvolved[${i}].checked`, checkValue);
        }
    }

    const domainsOptions: SelectValueLabel[] = (domains || []).map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));
    const priorityLevelsOptions: SelectValueLabel[] = (priorityLevels || []).map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));
    const authorsOptions: SelectValueLabel[] = (authors || []).map(x => ({ value: x.id || '', label: x.realName || '', active: x.active != undefined ? x.active : true }));
    const strategicAxesOptions: SelectValueLabel[] = (strategicAxes || []).map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));
    const productsOptions: SelectValueLabel[] = (products || []).map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));

    const concernedPartnersItems: CheckBoxGroupItem[] = concernedPartners.fields.filter(x => (x.active || (!x.active && x.checked))).map(ce => ({
        id: ce.partnerId!,
        label: ce.name!,
        active: ce.active!,
        checked: ce.checked
    }));

    const toggleSelectAllConcernedPartners = () => {
        const checkValue = watch<string, boolean>('selectAllConcernedPartners');

        for (let i = 0; i < concernedPartnersItems.length; i++) {
            form.setValue(`concernedPartners[${i}].checked`, checkValue);
        }
    }

    const formatDate = () => {
        return form.getValues('updatedDate')?.toString() ?? '';
    }

    return (
        <Box
            title={t('project.project_identification.title')}
            icon={<VscListSelection />}
            contentClassName={styles.boxContent}
            date={
                mode != 'copy' && formatDate() ?
                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: '1rem', fontSize: '0.9rem' }}>
                        <Label className={styles.bold} style={{ margin: 0, padding: 0 }}>{t('project.project_identification.updatedProjectDate')}:&nbsp;</Label>
                        <DateFormat value={formatDate()} format={DATE_TIME_FORMAT_DEFAUT}></DateFormat>
                    </div>
                    : undefined
            }
        // rightHeader={<Badge preset="primaryDark" size="big" className={styles.boxBadge}>Planifié</Badge>}
        >
            <BoxContent>
                <Row>
                    <Col xs={6} lg={2}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_identification.project_identification')}</Label>
                            <InputResizable maxLength={255} name="identification" placeholder={t('project.project_identification.project_identification')} ref={register({ maxLength: 255 })} disabled={isDetails} />
                            <InputError error={errors.identification} />
                        </FormItem>
                    </Col>
                    <Col xs={6} lg>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_identification.project_designation')} {isDetails ? '' : '*'}</Label>
                            <InputResizable maxLength={255} name="designation"
                                placeholder={t('project.project_identification.project_designation')}
                                ref={register({ required: true, maxLength: 255 })}
                                disabled={isDetails}
                                text={changeProjectDesignation}
                            />{/* autoFocus={true} />*/}
                            <InputError error={errors.designation} />
                        </FormItem>
                    </Col>
                    <Col xs={6} lg={4}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_identification.priority_level')}</Label>
                            <SelectController
                                name={'priorityLevelId'}
                                form={form}
                                options={priorityLevelsOptions.filter(x => x.active)}
                                allOptions={priorityLevelsOptions}
                                isClearable
                                isDisabled={isDetails}
                                placeholder={t('project.project_identification.priority_level')}
                            />
                            <InputError error={errors.priorityLevelId} />
                        </FormItem>
                    </Col>
                    <Col xs={6} lg={2}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_identification.author')}</Label>
                            <SelectController
                                name={'authorId'}
                                form={form}
                                options={authorsOptions.filter(x => x.active)}
                                allOptions={authorsOptions}
                                isClearable
                                isDisabled={isDetails}
                                placeholder={t('project.project_identification.author')}
                            />
                            <InputError error={errors.authorId} />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_identification.domain')}</Label>
                            <SelectController
                                name={'domainId'}
                                form={form}
                                options={domainsOptions.filter(x => x.active)}
                                allOptions={domainsOptions}
                                isClearable
                                isDisabled={isDetails}
                                placeholder={t('project.project_identification.domain')}
                                onChange={data => {
                                    onChangeDomaine(data?.value || '')
                                }}
                            />
                            <InputError error={errors.domainId} />
                        </FormItem>
                    </Col>
                    <Col xs={6}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_identification.strategic_axes')}</Label>
                            <Controller
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            options={strategicAxesOptions.filter(x => x.active)}
                                            isClearable
                                            isMulti
                                            placeholder={t('project.project_identification.strategic_axes')}
                                            onChange={(data: SelectValueLabel[]) => {
                                                onChange(data?.map(x => ({ strategicAxisId: x.value })));
                                            }}
                                            value={value ? strategicAxesOptions.filter(x => value.find((y: ProjectStrategicAxesViewModel) => y.strategicAxisId === x.value)) : []}
                                            isDisabled={isDetails}
                                            filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                        />
                                    );
                                }}
                                control={control}
                                name="strategicAxes"
                                defaultValue={[]}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_identification.products')}</Label>
                            <Controller
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            options={productsOptions.filter(x => x.active)}
                                            isClearable
                                            isMulti
                                            placeholder={t('project.project_identification.products')}
                                            onChange={(data: SelectValueLabel[]) => {
                                                onChange(data?.map(x => ({ productId: x.value })));
                                            }}
                                            value={value ? productsOptions.filter(x => value.find((y: ProjectProductViewModel) => y.productId === x.value)) : []}
                                            isDisabled={isDetails}
                                            filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                        />
                                    );
                                }}
                                control={control}
                                name="products"
                                defaultValue={[]}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </BoxContent>
            <SubBox
                title={t('project.project_identification.concerned_establishments')}
                rightHeader={<Controller
                    control={control}
                    name={'selectAllConcernedEstablishments'}
                    render={({ value }) => {
                        return (
                            <CheckBox
                                onChange={e => {
                                    form.setValue('selectAllConcernedEstablishments', e.target.checked);
                                    toggleSelectAllConcernedEstablishments();
                                }}
                                label={t('project.project_identification.all_group')}
                                checked={value ? true : false}
                                disabled={isDetails}
                            />
                        );
                    }}
                    defaultValue={form.getValues('selectAllConcernedEstablishments') ? true : false}
                />}
            >
                <CheckBoxGroup
                    name="concernedEstablishments"
                    childrenName="poles"
                    items={concernedEstablishmentsItems}
                    form={form}
                    hasChildren={true}
                    disabled={isDetails}
                    onChange={() => {

                        if (
                            !!form.getValues('concernedEstablishments').find(x => !x.checked) ||
                            !!form.getValues('concernedEstablishments').find(x => !!x.poles?.find(p => !p.checked))
                        ) {
                            form.setValue('selectAllConcernedEstablishments', false);
                        }

                        buildInvestmentsTypesAndTotalsByDates();
                        buildInternalAllocationTypesByDates();
                    }}
                />
            </SubBox>
            <SubBox
                title={t('project.project_identification.partners')}
                rightHeader={<Controller
                    control={control}
                    name={'selectAllConcernedPartners'}
                    render={({ value }) => {
                        return (
                            <CheckBox
                                onChange={e => {
                                    form.setValue('selectAllConcernedPartners', e.target.checked);
                                    toggleSelectAllConcernedPartners();
                                }}
                                label={t('project.project_identification.select_all')}
                                checked={value ? true : false}
                                disabled={isDetails}
                            />
                        );
                    }}
                    defaultValue={form.getValues('selectAllConcernedPartners') ? true : false}
                />}
            >
                <CheckBoxGroup
                    name="concernedPartners"
                    items={concernedPartnersItems}
                    form={form}
                    disabled={isDetails}
                />
            </SubBox>
            <SubBox
                title={t('project.project_identification.directions_instances_involved')}
                rightHeader={<Controller
                    control={control}
                    name={'selectAllDepartmentsInvolved'}
                    render={({ value }) => {
                        return (
                            <CheckBox
                                onChange={e => {
                                    form.setValue('selectAllDepartmentsInvolved', e.target.checked);
                                    toggleSelectAllDepartmentsInvolved();
                                }}
                                label={t('project.project_identification.select_all')}
                                checked={value ? true : false}
                                disabled={isDetails}
                            />
                        );
                    }}
                    defaultValue={form.getValues('selectAllDepartmentsInvolved') ? true : false}
                />}
            >
                <CheckBoxGroup
                    name="departmentsInvolved"
                    items={departmentsInvolvedItems}
                    form={form}
                    disabled={isDetails}
                />
            </SubBox>
        </Box>
    );
}

export default memo(ProjectIdentification);
