/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { memo } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce/lib';

import { SearchField, SearchType } from '../../../api/projects/models/ProjectsSearchCriteria';
import Input from '../../../common/components/input/Input';
import styles from './ProjectsFiltersInput.module.scss';
import InputRange from '../../../common/components/input/InputRange';
import { useTranslation } from 'react-i18next';

export type Props = {
    searchType: string;
    searchField: string;
    placeholder?: string;
    name: string;
    onChange: () => void;
    formAux: UseFormMethods<any>;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const ProjectsFiltersInput = React.forwardRef<HTMLInputElement, Props>(({ searchType, searchField, name, placeholder, onChange, formAux, ...props }, ref) => {

    const { t } = useTranslation();

    // Debounce callback
    const debounced = useDebouncedCallback(
        // function
        () => {
            onChange();
        },
        // delay in ms
        500
    );

    return (

        <div className={styles.inputGroup}>
            {searchType == SearchType.TEXT ?
                <Input
                    {...props}
                    className={styles.input}
                    name={searchField}
                    placeholder={placeholder ?? name}
                    maxLength={255}
                    ref={formAux.register()}
                    onChange={() => debounced()}
                    style={ { height: '100%' } }
                /> : ''}

            {searchType == SearchType.RANGE_VALUE ?
                <InputRange
                    className={styles.paddingSearch}
                    icon={searchField == SearchField.BUDGETINGS ? '€' :
                        searchField == SearchField.TOTAL_CHARGES ? 'j' :
                            searchField == SearchField.TOTAL_RECURRING ? '€' :
                                searchField == SearchField.SUBVENTIONS_EXPECTED ? '€' :
                                    searchField == SearchField.SUBVENTIONS_OBTAINED ? '€' :
                                        searchField == SearchField.INVESTIMENTS ? '€' : ''}
                    nameValues={searchField == SearchField.BUDGETINGS ? [SearchField.START_BUDGETINGS, SearchField.END_BUDGETINGS] :
                        searchField == SearchField.TOTAL_CHARGES ? [SearchField.START_CHARGES, SearchField.END_CHARGES] :
                            searchField == SearchField.TOTAL_RECURRING ? [SearchField.START_RECURRING, SearchField.END_RECURRING] :
                                searchField == SearchField.INVESTIMENTS ? [SearchField.START_INVESTIMENTS, SearchField.END_INVESTIMENTS] :
                                    searchField == SearchField.SUBVENTIONS_EXPECTED ? [SearchField.START_SUBVENTIONS_EXPECTED, SearchField.END_SUBVENTIONS_EXPECTED] :
                                        searchField == SearchField.SUBVENTIONS_OBTAINED ? [SearchField.START_SUBVENTIONS_OBTAINED, SearchField.END_SUBVENTIONS_OBTAINED] : []}
                    placeholderValues={[t('common.from'), t('common.to')]}
                    maxLength={255}
                    ref={formAux.register()}
                    onChange={() => debounced()} />
                : ''}
        </div>

    );
});

export default memo(ProjectsFiltersInput);
