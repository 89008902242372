import { CSSProperties } from 'react';
import { TableColumn, TableRow } from './Table';

class TableUtils {

    public getRowCellStyle(col: TableColumn, row?: TableRow): CSSProperties {
        return {
            textAlign: col.cellAlignment || 'left',
            ...this.getCellWidthStyle(col),
            ...(col.cellStyle ? (typeof col.cellStyle === 'function' && row ? col.cellStyle({ column: col, row }) : col.cellStyle) : {}),
        }
    }

    public getCellWidthStyle(col: TableColumn) {
        const result: any = {};
        if (col.width != undefined && col.width != null) {
            if (col.width === 'fill') {
                result['flex'] = 1;
            }
            else if (typeof col.width === 'string') {
                result['flex'] = `0 0 ${col.width}`;
            } else {
                result['width'] = ((col.width * 100) / 20) + '%';
            }
        }
        if (col.minWidth != undefined && col.minWidth != null) {
            result['minWidth'] = col.minWidth + 'px';
        }
        return result;
    }

}

export default new TableUtils();
