import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { PoleSearchCriteria } from './models/PoleSearchCriteria';
import { PoleViewModel } from './models/PoleViewModel';

class PoleService {

    public getList(criteria: PoleSearchCriteria) {
        return Http.get<Page<PoleViewModel>>('poles', criteria);
    }

    public get(id?: string) {
        return Http.get<PoleViewModel>('poles/' + id);
    }

    public save(model: PoleViewModel) {
        return !!model.id ? Http.put('poles', model) : Http.post('poles', model);
    }

    public remove(model: PoleViewModel) {
        return  Http.put('poles/deactivate', model);
    }
}

export default new PoleService();