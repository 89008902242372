import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { StrategicAxesSearchCriteria } from './models/StrategicAxesSearchCriteria';
import { StrategicAxesViewModel } from './models/StrategicAxesViewModel';

class StrategicAxesService {
    
    public getList(criteria: StrategicAxesSearchCriteria) {
        return Http.get<Page<StrategicAxesViewModel>>('strategicAxes', criteria);
    }

    public getStrategicAxesById(id: string) {
        return Http.get<StrategicAxesViewModel>('strategicAxes/'+ id);
    }

    public save(model: StrategicAxesViewModel) {
        return !!model.id ? Http.put('strategicAxes', model) : Http.post('strategicAxes', model);
    }

    public remove(model: StrategicAxesViewModel) {
        return  Http.put('strategicAxes/deactivate', model);
    }
}

export default new StrategicAxesService();