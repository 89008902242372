import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { StatusViewModel } from '../../api/status/models/StatusViewModel';
import StatusService from '../../api/status/StatusService';
import Button from '../../common/components/button/Button';
import FormItem from '../../common/components/formItem/FormItem';
import Input from '../../common/components/input/Input';
import InputError from '../../common/components/inputError/InputError';
import Label from '../../common/components/label/Label';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import Loading from '../../common/services/Loading';
import styles from './StatusScreen.module.scss';
import ColorPicker from '../../common/components/colorPicker/ColorPicker';
import TextareaAutosize from 'react-autosize-textarea/lib';

enum ScreenType {
    NEW,
    EDIT,
    DETAILS
}

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    statusId?: string;
    afterSave: () => void;
    isDetails: boolean;
};

type Form = {
    name: string;
    color: string;
}

const StatusScreen: React.FC<Props> = ({ groupId, statusId, afterSave, isDetails, userCanRead, userCanWrite }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, setValue } = useForm<Form>();
    const [screenMode, setScreenMode] = useState<ScreenType>(isDetails && !!statusId ? ScreenType.DETAILS : !!statusId ? ScreenType.EDIT : ScreenType.NEW);
    const [itemToRemove, setItemToRemove] = useState<StatusViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const colorDefault = '#ffffff';
    const [palleteColor, setPalleteColor] = useState(false);
    const [color, setColor] = useState(colorDefault);

    const [description, setDescription] = useState<string>();


    const onCancel = () => {
        afterSave();
    }

    const onSubmit = async ({ name, color }: Form) => {
        try {

            if (!name) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();
            await StatusService.save({ id: statusId, name: name, color: color, groupId: groupId, description });
            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            onCancel();

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getStatus = async () => {
        try {
            Loading.show();
            const result = await StatusService.get(statusId);
            if (result) {
                setValue('name', result?.name || '');
                setValue('color', result?.color || '');
                setColor(result?.color || colorDefault);
                setDescription(result.description ?? '');
            }
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getPalleteColor = () => {
        setPalleteColor(!palleteColor);
    };

    const onChangeColor = (color: string) => {
        setColor(color || colorDefault);
    }

    const openPallete = (open: boolean) => {
        setPalleteColor(open);
    };

    const showRemoveItemDialog = (item: StatusViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await StatusService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onCancel();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onGoBack = async () => {
        if (screenMode === ScreenType.EDIT && !!isDetails) {
            setScreenMode(ScreenType.DETAILS);
            getStatus();
        } else {
            onCancel()
        }
    };

    useEffect(() => {
        if (userCanRead) {
            if (statusId) {
                getStatus();
            }
        }
    }, [statusId]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.name')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Input maxLength={255} name="name" placeholder={t('common.name')} ref={register({ required: true, maxLength: 255 })} disabled={screenMode === ScreenType.DETAILS} onClick={() => setPalleteColor(false)} />
                    <InputError error={errors.name} />
                </FormItem>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.color')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Input className={styles.inputColor} readOnly style={{ backgroundColor: color }} value={color} onClick={() => getPalleteColor()} maxLength={255} name="color" placeholder={t('common.color')} ref={register({ required: true, maxLength: 255 })} disabled={screenMode === ScreenType.DETAILS} />
                    {palleteColor && screenMode !== ScreenType.DETAILS && <ColorPicker value={color} onOpenPallete={openPallete} onChangeColor={onChangeColor} />}
                </FormItem>
            </div>

            <div className={styles.formLine}>
                <FormItem className={styles.formItem}>
                    <Label className={styles.bold}>{t('common.description')}</Label>
                    <TextareaAutosize
                        style={{ resize: 'none' }}
                        name='description'
                        value={description}
                        disabled={screenMode === ScreenType.DETAILS}
                        className={`${styles.input} ${styles.inputDefault} ${screenMode === ScreenType.DETAILS ? styles.inputDisabled : ''}`}
                        onChange={e => {
                            setDescription(e.currentTarget.value);
                        }}
                        rows={2}
                    />
                </FormItem>
            </div>
            {screenMode !== ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={() => onGoBack()} />
                <Button type="submit" text={t('common.save')} size={'small'} />
            </div>}
            {screenMode === ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.back')} preset={'secondary'} size={'small'} onClick={() => { onCancel() }} />
                {userCanWrite && <Button type="button" text={t('common.remove')} preset={'danger'} size={'small'} onClick={() => showRemoveItemDialog({ id: statusId } as StatusViewModel)} />}
                {userCanWrite && <QuestionYesNo message={t('common.messages.remove_record')}
                    isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />}
                {userCanWrite && <Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
            </div>}
        </form>
    );
}

export default StatusScreen;
