import React, { useState } from 'react';

import ListDomainsScreen from '../../domains/list/ListDomainsScreen';
import DomainsScreen from '../../domains/DomainsScreen';
import { DomainViewModel } from '../../../api/domains/models/DomainViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_DOMAINS,
    DOMAIN
}

const ParametersDomainsScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_DOMAINS);
    const [currentDomain, setCurrentDomain] = useState<DomainViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_DOMAINS && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.DOMAIN_PROJECTS}/> }
            { screen === ScreenType.LIST_DOMAINS && <ListDomainsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewDomain={() => {
                    setIsDetails(false);
                    setCurrentDomain(null);
                    setScreen(ScreenType.DOMAIN)
                }}
                onClickItem={(item: DomainViewModel, isDetails: boolean) => {
                    if(item.active)
                    {
                        setIsDetails(isDetails);
                        setCurrentDomain(item);
                        setScreen(ScreenType.DOMAIN);
                    }
                }}
            ></ListDomainsScreen>}
            { screen === ScreenType.DOMAIN && <DomainsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                domainId={currentDomain?.id}
                afterSave={() => {
                    setCurrentDomain(null);
                    setScreen(ScreenType.LIST_DOMAINS);
                }}></DomainsScreen>}
        </div>

    );
}

export default ParametersDomainsScreen;
