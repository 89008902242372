import ReactStars from 'react-stars'
import React from 'react'
import styles from './RatingStars.module.scss'
import colors from '../../../styles/export/colors.module.scss'

type Props = {
    value: number;
    isEdit: boolean;
    onChange: (result: number) => void
};

const RatingStars: React.FC<Props> = ({ value, isEdit, onChange }: Props) => {
    return (
        <ReactStars 
            className={styles.content}
            count={5}
            onChange={(onChange)}
            size={24}
            color1={colors.veryLightGrey}
            color2={colors.primary} //color when is selected
            half={false}
            edit={isEdit} 
            value={value}
            />
    );

    
}

export default RatingStars;
