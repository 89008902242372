import React, { memo, ReactElement } from 'react';
import styles from './ProjectsTimesTable.module.scss';
import { useTranslation } from 'react-i18next';
import { EqualHeightElement } from 'react-equal-height';
import ProjectsTimesTableLine from '../ProjectTimesTableLine/ProjectsTimesTableLine';
import ProjectsTimesTablePhaseLine from '../ProjectTimesTablePhaseLine/ProjectsTimesTablePhaseLine';
import Utils from '../../../common/services/Utils';
import { ProjectTimesDto } from '../../../api/projectsTimes/models/ProjectTimesDto';
import { Filters } from '../../dasboard/components/ProjectAdvancements/ProjectAdvancementsTableFilters/ProjectAdvancementsTableFilters';
import { TABLE_EQUAL_HEIGHT_NAMES } from '../../../common/components/table/Table';

type Props = {
    projects: ProjectTimesDto[];
    reRenderContentPhases: number;
    searchComponent?: ReactElement;
    onExpandUsersClick?: (projectIndex?: number, phaseIndex?: number) => void;
    filters?: Filters;
    onRefresh: () => void;
    userCanWriteTimes?: boolean;

} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectsTimesTable: React.FC<Props> = ({
    projects, reRenderContentPhases, searchComponent, onExpandUsersClick, filters, onRefresh, userCanWriteTimes, ...props
}: Props) => {

    const { t } = useTranslation();

    return (
        <div {...props} id={'ProjectsTimessTable'}>
            <EqualHeightElement name={TABLE_EQUAL_HEIGHT_NAMES.ROW_COLUMN_NAMES}>
                <div className={styles.flexRow}>
                    <div className={styles.flexRowColumns} style={Utils.calculateCellWidthStyle(40)}>
                        {t('projectstimes.table.responsable_dsi')}
                    </div>
                    <div className={styles.flexRowColumns} style={Utils.calculateCellWidthStyle(80)}>
                        {t('projectstimes.table.planned_realized')}
                    </div>
                </div>
            </EqualHeightElement>
            <EqualHeightElement name={TABLE_EQUAL_HEIGHT_NAMES.ROW_FILTERS}>
                <div className={styles.header}>
                    {searchComponent}
                </div>
            </EqualHeightElement>
            <div className={styles.content}>
                {projects.map((item, i) => {
                    return (<div key={`rt-row-${i}`}>
                        <div>
                            <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.ROW_INDEX}${i}`}>
                                <ProjectsTimesTableLine project={item} key={`${TABLE_EQUAL_HEIGHT_NAMES.ROW_INDEX}${i}`} />
                            </EqualHeightElement>
                        </div>
                        <div key={'contentPhasesTable_' + reRenderContentPhases}>
                            {item.showSubRows && item.phases && item.phases.map((phase, ix) => {
                                return (
                                    <EqualHeightElement key={`rt-row-sub-${ix}-${i}`} name={`${TABLE_EQUAL_HEIGHT_NAMES.SUB_ROW_INDEX}${i}-${ix}`}>
                                        <ProjectsTimesTablePhaseLine
                                            key={`${i}-${ix}`}
                                            phase={phase}
                                            phaseIndex={ix}
                                            projectIndex={i}
                                            onExpandUsersClick={onExpandUsersClick}
                                            filters={filters}
                                            onRefresh={onRefresh}
                                            userCanWriteTimes={userCanWriteTimes} 
                                        />
                                    </EqualHeightElement>
                                )
                            })} </div>
                    </div>
                    )
                })}

                {projects && !projects.length && <div>
                    <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.NO_ELEMENTS}`}>
                        <div className={`${styles.row} ${styles.noResultsText}`}>{t('common.no_results')} </div>
                    </EqualHeightElement>
                </div>}
            </div>
        </div>
    );
}

export default memo(ProjectsTimesTable);
