import React, { useEffect, useRef, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import styles from './ColorPicker.module.scss';

type Props = {
    value: string;
    onChangeColor: (color: string) => void;
    onOpenPallete: (open: boolean) => void;
};

const ColorPicker: React.FC<Props> = ({ value, onChangeColor, onOpenPallete }: Props) => {

    const [color, setColor] = useState<string>(value);
    const ref = useRef<HTMLDivElement>(null);

    const changeColor = (data: ColorResult) => {
      setColor(data.hex);
      onChangeColor && onChangeColor(data.hex);
    };

    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOpenPallete && onOpenPallete(false);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);

    return (
      <div ref={ref}> 
        <SketchPicker className={styles.pallete} color={color} onChange={changeColor} onChangeComplete={changeColor} /> 
      </div>
    );
}

export default ColorPicker;