import React, { useState } from 'react';

import ListRolesScreen from '../../roles/list/ListRolesScreen';
import RolesScreen from '../../roles/RolesScreen';
import { RoleViewModel } from '../../../api/roles/models/RoleViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_ROLES,
    ROLE
}

const ParametersRolesScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_ROLES);
    const [currentRole, setCurrentRole] = useState<RoleViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_ROLES && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.ROLES}/> }
            { screen === ScreenType.LIST_ROLES && <ListRolesScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewRole={() => {
                    setIsDetails(false);
                    setCurrentRole(null);
                    setScreen(ScreenType.ROLE)
                }}
                onClickItem={(item: RoleViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentRole(item);
                    setScreen(ScreenType.ROLE);
                }}
            ></ListRolesScreen>}
            { screen === ScreenType.ROLE && <RolesScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                roleId={currentRole?.id}
                afterSave={() => {
                    setCurrentRole(null);
                    setScreen(ScreenType.LIST_ROLES);
                }}></RolesScreen>}
        </div>

    );
}

export default ParametersRolesScreen;
