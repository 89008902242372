import React, { memo, ReactElement, useState } from 'react';
import { IconType } from 'react-icons';
import Label from '../label/Label';
import styles from './Box.module.scss';
import BoxContent from './BoxContent';
import { useTranslation } from 'react-i18next';

export type Props = {
    title: string;
    icon?: React.ReactElement<IconType> | undefined;
    opened?: boolean | undefined;
    contentClassName?: string;
    rightHeader?: ReactElement;
    date?: React.ReactElement<IconType> | undefined;
    setIsOpenedFromParent?: (isOpened: boolean) => void;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Box: React.FC<Props> = ({ children, contentClassName, rightHeader, date, setIsOpenedFromParent, opened, icon, title, ...props }: Props) => {

    const { t } = useTranslation();
    const [showContent, setShowContent] = useState(opened?? true); 

    const toggle = () => {
        setShowContent(!showContent)
        setIsOpenedFromParent && setIsOpenedFromParent(!showContent);
    }

    return (
        <div {...props}>
            <div className={styles.header} onClick={toggle}>
                <div className={styles.shadowBall}></div>
                <div className={styles.labelContainer}>
                    {icon && <span className={styles.labelHeader && styles.iconHeader}>{icon}</span>}
                    <Label className={styles.labelHeader}>
                        {title}
                    </Label>
                </div>
                {rightHeader && <div>
                    {rightHeader}
                </div>}
                {date && date}
                <span className={styles.labelClose}>{t(showContent ? 'common.close' : 'common.open')}</span>
            </div>
            {showContent && <BoxContent className={`${contentClassName || ''}`}>
                {children}
            </BoxContent>}
        </div>
    );
}

export default memo(Box);
