import Http from '../../common/services/Http';
import { UserProfile } from './models/UserProfile';
import { LoginViewModel } from './models/LoginViewModel';
import { PasswordResetTokenViewModel } from './models/PasswordResetTokenViewModel';
import { PasswordResetViewModel } from './models/PasswordResetViewModel';

class AccountService {

    public login(model: LoginViewModel) {
        return Http.post<UserProfile>('account/token', model);
    }

    public generateResetPasswordCode(model: PasswordResetTokenViewModel) {
        return Http.post('account/generate-reset-password-code', model);
    }

    public resetPassword(model: PasswordResetViewModel) {
        return Http.post('account/reset-password', model);
    }
}

export default new AccountService();
