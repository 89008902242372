import Http from '../../common/services/Http';
import { IdNameDto } from '../common/models/IdNameDto';
import { Page } from '../common/types/Page';
import { RoleDto } from './models/RoleDto';
import { RoleSearchCriteria } from './models/RoleSearchCriteria';
import { RoleViewModel } from './models/RoleViewModel';
import { CheckNameAvailabilityViewModel } from './models/CheckNameAvailabilityViewModel';

class RolesService {
    
    public getList(criteria: RoleSearchCriteria) {
        return Http.get<Page<RoleDto>>('roles', criteria);
    }

    public getRoleById(id: string) {
        return Http.get<RoleDto>('roles/'+ id);
    }

    public save(model: RoleViewModel) {
        return !!model.id ? Http.put('roles', model) : Http.post('roles', model);
    }

    public remove(model: RoleViewModel) {
        return  Http.put('roles/deactivate', model);
    }

    public getRoles(groupId?: string) {
        return Http.get<IdNameDto[]>('roles/get-all-roles-for-select-item/' + groupId);
    }

    public checkNameAvailability(model: CheckNameAvailabilityViewModel) {
        return Http.post<any>('roles/check-name-availability', model);
    }
}

export default new RolesService();



