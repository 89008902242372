import React, { CSSProperties, ReactElement } from 'react';
import styles from './Table.module.scss';
import MoneyFormat from '../moneyFormat/MoneyFormat';
import { Property } from 'csstype'
import { useTranslation } from 'react-i18next';
import DateFormat from '../dateFormat/dateFormat';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { EqualHeightElement } from 'react-equal-height';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import ReactTooltip from 'react-tooltip';
import TableUtils from './TableUtils';
import Utils from '../../services/Utils';

export const TABLE_EQUAL_HEIGHT_NAMES = {
    ROW_FILTERS: 'rowFilters',
    ROW_COLUMN_NAMES: 'rowColumNames',
    ROW_INDEX: 'eh-row-',
    SUB_ROW_INDEX: 'eh-sub-row-',
    FOOTER_ROW_INDEX: 'f-eh-row-',
    NO_ELEMENTS: 'row-no-elements'
}

export type TableCellFormat = 'default' | 'money' | 'date';

export interface TableCell {
    column: TableColumn;
    row: TableRow;
}

export interface TableColumn {
    field: string;
    fields?: string[];
    name: string;
    renderCell?: (cell: TableCell) => ReactElement;
    cellAlignment?: Property.TextAlign;
    cellStyle?: CSSProperties | ((cell: TableCell) => CSSProperties);
    cellFormat?: TableCellFormat;
    cellFormatUnitByField?: string;
    columnCellAlignment?: Property.TextAlign;
    width?: 'fill' | number | string;
    minWidth?: number | string;
    preventClick?: boolean;
    order?: string;
    isActionColumn?: boolean;
    showAlways?: boolean;
    searchType?: string;
    searchField?: string;
    searchFields?: string[];
    defaultSearchValue?: string[];
    resizable?: boolean;
    isDefault?: boolean;
    placeholder?: string;
    extraArgs?: any;
    onlyOneRow?: boolean;
    render?: () => ReactElement;
}

export interface TableRow {
    [field: string]: any;
    showSubRows?: boolean;
}

interface Props {
    columns: TableColumn[];
    rows: TableRow[];
    allowHover?: boolean;
    onRowClick?: (row: TableRow, rowIndex?: number) => void;
    onOrderList?: (column: TableColumn) => void;
    searchComponent: any;
    subRowsFieldName?: string;
    subRowsColumns?: TableColumn[];
    footerRows?: TableRow[];
    footerColumns?: TableColumn[];
    onExpandClick?: (row: TableRow, rowIndex?: number) => void;
    onSubRowClick?: (row: TableRow, rowIndex?: number) => void;
    orderBy?: string;
    orderColumn?: string;
    isSubRowOpen?: (row: TableRow, rowIndex?: number) => boolean;
}

const Table: React.FunctionComponent<Props> = ({ columns, rows, allowHover,
    onRowClick, onOrderList, searchComponent, subRowsFieldName, subRowsColumns, onExpandClick, onSubRowClick, orderBy, orderColumn,
    isSubRowOpen, footerRows, footerColumns
}: Props) => {

    const { t } = useTranslation();

    const getColumnCellStyle = (col: TableColumn) => {
        return {
            textAlign: col.columnCellAlignment || 'left',
            ...TableUtils.getCellWidthStyle(col),
        }
    }

    const getRowCellValue = (row: TableRow, col: TableColumn) => {
        if (col.renderCell) {
            return col.renderCell({ row, column: col });
        }

        const value = row[col.field];

        if (col.cellFormat && col.cellFormat === 'money') {
            return <MoneyFormat value={value} suffix={col.cellFormatUnitByField ? row[col.cellFormatUnitByField] : null} />;
        }

        if (col.cellFormat && col.cellFormat === 'date') {
            return <DateFormat value={value} />;
        }

        if (col.onlyOneRow) {
            return !!value ? value : <span>&nbsp;</span>;
        } else {
            return !!value ? value : <span>&nbsp;</span>;
        }
    }

    const getRowCellValueTooltip = (row: TableRow, col: TableColumn) => {
        if (col.renderCell) {
            return col.renderCell({ row, column: col });
        }
        const value = row[col.field];

        if (col.cellFormat && col.cellFormat === 'money') {
            return <MoneyFormat value={value} suffix={col.cellFormatUnitByField ? row[col.cellFormatUnitByField] : null} />;
        }

        if (col.cellFormat && col.cellFormat === 'date') {
            return <DateFormat value={value} />;
        }

        return !!value ? value : null;//<span>&nbsp;</span>;
    }

    const renderColumn = (rowIndex: number, colIndex: number, row: TableRow, col: TableColumn) => {
        const drawExpand = columns && columns.length - 1 == colIndex && subRowsFieldName && subRowsColumns && subRowsColumns.length > 0 &&
            row[subRowsFieldName] && row[subRowsFieldName].length > 0;
        return (
            <div
                key={`row-cell-${rowIndex}-${colIndex}`} className={styles.rowCell}
                style={TableUtils.getRowCellStyle(col, row)}
                onClick={drawExpand ? (e: any) => {
                    e.stopPropagation();
                    !col.preventClick && onExpandClick && onExpandClick(row, rowIndex)
                } : undefined}
            >
                <div className={styles.contentTd}>
                    {getRowCellValue(row, col)}
                </div>
                { columns && columns.length - 1 == colIndex && subRowsFieldName && subRowsColumns && subRowsColumns.length > 0 && <div className={styles.expandCell}>
                    {drawExpand && <div>
                        {(isSubRowOpen ? !isSubRowOpen(row, rowIndex) : !row.showSubRows) && <GoTriangleDown className={styles.iconExpand}></GoTriangleDown>}
                        {(isSubRowOpen ? isSubRowOpen(row, rowIndex) : row.showSubRows) && <GoTriangleUp className={styles.iconExpand}></GoTriangleUp>}
                    </div>}
                </div>}
            </div>
        )
    };

    const renderTable = () => (
        <div className={styles.table}>
            <EqualHeightElement name={TABLE_EQUAL_HEIGHT_NAMES.ROW_COLUMN_NAMES}>
                <div className={styles.columns}>
                    {columns.map((col, colIndex) => (
                        <div key={`col-${colIndex}`} className={!col.isActionColumn ? styles.columnCell : styles.columnCellAction} style={getColumnCellStyle(col)}
                            onClick={() => !col.preventClick && onOrderList && onOrderList(col)}>
                            {col.name}
                            {!col.isActionColumn && orderColumn == col.order && <span>
                                {(!orderBy || orderBy === 'asc') && <FaSortAlphaDown className={styles.iconOrder}></FaSortAlphaDown>}
                                {orderBy === 'desc' && <FaSortAlphaUp className={styles.iconOrder}></FaSortAlphaUp>}
                            </span>
                            }
                        </div>
                    ))}
                </div>
            </EqualHeightElement>
            <div className={styles.rows}>
                <div className={`${styles.rowFilter}`}>
                    <EqualHeightElement name={TABLE_EQUAL_HEIGHT_NAMES.ROW_FILTERS}>
                        {searchComponent}
                    </EqualHeightElement>
                </div>
                {rows.map((row, rowIndex) => (
                    <div key={`row-${rowIndex}`}>
                        <div>
                            <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.ROW_INDEX}${rowIndex}`}>
                                <div className={`${styles.row} ${allowHover && styles.rowHover} ${Boolean(onRowClick) && styles.rowClickable}`} onClick={() => onRowClick && onRowClick(row, rowIndex)}>
                                    {columns.map((col, colIndex) => renderColumn(rowIndex, colIndex, row, col))}
                                </div>
                            </EqualHeightElement>
                        </div>
                        {subRowsFieldName && subRowsColumns && (isSubRowOpen ? isSubRowOpen(row, rowIndex) : row.showSubRows) && <div>
                            {row[subRowsFieldName].map((subRow: TableRow, subRowIndex: number) => (
                                <EqualHeightElement key={`sub-row-${rowIndex}-${subRowIndex}`} name={`${TABLE_EQUAL_HEIGHT_NAMES.SUB_ROW_INDEX}${rowIndex}-${subRowIndex}`}>
                                    <div className={`${styles.row} ${allowHover && styles.rowHover} ${Boolean(onSubRowClick) && styles.rowClickable}`}>
                                        {subRowsColumns.map((col: TableColumn, colIndex: number) => (
                                            <div data-tip={getRowCellValueTooltip(subRow, col)} data-for={`global-${subRowIndex}-${colIndex}`}
                                                key={`sub-row-cell-${subRowIndex}-${colIndex}`}
                                                className={`${styles.rowCell} ${styles.flexEnd}`}
                                                onClick={() => !col.preventClick && onSubRowClick && onSubRowClick(subRow)}
                                                style={TableUtils.getRowCellStyle(col, row)}>
                                                <div className={`${styles.contentTd} ${styles.tooltip}`}>
                                                    {getRowCellValue(subRow, col)}
                                                    <ReactTooltip id={`global-${subRowIndex}-${colIndex}`} type='info' getContent={(dataTip: string) => <span>{dataTip}</span>}></ReactTooltip>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </EqualHeightElement>
                            ))}
                        </div>}
                    </div>
                ))}
                {(footerRows || []).map((row, rowIndex) => (
                    <div key={`row-${rows.length + rowIndex}`}>
                        <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.FOOTER_ROW_INDEX}${rowIndex}`}>
                            <div
                                className={`${styles.row} ${allowHover && styles.rowHover} ${Boolean(onRowClick) && styles.rowClickable}`}
                                onClick={() => onRowClick && onRowClick(row, rows.length + rowIndex)}
                            >
                                {(footerColumns || []).map((col, colIndex) => renderColumn(rows.length + rowIndex, colIndex, row, col))}
                            </div>
                        </EqualHeightElement>
                    </div>
                ))}
                {Boolean(rows.length <= 0 && (footerRows || []).length <= 0) && <div>
                    <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.NO_ELEMENTS}`}>
                        <div className={`${styles.row} ${styles.noResultsText}`}>{t('common.no_results')} </div>
                    </EqualHeightElement>
                </div>}
            </div>
        </div>
    );

    return (
        <div className={styles.tables}>
            {renderTable()}
        </div>
    );
}

export default Table;
