import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../common/components/box/Box';
import SubBox from '../../../../common/components/subBox/SubBox';
import { VscListSelection } from 'react-icons/vsc';
import FormItem from '../../../../common/components/formItem/FormItem';
import Label from '../../../../common/components/label/Label';
import InputError from '../../../../common/components/inputError/InputError';
import { Controller, UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../models/ProjectForm';
import Input from '../../../../common/components/input/Input';
import SelectController from '../../../../common/components/select/SelectController';
import { Col, Row } from 'react-flexbox-grid';
import Select from '../../../../common/components/select/Select';
import styles from './ProjectOrganizationAndConduct.module.scss';
import BoxContent from '../../../../common/components/box/BoxContent';
import { SelectValueLabel } from '../../../../common/types/SelectValueLabel';
import { IoAddCircleOutline, IoTrash } from 'react-icons/io5';
import Button from '../../../../common/components/button/Button';
import { DateMode, ProjectPhaseViewModel, RiskLevel } from '../../../../api/projects/models/ProjectViewModel';
import { StatusViewModel } from '../../../../api/status/models/StatusViewModel';
import { RelatedProjectViewModel } from '../../../../api/projects/models/ProjectViewModel';
import { ProjectsListDto } from '../../../../api/projects/models/ProjectsListDto';
import { SourceViewModel } from '../../../../api/sources/models/SourceViewModel';
import ProjectScreenFormUtil from '../../utils/ProjectScreenFormUtil';
import { ProjectManagerViewModel } from '../../../../api/projectManagers/models/ProjectManagerViewModel';
import { ProjectResponsibleViewModel } from '../../../../api/projectResponsibles/models/ProjectResponsibleViewModel';
import ProjectEvaluationScheduleBox from './components/projectEvaluationScheduleBox/ProjectEvaluationScheduleBox';
import IconCalendar from '../../../../assets/svg/icon_calendar.svg';
import DateTimePicker from '../../../../common/components/dateTimePicker/DateTimePicker';
import { FaCalendarAlt } from 'react-icons/fa';
import InputGroup from '../../../../common/components/inputGroup/InputGroup';
import { TypologiesViewModel } from '../../../../api/typologies/models/TypologiesViewModel';
import SummernoteController from '../../../../common/components/summernoteController/SummernoteController'
import { ProjectResponsibleDsiViewModel } from '../../../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiViewModel';
import QuestionYesNo from '../../../../common/components/questionYesNo/QuestionYesNo';
import Semaphore from '../../../projects/components/Semaphore/Semaphore';
import Loading from '../../../../common/services/Loading';
import { useSelector } from 'react-redux';
import { UserProfile } from '../../../../api/account/models/UserProfile';
import { POLICIES } from '../../../../Config';
import { Reducers } from '../../../../store/types';

type Props = {
    form: UseFormMethods<ProjectForm>;
    isDetails: boolean;
    phases: UseFieldArrayMethods<ProjectPhaseViewModel, 'id'>;
    sources: SourceViewModel[];
    relatedProjects: ProjectsListDto[];
    status: StatusViewModel[];
    projectManagers: ProjectManagerViewModel[];
    projectResponsibles: ProjectResponsibleViewModel[];
    dateMode: string;
    dateFormat: string,
    buildInvestmentsTypesAndTotalsByDates: () => void;
    buildInternalAllocationTypesByDates: () => void;
    buildPhasesByDates: (unapplySetValues: boolean) => string[];
    typologies: TypologiesViewModel[];
    projectResponsiblesDsi: ProjectResponsibleDsiViewModel[];
};

const ProjectOrganizationAndConduct: React.FC<Props> = ({
    form, isDetails, phases, sources, relatedProjects, status, projectManagers, projectResponsibles,
    dateMode, dateFormat, buildInvestmentsTypesAndTotalsByDates, buildInternalAllocationTypesByDates, buildPhasesByDates, typologies, projectResponsiblesDsi
}: Props) => {

    const { t } = useTranslation();
    const { register, errors, control } = form;

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const userCanReadTimes = !!loggedUser?.policies.find(x => x == POLICIES.COLLABORATORS_TIMES_READ || x == POLICIES.COLLABORATORS_TIMES_WRITE);
    const userCanWriteTimes = !!loggedUser?.policies.find(x => x == POLICIES.COLLABORATORS_TIMES_WRITE);

    const sourcesOptions: SelectValueLabel[] = (sources || []).map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));
    let statusOptions: SelectValueLabel[] = (status || []).map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));
    if (!statusOptions.find(x => x.value == form.getValues('statusId'))) {
        statusOptions = [
            ...statusOptions,
            { value: form.getValues('statusId'), label: form.getValues('statusName') ?? '' }
        ].sort((a, b) => a.label.localeCompare(b.label));
    }
    const relatedProjectsOptions: SelectValueLabel[] = (relatedProjects || []).map(x => ({ value: x.id || '', label: x.designation || '', active: x.active != undefined ? x.active : true }));
    const projectManagersOptions: SelectValueLabel[] = projectManagers.map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));
    const projectResponsiblesOptions: SelectValueLabel[] = projectResponsibles.map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));
    const typologiesOptions: SelectValueLabel[] = typologies.map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));
    const projectResponsiblesDsiOptions: SelectValueLabel[] = projectResponsiblesDsi.map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));

    const [questionDeleteResponsibleIsOpen, setQuestionDeleteResponsibleIsOpen] = React.useState(false);
    const [questionDeletePhaseIsOpen, setQuestionDeletePhaseIsOpen] = React.useState(false);
    const [phaseToRemove, setPhaseToRemove] = useState<number>();


    const addPhases = () => {
        const startDate = form.watch('startDate');
        const endDate = form.watch('endDate');
        const phasesFields = form.watch<string, ProjectPhaseViewModel[]>('phases');
        phases.append(ProjectScreenFormUtil.createNewPhase(startDate, endDate, dateMode as DateMode, dateFormat, phasesFields.length + 1));
        buildPhasesByDates(false);
    }

    const removePhases = (index: number) => {
        if (index != null) {
            const phaseGuid = form.watch<string, ProjectPhaseViewModel[]>('phases')[index].guid;
            const oldPhases = form.getValues('phases');
            if (!!oldPhases.find(o => {
                return o.guid == phaseGuid && !!o.dates.find(d => {
                    return !!d.periodsItems?.find(p => {
                        return !!p.provisionalSchedules?.find(ps => {
                            return !!ps.estimatedTime;
                        })
                    })
                })
            })) {
                setQuestionDeletePhaseIsOpen(true);
            }
            else {
                removePhase(index);
            }
        }
    }

    const removePhase = (index: number) => {
        phases.remove(index);
        const phasesFields = form.watch<string, ProjectPhaseViewModel[]>('phases')?.filter(x => x.name && x.name.length > 0) || [];
        phasesFields.forEach((x, index) => {
            x.order = index + 1;
            form.setValue(`phases[${index}].order`, x.order);
        });
    }

    const risk = form.watch<string, RiskLevel>('riskLevel');

    return (
        <Box
            title={t('project.project_organization.title')}
            icon={<VscListSelection />}
            contentClassName={styles.boxContent}
        >
            <BoxContent>
                <Row>
                    <Col xs={6}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.project_manager')}</Label>
                            <SelectController
                                name="projectManagerId"
                                form={form}
                                options={projectManagersOptions.filter(x => x.active)}
                                allOptions={projectManagersOptions}
                                isClearable
                                placeholder={t('project.project_organization.project_manager')}
                                isDisabled={isDetails}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={6}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.project_manager_responsibles')}</Label>
                            <Controller
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            options={projectResponsiblesOptions.filter(x => x.active)}
                                            isClearable
                                            isMulti
                                            placeholder={t('project.project_organization.project_manager_responsibles')}
                                            onChange={(data: SelectValueLabel[]) => {
                                                onChange(data?.map(x => ({ projectResponsibleId: x.value })));
                                            }}
                                            value={value ? projectResponsiblesOptions.filter((x: any) => value.find((y: any) => y.projectResponsibleId === x.value)) : []}
                                            isDisabled={isDetails}
                                            filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                        />
                                    );
                                }}
                                control={control}
                                name="responsibles"
                                defaultValue={[]}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.project_status')} {isDetails ? '' : '*'}</Label>
                            <SelectController
                                name="statusId"
                                form={form}
                                rules={{ required: true }}
                                options={statusOptions.filter(x => x.active)}
                                allOptions={statusOptions}
                                isClearable
                                placeholder={t('project.project_organization.project_status')}
                                isDisabled={isDetails}
                            />
                            <InputError error={errors.statusId} />
                        </FormItem>
                    </Col>
                    <Col xs={6}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.project_source')}</Label>
                            <SelectController
                                name="sourceId"
                                form={form}
                                options={sourcesOptions.filter(x => x.active)}
                                allOptions={sourcesOptions}
                                isClearable
                                placeholder={t('project.project_organization.project_source')}
                                isDisabled={isDetails}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.risk')}</Label>
                            <div className={styles.riskContainer}>
                                <Semaphore singleLight={true} allowClick={!isDetails} selectedItems={[risk]} onChange={(items: string[]) => {
                                    form.setValue('riskLevel', (!!items && items.length) > 0 ? items[0] : null);
                                }} />
                                <div className={styles.riskDescription}>
                                    <Input
                                        maxLength={255}
                                        name={'riskDescription'}
                                        defaultValue={form.getValues('riskDescription') ?? ''}
                                        placeholder={t('project.project_organization.description')}
                                        ref={register({ maxLength: 255, required: false })}
                                        disabled={isDetails}
                                    />
                                </div>
                            </div>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.typology')}</Label>
                            <SelectController
                                name="typologyId"
                                form={form}
                                options={typologiesOptions.filter(x => x.active)}
                                allOptions={typologiesOptions}
                                isClearable
                                placeholder={t('project.project_organization.typology')}
                                isDisabled={isDetails}
                            />
                            <InputError error={errors.typologyId} />
                        </FormItem>
                    </Col>
                    <Col xs={6}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.project_manager_responsibles_dsi')}</Label>
                            <Controller
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            options={projectResponsiblesDsiOptions.filter(x => x.active)}
                                            isClearable
                                            isMulti
                                            placeholder={t('project.project_organization.project_manager_responsibles_dsi')}
                                            onChange={(data: SelectValueLabel[]) => {

                                                Loading.show();
                                                const result = data?.map(x => ({ projectResponsibleDsiId: x.value }));

                                                onChange(result);

                                                const aux = buildPhasesByDates(true);
                                                if (aux.length > 0) {
                                                    setTimeout(() => {
                                                        Loading.hide();
                                                    });
                                                    setQuestionDeleteResponsibleIsOpen(true);
                                                } else {
                                                    buildPhasesByDates(false);
                                                    setTimeout(() => {
                                                        Loading.hide();
                                                    });
                                                }

                                            }}
                                            value={value ? projectResponsiblesDsiOptions.filter((x: any) => value.find((y: any) => y.projectResponsibleDsiId === x.value)) : []}
                                            isDisabled={isDetails}
                                            filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                        />
                                    );
                                }}
                                control={control}
                                name="responsiblesDsi"
                                defaultValue={[]}
                            />
                        </FormItem>
                    </Col>

                    <QuestionYesNo message={t('project.project_organization.responsible_has_planned_times_message')}
                        isVisible={questionDeleteResponsibleIsOpen}
                        onYes={() => {
                            setQuestionDeleteResponsibleIsOpen(false);
                            Loading.show();
                            buildPhasesByDates(false);
                            setTimeout(() => {
                                Loading.hide();
                            });
                        }}
                        onNo={() => {
                            setQuestionDeleteResponsibleIsOpen(false);
                            Loading.show();
                            const aux = buildPhasesByDates(true);
                            form.setValue('responsiblesDsi', [
                                ...form.watch('responsiblesDsi'),
                                ...aux?.map(x => ({ projectResponsibleDsiId: x }))
                            ]);
                            setTimeout(() => {
                                Loading.hide();
                            });

                        }} />

                    <QuestionYesNo
                        message={t('project.project_organization.phase_has_planned_times_message')}
                        isVisible={questionDeletePhaseIsOpen}
                        onYes={() => {
                            setQuestionDeletePhaseIsOpen(false);
                            if (phaseToRemove != null) {
                                removePhase(phaseToRemove);
                            }
                        }}
                        onNo={() => {
                            setQuestionDeletePhaseIsOpen(false);
                        }} />

                </Row>
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.deadlines')}</Label>
                            <SummernoteController name="deadlines" isDisabled={isDetails} form={form} placeholder={t('project.project_organization.deadlines')} />
                            <InputError error={errors.deadlines} />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.related_projects')}</Label>
                            <Controller
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            options={relatedProjectsOptions.filter(x => x.active)}
                                            isClearable
                                            isMulti
                                            placeholder={t('project.project_organization.related_projects')}
                                            onChange={(data: SelectValueLabel[]) => {
                                                onChange(data?.map(x => ({ relatedProjectId: x.value })));
                                            }}
                                            value={value ? relatedProjectsOptions.filter(x => value.find((y: RelatedProjectViewModel) => y.relatedProjectId === x.value)) : []}
                                            isDisabled={isDetails}
                                            filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                        />
                                    );
                                }}
                                control={control}
                                name="relatedProjects"
                                defaultValue={[]}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </BoxContent>
            <SubBox
                title={t('project.project_organization.implementation_process_methodology')}
            >
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.implementation_methods')}</Label>
                            <SummernoteController name="implementationMethods" isDisabled={isDetails} form={form} placeholder={t('project.project_organization.implementation_methods')} />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_organization.observations_impacts_organization')}</Label>
                            <SummernoteController name="observationsImpactsOrganization" isDisabled={isDetails} form={form} placeholder={t('project.project_organization.observations_impacts_organization')} />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <Label className={styles.title}>
                            <span className={styles.line}>{t('project.project_organization.project_phases')}</span>
                        </Label>
                    </Col>
                </Row>
                <FormItem style={{ marginBottom: '0px' }}>
                    <Row>
                        {phases.fields.length > 0 && phases.fields.sort(x => Number(x.order)).map((item, i) => {
                            const name = `phases[${i}].name`;
                            return (
                                <Col xs={6} key={item.id} style={{ marginBottom: '10px' }}>
                                    <Row>
                                        <Col xs={11} style={{ paddingBottom: '10px' }}>
                                            <Label className={styles.bold}>{t('project.project_organization.phase')} {i + 1} {isDetails ? '' : '*'}</Label>
                                            <Input
                                                maxLength={255}
                                                name={name}
                                                defaultValue={form.getValues(name)}
                                                placeholder={t('project.project_organization.phase')}
                                                ref={register({ maxLength: 255, required: true })}
                                                disabled={isDetails}
                                                onChange={aux => {
                                                    form.setValue(name, aux.target.value);
                                                }}
                                            />

                                        </Col>
                                        <Col xs={1} style={{ paddingLeft: '0px', paddingBottom: '10px', alignSelf: 'center', textAlign: 'center', marginTop: '15px' }}>
                                            {!isDetails &&
                                                <Button type="button" preset={'secondary'} size="small" onlyIcon={true} onClick={() => {
                                                    removePhases(i);
                                                    setPhaseToRemove(i);
                                                }}>
                                                    <IoTrash />
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <InputError error={errors.phases?.find((x, index) => index == i)?.name} />
                                    </Row>
                                </Col>
                            )
                        })}
                        {isDetails && phases.fields.length == 0 &&
                            <Col xs>
                                <div className={styles.withoutElements}>{t('common.without_elements')}</div>
                            </Col>
                        }
                    </Row>
                </FormItem>
                <Row style={{ marginBottom: '10px' }}>
                    <Col xs={12}>
                        {!isDetails && <Button size={'small'} className={styles.addPhaseButton} onClick={addPhases}>
                            <IoAddCircleOutline className={styles.addPhaseButtonIcon} size={24} />
                            <span className={styles.addPhaseButtonText}>{t('project.project_organization.add_phase')}</span>
                        </Button>
                        }
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <Col xs>
                        <Label className={styles.title}>
                            <span className={styles.line}>{t('project.project_organization.planning')}</span>
                        </Label>

                    </Col>
                </Row>
                <Row>
                    <Col xs={6} lg={3}>
                        <FormItem>
                            <Label>{t('project.project_evaluation.project_start_date')} {isDetails ? '' : '*'}</Label>
                            <DateTimePicker
                                locale='fr'
                                placeholderText={t('project.project_evaluation.project_start_date')}
                                onChange={(date: Date) => {
                                    form.setValue('startDate', date);
                                    buildInvestmentsTypesAndTotalsByDates();
                                    buildInternalAllocationTypesByDates();
                                    buildPhasesByDates(false);
                                }}
                                selected={form.watch('startDate')}
                                disabled={isDetails}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                name="startDate"
                                maxDate={form.watch('endDate')}
                            />
                            <InputError error={errors.startDate} />
                        </FormItem>
                    </Col>
                    <Col xs={6} lg={3}>
                        <FormItem>
                            <Label>{t('project.project_evaluation.project_end_date')} {isDetails ? '' : '*'}</Label>
                            <DateTimePicker
                                locale='fr'
                                placeholderText={t('project.project_evaluation.project_end_date')}
                                onChange={(date: Date) => {
                                    form.setValue('endDate', date);
                                    buildInvestmentsTypesAndTotalsByDates();
                                    buildInternalAllocationTypesByDates();
                                    buildPhasesByDates(false);
                                }}
                                selected={form.watch('endDate')}
                                disabled={isDetails}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                name="endDate"
                                minDate={form.watch('startDate')}
                            />
                            <InputError error={errors.endDate} />
                        </FormItem>
                    </Col>
                </Row>
                <ProjectEvaluationScheduleBox
                    style={{ marginBottom: '20px' }}
                    title={t('project.project_organization.phase_schedule')}
                    icon={<img src={IconCalendar} />}
                    form={form}
                    phases={phases}
                    isDetails={isDetails}
                    projectResponsiblesDsiOptions={projectResponsiblesDsiOptions}
                    userCanReadTimes={userCanReadTimes}
                    userCanWriteTimes={userCanWriteTimes}
                />

            </SubBox>
            <SubBox
                title={t('project.project_organization.market_technologies_solutions')}
            >
                <FormItem>
                    <Label className={styles.bold}>{t('project.project_organization.description')}</Label>
                    <SummernoteController name="technologiesSolutionsDescription" isDisabled={isDetails} form={form} placeholder={t('project.project_organization.description')} />
                </FormItem>
            </SubBox>
        </Box>
    );
}

export default memo(ProjectOrganizationAndConduct);
