import React, { memo } from 'react';
import styles from './ProjectAdvancementTableLine.module.scss';
import { ScrollSyncPane } from 'react-scroll-sync';
import { ProjectAdvancementDate } from '../../ProjectAdvancements/ProjectAdvancements';


type Props = {
    dates: ProjectAdvancementDate[];
    widthItems: string
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectAdvancementTableLine: React.FC<Props> = ({
    widthItems, dates, ...props
}: Props) => {
    const resultOfCheckeds = dates.filter(x => x.checked == true);
    return (
        <div {...props} className={styles.container}>
            <ScrollSyncPane>
                <div className={`${styles.areaValue} ${styles.scrollPane}`}>
                    {dates.map((date, dateIndex) => {
                        return (
                            <div
                                key={dateIndex}
                                className={styles.boxValues}
                                style={{ width: widthItems }}
                            >
                                <div className={`${styles.monthsContainer}`}>
                                    <div className={`${styles.monthItem} 
                                                     ${date.checked ? styles.checked : ''} 
                                                     ${!!resultOfCheckeds[0] && resultOfCheckeds[0].date == date.date && !date.filteredStart ? styles.borderRadiusLeft : ''}
                                                     ${!!resultOfCheckeds[resultOfCheckeds.length - 1] && resultOfCheckeds[resultOfCheckeds.length - 1].date == date.date && !date.filteredEnd ? styles.borderRadiusRight : ''}
                                                     ${!!date.filteredStart && styles.alignContinueLeft}
                                                     ${!!date.filteredEnd && styles.alignContinueRight}
                                                     `}>
                                        {date.checked && (date.filteredEnd || date.filteredStart) && <span className={styles.continue}>...</span>}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </ScrollSyncPane>
        </div>
    );
}

export default memo(ProjectAdvancementTableLine);
