import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import styles from './ParametersScreen.module.scss';
import Accordion, { AccordionItem } from '../../common/components/accordion/Accordion';
import Tabs from '../../common/components/tabs/Tabs';
import Button from '../../common/components/button/Button';
import Loading from '../../common/services/Loading';
import { useToasts } from 'react-toast-notifications';
import ParametersEstablishmentsScreen from './components/ParametersEstablishmentsScreen';
import ParametersUsersScreen from './components/ParametersUsersScreen';
import GroupService from '../../api/groups/GroupService';
import { IdNameDto, IdNameWithChildsDto } from '../../api/common/models/IdNameDto';
import GroupsScreen from '../groups/GroupsScreen';
import EstablishmentsScreen from '../establishments/EstablishmentsScreen';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import Text from '../../common/components/text/Text';
import ParametersDomainsScreen from './components/ParametersDomainsScreen';
import ParametersStrategicAxesScreen from './components/ParametersStrategicAxesScreen';
import ParametersPolesScreen from './components/ParametersPolesScreen';
import ParametersPriorityLevelsScreen from './components/ParametersPriorityLevelsScreen';
import ParametersStatusScreen from './components/ParametersStatusScreen';
import ParametersProductsScreen from './components/ParametersProductsScreen';
import ParametersDepartmentsScreen from './components/ParametersDepartmentsScreen';
import ParametersEvaluationMethodologiesScreen from './components/ParametersEvaluationMethodologiesScreen';
import ParametersSuccessIndicatorsScreen from './components/ParametersSuccessIndicatorsScreen';
import Box from '../../common/components/box/Box';
import { FaStream } from 'react-icons/fa';
import colors from '../../styles/export/colors.module.scss';
import Panel from '../../common/components/panel/Panel';
import ParametersSourcesScreen from './components/ParametersSourcesScreen';
import ParametersProjectManagerScreen from './components/ParametersProjectManagersScreen';
import ParametersProjectResponsibleScreen from './components/ParametersProjectResponsiblesScreen';
import ParametersScoresScreen from './components/ParametersScoresScreen';
import ParametersQualityQuestionsScreen from './components/ParametersQualityQuestionsScreen';
import ParametersRolesScreen from './components/ParametersRolesScreen';
import ParametersPoliciesScreen from './components/ParametersPoliciesScreen';
import { useSelector } from 'react-redux';
import { UserProfile } from '../../api/account/models/UserProfile';
import { Reducers } from '../../store/types';
import { POLICIES, ROLES } from '../../Config';
import ParametersPartnersScreen from './components/ParametersPartnersScreen';
import ParametersTypologiesScreen from './components/ParametersTypologiesScreen';
import ParametersProjectResponsibleDsiScreen from './components/ParametersProjectResponsiblesDsiScreen';
import ParametersPacksScreen from './components/ParametersPacksScreen';
import ParametersLdapsScreen from './components/ParametersLdapsScreen';
import ParametersLoginLogsScreen from './components/ParametersLoginLogsScreen';

type Props = {

};

enum ScreenType {
    PRINCIPAL_PANEL,
    NEW_GROUP
}

enum TabMode {
    GENERAL,
    GROUP,
    ESTABLISHMENT,
    LOGIN_LOGS
}

enum TabSection {
    IDENTIFICATION_PROJECT,
    DEFINITION_PROJECT,
    PROJECT_ORGANIZATION_AND_CONDUCT,
    EVALUATION_OF_PROJECT
}


const ParametersScreen: React.FC<Props> = ({ }: Props) => {

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const userCanRead = !!loggedUser?.policies.find(x => x == POLICIES.PARAMETERS_READ || x == POLICIES.PARAMETERS_WRITE || x == POLICIES.GROUP_WRITE);
    const userCanWrite = !!loggedUser?.policies.find(x => x == POLICIES.PARAMETERS_WRITE);
    const userCanWriteGroups = !!loggedUser?.policies.find(x => x == POLICIES.GROUP_WRITE);
    const userCanWriteStatus = !!loggedUser?.policies.find(x => x == POLICIES.STATUS_WRITE);
    const userCanWriteUsers = !!loggedUser?.policies.find(x => x == POLICIES.PARAMETERS_WRITE || x == POLICIES.GROUP_WRITE);
    const userCanSeeLoginLogs = !!loggedUser?.roles.find(x => x == ROLES.ADMINISTRATOR) || !!loggedUser?.policies.find(x => x == POLICIES.SETTINGUP_LOGIN_LOGS_READ);

    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [screen, setScreen] = useState<ScreenType>(ScreenType.PRINCIPAL_PANEL);
    const [accordionItems, setAccordionItems] = useState<AccordionItem[]>([]);
    const [tabMode, setTabMode] = useState<TabMode | null>(TabMode.GENERAL);

    const accordionItemDefault: AccordionItem = { id: 'GENERAL', text: t('parameters.general'), disabled: false, childs: [], onClick: () => setTab(TabMode.GENERAL) };
    const accordionItemLoginLog: AccordionItem = { id: 'LOGIN_LOG', text: t('parameters.login_logs'), disabled: false, childs: [], onClick: () => setTab(TabMode.LOGIN_LOGS) };

    const [activeAccordionItemId, setActiveAccordionItemId] = useState<string | null>(null);
    const [currentGroupId, setCurrentGroupId] = useState<string>();

    const getTabItems = (tab: TabMode, section?: TabSection) => {
   
        if (tab === TabMode.GENERAL) {
            return [
                { id: 'tab-status-general', title: t('parameters.status'), content: <ParametersStatusScreen userCanRead={userCanWriteStatus} userCanWrite={userCanWriteStatus}></ParametersStatusScreen> },
            ].sort((a, b) => a.title.localeCompare(b.title));
        }
        if (tab === TabMode.GROUP) {
            if (section == TabSection.IDENTIFICATION_PROJECT) {
                return [
                    { id: 'tab-priority_levels', title: t('parameters.priority_level'), content: <ParametersPriorityLevelsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersPriorityLevelsScreen> },
                    { id: 'tab-domains', title: t('parameters.domains'), content: <ParametersDomainsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersDomainsScreen> },
                    { id: 'tab-strategic-axes', title: t('parameters.strategic_axes'), content: <ParametersStrategicAxesScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersStrategicAxesScreen> },
                    { id: 'tab-products', title: t('parameters.products'), content: <ParametersProductsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersProductsScreen> },
                    { id: 'tab-establishments', title: t('parameters.establishments'), content: <ParametersEstablishmentsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId} onRefreshAccordion={() => getGroups()}></ParametersEstablishmentsScreen> },
                    { id: 'tab-departments', title: t('parameters.directions_instances'), content: <ParametersDepartmentsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersDepartmentsScreen> },
                    { id: 'tab-partners', title: t('parameters.partners'), content: <ParametersPartnersScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersPartnersScreen> }
                ].sort((a, b) => a.title.localeCompare(b.title));
            }
            if (section == TabSection.DEFINITION_PROJECT) {
                return [
                    { id: 'tab-quality-questions', title: t('parameters.quality_questions'), content: <ParametersQualityQuestionsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersQualityQuestionsScreen> }
                ].sort((a, b) => a.title.localeCompare(b.title));
            }
            if (section == TabSection.PROJECT_ORGANIZATION_AND_CONDUCT) {
                return [
                    { id: 'tab-status', title: t('parameters.status'), content: <ParametersStatusScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersStatusScreen> },
                    { id: 'tab-source', title: t('parameters.source'), content: <ParametersSourcesScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersSourcesScreen> },
                    { id: 'tab-project-managers', title: t('parameters.project_managers'), content: <ParametersProjectManagerScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersProjectManagerScreen> },
                    { id: 'tab-project-responsibles', title: t('parameters.project_responsibles'), content: <ParametersProjectResponsibleScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersProjectResponsibleScreen> },
                    { id: 'tab-typologies', title: t('parameters.typologies'), content: <ParametersTypologiesScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersTypologiesScreen> },
                    { id: 'tab-project-responsibles-dsi', title: t('parameters.project_responsibles_dsi'), content: <ParametersProjectResponsibleDsiScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersProjectResponsibleDsiScreen> },
                ].sort((a, b) => a.title.localeCompare(b.title));
            }
            if (section == TabSection.EVALUATION_OF_PROJECT) {
                return [
                    { id: 'tab-success-indicators', title: t('parameters.success_indicators'), content: <ParametersSuccessIndicatorsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersSuccessIndicatorsScreen> },
                    { id: 'tab-evaluations', title: t('parameters.evaluation_methodologies'), content: <ParametersEvaluationMethodologiesScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersEvaluationMethodologiesScreen> },
                    { id: 'tab-project-scores', title: t('parameters.project_score'), content: <ParametersScoresScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersScoresScreen> },
                ].sort((a, b) => a.title.localeCompare(b.title));
            }
            return [
                { id: 'tab-details-group', title: t('parameters.group.details_group'), content: <GroupsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId} showPanel={false} onGoList={() => getGroups()} afterRemove={() => onBackNewGroup()} /> },
                { id: 'tab-users', title: t('parameters.users'), content: <ParametersUsersScreen userCanRead={userCanRead} userCanWrite={userCanWriteUsers} groupId={currentGroupId}></ParametersUsersScreen> },
                { id: 'tab-roles', title: t('parameters.roles'), content: <ParametersRolesScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersRolesScreen> },
                { id: 'tab-policies', title: t('parameters.policies.policies'), content: <ParametersPoliciesScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersPoliciesScreen> },
                { id: 'tab-packs-group', title: t('parameters.group.packs'), content: <ParametersPacksScreen userCanRead={userCanRead} userCanWrite={userCanWriteGroups} groupId={currentGroupId}></ParametersPacksScreen> },
                { id: 'tab-ldaps', title: t('parameters.ldaps'), content: <ParametersLdapsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId}></ParametersLdapsScreen> },
            ].sort((a, b) => a.title.localeCompare(b.title));
        }
        if (tab === TabMode.ESTABLISHMENT) {
            if (section == TabSection.IDENTIFICATION_PROJECT) {
                return !activeAccordionItemId ? [] : [
                    { id: 'tab-poles', title: t('parameters.poles'), content: <ParametersPolesScreen userCanRead={userCanRead} userCanWrite={userCanWrite} establishmentId={activeAccordionItemId}></ParametersPolesScreen> }
                ].sort((a, b) => a.title.localeCompare(b.title));
            }
            return !activeAccordionItemId ? [] : [
                { id: 'tab-details-estabilishments', title: t('parameters.establishment.details_establishment'), content: <EstablishmentsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId} establishmentId={activeAccordionItemId} isDetails={false} isDetailsToTab={true} afterSave={() => getGroups()} /> },
                { id: 'tab-users', title: t('parameters.users'), content: <ParametersUsersScreen userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={currentGroupId} establishmentId={activeAccordionItemId}></ParametersUsersScreen> },
            ].sort((a, b) => a.title.localeCompare(b.title));
        }
        if (tab === TabMode.LOGIN_LOGS) {
            return [
                { id: 'tab-login-logs', title: t('parameters.login_logs_connections'), content: <ParametersLoginLogsScreen userCanRead={userCanSeeLoginLogs}/> },
            ].sort((a, b) => a.title.localeCompare(b.title));
        }
        return [];
    }

    const [activeTabId, setActiveTabId] = useState<string | undefined>();
    const [activeTabIdentificationProjectId, setActiveTabIdentificationProjectId] = useState<string | undefined>();
    const [activeTabDefinitionProjectId, setActiveTabDefinitionProjectId] = useState<string | undefined>();
    const [activeTabProjectOrganizationId, setActiveProjectOrganizationTabId] = useState<string | undefined>();
    const [activeTabEvaluationProjectId, setActiveTabEvaluationProjectId] = useState<string | undefined>();

    const [isOpenedTabentificationProject, setIsOpenedTabentificationProject] = useState<boolean>(false);
    const [isOpenedTabProjectDefinition, setIsOpenedTabProjectDefinition] = useState<boolean>(false);
    const [isOpenedTabProjectOrganization, setIsOpenedTabProjectOrganization] = useState<boolean>(false);
    const [isOpenedTabEvaluationProject, setIsOpenedTabEvaluationProject] = useState<boolean>(false);

    const setTab = (mode: TabMode) => {
        setTabMode(null);
        setActiveTabId(undefined);
        setActiveTabIdentificationProjectId(undefined);
        setActiveProjectOrganizationTabId(undefined);
        setActiveTabEvaluationProjectId(undefined);
        setTimeout(() => {
            setTabMode(mode);
        }, 0);
    }

    const getGroups = async (firstTime?:boolean) => {
        try {
            Loading.show();
            
            let groups: IdNameWithChildsDto[] = [];
            
            if(!!loggedUser?.groupId){
                const result = await GroupService.getGroupWithEstablishments(loggedUser?.groupId);
                groups = [result];
            }else{
                groups = await GroupService.getAllGroupsForSelectItemWithEstablishments();
            }

            const refreshAcordionItems: AccordionItem[] = [];
            if (userCanWriteStatus) {
                refreshAcordionItems.push(accordionItemDefault);
                setActiveAccordionItemId(accordionItemDefault.id);
            }
            
            groups.map((hg: IdNameWithChildsDto) => {
                const item: AccordionItem = { id: hg.id, text: hg.name, disabled: false, onClick: () => { setCurrentGroupId(hg.id); setTab(TabMode.GROUP); }, childs: [] };
                hg.childs && hg.childs.map((h: IdNameDto) => {
                    item.childs.push({ id: h.id, text: h.name, disabled: false, onClick: () => setTab(TabMode.ESTABLISHMENT), childs: [] });
                });
                refreshAcordionItems.push(item);
            });

            if(userCanSeeLoginLogs){
                refreshAcordionItems.push(accordionItemLoginLog);
            }
            setAccordionItems(refreshAcordionItems.filter(item => !item.disabled));
            
            if (!userCanWriteStatus && firstTime) {
                setActiveAccordionItemId(refreshAcordionItems[0].id);
                setCurrentGroupId(refreshAcordionItems[0].id);
                setTab(TabMode.GROUP);
            }

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const onClickNewGroup = () => {
        setScreen(ScreenType.NEW_GROUP);
        const newGroup: AccordionItem = { id: 'new', text: t('parameters.group.new_group'), disabled: true, childs: [] };
        accordionItems.push(newGroup);
        setAccordionItems(accordionItems);
        setActiveAccordionItemId(newGroup.id);
    };

    const onAccordionItemClick = (item: AccordionItem) => {
        if (!item.disabled) {
            setActiveAccordionItemId(item.id);
            setAccordionItems(accordionItems.filter(item => !item.disabled));
            setScreen(ScreenType.PRINCIPAL_PANEL);
        }
    }

    const onBackNewGroup = () => {
        getGroups();
        setScreen(ScreenType.PRINCIPAL_PANEL);
        // setTabMode(TabMode.GENERAL);
        setActiveAccordionItemId(accordionItemDefault.id);
        setTab(TabMode.GENERAL);
    };

    useEffect(() => {
        getGroups(true);
    }, []);

    return (
        <ScreenTitle title={t('parameters.title')}>
            <ScreenContainer>
                <div className={styles.toolbar}>
                    <Text preset="screenHeaderTitle" className={styles.toolbarTitle}>{t('parameters.title')}</Text>
                    {userCanWriteGroups && tabMode !== TabMode.LOGIN_LOGS && <Button preset="primary" className={styles.toolbarButton} onClick={() => onClickNewGroup()}>{t('parameters.group.new_group')}</Button>}
                </div>
                <div className={styles.left}>
                    <div>
                        {activeAccordionItemId && <Accordion items={accordionItems} activeItemId={activeAccordionItemId} onClickItem={onAccordionItemClick} />}
                    </div>
                </div>
                <div className={styles.right}>
                    {activeAccordionItemId && screen === ScreenType.PRINCIPAL_PANEL && <div>
                        {currentGroupId && tabMode == TabMode.GROUP && <div>
                            <Panel isOptions={false}>
                                <Tabs items={getTabItems(tabMode)} activeTabId={activeTabId} onChange={tab => setActiveTabId(tab.id)}></Tabs>
                            </Panel>
                            <br />
                            <Box opened={isOpenedTabentificationProject} setIsOpenedFromParent={(isopen: boolean) => setIsOpenedTabentificationProject(isopen)} title={t('project.project_identification.project_identification')} icon={<FaStream color={colors.primary} />} contentClassName={styles.boxContentMinHeight} >
                                <Tabs items={getTabItems(tabMode, TabSection.IDENTIFICATION_PROJECT)} activeTabId={activeTabIdentificationProjectId} onChange={tab => setActiveTabIdentificationProjectId(tab.id)}></Tabs>
                            </Box>
                            <br />
                            <Box opened={isOpenedTabProjectDefinition} setIsOpenedFromParent={(isopen: boolean) => setIsOpenedTabProjectDefinition(isopen)} title={t('project.project_definition.title')} icon={<FaStream color={colors.primary} />} contentClassName={styles.boxContentMinHeight}>
                                <Tabs items={getTabItems(tabMode, TabSection.DEFINITION_PROJECT)} activeTabId={activeTabDefinitionProjectId} onChange={tab => setActiveTabDefinitionProjectId(tab.id)}></Tabs>
                            </Box>
                            <br />
                            <Box opened={isOpenedTabProjectOrganization} setIsOpenedFromParent={(isopen: boolean) => setIsOpenedTabProjectOrganization(isopen)} title={t('project.project_organization.title')} icon={<FaStream color={colors.primary} />} contentClassName={styles.boxContentMinHeight}>
                                <Tabs items={getTabItems(tabMode, TabSection.PROJECT_ORGANIZATION_AND_CONDUCT)} activeTabId={activeTabProjectOrganizationId} onChange={tab => setActiveProjectOrganizationTabId(tab.id)}></Tabs>
                            </Box>
                            <br />
                            <Box opened={isOpenedTabEvaluationProject} setIsOpenedFromParent={(isopen: boolean) => setIsOpenedTabEvaluationProject(isopen)} title={t('project.project_evaluation.title')} icon={<FaStream color={colors.primary} />} contentClassName={styles.boxContentMinHeight}>
                                <Tabs items={getTabItems(tabMode, TabSection.EVALUATION_OF_PROJECT)} activeTabId={activeTabEvaluationProjectId} onChange={tab => setActiveTabEvaluationProjectId(tab.id)}></Tabs>
                            </Box>
                        </div>}
                        {tabMode == TabMode.ESTABLISHMENT && <div>
                            <Panel isOptions>
                                <Tabs items={getTabItems(tabMode)} activeTabId={activeTabId} onChange={tab => setActiveTabId(tab.id)}></Tabs>
                            </Panel>
                            <br />
                            <Box opened={isOpenedTabentificationProject} setIsOpenedFromParent={(isopen: boolean) => setIsOpenedTabentificationProject(isopen)} title={t('project.project_identification.project_identification')} icon={<FaStream color={colors.primary} />} contentClassName={styles.boxContentMinHeight}>
                                <Tabs items={getTabItems(tabMode, TabSection.IDENTIFICATION_PROJECT)} activeTabId={activeTabIdentificationProjectId} onChange={tab => setActiveTabIdentificationProjectId(tab.id)}></Tabs>
                            </Box>
                        </div>
                        }
                        {tabMode == TabMode.GENERAL && userCanWriteStatus &&
                            <Panel isOptions>
                                <Tabs items={getTabItems(tabMode)} activeTabId={activeTabId} onChange={tab => setActiveTabId(tab.id)}></Tabs>
                            </Panel>
                        }
                        {tabMode == TabMode.LOGIN_LOGS && userCanSeeLoginLogs &&
                            <Panel isOptions>
                                <Tabs items={getTabItems(tabMode)} activeTabId={activeTabId} onChange={tab => setActiveTabId(tab.id)}></Tabs>
                            </Panel>
                        }
                        <br />
                    </div>}
                    {screen === ScreenType.NEW_GROUP && <GroupsScreen userCanRead={userCanRead} userCanWrite={userCanWrite} showPanel={true} onGoList={() => onBackNewGroup()} />}
                </div>

            </ScreenContainer>
        </ScreenTitle>
    );
}

export default ParametersScreen;