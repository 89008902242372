import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { TypologiesSearchCriteria } from './models/TypologiesSearchCriteria';
import { TypologiesViewModel } from './models/TypologiesViewModel';

class TypologiesService {
    
    public getList(criteria: TypologiesSearchCriteria) {
        return Http.get<Page<TypologiesViewModel>>('typologies', criteria);
    }

    public getTypologiesById(id: string) {
        return Http.get<TypologiesViewModel>('typologies/'+ id);
    }

    public save(model: TypologiesViewModel) {
        return !!model.id ? Http.put('typologies', model) : Http.post('typologies', model);
    }

    public remove(model: TypologiesViewModel) {
        return  Http.put('typologies/deactivate', model);
    }
    
}

export default new TypologiesService();