import React, { memo } from 'react';
import styles from './ProjectBudgetings.module.scss';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../../../models/ProjectForm';
import { ProjectBudgetingViewModel } from '../../../../../../api/projects/models/ProjectViewModel';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from '../../../../../../common/components/formItem/FormItem';
import Label from '../../../../../../common/components/label/Label'
import Button from '../../../../../../common/components/button/Button';
import { IoAddCircleOutline, IoTrash } from 'react-icons/io5';
import InputResizable from '../../../../../../common/components/input/InputResizable';
import InputGroupController from '../../../../../../common/components/inputGroup/InputGroupController';


type Props = {
    isDetails: boolean;
    form: UseFormMethods<ProjectForm>;
    budgetings: UseFieldArrayMethods<ProjectBudgetingViewModel, 'id'>;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectBudgetings: React.FC<Props> = ({
    isDetails, form, budgetings
}: Props) => {

    const { t } = useTranslation();
    const { register } = form;

    const addBudgeting = () => {
        budgetings.append({});
    }
    const removeBudgeting = (index: number) => {
        budgetings.remove(index);
    }

    return (
        <FormItem>
            {budgetings.fields.map((item, i) => {
                const nameInput = `budgetings[${i}].name`;
                const valueInput = `budgetings[${i}].value`;
                return (
                    <div className={styles.row} key={i}>
                        <div className={styles.col} style={{ flex: 1 }}>
                            <Label className={styles.bold}>{t('project.project_definition.roi_nature')}</Label>
                            <InputResizable
                                maxLength={255}
                                name={nameInput}
                                defaultValue={item.name}
                                placeholder={t('project.project_definition.roi_nature')}
                                ref={register({ maxLength: 255 })}
                                disabled={isDetails}
                            />
                        </div>
                        <div className={styles.col}>
                            <Label className={styles.bold}>{t('project.project_definition.amount')}</Label>
                            <InputGroupController
                                type="number"
                                name={valueInput}
                                form={form}
                                disabled={isDetails}
                                format="money"
                                text={t('common.euro')}
                            />
                        </div>
                        {!isDetails && <div className={styles.col} style={{ marginTop: '32px' }}>
                            <Button type="button" preset={'secondary'} size="small" onlyIcon={true} onClick={() => removeBudgeting(i)}>
                                <IoTrash />
                            </Button>
                        </div>}
                    </div>
                )
            })}
            {budgetings.fields.length == 0 &&
                <Row style={{ marginBottom: '10px' }}>
                    <Col xs>
                        <div className={styles.withoutElements}>{t('common.without_elements')}</div>
                    </Col>
                </Row>
            }
            <Row >
                <Col xs={12}>
                    {!isDetails && <Button size={'small'} className={styles.addButton} onClick={addBudgeting}>
                        <IoAddCircleOutline className={styles.addButtonIcon} size={24} />
                        <span className={styles.addButtonText}>{t('common.add')}</span>
                    </Button>
                    }
                </Col>
            </Row>
        </FormItem>
    );
}

export default memo(ProjectBudgetings);
