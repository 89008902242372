import React from 'react';
import InputHourGroup, { Props as InputHourGroupProps } from './InputHourGroup';
import { Controller } from 'react-hook-form';

type Props = InputHourGroupProps & {
    name: string;
    form: any;
    hideMessageLength?: boolean;
};

const InputHourGroupController = ({ name, form, hideMessageLength, onValueChange, ...props }: Props, ref: any) => {
    return (
        <Controller
            control={form.control}
            name={name}
            render={({ onChange: controllerOnChange, value }) => {
                return (
                    <InputHourGroup
                        hideMessageLength={hideMessageLength}
                        value={value}
                        ref={ref}
                        {...props}
                        onValueChange={value => {
                            const newValue = value != null && value != undefined ? Number(value) : value;
                            controllerOnChange(onValueChange ? onValueChange(newValue) : newValue);
                        }}
                    />
                );
            }}
            defaultValue={form.getValues(name)}
        />
    );
};

export default React.forwardRef<HTMLInputElement, Props>(InputHourGroupController);
