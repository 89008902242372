import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Button from '../../common/components/button/Button';
import FormItem from '../../common/components/formItem/FormItem';
import Input from '../../common/components/input/Input';
import InputError from '../../common/components/inputError/InputError';
import Label from '../../common/components/label/Label';
import Loading from '../../common/services/Loading';
import styles from './QualityQuestionsScreen.module.scss';
import QualityQuestionsService from '../../api/qualityQuestions/QualityQuestionsService';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import { QualityQuestionViewModel } from '../../api/qualityQuestions/models/QualityQuestionViewModel';
import TextareaAutosize from 'react-autosize-textarea/lib';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    afterSave: () => void;
    qualityQuestionId?: string;
    isDetails: boolean;
};

type Form = {
    name: string;
}

enum ScreenType {
    NEW,
    EDIT,
    DETAILS
}

const QualityQuestionsScreen: React.FC<Props> = ({ qualityQuestionId, groupId, afterSave, isDetails, userCanRead, userCanWrite }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, setValue } = useForm<Form>();
    const [screenMode, setScreenMode] = useState<ScreenType>(isDetails && !!qualityQuestionId ? ScreenType.DETAILS : !!qualityQuestionId ? ScreenType.EDIT : ScreenType.NEW);
    const [itemToRemove, setItemToRemove] = useState<QualityQuestionViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [description, setDescription] = useState<string>();


    const onCancel = () => {
        afterSave();
    }

    const onSubmit = async ({ name }: Form) => {
        try {

            if (!name) {
                return;
            }

            Loading.show();
            if (groupId) {
                await QualityQuestionsService.save({ id: qualityQuestionId, name: name, groupId, description });
            }
            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            afterSave();

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getQualityQuestion = async () => {
        try {

            Loading.show();

            if (qualityQuestionId) {
                const result = await QualityQuestionsService.getQualityQuestionById(qualityQuestionId);
                setValue('name', result?.name);
                setDescription(result.description ?? '');
            }

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: QualityQuestionViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await QualityQuestionsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onCancel();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onCancelEdit = () => {
        if (screenMode === ScreenType.EDIT && !!isDetails) {
            setScreenMode(ScreenType.DETAILS);
            getQualityQuestion();
        } else {
            onCancel()
        }
    }

    useEffect(() => {
        if (!!qualityQuestionId) {
            getQualityQuestion();
        }
    }, [qualityQuestionId]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.name')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Input name="name" placeholder={t('common.name')} ref={register({ required: true, maxLength: 255 })} disabled={screenMode === ScreenType.DETAILS} maxLength={255} />
                    <InputError error={errors.name} />
                </FormItem>
                <FormItem className={styles.formItem}>
                    <Label className={styles.bold}>{t('common.description')}</Label>
                    <TextareaAutosize
                        style={{ resize: 'none' }}
                        name='description'
                        value={description}
                        disabled={screenMode === ScreenType.DETAILS}
                        className={`${styles.input} ${styles.inputDefault} ${screenMode === ScreenType.DETAILS ? styles.inputDisabled : ''}`}
                        onChange={e => {
                            setDescription(e.currentTarget.value);
                        }}
                        rows={2}
                    />
                </FormItem>
            </div>
            {screenMode !== ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={onCancelEdit} />
                {userCanWrite && <Button type="submit" text={t('common.save')} size={'small'} />}
            </div>}
            {screenMode === ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.back')} preset={'secondary'} size={'small'} onClick={() => onCancel()} />
                {userCanWrite && <Button type="button" text={t('common.remove')} preset={'danger'} size={'small'} onClick={() => showRemoveItemDialog({ id: qualityQuestionId } as QualityQuestionViewModel)} />}
                <QuestionYesNo message={t('common.messages.remove_record')}
                    isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />
                {userCanWrite && <Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
            </div>}
        </form>
    );
}

export default QualityQuestionsScreen;
