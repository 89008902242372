import React, { memo } from 'react';
import MoneyFormat from '../../../common/components/moneyFormat/MoneyFormat';
import styles from './TimesPlanningTableSingleValueColumn.module.scss';

type Props = {
    value?: number;
    color?: string;
    draw?: boolean;
};

const TimesPlanningTableSingleValueColumn = ({ value, color, draw }: Props) => {
    return (
        <div className={styles.item} style={color ? { background: color } : undefined}>
            <span>{value != null && value != undefined ? <MoneyFormat value={value} suffix={'h'} /> : (!!draw ?'-' : '')}&nbsp;</span>
        </div>
    );
}

export default memo(TimesPlanningTableSingleValueColumn);
