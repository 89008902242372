/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { memo, useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { useTranslation } from 'react-i18next';
import styles from './Input.module.scss';

type InputPreset = 'default' | 'login';

export type Props = {
    preset?: InputPreset;
    removeBackground?: boolean;
    text?: (designation: string) => void;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

const InputResizable = React.forwardRef<HTMLTextAreaElement, Props>(({ preset, removeBackground, text, ...props }, ref) => {
    const presetClassName = preset ? styles[preset] : styles.default;

    const { t } = useTranslation();
    const [textLength, setTextLength] = useState<number>(0);

    const setText = (value: string) => {
        text ? text(value) : undefined;
    }
    return (
        <div>
            <TextareaAutosize
                style={{ resize: 'none' } && (removeBackground ? { backgroundColor: 'white' } : {})}
                className={`${styles.input} ${presetClassName} ${props.className || ''} ${props.disabled ? styles.disabled : ''} `}
                {...props}
                ref={ref}
                onChange={e => {
                    setTextLength(e.currentTarget.value.length)
                    setText(e.currentTarget.value);
                    props.onChange && props.onChange(e as any);
                }}

                rows={1}
            />
            { textLength === props.maxLength && <div className={styles.maxLength}>{t('common.field_max_length', { value: textLength })}</div>}
        </div>
    );
});

export default memo(InputResizable);
