import React, { useState } from 'react';
import ListProductsScreen from '../../products/list/ListProductsScreen';
import ProductsScreen from '../../products/ProductsScreen';
import { ProductViewModel } from '../../../api/products/models/ProductViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_Products,
    Product
}

const ParametersProductsScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_Products);
    const [currentProduct, setCurrentProduct] = useState<ProductViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_Products && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.PRODUCTS}/> }
            { screen === ScreenType.LIST_Products && <ListProductsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewProduct={() => {
                    setIsDetails(false);
                    setCurrentProduct(null);
                    setScreen(ScreenType.Product)
                }}
                onClickItem={(item: ProductViewModel, isDetails: boolean) => {
                    if(item.active)
                    {
                        setIsDetails(isDetails);
                        setCurrentProduct(item);
                        setScreen(ScreenType.Product);
                    }
                }}
            ></ListProductsScreen>}
            { screen === ScreenType.Product && groupId && <ProductsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                productId={currentProduct?.id}
                afterSave={() => {
                    setCurrentProduct(null);
                    setScreen(ScreenType.LIST_Products);
                }}></ProductsScreen>}
        </div>

    );
}

export default ParametersProductsScreen;
