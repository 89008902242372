import React, { useState } from 'react';
import ListUsersScreen from '../../users/list/ListUsersScreen';
import LdapsScreen from '../../ldaps/LdapsScreen';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';
import { LdapViewModel } from '../../../api/ldaps/models/LdapViewModel';
import ListLdapsScreen from '../../ldaps/list/ListLdapsScreen';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;    
};

enum ScreenType {
    LIST_LDAPS,
    LDAPS
}

const ParametersLdapsScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_LDAPS);
    const [currentLdap, setCurrentLdap] = useState<LdapViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_LDAPS && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.LDAPS}/> }
            { screen === ScreenType.LIST_LDAPS && <ListLdapsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}                
                createNewLdap={() => {
                     setIsDetails(false);
                     setCurrentLdap(null);
                     setScreen(ScreenType.LDAPS)
                }}
                onClickItem={(item: LdapViewModel, isDetails: boolean) => {
                     setIsDetails(isDetails);
                     setCurrentLdap(item);
                     setScreen(ScreenType.LDAPS);
                }}
            ></ListLdapsScreen>}
            { screen === ScreenType.LDAPS && <LdapsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}                
                id={currentLdap?.id}
                afterSave={() => {
                    setCurrentLdap(null);
                    setScreen(ScreenType.LIST_LDAPS);
                }}></LdapsScreen>}
        </div>

    );
}

export default ParametersLdapsScreen;
