
import React from 'react';
import { EqualHeight } from 'react-equal-height';
import { useTranslation } from 'react-i18next';
import { CollaboratorsTimesSearchCriteria } from '../../../api/collaboratorsTimes/models/CollaboratorsTimesSearchCriteria';
import { CollaboratorTimesValueDateDto, CollaboratorTimesDto, CollaboratorTimesDateDto } from '../../../api/collaboratorsTimes/models/CollaboratorTimesDto';
import DateFormat from '../../../common/components/dateFormat/dateFormat';
import Table, { TableCell, TableColumn } from '../../../common/components/table/Table';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import { DATE_FORMAT_MONTH_YEAR } from '../../../Config';
import TimesTableFilters from '../timesTableFilters/TimesTableFilters';
import TimesPlanningTable from '../timesPlanningTable/TimesPlanningTable';
import styles from './TimesTable.module.scss';
import colors from '../../../styles/export/colors.module.scss';
import PaginationTextInfo from '../../../common/components/pagination/PaginationTextInfo';
import Pagination from '../../../common/components/pagination/Pagination';
import Badge from '../../../common/components/badge/Badge';
import { ColumnField, SearchField, SearchType } from '../../../api/projects/models/ProjectsSearchCriteria';

type FooterRow = {
    name: string;
    cellStyle: React.CSSProperties;
}

type Props = {
    criteria: CollaboratorsTimesSearchCriteria;
    totalItems: number;
    currentPage: number;
    itemsPerPage: number;
    onPageChange: (page: number) => void;
    onChangeCriteria: (criteria: CollaboratorsTimesSearchCriteria) => void;
    collaboratorsTimes: CollaboratorTimesDto[];
    statusOptions: SelectValueLabel[];
    openRowsIds: string[];
    onToggleOpenRow: (id: string) => void;
    supportDates: CollaboratorTimesDateDto[];
    totalPlannedDates: CollaboratorTimesValueDateDto[];
    totalExecutedDates: CollaboratorTimesValueDateDto[];
    reRender: number;
    onRowClick: (projectId: string) => void;
}

const TimesTable = ({
    criteria, onChangeCriteria, collaboratorsTimes, statusOptions, openRowsIds, onToggleOpenRow, totalItems, itemsPerPage,
    currentPage, onPageChange, supportDates, totalPlannedDates, totalExecutedDates, reRender, onRowClick
}: Props) => {
    const { t } = useTranslation();

    const tableColumns: TableColumn[] = [
        {
            field: ColumnField.IDENTIFICATION,
            searchType: SearchType.TEXT,
            searchField: SearchField.IDENTIFICATION,
            order: 'identification',
            name: t('collaborators_times.table.identification'),
            width: '10%',
        },
        {
            field: ColumnField.DESIGNATION,
            searchType: SearchType.TEXT,
            searchField: SearchField.DESIGNATION,
            order: 'designation',
            name: t('collaborators_times.table.designation'),
            width: '40%',
            renderCell: (cell: TableCell) => (
                <label className={styles.clickable}
                    onClick={(e: any) => {
                        e.stopPropagation();
                        onRowClick(cell.row.id);
                    }}
                >
                    {cell.row.designation}
                </label>
            )
        },
        {
            field: ColumnField.STATUS,
            searchType: SearchType.MULTISELECT,
            searchField: SearchField.STATUS,
            order: 'status',
            name: t('collaborators_times.table.status'),
            width: '22%',
            extraArgs: { options: statusOptions },
            renderCell: (cell: TableCell) => (
                <div style={{ textAlign: 'center' }}>
                    <Badge color={cell.row.statusColor}>{cell.row.status}</Badge>
                </div>
            ),
        },
        {
            field: ColumnField.START_END_DATE,
            searchType: SearchType.RANGE_DATE,
            searchFields: [SearchField.START_DATE, SearchField.END_DATE],
            order: 'start_date',
            name: t('collaborators_times.table.start_end'),
            width: '28%',
            renderCell: (cell: TableCell) => (
                <div>
                    {Boolean(cell.row.startDate && cell.row.endDate) && (<>
                        <DateFormat
                            value={cell.row.startDate}
                            format={DATE_FORMAT_MONTH_YEAR}
                        /> - <DateFormat
                            value={cell.row.endDate}
                            format={DATE_FORMAT_MONTH_YEAR}
                        />
                    </>)}
                </div>
            ),
            cellAlignment: 'center',
            columnCellAlignment: 'center'
        },
    ];

    const tableSubColumns: TableColumn[] = [
        {
            field: 'name',
            name: t('collaborators_times.table.name'),
            width: '100%',
            cellAlignment: 'right',
            columnCellAlignment: 'right',
            cellStyle: { backgroundColor: colors.catskillWhiteOriginal, paddingRight: '1rem' }
        }
    ];

    const footerColumns: TableColumn[] = [
        {
            field: '',
            name: '',
            width: '10%',
            cellStyle: cell => cell.row.cellStyle,
        },
        {
            field: '',
            name: '',
            width: '90%',
            renderCell: cell => <span>{cell.row.name}</span>,
            cellStyle: cell => cell.row.cellStyle,
        },
    ];

    const footerRows: FooterRow[] = [
        {
            name: t('collaborators_times.table.support'),
            cellStyle: {
                backgroundColor: colors.catskillWhiteOriginal,
                height: '100%',
                alignItems: 'center',
            },
        },
        {
            name: t('collaborators_times.table.total_planned'),
            cellStyle: {
                fontWeight: 'bold',
                height: '100%',
                alignItems: 'center',
            },
        },
        {
            name: t('collaborators_times.table.total_executed'),
            cellStyle: {
                fontWeight: 'bold',
                backgroundColor: colors.catskillWhiteOriginal,
                height: '100%',
                alignItems: 'center',
            },
        },
    ];

    const onOrderList = (column: TableColumn) => {
        const _orderBy = criteria.orderColumn !== column.order ? 'asc' : criteria.orderBy == 'desc' ? 'asc' : 'desc';
        onChangeCriteria({
            ...criteria,
            orderBy: _orderBy,
            orderColumn: column.order,
        });
    }

    return (
        <div>
            <EqualHeight>
                <div className={styles.divTablesContent}>
                    <div className={styles.divTablesLeft} key={'rerenderLeft' + reRender}>
                        <Table
                            columns={tableColumns}
                            rows={collaboratorsTimes}
                            allowHover={true}
                            searchComponent={<TimesTableFilters
                                filters={criteria}
                                onChange={onChangeCriteria}
                                columns={tableColumns}
                                cellHeight={'100%'}
                            />}
                            onOrderList={column => onOrderList(column as TableColumn)}
                            subRowsFieldName={'phases'}
                            subRowsColumns={tableSubColumns}
                            onRowClick={row => onToggleOpenRow(row.id)}
                            onExpandClick={row => onToggleOpenRow(row.id)}
                            isSubRowOpen={row => Boolean(openRowsIds.find(id => id === row.id))}
                            orderBy={criteria.orderBy}
                            orderColumn={criteria.orderColumn}
                            footerColumns={collaboratorsTimes.length || supportDates.length  ? footerColumns : undefined}
                            footerRows={collaboratorsTimes.length || supportDates.length ? footerRows : undefined}
                        />
                    </div>
                    <div className={styles.divTablesRight} id="divRightTable" key={'rerenderRight' + reRender}>
                        <TimesPlanningTable
                            collaboratorsTimes={collaboratorsTimes}
                            openRowsIds={openRowsIds}
                            supportDates={supportDates}
                            totalPlannedDates={totalPlannedDates}
                            totalExecutedDates={totalExecutedDates}
                        />
                    </div>
                </div>
            </EqualHeight>
            {totalItems > 0 && <div className={styles.paginationContainer}>
                <PaginationTextInfo className={styles.paginationInfo} itemName={t('projects.items')} items={collaboratorsTimes.length} totalItems={totalItems} />
                <Pagination currentPage={currentPage} onChange={onPageChange} totalItems={totalItems} itemsPerPage={itemsPerPage} />
            </div>}
        </div>
    );
}

export default TimesTable;
