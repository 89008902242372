import dayjs from 'dayjs';
import { LOGGER_LOG_TYPE, LOGGER_MESSAGE_TYPE, LOGGER_PRINT_LOGS, LOGGER_PRINT_LOGS_LEVEL } from '../../Config';

class Logger {
    private systemName = '';
    private systemVersion = '';
    private appVersion = '';
    private userId?: number;

    public setUserId(userId: number) {
        this.userId = userId;
    }

    public error(logType: LOGGER_LOG_TYPE, message: string, extraData?: any, allowSend = true): void {
        this.log(LOGGER_MESSAGE_TYPE.ERROR, logType, message, extraData, allowSend);
    }

    public info(logType: LOGGER_LOG_TYPE, message: string, extraData?: any, allowSend = true): void {
        this.log(LOGGER_MESSAGE_TYPE.INFO, logType, message, extraData, allowSend);
    }

    private log(messageType: LOGGER_MESSAGE_TYPE, logType: LOGGER_LOG_TYPE, message: string, extraData?: any, allowSend = true) {
        const data = {
            type: LOGGER_MESSAGE_TYPE[messageType],
            logType: LOGGER_LOG_TYPE[logType],
            date: dayjs.utc(new Date()).format('DD/MM/YYYY hh:mm:ss'),
            systemName: this.systemName,
            systemVersion: this.systemVersion,
            appVersion: this.appVersion,
            userId: this.userId,
            message,
            extraData,
        };

        if (LOGGER_PRINT_LOGS && messageType >= LOGGER_PRINT_LOGS_LEVEL) {
            const logMessage = `[${data.date}] ${data.message}: ${data.extraData && data.extraData.message ? data.extraData.message : ''}`;
            switch (messageType) {
                case LOGGER_MESSAGE_TYPE.INFO:
                    console.info(logMessage, data);
                    break;
                case LOGGER_MESSAGE_TYPE.ERROR:
                    console.info(logMessage, data);
                    break;
                default:
                    console.log(logMessage, data);
                    break;
            }
        }

        if (allowSend) {
            //LogStorage.save({ data: JSON.stringify(data), dateTime: new Date(), application: APP_NAME });
            // TODO: send
        }
    }
}

export default new Logger();
