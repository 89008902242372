import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { PartnersSearchCriteria } from './models/PartnersSearchCriteria';
import { PartnersViewModel } from './models/PartnersViewModel';

class PartnersService {
    
    public getList(criteria: PartnersSearchCriteria) {
        return Http.get<Page<PartnersViewModel>>('partners', criteria);
    }

    public getPartnersById(id: string) {
        return Http.get<PartnersViewModel>('partners/'+ id);
    }

    public save(model: PartnersViewModel) {
        return !!model.id ? Http.put('partners', model) : Http.post('partners', model);
    }

    public remove(model: PartnersViewModel) {
        return  Http.put('partners/deactivate', model);
    }
}

export default new PartnersService();