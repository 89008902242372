import React from 'react';
import styles from './ScreenContainer.module.scss';

type Props = {
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ScreenContainer: React.FC<Props> = ({ children, ...props }: Props) => (
    <div
        id='screenContainer'
        {...props}
        className={`${styles.container} ${props.className || ''}`}
    >
        {children}
    </div>
);

export default ScreenContainer;
