import React, { useState } from 'react';
import ListTypologiesScreen from '../../typologies/list/ListTypologiesScreen';
import TypologiesScreen from '../../typologies/TypologiesScreen';
import { TypologiesViewModel } from '../../../api/typologies/models/TypologiesViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead:boolean;
    userCanWrite:boolean;
    groupId?: string
};

enum ScreenType {
    LIST_TYPOLOGIES,
    TYPOLOGIES
}

const ParametersTypologiesScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_TYPOLOGIES);
    const [currentTypologies, setCurrentTypologies] = useState<TypologiesViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_TYPOLOGIES && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.TYPOLOGIES}/> }
            { screen === ScreenType.LIST_TYPOLOGIES && <ListTypologiesScreen
            userCanRead={userCanRead}
            userCanWrite={userCanWrite}
                groupId={groupId}
                createNewTypologies={() => {
                    setIsDetails(false);
                    setCurrentTypologies(null);
                    setScreen(ScreenType.TYPOLOGIES)
                }}
                onClickItem={(item: TypologiesViewModel, isDetails: boolean) => {
                    if(item.active)
                    {
                        setIsDetails(isDetails);
                        setCurrentTypologies(item);
                        setScreen(ScreenType.TYPOLOGIES);
                    }
                }}
            ></ListTypologiesScreen>}
            { screen === ScreenType.TYPOLOGIES && <TypologiesScreen
            userCanRead={userCanRead}
            userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                typologiesId={currentTypologies?.id}
                afterSave={() => {
                    setCurrentTypologies(null);
                    setScreen(ScreenType.LIST_TYPOLOGIES);
                }}></TypologiesScreen>}
        </div>

    );
}

export default ParametersTypologiesScreen;
