import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../common/components/box/Box';
import { VscListSelection } from 'react-icons/vsc';
import { UseFieldArrayMethods, useFormContext } from 'react-hook-form';
import { ProjectForm } from '../../models/ProjectForm';
import styles from './ProjectInvestmentsSummary.module.scss';
import BoxContent from '../../../../common/components/box/BoxContent';
import { Row, Col } from 'react-flexbox-grid';
import ProjectInvestmentsSummaryBox from './components/projectInvestmentsSummaryBox/ProjectInvestmentsSummaryBox';
import IconTotalCost from '../../../../assets/svg/custo_total.svg';
import IconInvestments from '../../../../assets/svg/icon_investissements.svg';
import { ProjectInvestmentTypeViewModel } from '../../../../api/projects/models/ProjectViewModel';
import RenderSVG from '../../../../common/components/renderSVG/RenderSVG';

type Props = {
    isDisabled: boolean;
    investmentTypes: UseFieldArrayMethods<ProjectInvestmentTypeViewModel, 'id'>;
};

const ProjectInvestmentsSummary: React.FC<Props> = ({
    isDisabled, investmentTypes
}: Props) => {

    const form = useFormContext<ProjectForm>();
    const { t } = useTranslation();
    const investmentTypesName = `investmentTypes`;
    const investmentTypesWatch = form.watch<string, ProjectInvestmentTypeViewModel[]>(investmentTypesName, []) || [];
    const totalOperatingInvestmentsWatch = form.watch<string, number>('totalOperatingInvestments', 0) || 0;
    const totalCost = investmentTypes.fields.map(x => (x?.dates || []).map(x => x.total).sum()).sum();
    
    return (
        <Box
            title={t('project.investments_summary.title')}
            icon={<VscListSelection />}
            contentClassName={styles.boxContent}
        >
            <BoxContent>
                <Row>
                    {investmentTypes.fields.map((investmentType, i) => {
                        const total = investmentTypesWatch[i] ? investmentTypesWatch[i].dates.map(x => x.total).sum() : 0;
                        return (
                            <Col md={6} lg={6} xl={3} className={styles.col} key={i}>
                                <ProjectInvestmentsSummaryBox
                                    form={form}
                                    icon={investmentType.iconSvg ? <RenderSVG item={investmentType.iconSvg} /> : undefined}
                                    subtitle={investmentType.name || '-'}
                                    commentName={`${investmentTypesName}[${i}].totalComment`}
                                    isDisabled={isDisabled}
                                    total={total}
                                />
                            </Col>
                        );
                    })}
                </Row>
                <Row> 
                    <Col xs>
                        <div className={styles.separator}></div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg className={styles.col}>
                        <ProjectInvestmentsSummaryBox
                            form={form}
                            icon={<img src={IconInvestments} />}
                            title={t('project.investments_summary.functional_investments')}
                            subtitle={t('project.investments_summary.recurrent_cost')}
                            isDisabled={isDisabled}
                            standout={true}
                            total={totalOperatingInvestmentsWatch}
                            commentName={'totalOperatingInvestmentsComment'}
                            canEdit={true}
                            editName={'totalOperatingInvestments'}
                        />
                    </Col>
                    <Col md={12} lg className={styles.col}>
                        <ProjectInvestmentsSummaryBox
                            form={form}
                            icon={<img src={IconTotalCost} />}
                            subtitle={t('project.investments_summary.total_cost')}
                            commentName="totalCostComment"
                            isDisabled={isDisabled}
                            standout={true}
                            total={totalCost}
                        />
                    </Col>
                </Row>
            </BoxContent>
        </Box>
    );
}

export default memo(ProjectInvestmentsSummary);
