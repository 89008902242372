import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import EstablishmentService from '../../api/establishments/EstablishmentService';
import { EstablishmentViewModel } from '../../api/establishments/models/EstablishmentViewModel';
import Button from '../../common/components/button/Button';
import FormItem from '../../common/components/formItem/FormItem';
import Input from '../../common/components/input/Input';
import InputError from '../../common/components/inputError/InputError';
import Label from '../../common/components/label/Label';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import Loading from '../../common/services/Loading';
import styles from './EstablishmentsScreen.module.scss';

enum ScreenType {
    NEW,
    EDIT,
    DETAILS,
    DETAILS_TAB
}

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    establishmentId?: string;
    afterSave: () => void;
    isDetails: boolean;
    isDetailsToTab: boolean;
};

type Form = {
    name: string;
    address: string;
    index?: number;
}

const EstablishmentsScreen: React.FC<Props> = ({ groupId, establishmentId, isDetails, isDetailsToTab, userCanRead, userCanWrite, ...props }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, setValue } = useForm<Form>();
    const [screenMode, setScreenMode] = useState<ScreenType>(isDetails && !!establishmentId ? ScreenType.DETAILS : isDetailsToTab && !!establishmentId ? ScreenType.DETAILS_TAB : !!establishmentId ? ScreenType.EDIT : ScreenType.NEW);
    const [itemToRemove, setItemToRemove] = useState<EstablishmentViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [description, setDescription] = useState<string>();

    const onCancel = () => {
        isDetails && setScreenMode(ScreenType.DETAILS);
        if (isDetailsToTab) {
            setScreenMode(ScreenType.DETAILS_TAB);
            getEstablishment();
        }
        props.afterSave();
    }

    const onSubmit = async ({ name, address, index }: Form) => {
        try {

            if (!name) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();
            if (groupId) {
                await EstablishmentService.save({ id: establishmentId, name, groupId, address, index, description });
            }
            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            onCancel();

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getEstablishment = async () => {
        try {
            Loading.show();
            const result = await EstablishmentService.get(establishmentId);
            if (result) {
                setValue('name', result?.name || '');
                setValue('address', result?.address || '');
                setValue('index', result?.index);
                setDescription(result.description ?? '');
            }
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const showRemoveItemDialog = (item: EstablishmentViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await EstablishmentService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onCancel();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onCancelEdit = () => {
        if (screenMode === ScreenType.EDIT && !!isDetails) {
            setScreenMode(ScreenType.DETAILS);
            getEstablishment();
        } else {
            onCancel()
        }
    }

    useEffect(() => {
        if (userCanRead) {
            if (establishmentId) {
                getEstablishment();
            }
        }
    }, [establishmentId]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.name')} {screenMode === ScreenType.DETAILS || screenMode === ScreenType.DETAILS_TAB ? '' : '*'}</Label>
                    <Input maxLength={255} disabled={screenMode === ScreenType.DETAILS || screenMode === ScreenType.DETAILS_TAB} name="name" placeholder={t('common.name')} ref={register({ required: true, maxLength: 255 })} />
                    {screenMode != ScreenType.DETAILS && <InputError error={errors.name} />}
                </FormItem>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.address')} {screenMode === ScreenType.DETAILS || screenMode === ScreenType.DETAILS_TAB ? '' : '*'}</Label>
                    <Input maxLength={255} disabled={screenMode === ScreenType.DETAILS || screenMode === ScreenType.DETAILS_TAB} name="address" placeholder={t('common.address')} ref={register({ required: true, maxLength: 255 })} />
                    {screenMode != ScreenType.DETAILS && <InputError error={errors.address} />}
                </FormItem>
            </div>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.index')}</Label>
                    <Input name="index" placeholder={t('common.index')} type={'number'} ref={register({})} disabled={screenMode === ScreenType.DETAILS} pattern="[0-9]*" />
                    <InputError error={errors.index} />
                </FormItem>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.description')}</Label>
                    <TextareaAutosize
                        style={{ resize: 'none' }}
                        name='description'
                        value={description}
                        disabled={screenMode === ScreenType.DETAILS}
                        className={`${styles.input} ${styles.inputDefault} ${screenMode === ScreenType.DETAILS ? styles.inputDisabled : ''}`}
                        onChange={e => {
                            setDescription(e.currentTarget.value);
                        }}
                        rows={2}
                    /> </FormItem>
            </div>

            {screenMode !== ScreenType.DETAILS && screenMode !== ScreenType.DETAILS_TAB && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={onCancelEdit} />
                {userCanWrite && <Button type="submit" text={t('common.save')} size={'small'} />}
            </div>}
            {screenMode === ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.back')} preset={'secondary'} size={'small'} onClick={() => onCancel()} />
                {userCanWrite && <Button type="button" text={t('common.remove')} preset={'danger'} size={'small'} onClick={() => showRemoveItemDialog({ id: establishmentId } as EstablishmentViewModel)} />}
                <QuestionYesNo message={t('common.messages.remove_record')}
                    isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />
                {userCanWrite && <Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
            </div>}
            {screenMode === ScreenType.DETAILS_TAB && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                {userCanWrite && <Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
            </div>}
        </form>
    );
}

export default EstablishmentsScreen;
