import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProjectAdvancementsTableFilters.module.scss';
import { FaEraser } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { TableColumn } from '../../../../../common/components/table/Table';
import Button from '../../../../../common/components/button/Button';
import { SearchField, SearchType } from '../../../../../api/projects/models/ProjectsSearchCriteria';
import ProjectsFiltersInput from '../../../../projects/components/ProjectsFiltersInput';
import DateTimePickerRange from '../../../../../common/components/dateTimePickerRange/DateTimePickerRange';
import SelectController from '../../../../../common/components/select/SelectController';
import { StatusViewModel } from '../../../../../api/status/models/StatusViewModel';
import { SelectValueLabel } from '../../../../../common/types/SelectValueLabel';
import { ProjectResponsibleDsiViewModel } from '../../../../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiViewModel';
import TableUtils from '../../../../../common/components/table/TableUtils';

export interface Filters {
    identification?: string;
    designation?: string;
    statusId?: string[];
    projectManageId?: string[];
    priorityLevelId?: string[];
    strategicAxisId?: string[];
    departmentId?: string[];
    domainId?: string[];
    searchEstablishment?: string;
    partnerId?: string[];
    productId?: string[];
    responsibleId?: string[];
    typologyId?: string[];
    dateUpdatedProject?: Date;
    userUpdatedProject?: string[];
    startBudgetings?: number;
    endBudgetings?: number;
    startDate?: Date | null;
    endDate?: Date | null;
    startCharges?: number;
    endCharges?: number;
    startRecurring?: number;
    endRecurring?: number;
    startInvestments?: number;
    endInvestments?: number;
    authorId?: string[];
    startSubventionsExpected?: number;
    endSubventionsExpected?: number;
    startSubventionsObtained?: number;
    endSubventionsObtained?: number;
    responsibleDsiId?: string[];
    riskLevel?: string[];
}

type Props = {
    filters: Filters;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
    onClearFilters: () => void;
    columns: TableColumn[];
    enableRightTable?: boolean;
    cellHeight?: string;
    status?: StatusViewModel[];
    responsablesDsi?: ProjectResponsibleDsiViewModel[];
}

const ProjectAdvancementsTableFilters: React.FC<Props> = ({ filters, onChange, onFilter, onClearFilters, columns, enableRightTable, cellHeight, status, responsablesDsi }: Props) => {
    const { t } = useTranslation();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    let formAux: HTMLFormElement;

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [reRender, setReRender] = useState<number>(1);
    const statusOptions: SelectValueLabel[] = (status || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const responsablesDsiOptions: SelectValueLabel[] = (responsablesDsi || []).map(x => ({ value: x.id || '', label: x.name || '' }));

    useEffect(() => {
        setFilters(filters ?? undefined);
    }, [filters]);

    const clearFilters = () => {
        form.reset();
        form.setValue('identification', null);
        form.setValue('designation', null);
        form.setValue('startDate', null);
        form.setValue('endDate', null);
        form.setValue('statusId', null);
        form.setValue('responsibleDsiId', null);
        onClearFilters();
        form.handleSubmit(onSubmit)();
        if (formAux) {
            formAux.reset();
        }
    }

    const setFilters = (filters: Filters | undefined) => {

        if (filters) {
            form.setValue('identification', filters.identification);
            form.setValue('designation', filters.designation);
            if (filters.startDate) {
                const d = new Date(filters.startDate);
                setStartDate(d)
                form.setValue('startDate', d);
            }
            if (filters.endDate) {
                const d = new Date(filters.endDate);
                setEndDate(d);
                form.setValue('endDate', d);
            }
            form.setValue('statusId', filters.statusId);
            form.setValue('responsibleDsiId', filters.responsibleDsiId);
            setReRender(reRender + 1);
        }
    }

    const onSubmit = (filters: Filters) => {
        onFilter(filters);
    }

    const onInputChange = () => {
        onChange(form.getValues());
    }

    const getColumnCellStyle = (col: TableColumn) => {
        return {
            textAlign: col.columnCellAlignment || 'left',
            ...TableUtils.getCellWidthStyle(col),
            ...(enableRightTable && cellHeight ? { height: cellHeight } : {}),
        }
    }

    const capitalize = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    return (
        <form ref={(el: any) => formAux = el} className={styles.formContent} onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.columns}>
                {columns.map((col, colIndex) => (
                    <div key={`col-search-${colIndex}`} className={!col.isActionColumn ? styles.columnCell : styles.columnCellAction} style={getColumnCellStyle(col)}>

                        {/* filtros que precisam de debounce - so pesquisar no fim de acabar de escrever */}
                        { col.searchType && (col.searchType == SearchType.TEXT || col.searchType == SearchType.RANGE_VALUE) ?
                        <div style={ { height: '42.59px' } }>
                            <ProjectsFiltersInput className={styles.paddingInputSearch} searchType={col.searchType}
                                searchField={col.searchField ?? ''} name={col.name} formAux={form}
                                onChange={() => {
                                    onInputChange();
                                    form.handleSubmit(onSubmit)();
                                }}></ProjectsFiltersInput>
                                </div> : ''}

                        { col.searchType && col.searchType == SearchType.RANGE_DATE ?
                            <div className={styles.dateRangeCustom} key={'range_' + reRender}>
                                <DateTimePickerRange
                                    dateFormat="MM/yyyy"
                                    locale='fr'
                                    start={startDate}
                                    end={endDate}
                                    showMonthYearPicker
                                    placeholderText={t('common.start_end')}
                                    onChange={(dates: [Date, Date]) => { console.log('change') }}
                                    onChangeRange={(start: Date | null, end: Date | null) => {
                                        form.setValue('startDate', start || null);
                                        setStartDate(start ?? undefined);
                                        let dateLastDayOfMonth = end;
                                        if (end) {
                                            dateLastDayOfMonth = new Date(end.getFullYear(), end.getMonth() + 1, 0, 23, 59, 59);
                                        }
                                        form.setValue('endDate', dateLastDayOfMonth);
                                        setEndDate(end ?? undefined);
                                        form.handleSubmit(onSubmit)();
                                    }}
                                />
                            </div>

                            : ''}


                        { col.searchType && col.searchType == SearchType.SELECT ?
                            <SelectController isClearable name={col.searchField || ''}
                                isMulti={true as any}
                                showOnlyFirstSelected={true}
                                form={form}
                                isDisabled={false}
                                placeholder={capitalize(col.name)}
                                isSelectSearch={true}
                                options={col.searchField == SearchField.STATUS ? statusOptions : col.searchField == SearchField.RESPONSIBLE_DSI ? responsablesDsiOptions : []}
                                onChange={() => {
                                    onInputChange();
                                    form.handleSubmit(onSubmit)();
                                }}
                                styles={{
                                    valueContainer: base => ({
                                        textTransform: 'none',
                                        fontWeight: 'normal'
                                    })
                                }}
                                height={'42.59px'}
                            /> : ''}

                        { col.isActionColumn ? <Button onClick={() => clearFilters()} preset="primary" onlyIcon={true} className={styles.toolbarButton}>
                            <FaEraser className={styles.toolbarButtonIcon} /> </Button> : ''}

                    </div>
                ))}
            </div>
        </form>
    );
}

export default memo(ProjectAdvancementsTableFilters);