import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../../../models/ProjectForm';
import styles from './ProjectScore.module.scss';
import { ProjectScoreViewModel } from '../../../../../../api/projects/models/ProjectViewModel';
import RatingStars from '../../../../../../common/components/ratingStars/RatingStars';
import { FaStar } from 'react-icons/fa';
import { Col, Row } from 'react-flexbox-grid';
import { ScoreType } from '../../../../../../api/scores/enums/ScoreType';

type Props = {
    form: UseFormMethods<ProjectForm>;
    isDetails: boolean;
    scores: UseFieldArrayMethods<ProjectScoreViewModel & { isNew: boolean }, 'id'>;
    type?: ScoreType;
};

export interface TableRow {
    [field: string]: any;
}

const ProjectScore: React.FC<Props> = ({ isDetails, scores, form, type }: Props) => {

    const { t } = useTranslation();

    const renderTableCell = (val: number, index: number) => {
        return (
            <Controller
                render={({ onChange, value }) => {
                    return (
                        <RatingStars
                            value={value}
                            isEdit={!isDetails}
                            onChange={result => {
                                onChange(result);
                            }}
                        />
                    );
                }}
                control={form.control}
                name={`scores[${index}].value`}
                defaultValue={val}
            />
        );
    }

    return (
        <div>
            <div className={styles.columnsContainer}>
                {scores.fields.filter(x => (!isDetails || isDetails && !x.isNew)).map((x, i) => {
                    return x.type == type ? (<div key={i} className={styles.column}>
                        <div className={styles.colHeader}>{x.name}</div>
                        <div className={styles.colContent}>{renderTableCell(x.value ?? 0, i)}</div>
                    </div>) : null
                })}

            </div>
            {!scores.fields.filter(x => (!isDetails || isDetails && !x.isNew) && x.type == type).length && <Row>
                <Col xs>
                    <div className={styles.withoutElements}>{t('common.without_elements')}</div>
                </Col>
            </Row>
            }
            <div className={styles.subtitleLine}>
                <span className={styles.subtitleText}>{1} <FaStar size={'14'} className={styles.starsVerticalAlign} /> {t('project.project_evaluation.very_low')}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className={styles.subtitleText}>{2} <FaStar size={'14'} className={styles.starsVerticalAlign} /> {t('project.project_evaluation.low')}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className={styles.subtitleText}>{3} <FaStar size={'14'} className={styles.starsVerticalAlign} /> {t('project.project_evaluation.medium')}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className={styles.subtitleText}>{4} <FaStar size={'14'} className={styles.starsVerticalAlign} /> {t('project.project_evaluation.strong')}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className={styles.subtitleText}>{5} <FaStar size={'14'} className={styles.starsVerticalAlign} /> {t('project.project_evaluation.very_strong')}</span>
            </div>
        </div>
    );
}

export default memo(ProjectScore);
