import React, { useState } from 'react';
import styles from './Accordion.module.scss';
import { FaChevronLeft, FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export interface AccordionItem {
    id: string;
    text: string;
    disabled: boolean;
    onClick?: (item: AccordionItem) => void;
    childs: AccordionItem[];
}

type Props = {
    items: AccordionItem[];
    activeItemId: string | null;
    onClickItem: (item: AccordionItem) => void;
};

const Accordion: React.FC<Props> = ({ items, activeItemId, onClickItem }: Props) => {

    const { t } = useTranslation();
    
    const [openItemId, setOpenItemId] = useState<string | null>(activeItemId);

    const handleClick = (e: { preventDefault: () => void; }, item: AccordionItem) => {
        e.preventDefault();
        
        if (onClickItem) {
            onClickItem(item);
        }

        if (item.onClick) {
            item.onClick(item);
        }

        setOpenItemId(item.id === openItemId ? null : item.id);
    }

    return (
        <div>
            { items && items.map((parent, p) => {
                const isActive = parent.id === activeItemId || Boolean(parent?.childs.find(x => x.id === activeItemId));
                const isOpen = (parent.id === openItemId || Boolean(parent?.childs.find(x => x.id === openItemId))) && isActive;
                return (
                    <div key={p} className={styles.content}>
                        <div className={ `${styles.buttonParent} ${isActive ? styles.active : '' } ${ parent.disabled ? styles.buttonDisable : ''}` } 
                            onClick={e => handleClick(e, parent)}>
                            <div className={styles.buttonText}>
                                {parent.text}
                            </div>
                            { Boolean(parent.childs.length) && <div className={styles.buttonIcon}>
                                { Boolean(parent.childs.length) && !isOpen && <FaChevronLeft className={styles.iconSize} /> }
                                { Boolean(parent.childs.length) && isOpen && <FaChevronDown className={styles.iconSize} /> }
                                </div>
                            }
                            { parent.disabled && <label className={ `${styles.buttonLabel} ${styles.buttonIcon}`} >{t('common.new')}</label> }
                        </div>
                        { Boolean(parent.childs.length) && isOpen && parent.childs.map((child, c) => {
                            const isItemActive = child.id === activeItemId;
                            return (
                                <div key={c} className={ `${styles.buttonChild} ${isItemActive ? styles.active : '' }` } 
                                    onClick={e => handleClick(e, child)}>
                                {child.text}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    );
}

export default Accordion;