import React, { memo, useEffect, useState } from 'react';
import styles from './ProjectEvaluationLine.module.scss';
import InputGroup from '../../../../../../common/components/inputGroup/InputGroup';
import { FaCaretRight, FaCaretDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { ProjectInvestmentTypeEstablishmentViewModel, ProjectInvestmentTypeViewModel } from '../../../../../../api/projects/models/ProjectViewModel';
import ProjectEvaluationLineEstablishment from '../projectEvaluationLineEstablishment/ProjectEvaluationLineEstablishment';
import { ArrayField, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../../../models/ProjectForm';
import RenderSVG from '../../../../../../common/components/renderSVG/RenderSVG';
import PercentageFormat from '../../../../../../common/components/percentageFormat/PercentageFormat';
import InputGroupController from '../../../../../../common/components/inputGroup/InputGroupController';
import { ScrollSyncPane } from 'react-scroll-sync';

type Props = {
    isDetails: boolean;
    opened?: boolean | undefined,
    isTotalColumn?: boolean | undefined,
    showColumns: boolean;
    numberColumns: number;
    form: UseFormMethods<ProjectForm>;
    investmentType: Partial<ArrayField<ProjectInvestmentTypeViewModel, 'id'>>;
    investmentTypeName: string;
    investmentTypeIndex: number;
    numberItemsFullWidth: number;
    setInvestimentTotals: (i: number, investmentTypeIndex: number) => void;
    unit: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectEvaluationLine: React.FC<Props> = ({
    isDetails,
    isTotalColumn, showColumns, numberColumns, investmentType, opened, unit,
    form, investmentTypeName, investmentTypeIndex, numberItemsFullWidth, setInvestimentTotals,
    ...props
}: Props) => {

    const { t } = useTranslation();
    const columns = new Array(numberColumns).fill({})

    const [showContent, setShowContent] = useState(opened ?? false);

    const investmentTypeWatch = form.watch<string, ProjectInvestmentTypeViewModel>(investmentTypeName);
    const total = investmentTypeWatch?.dates?.map(x => Number(x.total)).sum();

    let establishmentsPercentage = investmentTypeWatch.establishments.map(x => x.percentage).sum();
    establishmentsPercentage = establishmentsPercentage > 100 ? 100 : (establishmentsPercentage < 0 ? 0 : establishmentsPercentage);
    const roundEstablishmentsPercentage = Number(establishmentsPercentage.toFixed(2));


    const validateHasErrors = () => {
        // Validate Establishment Percentage
        if (total > 0 && roundEstablishmentsPercentage < 100) {
            return true;
        }

        // Validate Poles Percentage
        let polesHasError = false;
        investmentType.establishments?.map(establishment => {
            if (!establishment.poles || establishment.poles.length == 0) {
                polesHasError = false;
                return;
            }

            let totalPolesPercentage = establishment.poles.map(x => Number(x.percentage || 0)).sum();
            totalPolesPercentage = totalPolesPercentage > 100 ? 100 : (totalPolesPercentage < 0 ? 0 : totalPolesPercentage);
            const roundTotalPolesPercentage = Number(totalPolesPercentage.toFixed(2));

            if (establishment.total > 0 && roundTotalPolesPercentage < 100) {
                polesHasError = true;
            }
        });

        if (polesHasError) {
            return true;
        }

        return false;
    }


    useEffect(() => {
        const hasErrors = validateHasErrors();
        setShowContent(hasErrors);
    }, [form.formState.submitCount])


    return (
        <div {...props} className={styles.bordercontentLine}>

            <div className={styles.flex}>
                <div className={styles.areaDetails} onClick={() => { setShowContent(!showContent); }}>
                    <div className={styles.boxDetails}>
                        <div className={styles.itemDetails}>
                            {!!investmentType.iconSvg && <RenderSVG item={investmentType.iconSvg} className={styles.divSVG} />}
                        </div>
                        {!!investmentType.establishments && investmentType.establishments.length > 0 && <div className={styles.itemDetails}>
                            {!showContent && <FaCaretRight className={styles.areaDetails} />}
                            {showContent && <FaCaretDown className={styles.areaDetails} />}
                        </div>}
                        <div className={styles.itemDetails}>
                            {investmentType.name}
                        </div>
                    </div>
                </div>
                {/* SCROLL */}
                <ScrollSyncPane>
                    <div className={`${columns.length > numberItemsFullWidth ? styles.areaValue : styles.areaValueFixed} ${styles.scrollPane}`}>
                        {showColumns && columns && columns.map((_, i) => {
                            const fieldName = `${investmentTypeName}.dates[${i}].total`;
                            return (
                                <div
                                    key={i}
                                    className={columns.length > numberItemsFullWidth ? styles.boxValues : styles.boxValuesFixed}
                                    style={columns.length > numberItemsFullWidth ? {} : { width: (100 / (columns.length + 1)) + '%' }}
                                >
                                    <InputGroupController
                                        type="number"
                                        text={unit}
                                        name={fieldName}
                                        form={form}
                                        disabled={isDetails}
                                        noBorder={true}
                                        hideMessageLength={true}
                                        onChange={() => {
                                            setInvestimentTotals(i, investmentTypeIndex);
                                        }}
                                        format="money"
                                    />
                                </div>
                            );
                        })}
                        {isTotalColumn && <div className={columns.length > numberItemsFullWidth ? styles.boxValues : styles.boxValuesFixed}
                            style={columns.length > numberItemsFullWidth ? {} : { width: (100 / (columns.length + 1)) + '%' }}>
                            <InputGroup type={'number'} value={total} disabled noBorder={true} text={unit} format="money" />
                        </div>}
                    </div>
                </ScrollSyncPane>
            </div>
            <div className={styles.break}></div>
            {showContent && !!investmentType.establishments && investmentType.establishments.length > 0 && <div className={styles.contentLine}>
                {total > 0 && <span className={`${styles.textFormat} ${roundEstablishmentsPercentage < 100 ? styles.textDanger : ''}`}>
                    <PercentageFormat value={roundEstablishmentsPercentage} renderAsText={true} />
                    {t('project.investments.total_value_distributed_by_the_poles')}
                </span>}
                {(investmentType.establishments || []).map((item, i) => (
                    <div key={`item-${i}-${item.establishmentId}`}>
                        <ProjectEvaluationLineEstablishment
                            isDetails={isDetails}
                            key={i}
                            className={styles.lineEstablishment}
                            item={item}
                            form={form}
                            investmentTypeName={investmentTypeName}
                            establishmentName={`${investmentTypeName}.establishments[${i}]`}
                            valueUnit={unit}
                        />
                    </div>

                ))}
            </div>}

        </div>
    );
}

export default memo(ProjectEvaluationLine);
