import Http from '../../common/services/Http';
import { IdNameDto } from '../common/models/IdNameDto';
import { Page } from '../common/types/Page';
import { ProductSearchCriteria } from './models/ProductSearchCriteria';
import { ProductViewModel } from './models/ProductViewModel';

class ProductService {
    
    public getList(criteria: ProductSearchCriteria) {
        return Http.get<Page<ProductViewModel>>('products', criteria);
    }

    public getProductById(id: string) {
        return Http.get<ProductViewModel>('products/'+ id);
    }

    public save(model: ProductViewModel) {
        return !!model.id ? Http.put('products', model) : Http.post('products', model);
    }

    public remove(model: ProductViewModel) {
        return  Http.put('products/deactivate', model);
    }

    public getProducts(criteria: ProductSearchCriteria) {
        return Http.get<IdNameDto[]>('products/get-all-domain-projects-for-select-item', criteria);
    }
}

export default new ProductService();