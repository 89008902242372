import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import reducers from './reducers';

const store = createStore(
	combineReducers(reducers),
	composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
