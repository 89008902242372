import React, { useState } from 'react';
import ListStatusScreen from '../../status/list/ListStatusScreen';
import StatusScreen from '../../status/StatusScreen';
import { StatusViewModel } from '../../../api/status/models/StatusViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
};

enum ScreenType {
    LIST_STATUS,
    STATUS
}

const ParametersStatusScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_STATUS);
    const [currentStatus, setCurrentStatus] = useState<StatusViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_STATUS && !!groupId && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.STATUS}/> }
            { screen === ScreenType.LIST_STATUS && <ListStatusScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewStatus={() => {
                    setIsDetails(false);
                    setCurrentStatus(null);
                    setScreen(ScreenType.STATUS)
                }}
                onClickItem={(item: StatusViewModel, isDetails: boolean) => {
                    if(item.active)
                    {
                        setIsDetails(isDetails);
                        setCurrentStatus(item);
                        setScreen(ScreenType.STATUS);
                    }
                }}
            ></ListStatusScreen>}
            { screen === ScreenType.STATUS && <StatusScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                statusId={currentStatus?.id}
                afterSave={() => {
                    setCurrentStatus(null);
                    setScreen(ScreenType.LIST_STATUS);
                }}></StatusScreen>}
        </div>

    );
}

export default ParametersStatusScreen;
