import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from './Navbar.module.scss';
import { UserProfile } from '../../../api/account/models/UserProfile';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../../store/types';
import DropdownWrapper from 'react-dropdown-wrapper';
import { DropdownWrapperChildFunc } from '../../types/ReactDropdownWrapper';
import { FaSignOutAlt } from 'react-icons/fa';
import { logout } from '../../../store/authentication/action';
import { APP_VERSION, NAVBAR_ID } from '../../../Config';

export interface NavbarItem {
    text: string;
    url: string;
}

type Props = {
    items: NavbarItem[];
}

const Navbar: React.FunctionComponent<Props> = ({ items }: Props) => {
    const { t } = useTranslation();
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const dispatch = useDispatch();

    const isActive = (path: any, match: any, location: any) => !!(match || path === location.pathname);

    const onLogoutClick = () => {
        dispatch(logout());
    }

    return (
        <div className={styles.container} id={NAVBAR_ID}>
            <div className={styles.innerContainer}>
                <div className={styles.title}>
                    <NavLink to={'/'}>
                        <div className={styles.appName}><span>{t('app.name_pre')}</span><span>{t('app.name_suf')}</span></div>
                        <div className={styles.appVersion}>{t('app.version')} {APP_VERSION}</div>
                    </NavLink>
                </div>
                <div className={styles.itemsContainer}>
                    {items.map((item, i) => (
                        <div className={styles.item} key={`navbar-item-${i}`}>
                            <NavLink
                                strict={false}
                                to={item.url}
                                activeClassName={styles.itemActive}
                                isActive={isActive.bind(this, item.url)}
                                exact={item.url === '/' ? true : false}
                            >
                                {item.text}
                            </NavLink>
                        </div>
                    ))}
                    <div className={styles.rightContainer}>
                        <DropdownWrapper>
                            {({ changeStatus, isShow }: DropdownWrapperChildFunc) => (
                                <>
                                    <div className={styles.userItem} onClick={() => changeStatus(!isShow)}>
                                        <div className={styles.avatar}></div>
                                        <div className={styles.userName}>{user?.realName}</div>
                                    </div>
                                    {isShow && (
                                        <div className={styles.dropdownContainer}>
                                            <button className={styles.dropdownItem} onClick={onLogoutClick}>
                                                <FaSignOutAlt className={styles.dropdownItemIcon} /> <span>{t('menu.logout')}</span>
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </DropdownWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
