import Http from '../../common/services/Http';
import { InternalAllocationTypeDto } from './models/InternalAllocationTypeDto';
class InternalAllocationTypesService {

    public getByProjectType(projectType: string) {
        return Http.get<InternalAllocationTypeDto[]>('internalAllocationTypes/get-by-project-type/' + projectType);
    }
   
}

export default new InternalAllocationTypesService();