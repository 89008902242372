import React, { memo }  from 'react';
import { IconType } from 'react-icons';
import Label from '../../../../../../common/components/label/Label';
import styles from './ProjectInternalAllocationBox.module.scss';
import MoneyFormat from '../../../../../../common/components/moneyFormat/MoneyFormat';
import ProjectEvaluationLine from '../projectEvaluationLine/ProjectEvaluationLine';
import { useTranslation } from 'react-i18next';
import { ProjectAllocationTotalsViewModel, ProjectAllocationTypeViewModel } from '../../../../../../api/projects/models/ProjectViewModel';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../../../models/ProjectForm';
import DateFormat from '../../../../../../common/components/dateFormat/dateFormat';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'

export interface DateFormatModeItem {
   date: Date,
   format: string
}

type Props = {
    isDetails: boolean;
    title: string;
    icon?: React.ReactElement<IconType> | undefined,
    isTotal?: boolean | undefined,
    form: UseFormMethods<ProjectForm>;
    internalAllocationTypes: UseFieldArrayMethods<ProjectAllocationTypeViewModel, 'id'>;
    internalAllocationTotals: UseFieldArrayMethods<ProjectAllocationTotalsViewModel, 'id'>;
    unit: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectInternalAllocationBox: React.FC<Props> = ({ isDetails, isTotal, internalAllocationTypes, form, internalAllocationTotals, unit, ...props }: Props) => {

    const { t } = useTranslation();

    const table = document.getElementById('ProjectInternalAllocationsTable');
    const tableWidth = table?.getBoundingClientRect().width ?? 0;

    const DEFAULT_NUMBER_ITEMS_FULLWIDTH = parseInt((tableWidth / 300).toString());

    
    const name = `internalAllocationTypes`;
    const allocationTotalsName = `internalAllocationTotals`;

    let totalHeader = 0;

    const columnSize = (100).divide(internalAllocationTotals.fields.length.plus(1)) + '%';

    const internalAllocationTypesWatch = form.watch<string, ProjectAllocationTypeViewModel[]>(name);
    const internalAllocationTotalsWatch = form.watch<string, ProjectAllocationTotalsViewModel[]>(allocationTotalsName);

    const setInvestimentTotals = (i: number, allocationTypeIndex: number) => {
        const internalAllocationTypesWatch = form.watch<string, ProjectAllocationTypeViewModel[]>(name);
        const allocationTotal = internalAllocationTypesWatch.map(x => Number(x.dates[i].total)).sum();
        form.setValue(`${allocationTotalsName}[${i}].total`, allocationTotal);

        // Update establishments totals
        const investmentType = form.watch<string, ProjectAllocationTypeViewModel>(`${name}[${allocationTypeIndex}]`);
        const total = investmentType?.dates?.map(x => Number(x.total)).sum();

        investmentType.establishments.forEach((est, estIndex) => {
            let per = Number(est.percentage.toFixed(2));
            
            if(investmentType.establishments.length == 1){
                per = 100;
            }
            const val = (total.multiply(per)).divide(100);
            form.setValue(`${name}[${allocationTypeIndex}].establishments[${estIndex}].total`, val);
            form.setValue(`${name}[${allocationTypeIndex}].establishments[${estIndex}].percentage`, per);

            if(est?.poles.length == 1){
                form.setValue(`${name}[${allocationTypeIndex}].establishments[${estIndex}].poles[0].percentage`, 100);
            }
        });
    }

    return (
        <ScrollSync horizontal={true} vertical={false}>
            <div {...props} id={'ProjectInternalAllocationsTable'}>

                {/* Dates */}
                <div className={styles.flex}>
                    <div className={styles.areaDetails}>
                        <div className={ `${styles.headerOutside} ${styles.boxDetails}` }></div>
                    </div>
                    <ScrollSyncPane>
                        <div className={`${(internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? styles.areaValue : styles.areaValueFixed)} ${styles.scrollPane}`}>
                            {internalAllocationTotals.fields && internalAllocationTotals.fields.map((item: any, i: number) => {
                                return (
                                    <div key={i} className={ 
                                        internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues} ${styles.labelHeaderOutside}` : `${styles.boxValuesFixed} ${styles.labelHeaderOutside}` }
                                        style={ internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>
                                        <DateFormat value={item.date} format={item.dateFormat}></DateFormat>
                                    </div>
                                );
                            }) }
                            {isTotal && <div className={internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValuesTotal} ${styles.labelHeaderOutside}` : `${styles.boxValuesTotalFixed} ${styles.labelHeaderOutside}` }
                                style={internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>{t('common.total')}</div> }
                        </div>
                    </ScrollSyncPane>
                </div>

                <div className={styles.flex}>
                    <div className={ `${styles.areaDetails}` }>
                        <div className={ `${styles.header} ${styles.boxDetails}` }>
                            <div className={styles.shadowBall}></div>
                            <div className={styles.labelContainer}>
                                { props.icon && <span className={styles.labelHeader && styles.iconHeader}>{props.icon}</span> }
                                <Label className={styles.labelHeaderTitle}>
                                    {props.title}
                                </Label>
                            </div>
                        </div>
                    </div>
                    {/* SCROLL */}
                    <ScrollSyncPane>
                        <div className={`${internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? styles.areaValueHeader : styles.areaValueHeaderFixed} ${styles.scrollPane}`}>
                            { internalAllocationTotals.fields && internalAllocationTotals.fields.map((item, i: number) => {
                                const totalColumnHeader = internalAllocationTypesWatch?.map(x => Number(x.dates[i].total)).sum();
                                totalHeader = totalHeader.plus(totalColumnHeader);
                                return (
                                    <Label 
                                        key={i} 
                                        className={ internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues} ${styles.labelHeader}` : `${styles.boxValuesFixed} ${styles.labelHeader}` } 
                                        style={ internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>
                                            <MoneyFormat value={totalColumnHeader} suffix={unit} />
                                    </Label> 
                                );
                            }) }
                            {isTotal && <div className={internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues} ${styles.labelHeader}` : `${styles.boxValuesFixed} ${styles.labelHeader}` }
                                style={ internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>
                                <MoneyFormat value={totalHeader} suffix={unit}/>
                            </div> }
                        </div>
                    </ScrollSyncPane>
                </div>

                <div>
                    <div className={styles.content}>
                        { internalAllocationTypes && internalAllocationTypes.fields.map((item, i) => (
                            <ProjectEvaluationLine
                                isDetails={isDetails}
                                key={i}
                                investmentType={item}
                                showColumns={true}
                                numberColumns={internalAllocationTotalsWatch.length}
                                isTotalColumn={isTotal}
                                form={form}
                                investmentTypeName={`${name}[${i}]`}
                                investmentTypeIndex={i}
                                numberItemsFullWidth={DEFAULT_NUMBER_ITEMS_FULLWIDTH}
                                setInvestimentTotals={setInvestimentTotals}
                                unit={unit}
                            />
                        )) }
                    </div>
                </div>

                {internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH &&
                    <div className={`${styles.flex} ${styles.footerContainer}`}>
                        <div className={styles.areaDetails}>
                            <div className={`${styles.footer} ${styles.boxDetails}`}></div>
                        </div>
                        {/* SCROLL */}
                        <ScrollSyncPane>
                            <div className={`${internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? styles.areaValueFooter : styles.areaValueFooterFixed}`}>
                                {internalAllocationTotals.fields && internalAllocationTotals.fields.map((item, i: number) => {
                                    return (
                                        <div key={i} className={internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues}` : `${styles.boxValuesFixed} `}
                                            style={internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>
                                            
                                        </div>
                                    );
                                })}
                                { isTotal && <div className={internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues}` : `${styles.boxValuesFixed} `}
                                    style={internalAllocationTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>
                                </div>}
                            </div>
                        </ScrollSyncPane>
                    </div>
                }

            </div>
        </ScrollSync>
    );
}

export default memo(ProjectInternalAllocationBox);
