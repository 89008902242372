import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from '../formItem/FormItem';
import Label from '../label/Label';
import styles from './RangeInputPicker.module.scss';
import DateTimePicker from '../dateTimePicker/DateTimePicker';
import { useTranslation } from 'react-i18next';
import InputGroup from '../inputGroup/InputGroup';
import { FaCalendarAlt } from 'react-icons/fa';
import moment from 'moment';
import Input from '../input/Input';
import Storage from '../../services/Storage';
import { STORAGE, DEFAULT_LANGUAGE } from '../../../Config';

type Props = {
  label?: string,
  onChange: (startValue: any, endValue: any) => any,
  isDatePicker?: boolean,
  showMonthYearPicker?: boolean,
  defaultStartValue?: any,
  defaultEndValue?: any,
  groupSymbol?: string,
  type?: string,
  dateFormat?: string,
  showTimeInput?: boolean
};

const RangeInputPicker: React.FC<Props> = ({ label, isDatePicker, showMonthYearPicker, defaultStartValue, defaultEndValue, groupSymbol, onChange, type, dateFormat, showTimeInput }: Props) => {

  const [startValue, setStartValue] = useState<any | null>(defaultStartValue != null ? defaultStartValue : null);
  const [endValue, setEndValue] = useState<any | null>(defaultEndValue != null ? defaultEndValue : null);

  useEffect(() => {
    setStartValue(defaultStartValue);
  }, [defaultStartValue]);

  useEffect(() => {
    setEndValue(defaultEndValue);
  }, [defaultEndValue]);

  const { t } = useTranslation();
  const locale = Storage.get(STORAGE.CURRENT_LOCALE) || DEFAULT_LANGUAGE;
  
  return (
    <Row>
      <Col className={styles.break}>
        <Row>
          <Col xs={12}>
            <div style={{ display: 'inline-flex' }}>
              {label && <Label className={styles.bold}>{label}</Label>}
              {Boolean(startValue) && <Label className={styles.light} style={{ marginLeft: '0.5rem' }}>{t('common.range.from')}</Label>}
            </div>
          </Col>
          <Col xs={12}>
            <FormItem>
              {isDatePicker && <DateTimePicker
                name="startDate"
                dateFormat={dateFormat ? dateFormat :'dd/MM/yyyy'}
                locale={locale}
                autoComplete='off'
                placeholderText={t('common.range.from')}
                onChange={(date: Date) => {
                  setStartValue(date);
                  onChange(date, endValue);
                }}
                selected={startValue ? moment(startValue).toDate() : undefined}
                customInput={<InputGroup icon={<FaCalendarAlt />} />}
                maxDate={endValue}
                timeInputLabel={t('common.time')}
                showMonthYearPicker={showMonthYearPicker}
                showTimeInput={showTimeInput}
              />}
              {!isDatePicker && groupSymbol && <InputGroup
                value={startValue}
                type="number"
                text={groupSymbol}
                placeholder={t('common.range.from')}
                format="money"
                min={0}
                max={endValue}
                onValueChange={value => {
                  setStartValue(value || value === 0 ? Number(value) : undefined);
                  onChange(value || value === 0 ? Number(value) : undefined, endValue);
                }}
              />}
              {!isDatePicker && !groupSymbol && <Input
                type={type ?? 'text'}
                min={type == 'number' ? 0 : undefined}
                placeholder={t('common.range.from')}
                onChange={e => {
                  const value = e.currentTarget.value;
                  setStartValue(value);
                  onChange(value, endValue);
                }}
                value={startValue}
              />}
            </FormItem>
          </Col>
        </Row>
      </Col>
      <Col className={styles.break}>
        <Row>
          <Col xs={12}>
            <div style={{ display: 'inline-flex' }}>
              {Boolean(endValue) && <Label className={styles.light}>{t('common.range.to')}</Label>}
            </div>
          </Col>
          <Col xs={12}>
            <FormItem>
              {isDatePicker && <DateTimePicker
                name="endDate"
                dateFormat={dateFormat ? dateFormat : 'dd/MM/yyyy'}
                locale={locale}
                autoComplete='off'
                placeholderText={t('common.range.to')}
                onChange={(date: Date) => {
                  const newDate = date && showMonthYearPicker ? new Date(date.getFullYear(), date.getMonth() + 1, 0) : date;

                  setEndValue(newDate);
                  onChange(startValue, newDate);
                }}
                selected={endValue ? moment(endValue).toDate() : undefined}
                customInput={<InputGroup icon={<FaCalendarAlt />} />}
                minDate={startValue}
                timeInputLabel={t('common.time')}
                showMonthYearPicker={showMonthYearPicker}
                showTimeInput={showTimeInput}
              />}
              {!isDatePicker && groupSymbol && <InputGroup
                value={endValue}
                type="number"
                text={groupSymbol}
                placeholder={t('common.range.to')}
                format="money"
                min={startValue}
                onValueChange={value => {
                  setEndValue(value || value === 0 ? Number(value) : undefined);
                  onChange(startValue, value || value === 0 ? Number(value) : undefined);
                }}
              />}
              {!isDatePicker && !groupSymbol && <Input
                type={type ?? 'text'}
                min={type == 'number' ? 0 : undefined}
                placeholder={t('common.range.to')}
                onChange={e => {
                  const value = e.currentTarget.value;
                  setEndValue(value);
                  onChange(startValue, value);
                }}
                value={endValue}
              />}
            </FormItem>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default RangeInputPicker;