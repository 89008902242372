import React, { memo, ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt, FaEraser, FaSortAlphaDown, FaSortAlphaUp, FaFilter } from 'react-icons/fa';
import { DepartmentViewModel } from '../../../../api/departments/models/DepartmentViewModel';
import { DomainViewModel } from '../../../../api/domains/models/DomainViewModel';
import { PartnersViewModel } from '../../../../api/partners/models/PartnersViewModel';
import { PriorityLevelViewModel } from '../../../../api/priorityLevels/models/PriorityLevelViewModel';
import { ProductViewModel } from '../../../../api/products/models/ProductViewModel';
import { ProjectManagerViewModel } from '../../../../api/projectManagers/models/ProjectManagerViewModel';
import { ProjectResponsibleViewModel } from '../../../../api/projectResponsibles/models/ProjectResponsibleViewModel';
import { ProjectsListDto } from '../../../../api/projects/models/ProjectsListDto';
import { SearchField, SearchType } from '../../../../api/projects/models/ProjectsSearchCriteria';
import { StatusViewModel } from '../../../../api/status/models/StatusViewModel';
import { StrategicAxesViewModel } from '../../../../api/strategicAxes/models/StrategicAxesViewModel';
import { TypologiesViewModel } from '../../../../api/typologies/models/TypologiesViewModel';
import Button from '../../../../common/components/button/Button';
import DateFormat from '../../../../common/components/dateFormat/dateFormat';
import DateTimePicker from '../../../../common/components/dateTimePicker/DateTimePicker';
import InputGroup from '../../../../common/components/inputGroup/InputGroup';
import MoneyFormat from '../../../../common/components/moneyFormat/MoneyFormat';
import SelectController from '../../../../common/components/select/SelectController';
import { TableColumn, TableRow } from '../../../../common/components/table/Table';
import { SelectValueLabel } from '../../../../common/types/SelectValueLabel';
import ProjectsFiltersInput from '../ProjectsFiltersInput';
import ReactTable, { CellInfo, RowInfo, SortingRule } from 'react-table';
import withFixedColumns, { ColumnFixed } from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import styles from './TableProjectBox.module.scss';
import { UserViewModel } from '../../../../api/users/models/UserViewModel';
import DateTimePickerRange from '../../../../common/components/dateTimePickerRange/DateTimePickerRange';
import { ProjectResponsibleDsiViewModel } from '../../../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiViewModel';
import { RiskLevel } from '../../../../api/projects/models/ProjectViewModel';
import Semaphore from '../Semaphore/Semaphore';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export interface Filters {
    identification?: string;
    designation?: string;
    statusId?: string[];
    projectManageId?: string[];
    priorityLevelId?: string[];
    strategicAxisId?: string[];
    departmentId?: string[];
    domainId?: string[];
    searchEstablishment?: string;
    partnerId?: string[];
    productId?: string[];
    responsibleId?: string[];
    typologyId?: string[];
    dateUpdatedProject?: Date;
    userUpdatedProject?: string[];
    startBudgetings?: number;
    endBudgetings?: number;
    startDate?: Date | null;
    endDate?: Date | null;
    startCharges?: number;
    endCharges?: number;
    startRecurring?: number;
    endRecurring?: number;
    startInvestments?: number;
    endInvestments?: number;
    authorId?: string[];
    startSubventionsExpected?: number;
    endSubventionsExpected?: number;
    startSubventionsObtained?: number;
    endSubventionsObtained?: number;
    responsibleDsiId?: string[];
    riskLevel?: string[];
}

type Props = {
    status: StatusViewModel[];
    projectManagers: ProjectManagerViewModel[];
    priorityLevels: PriorityLevelViewModel[];
    strategicAxes: StrategicAxesViewModel[];
    departments: DepartmentViewModel[];
    domains: DomainViewModel[];
    partners: PartnersViewModel[];
    products: ProductViewModel[];
    responsibles: ProjectResponsibleViewModel[];
    typologies: TypologiesViewModel[];
    authors: UserViewModel[];
    users: UserViewModel[];
    responsiblesDsi: ProjectResponsibleDsiViewModel[];

    columns: TableColumn[];
    rows: ProjectsListDto[];
    onRowClick?: (row: TableRow) => void;
    onOrderList?: (column: TableColumn) => void;
    filters: Filters | undefined;
    onFilter: (filters: Filters) => void;
    onClearFilters: () => void;
    setColumnsWidth: (obj: any) => void;
    filtersTotal: number;
    orderBy?: string;
    orderColumn?: string;

} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const TableProjectBox: React.FC<Props> = ({
    status, projectManagers, priorityLevels, strategicAxes, departments, domains, partners, products, responsibles, typologies, authors, users, responsiblesDsi,
    columns, rows, onRowClick, onOrderList, filters, onFilter, onClearFilters, setColumnsWidth, filtersTotal, orderBy, orderColumn,
    ...props
}: Props) => {

    const { t } = useTranslation();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [dateUpdateProject, setDateUpdateProject] = useState<Date>();

    const statusOptions: SelectValueLabel[] = (status || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const projectManagersOptions: SelectValueLabel[] = (projectManagers || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const priorityLevelsOptions: SelectValueLabel[] = (priorityLevels || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const strategicAxesOptions: SelectValueLabel[] = (strategicAxes || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const departmentsOptions: SelectValueLabel[] = (departments || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const domainsOptions: SelectValueLabel[] = (domains || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const partnersOptions: SelectValueLabel[] = (partners || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const productsOptions: SelectValueLabel[] = (products || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const responsiblesOptions: SelectValueLabel[] = (responsibles || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const typologiesOptions: SelectValueLabel[] = (typologies || []).map(x => ({ value: x.id || '', label: x.name || '' }));
    const authorsOptions: SelectValueLabel[] = (authors || []).map(x => ({ value: x.id || '', label: x.realName || '' }));
    const usersOptions: SelectValueLabel[] = (users || []).map(x => ({ value: x.id || '', label: x.realName || '' }));
    const responsiblesDsiOptions: SelectValueLabel[] = (responsiblesDsi || []).map(x => ({ value: x.id || '', label: x.name || '' }));


    const setFilters = (filters: Filters | undefined) => {
        if (filters) {
            form.setValue('identification', filters.identification);
            form.setValue('designation', filters.designation);
            form.setValue('statusId', filters.statusId);
            form.setValue('projectManageId', filters.projectManageId);
            form.setValue('priorityLevelId', filters.priorityLevelId);
            form.setValue('strategicAxisId', filters.strategicAxisId);
            form.setValue('departmentId', filters.departmentId);
            form.setValue('domainId', filters.domainId);
            form.setValue('searchEstablishment', filters.searchEstablishment);
            form.setValue('partnerId', filters.partnerId);
            form.setValue('productId', filters.productId);
            form.setValue('responsibleId', filters.responsibleId);
            form.setValue('typologyId', filters.typologyId);
            form.setValue('dateUpdatedProject', filters.dateUpdatedProject);
            if (filters.dateUpdatedProject) {
                const d = new Date(filters.dateUpdatedProject);
                setDateUpdateProject(d)
                form.setValue('dateUpdatedProject', d);
            }
            form.setValue('userUpdatedProject', filters.userUpdatedProject);
            form.setValue('startBudgetings', filters.startBudgetings);
            form.setValue('endBudgetings', filters.endBudgetings);
            if (filters.startDate) {
                const d = new Date(filters.startDate);
                setStartDate(d)
                form.setValue('startDate', d);
            }
            if (filters.endDate) {
                const d = new Date(filters.endDate);
                setEndDate(d);
                form.setValue('endDate', d);
            }
            form.setValue('startCharges', filters.startCharges);
            form.setValue('endCharges', filters.endCharges);
            form.setValue('startRecurring', filters.startRecurring);
            form.setValue('endRecurring', filters.endRecurring);
            form.setValue('startInvestments', filters.startInvestments);
            form.setValue('endInvestments', filters.endInvestments);
            form.setValue('authorId', filters.authorId);
            form.setValue('startSubventionsExpected', filters.startSubventionsExpected);
            form.setValue('endSubventionsExpected', filters.endSubventionsExpected);
            form.setValue('startSubventionsObtained', filters.startSubventionsObtained);
            form.setValue('endSubventionsObtained', filters.endSubventionsObtained);
            form.setValue('responsibleDsiId', filters.responsibleDsiId);
            form.setValue('riskLevel', filters?.riskLevel ?? []);
        }
    }

    useEffect(() => {
        setFilters(filters ?? undefined);
    }, []);

    const clearFilters = () => {
        form.reset();
        form.setValue('identification', null);
        form.setValue('designation', null);
        form.setValue('statusId', null);
        form.setValue('projectManageId', null);
        form.setValue('priorityLevelId', null);
        form.setValue('strategicAxisId', null);
        form.setValue('departmentId', null);
        form.setValue('domainId', null);
        form.setValue('searchEstablishment', null);
        form.setValue('partnerId', null);
        form.setValue('productId', null);
        form.setValue('responsibleId', null);
        form.setValue('typologyId', null);
        form.setValue('dateUpdatedProject', null);
        form.setValue('userUpdatedProject', null);
        form.setValue('startBudgetings', null);
        form.setValue('endBudgetings', null);
        form.setValue('startDate', null);
        form.setValue('endDate', null);
        form.setValue('startCharges', null);
        form.setValue('endCharges', null);
        form.setValue('startRecurring', null);
        form.setValue('endRecurring', null);
        form.setValue('startInvestments', null);
        form.setValue('endInvestments', null);
        form.setValue('authorId', null);
        form.setValue('startSubventionsExpected', null);
        form.setValue('endSubventionsExpected', null);
        form.setValue('startSubventionsObtained', null);
        form.setValue('endSubventionsObtained', null);
        form.setValue('responsibleDsiId', null);
        form.setValue('riskLevel', [RiskLevel.LOW, RiskLevel.MEDIUM, RiskLevel.HIGH]);
        
        onClearFilters();
        form.handleSubmit(onSubmit)();
    }

    const onSubmit = (filters: Filters) => {
        onFilter(filters);
    }

    const onInputChange = () => {
        //onFilter(form.getValues()); 
        //comentei isto porque ao filtrar numa drop o useEffects tava disparar duas vezes e estourava ao guardar os filtros do utilizador
        //com isto comentado funciona tudo, o guardar os filtros e a respectiva pesquisa
    }

    const capitalize = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const rendeHeader = (col: TableColumn) => (
        <div style={!!col.order ? { cursor: 'pointer' } : {}}>
            <span className={styles.headerName}>
                {!col.showAlways && !col.isActionColumn && <FaFilter className={styles.iconFilter}></FaFilter>}
                {col.name}</span>
            {!col.isActionColumn && orderColumn == col.order && <span>
                {(!orderBy || orderBy === 'asc') && <FaSortAlphaDown className={styles.iconOrder}></FaSortAlphaDown>}
                {orderBy === 'desc' && <FaSortAlphaUp className={styles.iconOrder}></FaSortAlphaUp>}
            </span>
            }
        </div>
    );

    const rendeSearch = (col: TableColumn): ReactElement => (
        <>
            {/* filtros que precisam de debounce - so pesquisar no fim de acabar de escrever */}
            { col.searchType && (col.searchType == SearchType.TEXT || col.searchType == SearchType.RANGE_VALUE) ?
                <ProjectsFiltersInput
                    searchType={col.searchType}
                    searchField={col.searchField ?? ''}
                    name={col.name}
                    formAux={form}
                    onChange={() => {
                        onInputChange();
                        form.handleSubmit(onSubmit)();
                    }}
                    placeholder={col.placeholder}></ProjectsFiltersInput> : ''}

            { col.searchType && col.searchType == SearchType.SELECT ?
                <SelectController isClearable name={col.searchField || ''}
                    form={form}
                    isDisabled={false}
                    placeholder={col.name}
                    isSelectSearch={true}
                    options={col.searchField == SearchField.STATUS ? statusOptions : col.searchField == SearchField.PROJECT_MANAGER ? projectManagersOptions :
                        col.searchField == SearchField.PRIORITY_LEVEL ? priorityLevelsOptions : col.searchField == SearchField.STRATEGIC_AXIS ? strategicAxesOptions :
                            col.searchField == SearchField.DEPARTMENT ? departmentsOptions : col.searchField == SearchField.DOMAIN ? domainsOptions :
                                col.searchField == SearchField.PARTNER ? partnersOptions : col.searchField == SearchField.PRODUCT ? productsOptions :
                                    col.searchField == SearchField.RESPONSIBLE ? responsiblesOptions : col.searchField == SearchField.TYPOLOGY ? typologiesOptions :
                                        col.searchField == SearchField.AUTHOR ? authorsOptions :
                                            col.searchField == SearchField.USER_UPDATED_PROJECT ? usersOptions :
                                                col.searchField == SearchField.RESPONSIBLE_DSI ? responsiblesDsiOptions : []}
                    onChange={el => {
                        onInputChange();
                        form.handleSubmit(onSubmit)();
                    }} /> : ''}



{ col.searchType && col.searchType == SearchType.MULTISELECT ?
                <SelectController isClearable name={col.searchField || ''}
                    isMulti={true as any}
                    showOnlyFirstSelected={true}
                    form={form}
                    isDisabled={false}
                    placeholder={col.name}
                    isSelectSearch={true}
                    options={col.searchField == SearchField.STATUS ? statusOptions : col.searchField == SearchField.PROJECT_MANAGER ? projectManagersOptions :
                        col.searchField == SearchField.PRIORITY_LEVEL ? priorityLevelsOptions : col.searchField == SearchField.STRATEGIC_AXIS ? strategicAxesOptions :
                            col.searchField == SearchField.DEPARTMENT ? departmentsOptions : col.searchField == SearchField.DOMAIN ? domainsOptions :
                                col.searchField == SearchField.PARTNER ? partnersOptions : col.searchField == SearchField.PRODUCT ? productsOptions :
                                    col.searchField == SearchField.RESPONSIBLE ? responsiblesOptions : col.searchField == SearchField.TYPOLOGY ? typologiesOptions :
                                        col.searchField == SearchField.AUTHOR ? authorsOptions :
                                            col.searchField == SearchField.USER_UPDATED_PROJECT ? usersOptions :
                                                col.searchField == SearchField.RESPONSIBLE_DSI ? responsiblesDsiOptions : []}
                    onChange={el => {
                        onInputChange();
                        form.handleSubmit(onSubmit)();
                    }} /> : ''}

            { col.searchType && col.searchType == SearchType.DATE ?
                <div>
                    <DateTimePicker
                        selected={dateUpdateProject}
                        name={col.searchField}
                        dateFormat="dd/MM/yyyy"
                        locale='fr'
                        autoComplete='off'
                        placeholderText={col.name}
                        customInput={<InputGroup noBorder={false} icon={<FaCalendarAlt />} />}
                        onChange={(date: Date) => {
                            if (col.searchField == SearchField.DATE_UPDATED_PROJECT) {
                                form.setValue('dateUpdatedProject', date);
                            }
                            form.handleSubmit(onSubmit)();
                        }} /> </div> : ''}


            { col.searchType && col.searchType == SearchType.RANGE_DATE ?
                <div className={styles.dateRangeCustom}>
                    <DateTimePickerRange
                        dateFormat="MM/yyyy"
                        locale='fr'
                        start={startDate}
                        end={endDate}
                        showMonthYearPicker
                        placeholderText={t('common.start_end')}
                        onChange={(dates: [Date, Date]) => { console.log('change') }}
                        onChangeRange={(start: Date | null, end: Date | null) => {
                            form.setValue('startDate', start || null);
                            setStartDate(start ?? undefined);
                            let dateLastDayOfMonth = end;
                            if (end) {
                                dateLastDayOfMonth = new Date(end.getFullYear(), end.getMonth() + 1, 0, 23, 59, 59);
                            }
                            form.setValue('endDate', dateLastDayOfMonth);
                            setEndDate(end ?? undefined);
                            form.handleSubmit(onSubmit)();
                        }}
                    />
                </div> : ''}


                { col.searchType && col.searchType == SearchType.LIGHTS ?
                    <Semaphore selectedItems={form.getValues('riskLevel') ?? []} onChange={(items:string[]) => {
                        form.setValue('riskLevel', items);
                        form.handleSubmit(onSubmit)();
                    }}></Semaphore>
                : ''}

            { col.isActionColumn ?
                <div className={styles.clearFilters}>
                    <Button onClick={() => clearFilters()} size="small" preset="primary" onlyIcon={true}>
                        <FaEraser />
                    </Button>
                    {filtersTotal > 0 && <small className={styles.totalFilters}>{filtersTotal} {t('common.filters_selected')}</small>}
                </div>
                : ''}
        </>
    );

    const getRowCellValue = (row: TableRow, col: TableColumn) => {
        if (col.renderCell) {
            return col.renderCell({ row, column: col });
        }

        const value = row[col.field ?? ''];

        if (col.cellFormat && col.cellFormat === 'money') {
            return <div className={!col.cellAlignment
                ? styles.textAlignLeft
                : col.cellAlignment === 'right'
                    ? styles.textAlignRight
                    : styles.textAlignCenter}>
                <MoneyFormat value={value}
                    suffix={col.cellFormatUnitByField ? row[col.cellFormatUnitByField] : null}
                />
            </div>;
        }

        if (col.cellFormat && col.cellFormat === 'date') {
            return <div className={!col.cellAlignment
                ? styles.textAlignLeft
                : col.cellAlignment === 'right'
                    ? styles.textAlignRight
                    : styles.textAlignCenter}>
                <DateFormat value={value} />
            </div>;
        }

        return !!value ? value : <span>&nbsp;</span>;
    };

    const customizeCell = (cellInfo: CellInfo, column: any, tblColumn: TableColumn) => {
        return getRowCellValue(cellInfo.original, tblColumn)
    }

    const mapColumns: ColumnFixed[] =
        columns.map(x => 
        (
            {
                Header: () => rendeHeader(x),
                accessor: x.field,
                fixed: x.showAlways ? 'left' : x.isActionColumn ? 'right' : undefined,
                width: x.width ? Number(x.width) : undefined,
                minWidth: x.minWidth ? Number(x.minWidth) : undefined,
                resizable: !x.isActionColumn,
                Cell: (cellInfo, column) => customizeCell(cellInfo, column, x),
                Filter: () => rendeSearch(x),
                manualFilters: true,
                style: {
                    minWidth: (!!x.minWidth ? x.minWidth : 100) + 'px'
                },
                headerStyle: {
                    minWidth: (!!x.minWidth ? x.minWidth : 100) + 'px'
                },
                sortable: !!x.order,

            } as ColumnFixed)
        );

    const onResize = (newResized: any, event: any) => {
        setColumnsWidth(newResized[0]);
    }

    const NoDataComponent = () => {
        return (
            <div className={styles.noData}>{t('common.without_elements_for_the_research')}</div>
        )
    }

    return ( 
        <div {...props}>
            <ReactTableFixedColumns
                filterable
                showPaginationBottom={false}
                data={rows}
                columns={mapColumns}
                defaultPageSize={rows.length}
                multiSort={false}
                NoDataComponent={NoDataComponent}
                className=""
                resizable={true}
                onResizedChange={onResize}
                getTdProps={(finalState: any, rowInfo?: RowInfo, column?: undefined, instance?: any) => ({
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    },
                    onClick: (e: any, t: any) => { onRowClick && onRowClick(rowInfo?.original as TableRow) }
                })}
                onSortedChange={(newSorted: SortingRule[], column: any, additive: boolean) => {
                    const columnInList: TableColumn = columns.filter(x => x.field == column.id)[0];
                    onOrderList && onOrderList(columnInList);
                }}
                defaultSortMethod={(a: any, b: any, desc: any) => 0}
            />
        </div>
    );
}

export default memo(TableProjectBox);
