import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { ProjectResponsibleDsiSearchCriteria } from './models/ProjectResponsibleDsiSearchCriteria';
import { ProjectResponsibleDsiViewModel } from './models/ProjectResponsibleDsiViewModel';


class ProjectResponsiblesDsiService {
    
    public getList(criteria: ProjectResponsibleDsiSearchCriteria) {
        return Http.get<Page<ProjectResponsibleDsiViewModel>>('projectResponsiblesDsi', criteria);
    }

    public getProjectResponsibleDsiById(id: string) {
        return Http.get<ProjectResponsibleDsiViewModel>('projectResponsiblesDsi/'+ id);
    }

    public save(model: ProjectResponsibleDsiViewModel) {
        return !!model.id ? Http.put('projectResponsiblesDsi', model) : Http.post('projectResponsiblesDsi', model);
    }

    public remove(model: ProjectResponsibleDsiViewModel) {
        return  Http.put('projectResponsiblesDsi/deactivate', model);
    }
}

export default new ProjectResponsiblesDsiService();