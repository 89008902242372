import React from 'react';
import Select, { Props as SelectProps } from './Select';
import { Controller, RegisterOptions, UseFormMethods } from 'react-hook-form';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';

type Props = {
    form: UseFormMethods<any>;
    name: string;
    options: SelectValueLabel[];
    allOptions?: SelectValueLabel[];
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    
    isClearable?: boolean;
    onChangeSelect?: (data: any) => void;
    height?: string;
} & SelectProps;

const SelectController = ({ form, name, options, allOptions, rules, isClearable, onChangeSelect, ...props }: Props) => {
    return (
        <Controller
            render={({ onChange, value, ref }) => {
                return (
                    <Select
                        {...props}
                        options={options}
                        // value={options.find(x => String(x.value || '').toString() === String(value || '').toString()) || ''}
                        
                        value={props.isMulti
                            	? value && value.find ? options.filter(x => value.find((y: any) => y === x.value)) : []
                            	: allOptions ? (allOptions.find(x => String(x.value || null).toString() === String(value || null).toString()) || null) 
                                    : (options.find(x => String(x.value || null).toString() === String(value || null).toString()) || null)}


                        ref={ref}
                        isClearable={isClearable}
                        
                        
                        // onChange={(data: SelectValueLabel, e: any) => {
                        //     onChange(data?.value);
                        //     if (props.onChange) {
                        //         props.onChange(data, e);
                        //     }
                        // }}


                        onChange={(data: SelectValueLabel | SelectValueLabel[], e: any) => {

                            if (props.isMulti) {
                                onChange((data as any)?.map((x: any) => x.value));
                            } else {
                                onChange((data as any)?.value);
                            }

                            if (props.onChange) {
                                props.onChange((data as any), e);
                            }
                            onChangeSelect && onChangeSelect(data);
                        }}



                        
                        filterOption={(candidate: any, input: any)=>{ 
                            if (input) {
                                return candidate.label.toUpperCase().includes(input.toUpperCase());
                            }
                            return true;
                        }}
                        menuPortalTarget={document.body}
                        styles={{ 
                            menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
                            ...props.styles
                        }}
                        height={props?.height ? props?.height : '100%'}
                    />
                );
            }}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name) || ''}
            rules={rules}
        />
    );
};

export default SelectController;
