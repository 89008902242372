import React, { memo } from 'react';
import styles from './Welcome.module.scss';
import { useTranslation } from 'react-i18next';
import Label from '../label/Label';
import ScreenContainer from '../screenContainer/ScreenContainer';

const Welcome: React.FC = () => {

    const { t } = useTranslation();

    return (
        <ScreenContainer className={styles.screenContainer}>
            <div className={styles.divTitle}>
                <h1 className={styles.title}>{t('common.welcome')}</h1>
            </div>
        </ScreenContainer>
    );
}

export default memo(Welcome);
