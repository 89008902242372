import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import HomeScreen from './screens/home/HomeScreen';
import ProjectsScreen from './screens/projects/ProjectsScreen';
import ProjectScreen from './screens/project/ProjectScreen';
import ParametersScreen from './screens/parameters/ParametersScreen';
import CollaboratorsTimes from './screens/collaboratorsTimes/CollaboratorsTimes';
import LoginScreen from './screens/login/LoginScreen';
import AuthenticatedLayout from './common/layouts//authenticatedLayout/AuthenticatedLayout';
import ProtectedRoute from './common/routes/ProtectedRoute';
import NotFoundScreen from './screens/errors/NotFoundScreen';
import PrivateRoute from './common/routes/PrivateRoute';
import { POLICIES } from './Config';
import NotAuthorizedScreen from './screens/errors/NotAuthorizedScreen';
import DashboardScreen from './screens/dasboard/DashboardScreen';
import { useSelector } from 'react-redux';
import { Reducers } from './store/types';
import { UserProfile } from './api/account/models/UserProfile';
import ProjectsTimes from './screens/projectsTimes/ProjectsTimes';

const DefaultRouter: React.FunctionComponent = () => {

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const seeProjects = !!loggedUser?.policies.find(x => x == POLICIES.PROJECT_READ || x == POLICIES.PROJECT_WRITE);

    return (
        <Router>
            <Switch>
                <Route path="/login" component={LoginScreen} />
                <Route path="/404" component={NotFoundScreen} />
                <ProtectedRoute path="*">
                    <AuthenticatedLayout>
                        <Switch>
                            <Route path="/" exact component={seeProjects ? DashboardScreen : HomeScreen} />
                            <PrivateRoute path="/dashboards" component={DashboardScreen} policies={[POLICIES.PROJECT_READ, POLICIES.PROJECT_WRITE]} />
                            <PrivateRoute path="/projects" component={ProjectsScreen} policies={[POLICIES.PROJECT_READ, POLICIES.PROJECT_WRITE]} />
                            <PrivateRoute path="/project/new" component={ProjectScreen} policies={[POLICIES.PROJECT_WRITE]}/>
                            <PrivateRoute path="/project/:id/:mode/:previous/:tab" component={ProjectScreen} policies={[POLICIES.PROJECT_READ, POLICIES.PROJECT_WRITE]} />
                            <PrivateRoute path="/parameters" component={ParametersScreen} policies={[POLICIES.PARAMETERS_READ, POLICIES.PARAMETERS_WRITE, POLICIES.GROUP_WRITE, POLICIES.STATUS_WRITE]}/>
                            <PrivateRoute path="/collaboratorstimes" component={CollaboratorsTimes} policies={[POLICIES.COLLABORATORS_TIMES_READ, POLICIES.COLLABORATORS_TIMES_WRITE]} />
                            <PrivateRoute path="/projectstimes" component={ProjectsTimes} policies={[POLICIES.PROJECT_READ, POLICIES.PROJECT_WRITE]} /> {/*TODO: POLICES*/}
                            <Route path="/not-authorized" component={NotAuthorizedScreen} />
                            <Route>
                                <Redirect to="/404" />
                            </Route>
                        </Switch>
                    </AuthenticatedLayout>
                </ProtectedRoute>
                <Route path="*">
                    <Redirect to="/404" />
                </Route>
            </Switch>
        </Router>
    );
}

export default DefaultRouter;
