import Http from '../../common/services/Http';

import { PacksViewModel } from './models/PacksViewModel';
import { GroupPackModel } from './models/GroupPackModel';

class PacksService {

    public getAll() {
        return Http.get<PacksViewModel[]>('packs');
    }

    public getByGroupId(groupId: string) {
        return Http.get<PacksViewModel[]>('packs/get-packs-by-group/' + groupId);
    }

    public saveGroupPack(model: GroupPackModel) {
        return Http.put('packs/group-packs/update', model);
    }



}

export default new PacksService();