import React, { memo } from 'react';
import { ProjectResponsibleDsiPlanningHours, ProjectResponsibleDsiPlanningViewModel } from '../../../../api/projectResponsibleDsiPlanning/models/ProjectResponsibleDsiPlanningViewModel';
import styles from './TableRow.module.scss';
import colors from '../../../../styles/export/colors.module.scss';
import DateTimePicker from '../../../../common/components/dateTimePicker/DateTimePicker';
import InputGroup from '../../../../common/components/inputGroup/InputGroup';
import { FaCalendarAlt } from 'react-icons/fa';
import EditableDiv from '../editableDiv/EditableDiv';
import { IoTrash } from 'react-icons/io5';

type Props = {
    data: ProjectResponsibleDsiPlanningViewModel;
    parentIndex: number;
    onRemove: (index: number) => void;
    onChange: (elem: ProjectResponsibleDsiPlanningViewModel, index: number) => void;
    submitted: boolean;
    usedYears: Date[];
    userCanWriteTimes?: boolean;
};

const TableRow: React.FC<Props> = ({ data, parentIndex, submitted, usedYears, onRemove, onChange, userCanWriteTimes }: Props) => {
    const years = usedYears.filter(x => x !== data.year).map(x => new Date(`${x}`).getTime());

    return (<div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <div className={`${styles.tableRow} ${styles.firstCol}`}>
            <div style={{ flex: 1 }} >
                <DateTimePicker
                    disabled={!userCanWriteTimes}
                    locale='fr'
                    onChange={(date: Date) => {
                        if (userCanWriteTimes) {
                            data.year = !years.find(x => x === date.getTime()) ? date : undefined;
                            onChange(data, parentIndex);
                        }
                    }}
                    selected={!!data.year ? new Date(data.year?.toString()) : undefined}
                    dateFormat="yyyy"
                    showYearPicker
                    customInput={<InputGroup
                        containerClassName={userCanWriteTimes ? styles.border : styles.borderDisabled}
                        className={styles.inputGroup}
                        style={{ borderColor: (submitted && !data.year) ? colors.danger : !userCanWriteTimes ? 'whisper' : 'white', fontSize: '14px', fontWeight: 'bold', color: 'black' }}
                        icon={!data.year ? <FaCalendarAlt /> : undefined}
                        inputGroupStyle={{ padding: '0 0.5rem' }} />}
                />
            </div>
        </div>
        {data.planningHours.map((elem: ProjectResponsibleDsiPlanningHours, index: number) => {
            return <div className={styles.tableRow} key={`input-${index}`}>
                <EditableDiv
                    key={`EditableDiv-${index}`}
                    value={(!elem.timeSpent && elem.timeSpent != 0) ? undefined : elem.timeSpent}
                    onValueChange={(value?: number | undefined) => {
                        elem.timeSpent = value;
                        onChange(data, parentIndex);
                    }}
                    editable={userCanWriteTimes}
                />
            </div>
        })}
        {userCanWriteTimes && <div className={styles.tableRemoveRow}>
            <div onClick={() => onRemove(parentIndex)} style={{ cursor: 'pointer', background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 3px 6px #00000029', borderRadius: '50%' }}>
                <IoTrash size={15} fill={colors.primary} style={{ margin: '0.5rem' }} />
            </div>
        </div>}
    </div >);
}

export default memo(TableRow);
