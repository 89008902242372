import React, { memo, useState } from 'react';
import ProjectTimePopoverHistory from './history/ProjectTimePopoverHistory';
import ProjectTimePopoverForm from './form/ProjectTimePopoverForm';
import { ProjectTimesHistoryDto } from '../../../api/projectsTimes/models/ProjectTimesHistoryDto';
import { ProjectTimesViewModel } from '../../../api/projectsTimes/models/ProjectTimesViewModel';
import { Filters } from '../../dasboard/components/ProjectAdvancements/ProjectAdvancementsTableFilters/ProjectAdvancementsTableFilters';
import styles from './ProjectTimePopover.module.scss';

export type ProjectTimePopoverScreen = 'HISTORY' | 'FORM';
export type ProjectTimePopoverMode = 'NEW' | 'EDIT' | 'DETAILS';

type Props = {
    screen: ProjectTimePopoverScreen,
    mode: ProjectTimePopoverMode,
    onClose: () => void,
    projectResponsibleDsiId: string,
    projectResponsibleDsiName: string,
    projectPhaseGuid: string;
    startDate?: Date;
    endDate?: Date;
    filters?: Filters;
    setRefresh: (screen: ProjectTimePopoverScreen) => void;
    userCanWriteTimes?: boolean;
}

const ProjectTimePopover: React.FC<Props> = ({ mode, screen, onClose, projectResponsibleDsiId, projectResponsibleDsiName, projectPhaseGuid, filters, setRefresh, userCanWriteTimes }: Props) => {

    const [popoverScreen, setPopoverScreen] = useState<ProjectTimePopoverScreen>(screen);
    const [popoverMode, setPopoverMode] = useState<ProjectTimePopoverMode>(mode);
    const [fromHistory, setFromHistory] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<ProjectTimesViewModel | undefined>();

    const [currentPageHistory, setCurrentPageHistory] = useState(1);
    const [startDate, setStartDate] = useState<Date | null>(filters?.startDate ?? null);
    const [endDate, setEndDate] = useState<Date | null>(filters?.endDate ?? null);
    const [orderBy, setOrderBy] = useState<string>('desc');
    const [orderColumn, setOrderColumn] = useState<string>('pt.date @ORDER, pt.id');

    const editItem = (item: ProjectTimesHistoryDto) => {
        setSelectedItem({
            ...item,
            date: new Date(item.date)
        });
        setFromHistory(true);
        setPopoverMode('EDIT');
        setPopoverScreen('FORM');
    }

    const cancelEdit = (refresh: boolean) => {
        //if (fromHistory) {
        setPopoverScreen('HISTORY');
        setSelectedItem(undefined);
        // } else {
        //     onClose();
        // }
        if (refresh) {
            setRefresh(popoverScreen);
        }
    }

    return (
        <div className={styles.popoverContent}>
            {popoverScreen == 'HISTORY' ? <ProjectTimePopoverHistory editItem={editItem}
                projectPhaseGuid={projectPhaseGuid}
                projectResponsibleDsiId={projectResponsibleDsiId}
                projectResponsibleDsiName={projectResponsibleDsiName}
                startDate={startDate ?? null}
                endDate={endDate ?? null}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                currentPage={currentPageHistory}
                setCurrentPage={setCurrentPageHistory}
                setRefresh={() => setRefresh(popoverScreen)}
                orderBy={orderBy}
                orderColumn={orderColumn}
                setOrderBy={setOrderBy}
                setOrderColumn={setOrderColumn}
                userCanWriteTimes={userCanWriteTimes}
            />
                :
                <ProjectTimePopoverForm mode={popoverMode}
                    onCancel={cancelEdit}
                    projectPhaseGuid={projectPhaseGuid}
                    projectResponsibleDsiId={projectResponsibleDsiId}
                    projectResponsibleDsiName={projectResponsibleDsiName}
                    item={selectedItem}
                />}
        </div>
    );
}

export default memo(ProjectTimePopover);