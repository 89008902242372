import React, { memo } from 'react';
import styles from './ProjectQualityQuestions.module.scss';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../../../models/ProjectForm';
import { ProjectQualityQuestionViewModel } from '../../../../../../api/projects/models/ProjectViewModel';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from '../../../../../../common/components/formItem/FormItem';
import SelectController from '../../../../../../common/components/select/SelectController';
import { SelectValueLabel } from '../../../../../../common/types/SelectValueLabel';
import InputResizable from '../../../../../../common/components/input/InputResizable';

type Props = {
    isDetails: boolean;
    form: UseFormMethods<ProjectForm>;
    qualityQuestions: UseFieldArrayMethods<ProjectQualityQuestionViewModel & { isNew: boolean }, 'id'>;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectQualityQuestions: React.FC<Props> = ({
    isDetails, form, qualityQuestions
}: Props) => {

    const { t } = useTranslation();
    const answerOptions: SelectValueLabel[] = [1, 2, 3, 4, 5].map(x => ({ label: x.toString(), value: x.toString() }));

    return (
        <div>
            <Row>
                {qualityQuestions.fields.filter(x => !isDetails || isDetails && !x.isNew).map((x, i) => {
                    const name = `qualityQuestions[${i}].value`;
                    return <Col xs={6} key={i}>
                        <Row>
                            <Col xs={9}>
                                <FormItem>
                                    <InputResizable value={x.name} disabled={true} removeBackground={!isDetails}/>
                                </FormItem>
                            </Col>
                            <Col xs={3}>
                                <FormItem>
                                    <SelectController
                                        name={name}
                                        form={form}
                                        options={answerOptions}
                                        isClearable
                                        isDisabled={isDetails}
                                        placeholder={'1-5'}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                })}
            </Row>
            <div className={styles.subtitleLine}>
                <span className={styles.subtitleText}>{1}&nbsp;&nbsp;{t('project.project_evaluation.very_low')}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={styles.subtitleText}>{2}&nbsp;&nbsp;{t('project.project_evaluation.low')}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={styles.subtitleText}>{3}&nbsp;&nbsp;{t('project.project_evaluation.medium')}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={styles.subtitleText}>{4}&nbsp;&nbsp;{t('project.project_evaluation.strong')}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={styles.subtitleText}>{5}&nbsp;&nbsp;{t('project.project_evaluation.very_strong')}</span>
            </div>
        </div>
    );
}

export default memo(ProjectQualityQuestions);
