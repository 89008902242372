import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { StatusSearchCriteria } from './models/StatusSearchCriteria';
import { StatusViewModel } from './models/StatusViewModel';

class StatusService {

    public getList(criteria: StatusSearchCriteria) {
        return Http.get<Page<StatusViewModel>>('status', criteria);
    }

    public get(id?: string) {
        return Http.get<StatusViewModel>('status/' + id);
    }

    public save(model: StatusViewModel) {
        return !!model.id ? Http.put('status', model) : Http.post('status', model);
    }

    public remove(model: StatusViewModel) {
        return  Http.put('status/deactivate', model);
    }
}

export default new StatusService();