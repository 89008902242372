import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PacksViewModel } from '../../../api/packs/models/PacksViewModel';
import Loading from '../../../common/services/Loading';
import { useToasts } from 'react-toast-notifications';
import PacksService from '../../../api/packs/PacksService';
import FormItem from '../../../common/components/formItem/FormItem';
import InputRadio from '../../../common/components/input/InputRadio';
import { useForm } from 'react-hook-form';
import styles from './ParametersPacksScreen.module.scss';
import Button from '../../../common/components/button/Button';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

enum ScreenType {
    EDIT,
    DETAILS
}

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
};

type Form = {
    name: string;
}

const ParametersPacksScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm<Form>();
    const [packs, setPacks] = useState<PacksViewModel[]>([]);
    const [packChecked, setPackChecked] = useState<PacksViewModel>({});
    const [screenMode, setScreenMode] = useState<ScreenType>(ScreenType.DETAILS);

    useEffect(() => {
        if (userCanRead) {
            getData();
        }
    }, [groupId, screenMode]);


    const onCancel = () => {
        setScreenMode(ScreenType.DETAILS);
    }

    const getData = async () => {

        try {

            Loading.show();

            const [packs, pack] = await Promise.all([
                PacksService.getAll(),
                PacksService.getByGroupId(groupId || '')
            ]);

            setPacks(packs);

            if (pack && pack.length> 0) {
                setPackChecked(pack[0]);
            }
            else if (packs) {                
                setPackChecked(packs[0]);
            }
            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }

    }

    const handleChange = (e: any) => {
        if (e && e.target.value) {
            const pack = packs.find(x => x.id == e.target.value);
            if (pack) {
                setPackChecked(pack);
            }
        }
    }

    const onSubmit = async () => {
        try {

            if (!packChecked) {
                return;
            }

            Loading.show();            
            
            await PacksService.saveGroupPack({ groupId: groupId || '', packId: packChecked.id || '' })

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });

            setScreenMode(ScreenType.DETAILS);

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };


    return (
        <>
        <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.PACKS}/>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
            <div>
                <FormItem>
                    {
                        packs.map((item, i) => {                            
                            return (<InputRadio key={i} name="name" onChange={handleChange} displayName={item.name} ref={register({ required: true, maxLength: 255 })} checked={item.id === packChecked?.id} value={item.id} disabled={screenMode === ScreenType.DETAILS} />)
                        })
                    }
                </FormItem>
            </div>
            <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                {screenMode !== ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                    <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={() => onCancel()} />
                    { userCanWrite && <Button type="submit" text={t('common.save')} size={'small'} />}
                </div>}
                {screenMode === ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                    { userCanWrite &&  <Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
                </div>}
            </div>
        </form>
        </>
    );
}

export default ParametersPacksScreen;
