import React, { memo, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ScreenNavbar, { ScreenNavbarItem } from '../../common/components/screenNavbar/ScreenNavbar';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import { useHistory, useParams } from 'react-router-dom';
import ProjectIdentification from './components/projectIdentification/ProjectIdentification';
import ProjectEvaluation from './components/projectEvaluation/ProjectEvaluation';
import styles from './ProjectScreen.module.scss';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import Button from '../../common/components/button/Button';
import Loading from '../../common/services/Loading';
import { useToasts } from 'react-toast-notifications';
import ProjectService from '../../api/projects/ProjectService';
import {
    ProjectViewModel, ProjectInvestmentTypeViewModel, DateMode, ProjectAllocationTotalsViewModel, ProjectAllocationTypeViewModel,
    ProjectEvaluationMethodologyViewModel, ProjectInvestmentTotalsViewModel, ProjectSuccessIndicatorViewModel, ProjectPhaseViewModel, ProjectScoreViewModel, ProjectQualityQuestionViewModel, ProjectSubventionViewModel, ProjectBudgetingViewModel
} from '../../api/projects/models/ProjectViewModel';
import DomainsService from '../../api/domains/DomainsService';
import { Reducers } from '../../store/types';
import { UserProfile } from '../../api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import PriorityLevelsService from '../../api/priorityLevels/PriorityLevelsService';
import UsersService from '../../api/users/UsersService';
import { DomainViewModel } from '../../api/domains/models/DomainViewModel';
import { PriorityLevelViewModel } from '../../api/priorityLevels/models/PriorityLevelViewModel';
import { UserViewModel } from '../../api/users/models/UserViewModel';
import { StrategicAxesViewModel } from '../../api/strategicAxes/models/StrategicAxesViewModel';
import StrategicAxesService from '../../api/strategicAxes/StrategicAxesService';
import EstablishmentService from '../../api/establishments/EstablishmentService';
import ProductsService from '../../api/products/ProductsService';
import { ProductViewModel } from '../../api/products/models/ProductViewModel';
import InvestmentTypesService from '../../api/investmentTypes/InvestmentTypesService';
import { InvestmentTypeDto } from '../../api/investmentTypes/models/InvestmentTypeDto';
import InternalAllocationTypesService from '../../api/internalAllocationTypes/InternalAllocationTypesService';
import { InternalAllocationTypeDto } from '../../api/internalAllocationTypes/models/InternalAllocationTypeDto';
import DepartmentsService from '../../api/departments/DepartmentsService';
import ProjectTypesService from '../../api/projectTypes/ProjectTypesService';
import { ProjectTypeViewModel } from '../../api/projectTypes/models/ProjectTypeViewModel';
import { SuccessIndicatorViewModel } from '../../api/successIndicators/models/SuccessIndicatorViewModel';
import SuccessIndicatorsService from '../../api/successIndicators/SuccessIndicatorsService';
import { EvaluationMethodologyViewModel } from '../../api/evaluationMethodologies/models/EvaluationMethodologyViewModel';
import EvaluationMethodologiesService from '../../api/evaluationMethodologies/EvaluationMethodologiesService';
import { ProductSearchCriteria } from '../../api/products/models/ProductSearchCriteria';
import ProjectOrganizationAndConduct from './components/projectOrganizationAndConduct/ProjectOrganizationAndConduct';
import StatusService from '../../api/status/StatusService';
import { StatusViewModel } from '../../api/status/models/StatusViewModel';
import { ProjectsListDto } from '../../api/projects/models/ProjectsListDto';
import { ProjectForm, ProjectFormConcernedEstablishment, ProjectFormConcernedPartner, ProjectFormDepartmentsInvolved, ProjectScreenMode } from './models/ProjectForm';
import ProjectScreenFormBuilder from './utils/ProjectScreenFormBuilder';
import ProjectScreenFormUtil from './utils/ProjectScreenFormUtil';
import { SourceViewModel } from '../../api/sources/models/SourceViewModel';
import SourcesService from '../../api/sources/SourcesService';
import ProjectManagersService from '../../api/projectManagers/ProjectManagersService';
import ProjectResponsiblesService from '../../api/projectResponsibles/ProjectResponsiblesService';
import { ProjectManagerViewModel } from '../../api/projectManagers/models/ProjectManagerViewModel';
import { ProjectResponsibleViewModel } from '../../api/projectResponsibles/models/ProjectResponsibleViewModel';
import ScoreService from '../../api/scores/ScoreService';
import { InView } from 'react-intersection-observer';
import ProjectInvestmentsSummary from './components/investmentsSummary/ProjectInvestmentsSummary';
import ProjectDefinition from './components/projectDefinition/ProjectDefinition';
import QualityQuestionsService from '../../api/qualityQuestions/QualityQuestionsService';
import { POLICIES } from '../../Config';
import PartnersService from '../../api/partners/PartnersService';
import { PartnersViewModel } from '../../api/partners/models/PartnersViewModel';
import TypologiesService from '../../api/typologies/TypologiesService';
import { TypologiesViewModel } from '../../api/typologies/models/TypologiesViewModel';
import ProjectResponsiblesDsiService from '../../api/projectResponsiblesDsi/ProjectResponsiblesDsiService';
import { ProjectResponsibleDsiViewModel } from '../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiViewModel';
import { ScoreType } from '../../api/scores/enums/ScoreType';
import { FaCaretDown } from 'react-icons/fa';
import Dropdown from '../../common/components/dropdown/Dropdown';
import DropdownItem from '../../common/components/dropdown/DropdownItem';
import NotifyEmailModal from './components/notifyEmailModal/NotifyEmailModal';
import { NotifyEmailForm } from './models/NotifyEmailForm';

const inViewThreshold = 0;
const inViewRootMargin = '-50%';

type ProjectParams = {
    id: string;
    mode: ProjectScreenMode;
    previous: string;
    tab?: string
}

enum ProjectSubNavbar {
    PROJECT_IDENTIFICATION = 'PROJECT_IDENTIFICATION',
    PROJECT_DEFINITION = 'PROJECT_DEFINITION',
    PROJECT_ORGANIZATION = 'PROJECT_ORGANIZATION',
    PROJECT_EVALUATION = 'PROJECT_EVALUATION',
    INVESTMENTS = 'INVESTMENTS',
    SUMMARY = 'REPORT',
}

const ProjectScreen: React.FC = () => {
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const { t } = useTranslation();
    const { id, mode, previous, tab } = useParams<ProjectParams>();
    const userCanRead = !!loggedUser?.policies.find(x => x == POLICIES.PROJECT_READ || x == POLICIES.PROJECT_WRITE);
    const userCanWrite = !!loggedUser?.policies.find(x => x == POLICIES.PROJECT_WRITE);
    const isDetails = !userCanWrite || mode === 'details';
    const { addToast } = useToasts();
    const history = useHistory();
    const [activeNavbarItemKey, setActiveNavbarItemKey] = useState(tab ?? ProjectSubNavbar.PROJECT_IDENTIFICATION.toString());
    const [status, setStatus] = useState<StatusViewModel[]>([]);
    const [domains, setDomains] = useState<DomainViewModel[]>([]);
    const [priorityLevels, setPriorityLevels] = useState<PriorityLevelViewModel[]>([]);
    const [authors, setAuthors] = useState<UserViewModel[]>([]);
    const [strategicAxes, setStrategicAxes] = useState<StrategicAxesViewModel[]>([]);
    const [products, setProducts] = useState<ProductViewModel[]>([]);
    const [auxInternalAllocationTypes, setAuxInternalAllocationTypes] = useState<InternalAllocationTypeDto[]>([]);
    const [projectType, setProjectType] = useState<ProjectTypeViewModel>();
    const [showForm, setShowForm] = useState(false);
    const projectIdentificationRef = useRef<HTMLDivElement>(null);
    const projectDefinitionRef = useRef<HTMLDivElement>(null);
    const projectOrganizationAndConductRef = useRef<HTMLDivElement>(null);
    const projectEvaluationRef = useRef<HTMLDivElement>(null);
    const projectSummaryRef = useRef<HTMLDivElement>(null);
    const [successIndicatorsOptions, setSuccessIndicatorsOptions] = useState<SuccessIndicatorViewModel[]>([]);
    const [evaluationMethodologiesOptions, setEvaluationMethodologiesOptions] = useState<EvaluationMethodologyViewModel[]>([]);
    const [auxInvestmentTypes, setAuxInvestmentTypes] = useState<InvestmentTypeDto[]>([]);
    const [auxDateFormat, setAuxDateFormat] = useState<string>('YYYY');
    const [auxDateMode, setAuxDateMode] = useState<string>(DateMode.YEAR);
    const [relatedProjects, setRelatedProjects] = useState<ProjectsListDto[]>([]);
    const [sources, setSources] = useState<SourceViewModel[]>([]);
    const [projectManagers, setProjectManagers] = useState<ProjectManagerViewModel[]>([]);
    const [projectResponsibles, setProjectResponsibles] = useState<ProjectResponsibleViewModel[]>([]);
    const [partners, setPartners] = useState<PartnersViewModel[]>([]);
    const [typologies, setTypologies] = useState<TypologiesViewModel[]>([]);
    const [projectResponsiblesDsi, setProjectResponsiblesDsi] = useState<ProjectResponsibleDsiViewModel[]>([]);
    const enableInView = useRef(false);
    const willBeToShowNotificationEmail = useRef(false);
    const [projectIdToModal, setProjectIdToModal] = useState<string | undefined>(undefined);
    const [projectIsNew, setProjectIsNew] = useState<boolean>(true);

    const [projectName, setProjectName] = useState<string>();

    const form = useForm<ProjectForm>({ shouldUnregister: false, shouldFocusError: true });
    const concernedEstablishments = useFieldArray<ProjectFormConcernedEstablishment>({
        control: form.control,
        name: 'concernedEstablishments',
    });
    const departmentsInvolved = useFieldArray<ProjectFormDepartmentsInvolved>({
        control: form.control,
        name: 'departmentsInvolved',
    });
    const investmentTypes = useFieldArray<ProjectInvestmentTypeViewModel>({
        control: form.control,
        name: 'investmentTypes',
    });
    const internalAllocationTypes = useFieldArray<ProjectAllocationTypeViewModel>({
        control: form.control,
        name: 'internalAllocationTypes',
    });
    const investmentTotals = useFieldArray<ProjectInvestmentTotalsViewModel>({
        control: form.control,
        name: 'investmentTotals',
    });
    const internalAllocationTotals = useFieldArray<ProjectAllocationTotalsViewModel>({
        control: form.control,
        name: 'internalAllocationTotals',
    });
    const successIndicators = useFieldArray<ProjectSuccessIndicatorViewModel>({
        control: form.control,
        name: 'successIndicators',
    });
    const evaluationMethodologies = useFieldArray<ProjectEvaluationMethodologyViewModel>({
        control: form.control,
        name: 'evaluationMethodologies',
    });
    const phases = useFieldArray<ProjectPhaseViewModel>({
        control: form.control,
        name: 'phases',
    });
    const scores = useFieldArray<ProjectScoreViewModel>({
        control: form.control,
        name: 'scores',
    });
    const qualityQuestions = useFieldArray<ProjectQualityQuestionViewModel & { isNew: boolean }>({
        control: form.control,
        name: 'qualityQuestions',
    });
    const subventions = useFieldArray<ProjectSubventionViewModel>({
        control: form.control,
        name: 'subventions',
    });
    const budgetings = useFieldArray<ProjectBudgetingViewModel>({
        control: form.control,
        name: 'budgetings',
    });
    const concernedPartners = useFieldArray<ProjectFormConcernedPartner>({
        control: form.control,
        name: 'concernedPartners',
    });

    const subNavbarItems: ScreenNavbarItem[] = [
        { key: ProjectSubNavbar.PROJECT_IDENTIFICATION.toString(), text: t('project.project_identification.title'), name: projectName },
        { key: ProjectSubNavbar.PROJECT_DEFINITION.toString(), text: t('project.project_definition.title') },
        { key: ProjectSubNavbar.PROJECT_ORGANIZATION.toString(), text: t('project.project_organization.title') },
        { key: ProjectSubNavbar.PROJECT_EVALUATION.toString(), text: t('project.project_evaluation.title') },
        // { key: ProjectSubNavbar.INVESTMENTS.toString(), text: t('project.investments.title') },
        { key: ProjectSubNavbar.SUMMARY.toString(), text: t('project.investments_summary.title') },
    ];

    const activeNavbarItem = subNavbarItems.find(x => x.key === activeNavbarItemKey) || subNavbarItems[0];

    const [showProjectDesignation, setShowProjectDesignation] = useState(false);

    const onNavbarChange = (item: ScreenNavbarItem) => {
        setActiveNavbarItemKey(item.key);

        let divRef: RefObject<HTMLDivElement> | null = null
        if (item.key === ProjectSubNavbar.PROJECT_IDENTIFICATION.toString()) {
            divRef = projectIdentificationRef;
        } else if (item.key === ProjectSubNavbar.PROJECT_DEFINITION.toString()) {
            divRef = projectDefinitionRef;
        } else if (item.key === ProjectSubNavbar.PROJECT_ORGANIZATION.toString()) {
            divRef = projectOrganizationAndConductRef;
        } else if (item.key === ProjectSubNavbar.PROJECT_EVALUATION.toString()) {
            divRef = projectEvaluationRef;
        } else if (item.key === ProjectSubNavbar.SUMMARY.toString()) {
            divRef = projectSummaryRef;
        }

        if (divRef && divRef.current) {
            const top = divRef.current.getBoundingClientRect().top + window.pageYOffset - 60;

            setTimeout(() => {
                enableInView.current = false;
                window.scrollTo({ top, behavior: 'smooth' });

                setTimeout(() => {
                    enableInView.current = true;
                }, 2000);
            });
        } else {
            setTimeout(() => {
                enableInView.current = true;
            }, 2000);
        }
    }

    const onPreSubmit = (model: ProjectViewModel) => {

        if (mode === 'copy') {
            model.id = undefined;
        }
        return model;
    }

    const onAfterSubmit = (id: string) => {
        if (mode === 'copy') {
            history.push(`/project/${id}/edit/projects/null`);
        }
    }

    const onSubmit = async (model: ProjectViewModel) => {
        if (!userCanWrite) return;
        try {
            Loading.show();
            model.id ? setProjectIsNew(false) : setProjectIsNew(true);
            const id = await ProjectService.save(onPreSubmit(model));
            const formBuilder = new ProjectScreenFormBuilder(form);
            formBuilder.buildProjectAfterUpdate(model, id as string, new Date());
            Loading.hide();
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });

            if(!willBeToShowNotificationEmail.current){
                onAfterSubmit(id as string);
            }
            else{        
                setProjectIdToModal(id);
            }
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const transformBeforeSubmit = (onSubmitFunc: (model: ProjectViewModel) => void) => (model: ProjectForm) => {
        const finalModel = model;
        finalModel.id = finalModel.id || id || undefined;
        finalModel.concernedEstablishments = ProjectScreenFormUtil.transformConcernedEstablishments(model, concernedEstablishments);
        finalModel.departmentsInvolved = finalModel.departmentsInvolved.filter(x => x.checked);
        finalModel.concernedPartners = finalModel.concernedPartners.filter(x => x.checked);
        finalModel.phases = ProjectScreenFormUtil.transformPhases(model);

        if (finalModel.concernedEstablishments.length > 0 && !finalModel.selectAllConcernedEstablishments) {
            if (ProjectScreenFormUtil.isInvestmentTypesValid(finalModel)) {
                addToast(t('project.investments.invalid_investment_values'), { appearance: 'warning' });
                return false;
            }

            if (ProjectScreenFormUtil.isInternalAllocationTypesValid(finalModel)) {
                addToast(t('project.investments.invalid_allocation_values'), { appearance: 'warning' });
                return false;
            }
        }

        onSubmitFunc(finalModel);
    }

    const buildInvestmentsTypesAndTotalsByDates = () => {
        const formBuilder = new ProjectScreenFormBuilder(form);
        formBuilder.buildInvestmentsTypesAndTotalsByDates(auxInvestmentTypes, auxDateMode, auxDateFormat);
    }

    const buildInternalAllocationTypesByDates = () => {
        const formBuilder = new ProjectScreenFormBuilder(form);
        formBuilder.buildInternalAllocationTypesByDates(auxInternalAllocationTypes, auxDateMode, auxDateFormat);
    }

    const buildPhasesByDates = (unapplySetValues: boolean): string[] => {
        const formBuilder = new ProjectScreenFormBuilder(form);
        return formBuilder.buildPhasesByDates(projectResponsiblesDsi, unapplySetValues);
    }

    const getData = async () => {
        if (!userCanRead) return;
        try {
            if (loggedUser) {

                Loading.show();

                const userPosition = { groupId: loggedUser.groupId, establishmentId: loggedUser.establishmentId, allData: true };

                const [domains, priorityLevels, users, strategicAxes, establishments, products, departments, investmentTypes, internalAllocationTypes, project] = await Promise.all([
                    DomainsService.getList(userPosition),
                    PriorityLevelsService.getList(userPosition),
                    UsersService.getAuthors(userPosition),
                    StrategicAxesService.getList(userPosition),
                    EstablishmentService.getAllEstablishmentsWithPoles(loggedUser.groupId),
                    ProductsService.getProducts(userPosition),
                    DepartmentsService.getList(userPosition),
                    InvestmentTypesService.getByProjectType('DEFAULT'),
                    InternalAllocationTypesService.getByProjectType('DEFAULT'),
                    id ? ProjectService.get(id) : null
                ]);
                const [projectType, rawSuccessIndicators, rawEvaluationMethodologies, status, sources, relatedProjects,
                    managers, responsibles, scores, qualityQuestions] = await Promise.all([
                        ProjectTypesService.getByName('DEFAULT'),
                        SuccessIndicatorsService.getSuccessIndicators(loggedUser.groupId, true),
                        EvaluationMethodologiesService.getEvaluationMethodologies(loggedUser.groupId, true),
                        StatusService.getList(userPosition),
                        SourcesService.getList(userPosition),
                        ProjectService.getList(userPosition),
                        ProjectManagersService.getList(userPosition),
                        ProjectResponsiblesService.getList(userPosition),
                        ScoreService.getList({ ...userPosition, allData: false }),
                        QualityQuestionsService.getList({ ...userPosition, allData: false })
                    ]);
                const [partners, typologies, responsiblesDsi] = await Promise.all([
                    PartnersService.getList(userPosition),
                    TypologiesService.getList(userPosition),
                    ProjectResponsiblesDsiService.getList(userPosition),
                    SuccessIndicatorsService.getSuccessIndicators(loggedUser.groupId, true),
                ]);

                // States
                setDomains(domains.items);
                setPriorityLevels(priorityLevels.items);
                setAuthors(users);
                setStrategicAxes(strategicAxes.items);
                setProducts(products);
                setAuxInvestmentTypes(investmentTypes);
                setAuxInternalAllocationTypes(internalAllocationTypes);
                setProjectType(projectType);
                if (projectType) {
                    setAuxDateFormat(projectType.dateFormat);
                    setAuxDateMode(projectType.dateMode);
                }
                setSuccessIndicatorsOptions(rawSuccessIndicators);
                setEvaluationMethodologiesOptions(rawEvaluationMethodologies);
                setStatus(status.items);
                setSources(sources.items);
                setRelatedProjects(mode != 'copy' ? project?.id ? relatedProjects.items.filter(x => x.id != project.id) : relatedProjects.items : relatedProjects.items);
                setProjectManagers(managers.items);
                setProjectResponsibles(responsibles.items);
                setPartners(partners.items);
                setTypologies(typologies.items);
                setProjectResponsiblesDsi(responsiblesDsi.items);

                // Form
                const formBuilder = new ProjectScreenFormBuilder(form);
                formBuilder.buildProject(id ? project : null, loggedUser, projectType, successIndicators, evaluationMethodologies);
                formBuilder.buildDepartmentsInvolved(departments, project);
                formBuilder.buildConcernedEstablishments(establishments, project);
                formBuilder.buildPhases(project);
                //ordem obrigatoria
                formBuilder.buildInvestmentsTypesAndTotals(investmentTypes, id ? project : null, mode, auxDateMode, auxDateFormat, setAuxDateFormat, setAuxDateMode, setAuxInvestmentTypes);//1
                formBuilder.buildInternalAllocationTypes(internalAllocationTypes, id ? project : null, mode, auxDateMode, auxDateFormat, setAuxInternalAllocationTypes);//2 
                //end: ordem obrigatoria
                formBuilder.buildScores(project, scores.items, [ScoreType.INITIAL, ScoreType.FINAL]);
                formBuilder.buildQualityQuestions(project, qualityQuestions.items);
                formBuilder.buildConcernedPartners(partners.items, project);
                setProjectName(project?.designation);

                setShowForm(true);

                Loading.hide();
            }
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const criteria: ProductSearchCriteria = {};

    const onChangeDomaine = async (selectedDomainId: string | undefined) => {
        if (selectedDomainId) {
            criteria.domainProjectId = selectedDomainId;
            const result = await ProductsService.getProducts(criteria)
            setProducts(result);
        } else {
            criteria.groupId = loggedUser?.groupId;
            const result = await ProductsService.getProducts(criteria);
            setProducts(result);
        }
    }

    const onChangeProjectName = (designation: string) => {
        setProjectName(designation);
    }

    const cancel = () => {
        history.push('/' + (!!previous ? previous : 'projects'));
    }

    const goToCopy = () => {
        if (!userCanWrite) return;
        setShowForm(false);
        enableInView.current = false;

        history.push(`/project/${id}/copy/projects/null`);

        setTimeout(() => {
            const formBuilder = new ProjectScreenFormBuilder(form);
            formBuilder.copyPhases();
            setRelatedProjects([
                ...relatedProjects,
                { id: form.getValues('id'), designation: form.getValues('designation') } as ProjectsListDto
            ].sort((a, b) => a.designation.localeCompare(b.designation)));
            setShowForm(true);
            onNavbarChange(activeNavbarItem);
        })
    }

    const goToEdit = () => {
        if (!userCanWrite) return;
        setShowForm(false);
        enableInView.current = false;

        history.push(`/project/${id}/edit/${previous}/${activeNavbarItemKey}`);

        setTimeout(() => {
            setShowForm(true);
            onNavbarChange(activeNavbarItem);
        })
    }

    const onViewChange = (isVisible: boolean, entry: IntersectionObserverEntry, subNavbar: ProjectSubNavbar) => {
        if (isVisible && enableInView.current) {
            setActiveNavbarItemKey(subNavbar.toString());
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
    
    const renderSubmitionButtons = () => {
        return (
            <div id='btnSubmition'>
                <Dropdown idDropdown={'btnSubmition'} useLocationOfItemClicked position={'top'} alignment={'center'} options={<>
                    <DropdownItem onClick={() => {willBeToShowNotificationEmail.current = true; form.handleSubmit(transformBeforeSubmit(onSubmit))(); }}>{t('common.save_and_notify')}</DropdownItem>
                    <DropdownItem onClick={() => form.handleSubmit(transformBeforeSubmit(onSubmit))()}>{t('common.save')}</DropdownItem>                
                </>}>
                    <Button type="button">{t('common.save')} <FaCaretDown /></Button> 
                </Dropdown>
            </div>
        );
    }

    const onCancelModal = () => {
        setProjectIdToModal(undefined);
        willBeToShowNotificationEmail.current = false;
    }

    const onSendEmailModal = (formValues: NotifyEmailForm) => {
        if (!formValues.to?.length || !formValues.subject || !formValues.message) {
            return;
        }
        Loading.show();

        ProjectService.sendEmailNotifyProjectChanges(formValues).then(() => {
            addToast(t('common.messages.sending_email_success'), { appearance: 'success' });
            onCancelModal();
            Loading.hide();
        }).catch(() => {
            addToast(t('common.messages.error_sending_email'), { appearance: 'error' });
            Loading.hide();
        });
    }

    useEffect(() => {
        onNavbarChange(activeNavbarItem);
        getData();
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 80 && !showProjectDesignation) {
                setShowProjectDesignation(true);
            } else {
                setShowProjectDesignation(false);
            }
        });
    }, []);

    return (
        <ScreenTitle title={t('project.title')}>
            <ScreenNavbar items={subNavbarItems} activeItem={activeNavbarItem} onChange={onNavbarChange} onExitClick={cancel} />
            <ScreenContainer className={styles.container}>
                {showProjectDesignation && (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', position: 'relative', zIndex: 5, top: '-4rem', alignItems: 'center' }}>
                        <div className={`${styles.floatingDesignation} ${styles.fadeInDesignation}`}>
                            {form.getValues('designation')}
                        </div>
                    </div>
                )}
                <FormProvider {...form} >
                    <form onSubmit={form.handleSubmit(transformBeforeSubmit(onSubmit))} onKeyDown={e => { handleKeyDown(e) }}>
                        {showForm && <>
                            <div className={styles.sectionSpacing} ref={projectIdentificationRef}>
                                <InView threshold={inViewThreshold} rootMargin={inViewRootMargin} onChange={(isVisible, entry) => onViewChange(isVisible, entry, ProjectSubNavbar.PROJECT_IDENTIFICATION)}>
                                    <ProjectIdentification
                                        form={form}
                                        concernedEstablishments={concernedEstablishments}
                                        departmentsInvolved={departmentsInvolved}
                                        domains={domains}
                                        priorityLevels={priorityLevels}
                                        authors={authors}
                                        strategicAxes={strategicAxes}
                                        products={products}
                                        isDetails={isDetails}
                                        onChangeDomaine={onChangeDomaine}
                                        buildInvestmentsTypesAndTotalsByDates={buildInvestmentsTypesAndTotalsByDates}
                                        buildInternalAllocationTypesByDates={buildInternalAllocationTypesByDates}
                                        concernedPartners={concernedPartners}
                                        changeProjectDesignation={onChangeProjectName}
                                        mode={mode}
                                    />
                                </InView>
                            </div>
                            <div className={styles.sectionSpacing} ref={projectDefinitionRef}>
                                <InView threshold={inViewThreshold} rootMargin={inViewRootMargin} onChange={(isVisible, entry) => onViewChange(isVisible, entry, ProjectSubNavbar.PROJECT_DEFINITION)}>
                                    <ProjectDefinition
                                        form={form}
                                        isDetails={isDetails}
                                        qualityQuestions={qualityQuestions}
                                        budgetings={budgetings}
                                        subventions={subventions}
                                    />
                                </InView>
                            </div>
                            <div className={styles.sectionSpacing} ref={projectOrganizationAndConductRef}>
                                <InView threshold={inViewThreshold} rootMargin={inViewRootMargin} onChange={(isVisible, entry) => onViewChange(isVisible, entry, ProjectSubNavbar.PROJECT_ORGANIZATION)}>
                                    <ProjectOrganizationAndConduct
                                        form={form}
                                        isDetails={isDetails}
                                        phases={phases}
                                        relatedProjects={relatedProjects}
                                        status={status}
                                        sources={sources}
                                        projectManagers={projectManagers}
                                        projectResponsibles={projectResponsibles}
                                        dateMode={auxDateMode}
                                        dateFormat={auxDateFormat}
                                        buildInvestmentsTypesAndTotalsByDates={buildInvestmentsTypesAndTotalsByDates}
                                        buildInternalAllocationTypesByDates={buildInternalAllocationTypesByDates}
                                        buildPhasesByDates={buildPhasesByDates}
                                        typologies={typologies}
                                        projectResponsiblesDsi={projectResponsiblesDsi}
                                    />
                                </InView>
                            </div>
                            <div className={styles.sectionSpacing} ref={projectEvaluationRef}>
                                <InView threshold={inViewThreshold} rootMargin={inViewRootMargin} onChange={(isVisible, entry) => onViewChange(isVisible, entry, ProjectSubNavbar.PROJECT_EVALUATION)}>
                                    <ProjectEvaluation
                                        isDetails={isDetails}
                                        investmentTotals={investmentTotals}
                                        investmentTypes={investmentTypes}
                                        internalAllocationTypes={internalAllocationTypes}
                                        internalAllocationTotals={internalAllocationTotals}
                                        evaluationMethodologies={evaluationMethodologies}
                                        successIndicators={successIndicators}
                                        form={form}
                                        successIndicatorsOptions={successIndicatorsOptions}
                                        evaluationMethodologiesOptions={evaluationMethodologiesOptions}
                                        //phases={phases}
                                        scores={scores}
                                    />
                                </InView>
                            </div>
                            <div className={styles.sectionSpacing} ref={projectSummaryRef}>
                                <InView threshold={inViewThreshold} rootMargin={inViewRootMargin} onChange={(isVisible, entry) => onViewChange(isVisible, entry, ProjectSubNavbar.SUMMARY)}>
                                    <ProjectInvestmentsSummary
                                        isDisabled={isDetails}
                                        investmentTypes={investmentTypes}
                                    />
                                </InView>
                            </div>
                            <div className={styles.bottomContainer}>
                                <div className={styles.bottomInnerContainer}>
                                    <Button preset="secondary" onClick={cancel}>{t('common.cancel')}</Button>
                                    {userCanWrite && isDetails && <Button type="button" onClick={goToEdit}>{t('common.edit')}</Button>}
                                    {userCanWrite && isDetails && <Button type="button" onClick={goToCopy}>{t('common.copy')}</Button>}
                                    {userCanWrite && !isDetails && renderSubmitionButtons()}
                                </div>
                            </div>
                        </>}
                    </form>
                </FormProvider>

                {projectIdToModal && <NotifyEmailModal 
                                        isOpen={!!projectIdToModal} 
                                        onCancel={onCancelModal} 
                                        onSend={onSendEmailModal} 
                                        projectId={projectIdToModal} 
                                        projectName={form.watch('designation', '')}
                                        projectIsNew={projectIsNew} 
                                        loggedUser={loggedUser}/>
                }
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default memo(ProjectScreen);
