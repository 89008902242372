import Http from '../../common/services/Http';
import { IdNameDto } from '../common/models/IdNameDto';
import { Page } from '../common/types/Page';
import { ScoreSearchCriteria } from './models/ScoreSearchCriteria';
import { ScoreViewModel } from './models/ScoreViewModel';

class ScoreService {
    
    public getList(criteria: ScoreSearchCriteria) {
        return Http.get<Page<ScoreViewModel>>('scores', criteria);
    }

    public getScoreById(id: string) {
        return Http.get<ScoreViewModel>('scores/'+ id);
    }

    public save(model: ScoreViewModel) {
        return !!model.id ? Http.put('scores', model) : Http.post('scores', model);
    }

    public remove(model: ScoreViewModel) {
        return  Http.put('scores/deactivate', model);
    }

    public getScores(groupId?: string) {
        return Http.get<IdNameDto[]>('scores/get-all-scores-for-select-item/' + groupId);
    }
}

export default new ScoreService();



