import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { PriorityLevelSearchCriteria } from './models/PriorityLevelSearchCriteria';
import { PriorityLevelViewModel } from './models/PriorityLevelViewModel';

class PriorityLevelsService {
    
    public getList(criteria: PriorityLevelSearchCriteria) {
        return Http.get<Page<PriorityLevelViewModel>>('priorityLevels', criteria);
    }

    public getPriorityLevelById(id: string) {
        return Http.get<PriorityLevelViewModel>('priorityLevels/'+ id);
    }

    public save(model: PriorityLevelViewModel) {
        return !!model.id ? Http.put('priorityLevels', model) : Http.post('priorityLevels', model);
    }

    public remove(model: PriorityLevelViewModel) {
        return  Http.put('priorityLevels/deactivate', model);
    }
}

export default new PriorityLevelsService();