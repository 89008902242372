import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { ProjectsTimesSearchCriteria } from './models/ProjectsTimesSearchCriteria';
import { ProjectsTimesSupportSearchCriteria } from './models/ProjectsTimesSupportSearchCriteria';
import { ProjectTimesDto } from './models/ProjectTimesDto'
import { ProjectTimesHistoryDto } from './models/ProjectTimesHistoryDto';
import { ProjectTimesHistorySearchCriteria } from './models/ProjectTimesHistorySearchCriteria';
import { ProjectTimesSupportDto } from './models/ProjectTimesSupportDto';
import { ProjectTimesTotalsDto } from './models/ProjectTimesTotalsDto';
import { ProjectTimesViewModel } from './models/ProjectTimesViewModel';

class ProjectTimesService {
    
    public getProjectsAndPhasesAndUsers(criteria: ProjectsTimesSearchCriteria) {
        return Http.get<Page<ProjectTimesDto>>('projectTimes/get-projects-and-phases-and-users', criteria);
    }

    public getProjectsAndPhasesAndUsersTotals(criteria: ProjectsTimesSearchCriteria) {
        return Http.get<ProjectTimesTotalsDto>('projectTimes/get-projects-and-phases-and-users-totals', criteria);
    }

    public save(model: ProjectTimesViewModel) {
        return model.id ? Http.put('projectTimes', model) : Http.post('projectTimes', model);
    }

    public remove(model: {id: string }) {
        return Http.put('projectTimes/deactivate', model);
    }

    public getProjectTimesHistory(criteria: ProjectTimesHistorySearchCriteria) {
        return Http.get<Page<ProjectTimesHistoryDto>>('projectTimes/get-project-times-history', criteria);
    }

    public getProjectTimesHistoryTotalHours(criteria: ProjectTimesHistorySearchCriteria) {
        return Http.get<number>('projectTimes/get-project-times-history-total-hours', criteria);
    }

    public getSupportTimes(criteria: ProjectsTimesSupportSearchCriteria) {
        return Http.get<ProjectTimesSupportDto>('projectTimes/get-support-times', criteria);
    }
}

export default new ProjectTimesService();