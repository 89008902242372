import React, { memo, ReactElement, useEffect, useState } from 'react';
import styles from './Semaphore.module.scss';
import { RiskLevel } from '../../../../api/projects/models/ProjectViewModel';
import ReactTooltip from 'react-tooltip';
import colors from '../../../../styles/export/colors.module.scss';


type Props = {
    allowClick?: boolean,
    onChange?: (items:string[]) => void,
    selectedItems: string[],
    tooltip?: string,
    singleLight?: boolean
};


const Semaphore: React.FC<Props> = ({ allowClick = true, onChange, selectedItems, tooltip, singleLight = false }: Props) => {   
    
    const [riskLevel, setRiskLevel] = useState<string[]>(selectedItems);    


    const handleClick = (level: RiskLevel) => {
        if(allowClick){
            let aux:string[] = [];
            
            if(singleLight){
                aux[0] = (!!selectedItems && selectedItems.length > 0 && selectedItems[0]) === level ? '' : level;
            }else{
                aux = [...riskLevel];
                if(riskLevel?.includes(level)){
                    aux = [...aux.filter(x => x !== level)]
                }else{
                    aux = [...aux, level]
                }
            }

            if(aux.length === 0){
                aux[0] = RiskLevel.NONE;
            }
            setRiskLevel(aux);
            onChange && onChange(aux)
        }
    }

    return (
        <div className={styles.riskContainer}>                        
                        <div className={styles.riskBullets} data-tip={tooltip} data-for='semaphore'>
                            <div
                                className={styles.riskBullet}
                                style={{ backgroundColor: riskLevel?.includes(RiskLevel.LOW) ? colors.success : colors.veryLightGrey, cursor: allowClick ? 'pointer' : 'no-drop' }}
                                onClick={() => {
                                        handleClick(RiskLevel.LOW)
                                    }
                                } />
                            <div
                                className={styles.riskBullet}
                                style={{ backgroundColor: riskLevel?.includes(RiskLevel.MEDIUM) ? colors.warning : colors.veryLightGrey, cursor: allowClick ? 'pointer' : 'no-drop' }}
                                onClick={() => {
                                    handleClick(RiskLevel.MEDIUM)
                                }
                            } />
                            <div
                                className={styles.riskBullet}
                                style={{ backgroundColor: riskLevel?.includes(RiskLevel.HIGH) ? colors.danger : colors.veryLightGrey, cursor: allowClick ? 'pointer' : 'no-drop' }}
                                onClick={() => {
                                    handleClick(RiskLevel.HIGH)
                                }
                            } />
                        </div>
                        <ReactTooltip id='semaphore' html={true} className={styles.tooltip} type='info'/>
                    </div>
    );
}

export default memo(Semaphore);
