import React, { useEffect, useState } from 'react';
import { GroupParamsInfoViewModel } from '../../../../api/groupParamsInfos/models/GroupParamsInfoViewModel';
import GroupParamsInfoService from '../../../../api/groupParamsInfos/GroupParamsInfoService';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Button from '../../../../common/components/button/Button';
import FormItem from '../../../../common/components/formItem/FormItem';
import Loading from '../../../../common/services/Loading';
import styles from './GroupParamsInfo.module.scss';
import InputResizable from '../../../../common/components/input/InputResizable';
import { TextareaAutosize } from 'react-autosize-textarea/lib/TextareaAutosize';
import { mode } from 'crypto-js';
import { Console } from 'console';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
    type?: string
};

type Form = {
    description: string;
    index?: number;
}

const GroupParamsInfo: React.FC<Props> = ({ groupId, type, userCanRead }: Props) => {
    
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors } = useForm<Form>();
    const [isDetails, setIsDetails] = useState<boolean>(true);
    const [currentDescription, setCurrentDescription] = useState<string>('');
    const [currentModel, setCurrentModel] = useState<GroupParamsInfoViewModel>({});
    

    useEffect(() => {
        if (userCanRead) {
            if (!!groupId) {
                getModel();
            }
        }
    }, [groupId]);


    const getModel = async() => {
        const model = await GroupParamsInfoService.get(groupId, type);
        if (model){        
            setCurrentModel(model);
            setCurrentDescription(model.description? model.description : '')
        }
        else{
            setCurrentModel({});
            setCurrentDescription('');
        }
    }

    const onSubmit = async () => {
        try {
            Loading.show();
            if (groupId) { 
                currentModel.description = currentDescription;
                currentModel.groupId=groupId;
                currentModel.type=type;
                await GroupParamsInfoService.save(currentModel);
                setIsDetails(true);
            }
            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
          

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formLine}>
                    <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                        <div style={{ 'flex': 1 }}>
                        <TextareaAutosize
                            style={{ resize: 'none' }}
                            name='description'
                            value={currentDescription}
                            disabled={isDetails}
                            className={`${styles.input} ${styles.inputDefault} ${isDetails ? styles.inputDisabled : ''}`}                            
                            onChange={e => {                                
                                setCurrentDescription(e.currentTarget.value);
                            }}
                            rows={1}
                        />
                    </div>
                    {isDetails && <Button type="button" text={t('common.edit')} size={'small'} className={styles.button} onClick={()=> setIsDetails(false)} />}
                    {!isDetails && <Button type="submit" text={t('common.save')} size={'small'} className={styles.button} />}
                    </FormItem>
                </div>
            </form>
        </div>

    );
}

export default GroupParamsInfo;
