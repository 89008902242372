import React, { useState } from 'react';
import ListProjectResponsibleScreen from '../../projectResponsibles/list/ListProjectResponsiblesScreen';
import ProjectResponsibleScreen from '../../projectResponsibles/ProjectResponsibleScreen';
import { ProjectResponsibleViewModel } from '../../../api/projectResponsibles/models/ProjectResponsibleViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_PROJECT_RESPONSIBLES,
    PROJECT_RESPONSIBLES
}

const ParametersProjectResponsibleScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_PROJECT_RESPONSIBLES);
    const [currentProjectResponsible, setCurrentProjectResponsible] = useState<ProjectResponsibleViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_PROJECT_RESPONSIBLES && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.PROJECT_RESPONSIBLES}/> }
            { screen === ScreenType.LIST_PROJECT_RESPONSIBLES && <ListProjectResponsibleScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewProjectResponsible={() => {
                    setIsDetails(false);
                    setCurrentProjectResponsible(null);
                    setScreen(ScreenType.PROJECT_RESPONSIBLES)
                }}
                onClickItem={(item: ProjectResponsibleViewModel, isDetails: boolean) => {
                    if(item.active)
                    {
                        setIsDetails(isDetails);
                        setCurrentProjectResponsible(item);
                        setScreen(ScreenType.PROJECT_RESPONSIBLES);
                    }
                }}
            ></ListProjectResponsibleScreen>}
            { screen === ScreenType.PROJECT_RESPONSIBLES && <ProjectResponsibleScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                projectResponsiblesId={currentProjectResponsible?.id}
                afterSave={() => {
                    setCurrentProjectResponsible(null);
                    setScreen(ScreenType.LIST_PROJECT_RESPONSIBLES);
                }}></ProjectResponsibleScreen>}
        </div>

    );
}

export default ParametersProjectResponsibleScreen;
