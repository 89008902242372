import { UserProfile } from '../../api/account/models/UserProfile';

export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';

export interface Action {
    type: string;
    [propName: string]: any;
}

export interface AuthenticationState {
	isAuthenticated: boolean;
    profile: UserProfile | null;
}
