/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React from 'react';
import styles from './Input.module.scss';

type InputPreset = 'default' | 'login';

type Props = {
    preset?: InputPreset;
    icon: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const InputIcon = React.forwardRef<HTMLInputElement, Props>(({ preset, icon, ...props }, ref) => {
    const presetClassName = preset ? styles[preset] : styles.default;

    return (
        <div className={styles.formLine}>
            <input ref={ref}
                type={props.type || 'text'}
                {...props}
                className={`${styles.inputWithIcon} ${presetClassName} ${props.className || ''} ${props.disabled ? styles.disabled : ''} `} />
            <div className={styles.iconInput}><b>{icon}</b></div>
        </div>
    );
});

export default InputIcon;
