/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { NUMBERS_MAX_LENGTH, NUMBERS_MAX_VALUE } from '../../../Config';
import styles from './InputHourGroup.module.scss';

export type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    text?: string;
    icon?: any;
    nameText?: string;
    noBorder?: boolean;
    containerClassName?: string;
    onValueChange?: (value?: number) => void;
    hideMessageLength?: boolean;
    onClick?: () => void;
    inputGroupStyle?: React.CSSProperties;
};


const InputHourGroup = React.forwardRef<HTMLInputElement, Props>(({ text, icon, noBorder, nameText, inputGroupStyle, onValueChange, hideMessageLength, containerClassName, onClick, ...props }, ref) => {

    const { t } = useTranslation();
    const [textLength, setTextLength] = useState<number>(0);

    const withValueCap = (inputObj: any) => {
        const { value } = inputObj;
        if (value <= NUMBERS_MAX_VALUE) return inputObj;
    };

    return (
        <div>
            <div className={`${styles.inputGroup} ${containerClassName || ''} ${!noBorder ? styles.border : ''}`}>
                {nameText && <span className={styles.nameText}>{nameText}</span>}
           
                <NumberFormat
                    getInputRef={ref}
                    displayType="input"
                    thousandSeparator={' '}
                    value={props.value as number}
                    type={'text'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalSeparator={','}
                    onValueChange={e => {
                        if (onValueChange) {
                            onValueChange(e.floatValue);
                        }
                    }}
                    onChange={e => {
                        setTextLength(e.currentTarget.value.length);
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                    className={`${styles.input} ${styles.inputMoney} ${props.className || ''} ${props.disabled ? styles.disabled : ''} ${!noBorder ? styles.border : ''}`}
                    disabled={props.disabled}
                    isAllowed={withValueCap}
                    required={props.required}
                    placeholder={props.placeholder}
                />

                {(text || icon) && <div className={styles.inputGroupAppend}>
                    <span
                        onClick={onClick}
                        className={`${styles.inputGroupText}`}
                        style={inputGroupStyle ?? {}}>{icon ? icon : text}</span>
                </div>}

            </div>
            {!hideMessageLength && textLength === NUMBERS_MAX_LENGTH && <div className={styles.maxLength}>{t('common.field_numbers_max_length', { value: NUMBERS_MAX_LENGTH - 3 })}</div>}
        </div>
    );
});

export default InputHourGroup;
