import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { ProjectAdvancementsDashboardSearchCriteria } from './models/ProjectAdvancementsDashboardSearchCriteria';
import { ProjectViewModel } from '../projects/models/ProjectViewModel';

class DashboardService {

    public getProjectsAndPhases(criteria: ProjectAdvancementsDashboardSearchCriteria) {
        return Http.get<Page<ProjectViewModel>>('dashboards/get-projects-and-phases', criteria);
    }
   
}

export default new DashboardService();