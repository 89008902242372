import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from '../../../../common/components/formItem/FormItem';
import Label from '../../../../common/components/label/Label';
import { Controller, useForm } from 'react-hook-form';
import { NotifyEmailForm } from '../../models/NotifyEmailForm';
import { Col, Row } from 'react-flexbox-grid';
import styles from './NotifyEmailModal.module.scss';

import SummernoteController from '../../../../common/components/summernoteController/SummernoteController';
import Modal from '../../../../common/components/modal/Modal';
import InputError from '../../../../common/components/inputError/InputError';
import Select from '../../../../common/components/select/Select';
import Button from '../../../../common/components/button/Button';
import Loading from '../../../../common/services/Loading';
import { useToasts } from 'react-toast-notifications';
import ProjectService from '../../../../api/projects/ProjectService';
import { ProjectAllResponsiblesViewModel, ProjectAllResponsiblesSelectable } from '../../../../api/projects/models/ProjectAllResponsiblesViewModel';
import { SelectValueLabel } from '../../../../common/types/SelectValueLabel';
import Input from '../../../../common/components/input/Input';
import dayjs from 'dayjs';
import { SERVER_BASE_URL } from '../../../../Config';
import { UserProfile } from '../../../../api/account/models/UserProfile';

type Props = {
    isOpen: boolean;
    projectId: string;
    onSend: (formValues: NotifyEmailForm) => void;
    onCancel: () => void;
    projectName: string;
    projectIsNew: boolean;
    loggedUser: UserProfile | null;
};

const NotifyEmailModal: React.FC<Props> = ({ isOpen, projectId, onSend, onCancel, projectName, projectIsNew, loggedUser }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const form = useForm<NotifyEmailForm>({ shouldUnregister: false, shouldFocusError: true });
    const { control, handleSubmit } = form;
    const [responsibles, setResponsibles] = useState<ProjectAllResponsiblesSelectable[]>([]);
    const formSubmitted = useRef(false);

    const getData = async () => {
        try {
            Loading.show();

            const result = await ProjectService.getAllResponsibles(projectId);
            const responsiblesDB  = (result || []).map(x => ({ value: x.id || '', label: x.name || '', subLabel: x.role || '', email: x.email || '' }));
            form.setValue('to', responsiblesDB.map(x => x.email));

            const subjectText = `${projectName}: ${(projectIsNew ? t('projects.project_create') : t('projects.project_update'))}`;
            form.setValue('subject', subjectText);

            const messageText = t('email.message_body', {
                value1: projectName, 
                value2: projectIsNew ? t('email.create') : t('email.update'), 
                value3: loggedUser?.realName, 
                value4: dayjs(new Date()).format('DD/MM/YYYY').toString(), 
                value5: dayjs(new Date()).format('HH:mm').toString(), 
                value6: `${SERVER_BASE_URL}/project/${projectId}/edit/projects/null`,
                value7: `${SERVER_BASE_URL}/project/${projectId}/edit/projects/null`
             })
            form.setValue('message', messageText);

            setResponsibles(responsiblesDB);           

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const isEmail = (val: string) => {
        const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test(val)){
          return false
        }
        return true;
    }


    useEffect(() => {
        getData()
    }, []);

    return (
        <Modal
            isOpen={isOpen}
        >
            <form onSubmit={handleSubmit(onSend)}>
                <Row className={`${styles.titleContainer} ${styles.noMarginHorizontal}`}>
                    <Col xs={12} className={styles.paddingDefault}>
                        <Label className={styles.textTitle}>{t('email.title_notify')}</Label>
                    </Col>
                </Row>

                <Row className={`${styles.contentModal} ${styles.noMarginHorizontal}`}>
                    <Col xs={12} className={styles.paddingDefault}>
                        <FormItem>
                            <Label className={styles.bold}>{t('email.to')} *</Label>
                            <Controller
                                render={({ onChange, value }) => {
                                    
                                    return (
                                        <Select
                                            options={responsibles}
                                            isClearable={true}
                                            isMulti={true}
                                            placeholder={t('email.to')}
                                            onChange={(data: ProjectAllResponsiblesViewModel[]) => {
                                                onChange(data?.map(x => x.email));
                                            }}
                                            value={value ? responsibles.filter(x => value.find((y: string) => y === x.email)) : []}
                                            filterOption={(lbl: any, input: any) => input ? lbl.label.toUpperCase().includes(input.toUpperCase()) : true}
                                            showLabelAndSubLabel
                                        />
                                    );
                                }}
                                control={control}
                                name="to"
                                defaultValue={responsibles}
                                rules={{ required: true }}
                            /> 
                            <InputError error={(formSubmitted.current && !form.watch('to')?.length) ? { type: 'required' } : undefined} />
                        </FormItem>
                        
                    </Col>

                    <Col xs={12} className={styles.paddingDefault}>
                    <FormItem>
                            <Label className={styles.bold}>{t('email.cc')}</Label>
                            <Controller
                                render={({ onChange }) => {
                                    return (
                                        <Select
                                            isCreatable={true}
                                            isClearable
                                            isMulti
                                            placeholder={t('email.cc')}
                                            onChange={(data: SelectValueLabel[]) => {
                                                if(data && data.length > 0 && !isEmail(data[data.length-1].value)){
                                                    addToast(t('common.messages.invalid_email'), { appearance: 'warning' });
                                                    onChange(data?.splice(-1).map(x => x.value));
                                                }
                                                else {
                                                    onChange(data?.map(x => x.value));
                                                }
                                            }}
                                            filterOption={(lbl: any, input: any) => input ? lbl.label.toUpperCase().includes(input.toUpperCase()) : true}
                                        />
                                    );
                                }}
                                control={control}
                                name="cc"
                                defaultValue={[]}
                            /> 
                        </FormItem>
                    </Col>

                    <Col xs={12} className={styles.paddingDefault}>
                        <FormItem>
                            <Label className={styles.bold}>{t('email.subject')} *</Label>
                            <Controller
                                render={({ onChange, value }) => {                                    
                                    return (
                                        <Input 
                                            onChange={val => {
                                                onChange(val.currentTarget?.value);
                                            }}
                                            placeholder={t('email.subject')}
                                            value={value} 
                                        />
                                    );
                                }}
                                control={control}
                                name="subject"
                                defaultValue={projectName}
                                rules={{ required: true }}
                            />                            
                            <InputError error={(formSubmitted.current && !form.watch('subject')?.length) ? { type: 'required' } : undefined} />
                        </FormItem>
                    </Col>

                    <Col xs={12} className={styles.paddingDefault}>
                        <FormItem>
                            <Label className={styles.bold}>{t('email.message')} *</Label>
                            <SummernoteController name="message" form={form} placeholder={t('email.message')} rules={{ required: true }}/>
                            <InputError error={(formSubmitted.current && !form.watch('message')?.length) ? { type: 'required' } : undefined} />
                        </FormItem>
                    </Col>
                </Row>
                
                <div className={styles.footerModal}>
                    <div className={styles.buttons_container}>
                        <Button text={t('common.cancel')}
                            preset={'secondary'}
                            onClick={onCancel}
                            size={'small'}
                            type={'button'}
                        />
                        <Button text={t('common.send')}
                            size={'small'}
                            onClick={() => {formSubmitted.current = true}}
                            type={'submit'}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
}

export default NotifyEmailModal;