import React, { memo, useState } from 'react';
import styles from './ProjectsTimesTablePhaseLine.module.scss';
import Utils from '../../../common/services/Utils';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import Button from '../../../common/components/button/Button';
import { FaPlus } from 'react-icons/fa';
import { TiInfoLarge } from 'react-icons/ti';
import Popover from '../../../common/components/popover/Popover';
import ProjectTimePopover, { ProjectTimePopoverMode, ProjectTimePopoverScreen } from '../ProjectTimePopover/ProjectTimePopover';
import { useTranslation } from 'react-i18next';
import DateFormat from '../../../common/components/dateFormat/dateFormat';
import { DATE_TIME_FORMAT_DEFAUT } from '../../../Config';
import { ProjectTimesPhaseDto } from '../../../api/projectsTimes/models/ProjectTimesDto';
import { Filters } from '../../dasboard/components/ProjectAdvancements/ProjectAdvancementsTableFilters/ProjectAdvancementsTableFilters';
import PlannedRealizedTimes from '../PlannedRealizedTimes/PlannedRealizedTimes';

type Props = {
    phase: ProjectTimesPhaseDto;
    phaseIndex: number;
    projectIndex: number;
    onExpandUsersClick?: (projectIndex?: number, phaseIndex?: number) => void;
    filters?: Filters;
    onRefresh: () => void;
    userCanWriteTimes?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectsTimesTablePhaseLine: React.FC<Props> = ({
    phase, phaseIndex, projectIndex, onExpandUsersClick, filters, onRefresh, userCanWriteTimes, ...props
}: Props) => {
    const { t } = useTranslation();

    const [popoverMode, setPopoverMode] = useState<ProjectTimePopoverMode>('NEW');
    const [popoverScreen, setPopoverScreen] = useState<ProjectTimePopoverScreen>('FORM');


    const btPosition = () => {
        const elemnt = document.getElementById('btTeste');
        const clientX = elemnt ? window.pageXOffset + elemnt.getBoundingClientRect().right : 0;
        //console.log(clientX)
        return (clientX < 1315);
    }

    const [isMobileView, setIsMobileView] = useState<boolean>(btPosition());
    React.useEffect(() => {
        window.addEventListener('resize', () => {
            setIsMobileView(btPosition());
        })
    }, []);

    return (
        <div {...props} className={`${styles.container} ${styles.flexColumn} ${!phase.showUsers ? undefined : styles.border}`}>
            <div className={`${styles.flexRow} ${styles.border}`}>
                <div className={styles.column} style={Utils.calculateCellWidthStyle(40)}>
                    <div onClick={(e: any) => {
                        onExpandUsersClick && onExpandUsersClick(projectIndex, phaseIndex);
                    }}>
                        {!phase.showUsers && <GoTriangleDown className={styles.iconExpand}></GoTriangleDown>}
                        {phase.showUsers && <GoTriangleUp className={styles.iconExpand}></GoTriangleUp>}
                    </div>
                </div>
                <div className={`${styles.column} ${styles.columnValues}`} style={Utils.calculateCellWidthStyle(80)}>
                    <label className={styles.bold}><PlannedRealizedTimes planned={phase.planned} realized={phase.realized} /></label>
                </div>
            </div>
            {phase.showUsers && (!phase.users || !phase.users.length) && (
                <div className={styles.flexRow} key={`phase-${phaseIndex}-user-`}>
                    <div className={styles.column} style={Utils.calculateCellWidthStyle(100)}>
                        {t('common.without_elements')}
                    </div>
                </div>
            )}
            {
                phase.showUsers && phase.users && phase.users.map((u, i) => {
                    const popoverContent = (<ProjectTimePopover
                        mode={popoverMode}
                        screen={popoverScreen}
                        onClose={() => {
                            //setIsPopoverOpen(false);
                        }}
                        projectPhaseGuid={phase.guid}
                        projectResponsibleDsiId={u.id}
                        projectResponsibleDsiName={u.name}
                        filters={filters}
                        setRefresh={(screen: ProjectTimePopoverScreen) => {
                            onRefresh();
                        }}
                        userCanWriteTimes={userCanWriteTimes}
                    />);
                    return (
                        <div className={`${styles.flexRow} ${i < (phase.users.length - 1) ? styles.border : undefined}`} key={`phase-${phaseIndex}-user-${i}`}>
                            <div className={styles.column} style={Utils.calculateCellWidthStyle(40)}>
                                {u.name}
                            </div>
                            <div className={`${styles.column} ${styles.columnValues}`} style={Utils.calculateCellWidthStyle(80)}>
                                <label className={styles.labelTime}><PlannedRealizedTimes planned={u.planned} realized={u.realized} /></label>
                                <Popover
                                    positions={isMobileView ? undefined : ['right']}
                                    align={isMobileView ? undefined : 'end'}
                                    parent={document.body}
                                    onClickOutside={() => {
                                        onExpandUsersClick && onExpandUsersClick(projectIndex, phaseIndex);
                                        onExpandUsersClick && onExpandUsersClick(projectIndex, phaseIndex);
                                    }}
                                    content={setIsPopoverOpen => popoverContent}>
                                    {(isPopoverOpen, setIsPopoverOpen) => (
                                        <div className={styles.buttons}>
                                            {userCanWriteTimes && <Button id={'btTeste'} type="button" preset={'secondary'} size="small" onClick={() => {
                                                setIsMobileView(btPosition());
                                                setPopoverMode('NEW');
                                                setPopoverScreen('FORM');
                                                setTimeout(() => {
                                                    setIsPopoverOpen(!isPopoverOpen);
                                                })
                                            }} onlyIcon={true} style={{ marginLeft: '1rem', width: '20px', height: '20px', fontSize: '10px' }} >
                                                <FaPlus color={'#034E92'} />
                                            </Button>}
                                            <Button type="button" preset={'gray'} size="small" onClick={() => {
                                                setIsMobileView(btPosition());
                                                setPopoverMode('DETAILS');
                                                setPopoverScreen('HISTORY');
                                                setTimeout(() => {
                                                    setIsPopoverOpen(!isPopoverOpen);
                                                })
                                            }} onlyIcon={true} style={{ marginLeft: '0.5rem', width: '20px', height: '20px', fontSize: '10px' }} >
                                                <TiInfoLarge />
                                            </Button>
                                        </div>
                                    )}
                                </Popover>

                                <div className={`${styles.columnInfo}`}>
                                    {u.lastUpdate && <label className={styles.labelInfo}>
                                        {t('projectstimes.table.last_change')}: {u.lastUpdateUser} <DateFormat value={u.lastUpdate} format={DATE_TIME_FORMAT_DEFAUT} />
                                    </label>}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default memo(ProjectsTimesTablePhaseLine);
