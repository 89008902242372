import Http from '../../common/services/Http';
import { IdNameDto } from '../common/models/IdNameDto';
import { Page } from '../common/types/Page';
import { SourceSearchCriteria } from './models/SourceSearchCriteria';
import { SourceViewModel } from './models/SourceViewModel';

class SourceService {
    
    public getList(criteria: SourceSearchCriteria) {
        return Http.get<Page<SourceViewModel>>('sources', criteria);
    }

    public getSourceById(id: string) {
        return Http.get<SourceViewModel>('sources/'+ id);
    }

    public save(model: SourceViewModel) {
        return !!model.id ? Http.put('sources', model) : Http.post('sources', model);
    }

    public remove(model: SourceViewModel) {
        return  Http.put('sources/deactivate', model);
    }

    public getSourcesByGroupId(groupId: string) {
        return Http.get<IdNameDto[]>('sources/get-all-sources-for-select-item/ '+ groupId);
    }
}

export default new SourceService();



