import React, { memo } from 'react';
import Box from '../../../../common/components/box/Box';
import SubBox from '../../../../common/components/subBox/SubBox';
import { VscListSelection } from 'react-icons/vsc';
import styles from './ProjectEvaluation.module.scss';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from '../../../../common/components/formItem/FormItem';
import Label from '../../../../common/components/label/Label';
import ProjectEvaluationBox from './components/projectEvaluationBox/ProjectEvaluationBox';
import ProjectInternalAllocationBox from './components/projectInternalAllocationBox/ProjectInternalAllocationBox';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectAllocationTypeViewModel, ProjectEvaluationMethodologyViewModel, ProjectInvestmentTotalsViewModel, ProjectInvestmentTypeViewModel, ProjectScoreViewModel, ProjectSuccessIndicatorViewModel } from '../../../../api/projects/models/ProjectViewModel';
import { ProjectForm } from '../../models/ProjectForm';
import InputError from '../../../../common/components/inputError/InputError';
import { FaPlus } from 'react-icons/fa';
import { EvaluationMethodologyViewModel } from '../../../../api/evaluationMethodologies/models/EvaluationMethodologyViewModel';
import { SuccessIndicatorViewModel } from '../../../../api/successIndicators/models/SuccessIndicatorViewModel';
import { SelectValueLabel } from '../../../../common/types/SelectValueLabel';
import Button from '../../../../common/components/button/Button';
import { IoTrash } from 'react-icons/io5';
import IconInvestments from '../../../../assets/svg/icon_investissements.svg';
import ProjectScore from './components/projectScore/ProjectScore';
import IconInternalAllocation from '../../../../assets/svg/icon_charges_internes.svg';
import IconSigma from '../../../../assets/svg/icon_sigma.svg';
import SelectController from '../../../../common/components/select/SelectController';
import { ScoreType } from '../../../../api/scores/enums/ScoreType';
import { Z_ASCII } from 'zlib';

type Props = {
    isDetails: boolean;
    investmentTotals: UseFieldArrayMethods<ProjectInvestmentTotalsViewModel, 'id'>
    investmentTypes: UseFieldArrayMethods<ProjectInvestmentTypeViewModel, 'id'>
    internalAllocationTypes: UseFieldArrayMethods<ProjectAllocationTypeViewModel, 'id'>
    internalAllocationTotals: UseFieldArrayMethods<ProjectInvestmentTotalsViewModel, 'id'>;
    form: UseFormMethods<ProjectForm>;
    evaluationMethodologiesOptions: EvaluationMethodologyViewModel[];
    successIndicatorsOptions: SuccessIndicatorViewModel[];
    successIndicators: UseFieldArrayMethods<ProjectSuccessIndicatorViewModel, 'id'>;
    evaluationMethodologies: UseFieldArrayMethods<ProjectEvaluationMethodologyViewModel, 'id'>;
    scores: UseFieldArrayMethods<ProjectScoreViewModel, 'id'>;
};


const ProjectEvaluation: React.FC<Props> = ({
    successIndicatorsOptions, evaluationMethodologiesOptions,
    isDetails, investmentTotals, investmentTypes, form, evaluationMethodologies, successIndicators,
    internalAllocationTypes, internalAllocationTotals,
    scores
}: Props) => {

    const { t } = useTranslation();

    const { errors } = form;

    const addEvaluationMethodologiesRow = () => {
        evaluationMethodologies.append({ evaluationMethodologyId: '00000000-0000-0000-0000-000000000000' });
    }

    const removeEvaluationMethodologiesRow = (index: number) => {
        evaluationMethodologies.remove(index);
    }

    const addSuccessIndicatorsRow = () => {
        successIndicators.append({ successIndicatorId: '00000000-0000-0000-0000-000000000000' });
    }

    const removeSuccessIndicatorsRow = (index: number) => {
        successIndicators.remove(index);
    }

    const evaluationsOptions: SelectValueLabel[] = (evaluationMethodologiesOptions || []).map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));
    const indicatorOptions: SelectValueLabel[] = (successIndicatorsOptions || []).map(x => ({ value: x.id || '', label: x.name || '', active: x.active != undefined ? x.active : true }));

    return (
        <Box
            title={t('project.project_evaluation.title')}
            icon={<VscListSelection />}
            contentClassName={styles.boxContent}
        >
            <SubBox
                title={t('project.project_evaluation.temporal_evaluation')}>

                <ProjectEvaluationBox
                    isDetails={isDetails}
                    title={t('project.project_evaluation.investments')}
                    icon={<img src={IconInvestments} />}
                    titleFooter={t('project.project_evaluation.budget') + ' + ' + t('project.project_evaluation.report')}
                    iconFooter={<img src={IconSigma} />}
                    isTotal={true}
                    showColumnsOutsideHeader={true}
                    showColumnsInsideHeader={true}
                    showColumnsFooter={true}
                    investmentTotals={investmentTotals}
                    investmentTypes={investmentTypes}
                    form={form}
                    unit={form.getValues('investimentsUnit')}

                />

                <ProjectInternalAllocationBox
                    isDetails={isDetails}
                    title={t('project.project_evaluation.internal_allocations')}
                    icon={<img src={IconInternalAllocation} />}
                    isTotal={true}
                    internalAllocationTypes={internalAllocationTypes}
                    internalAllocationTotals={internalAllocationTotals}
                    form={form}
                    className={styles.internalAllocationBox}
                    unit={form.getValues('internalAllocationsUnit')}
                />
            </SubBox>
            <SubBox title={t('project.project_evaluation.project_evaluation')}>
                <Row>
                    <Col xs={6}>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_evaluation.success_indicators')}</Label>
                            {successIndicators.fields.map((item, i) => {
                                const name = `successIndicators[${i}].successIndicatorId`;
                                return (
                                    <Row key={item.id} style={{ marginBottom: '10px' }}>
                                        <Col xs={isDetails ? 12 : 11} key={i}>
                                            <SelectController
                                                name={name}
                                                form={form}
                                                //rules={{ required: true }}
                                                options={indicatorOptions.filter(x => x.active)}
                                                allOptions={indicatorOptions}
                                                isClearable
                                                placeholder={t('project.project_evaluation.success_indicators')}
                                                isDisabled={isDetails}
                                            />
                                        </Col>
                                        {!isDetails && i < (successIndicators.fields.length - 1) && <Col xs={1} className={styles.align}>
                                            <Button type="button" preset={'secondary'} size="small" onlyIcon={true} onClick={() => removeSuccessIndicatorsRow(i)}>
                                                <IoTrash />
                                            </Button>
                                        </Col>}
                                        {!isDetails && i == (successIndicators.fields.length - 1) && <Col xs={1} className={styles.align}>
                                            <Button type="button" preset={'secondary'} size="small" onlyIcon={true} onClick={addSuccessIndicatorsRow}>
                                                <FaPlus color={'#034E92'} />
                                            </Button>
                                        </Col>}
                                        <Col xs={12}>
                                            <InputError error={errors.successIndicators?.find((x, index) => index == i)?.successIndicatorId} />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </FormItem>
                    </Col>
                    <Col xs={6}>
                        <FormItem >
                            <Label className={styles.bold}>{t('project.project_evaluation.evaluation_methodologies')}</Label>
                            {evaluationMethodologies.fields.map((item, i) => {
                                const name = `evaluationMethodologies[${i}].evaluationMethodologyId`;
                                return (
                                    <Row key={item.id} style={{ marginBottom: '10px' }}>
                                        <Col xs={isDetails ? 12 : 11} key={i}>
                                            <SelectController
                                                name={name}
                                                form={form}
                                                //rules={{ required: true }}
                                                options={evaluationsOptions.filter(x => x.active)}
                                                allOptions={evaluationsOptions}
                                                isClearable
                                                placeholder={t('project.project_evaluation.evaluation_methodologies')}
                                                isDisabled={isDetails}
                                            />
                                        </Col>
                                        {!isDetails && i < (evaluationMethodologies.fields.length - 1) && <Col xs={1} className={styles.align} >
                                            <Button type="button" preset={'secondary'} size="small" onlyIcon={true} onClick={() => removeEvaluationMethodologiesRow(i)}>
                                                <IoTrash />
                                            </Button>
                                        </Col>}
                                        {!isDetails && i == (evaluationMethodologies.fields.length - 1) && <Col xs={1} className={styles.align} >
                                            <Button type="button" preset={'secondary'} size="small" onlyIcon={true} onClick={addEvaluationMethodologiesRow}>
                                                <FaPlus color={'#034E92'} />
                                            </Button>
                                        </Col>}
                                        <Col xs={12}>
                                            <InputError error={errors.evaluationMethodologies?.find((x, index) => index == i)?.evaluationMethodologyId} />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </FormItem>
                    </Col>
                </Row>
            </SubBox>
            <SubBox
                title={t('project.project_evaluation.project_score')}>
                <Label className={styles.bold}>{t('project.project_evaluation.initial_score')}
                </Label>
                <ProjectScore
                    form={form}
                    isDetails={isDetails}
                    scores={scores}
                    type={ScoreType.INITIAL} />

                <Label className={styles.bold}>{t('project.project_evaluation.final_score')}</Label>
                <ProjectScore
                    form={form}
                    isDetails={isDetails}
                    scores={scores}
                    type={ScoreType.FINAL}
                />
            </SubBox>
        </Box>
    );
}

export default memo(ProjectEvaluation);