import React, { memo } from 'react';
import MoneyFormat from '../../../common/components/moneyFormat/MoneyFormat';
import styles from './PlannedRealizedTimes.module.scss';

type Props = {
    planned?: number;
    realized?: number;
};

const PlannedRealizedTimes: React.FC<Props> = ({
    planned,
    realized
}: Props) => {

    return (
        <label className={styles.labelHours}>
            {planned != null && planned != undefined ? <MoneyFormat value={planned} suffix={'h'} /> : '-'} / {realized != null && realized != undefined ? <MoneyFormat value={realized} suffix={'h'} /> : '-'}
        </label>
    );
}

export default memo(PlannedRealizedTimes);
