/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React from 'react';
import styles from './Input.module.scss';

type InputPreset = 'default';

type Props = {
    preset?: InputPreset;        
    displayName?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const InputRadio = React.forwardRef<HTMLInputElement, Props>(({ displayName, preset, ...props }, ref) => {
    const presetClassName = preset ? styles[preset] : styles.default;

    return (
        <div>
            <input ref={ref}
                type={'radio'}
                {...props}                
                className={`${styles.radioInput} ${presetClassName} ${props.className || ''} ${props.disabled ? styles.disabled : ''} `} />          
                <label className={styles.radioInputLabel}>{displayName}</label>
        </div>
    );
});

export default InputRadio;
