import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../common/components/box/Box';
import SubBox from '../../../../common/components/subBox/SubBox';
import { VscListSelection } from 'react-icons/vsc';
import FormItem from '../../../../common/components/formItem/FormItem';
import Label from '../../../../common/components/label/Label';
import {  UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../models/ProjectForm';
import { Col, Row } from 'react-flexbox-grid';
import styles from './ProjectDefinition.module.scss';
import BoxContent from '../../../../common/components/box/BoxContent';
import { ProjectBudgetingViewModel, ProjectQualityQuestionViewModel, ProjectSubventionViewModel } from '../../../../api/projects/models/ProjectViewModel';
import ProjectQualityQuestions from './components/projectQualityQuestions/ProjectQualityQuestions';
import ProjectSubventions from './components/projectSubventions/ProjectSubventions';
import ProjectBudgetings from './components/projectBudgetings/ProjectBudgetings';

import SummernoteController from '../../../../common/components/summernoteController/SummernoteController'

type Props = {
    form: UseFormMethods<ProjectForm>;
    isDetails: boolean;
    qualityQuestions: UseFieldArrayMethods<ProjectQualityQuestionViewModel & { isNew: boolean }, 'id'>;
    subventions: UseFieldArrayMethods<ProjectSubventionViewModel, 'id'>;
    budgetings: UseFieldArrayMethods<ProjectBudgetingViewModel, 'id'>;
};

const ProjectDefinition: React.FC<Props> = ({ form, isDetails, budgetings, qualityQuestions, subventions }: Props) => {

    const { t } = useTranslation();
    const { register } = form;


    return (
        <Box
            title={t('project.project_definition.title')}
            icon={<VscListSelection />}
            contentClassName={styles.boxContent}
        >
            <BoxContent>
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_definition.context_diagnostic')}</Label>
                            <SummernoteController name="contextDiagnosis" isDisabled={isDetails} form={form} placeholder={t('project.project_definition.context_diagnostic')}/>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_definition.project_objectives_challenges')}</Label>
                            <SummernoteController name="projectObjectivesAndChallenges" isDisabled={isDetails} form={form} placeholder={t('project.project_definition.project_objectives_challenges')}/>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_definition.target_populations')}</Label>
                            <SummernoteController name="targetPopulations" isDisabled={isDetails} form={form} placeholder={t('project.project_definition.target_populations')}/>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_definition.expected_features')}</Label>
                            <SummernoteController name="expectedFeatures" isDisabled={isDetails} form={form} placeholder={t('project.project_definition.expected_features')}/>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_definition.risks_not_doing')}</Label>
                            <SummernoteController name="risksOfNotDoing" isDisabled={isDetails} form={form} placeholder={t('project.project_definition.risks_not_doing')}/>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.bold}>{t('project.project_definition.vigilance_points')}</Label>
                            <SummernoteController name="vigilancePoints" isDisabled={isDetails} form={form} placeholder={t('project.project_definition.vigilance_points')}/>
                        </FormItem>
                    </Col>
                </Row>
            </BoxContent>
            <SubBox title={t('project.project_definition.roi_project_subsidy')} contentClassName={styles.boxContent}>
                <SubBox title={t('project.project_definition.quality_roi')} headerClassName={styles.subBoxHeader} headerTextClassName={styles.subBoxHeaderText}>
                    <ProjectQualityQuestions
                        isDetails={isDetails}
                        form={form}
                        qualityQuestions={qualityQuestions}></ProjectQualityQuestions>
                </SubBox>
                <SubBox title={t('project.project_definition.budget_roi')} headerClassName={styles.subBoxHeader} headerTextClassName={styles.subBoxHeaderText}>
                    <ProjectBudgetings
                        isDetails={isDetails}
                        form={form}
                        budgetings={budgetings}></ProjectBudgetings>
                </SubBox>
                <SubBox title={t('project.project_definition.subvention')} headerClassName={styles.subBoxHeader} headerTextClassName={styles.subBoxHeaderText}>
                    <ProjectSubventions
                        isDetails={isDetails}
                        form={form}
                        subventions={subventions}></ProjectSubventions>
                </SubBox>
            </SubBox>
        </Box >
    );
}

export default memo(ProjectDefinition);
