import { ProjectAllResponsiblesViewModel } from './models/ProjectAllResponsiblesViewModel';
import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { ProjectsListDto } from './models/ProjectsListDto';
import { ProjectsSearchCriteria } from './models/ProjectsSearchCriteria';
import { ProjectViewModel } from './models/ProjectViewModel';
import { NotifyEmailForm } from '../../screens/project/models/NotifyEmailForm';

class ProjectService {

    public getList(criteria: ProjectsSearchCriteria) {
        return Http.get<Page<ProjectsListDto>>('projects', criteria);
    }

    public get(id: string) {
        return Http.get<ProjectViewModel>('projects/' + id);
    }

    public save(model: ProjectViewModel) {
        return model.id ? Http.put<string>('projects', model) : Http.post<string>('projects', model);
    }

    public remove(model: ProjectViewModel) {
        return Http.put('projects/deactivate', model);
    }

    public getAllResponsibles(projectId: string) {
        return  Http.get<ProjectAllResponsiblesViewModel[]>('projects/getAllResponsibles/'+ projectId);
    }

    public sendEmailNotifyProjectChanges(model: NotifyEmailForm) {
        return  Http.post('projects/sendEmailNotifyProjectChanges', model);
    }
}

export default new ProjectService();