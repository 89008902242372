import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Button from '../../common/components/button/Button';
import FormItem from '../../common/components/formItem/FormItem';
import Input from '../../common/components/input/Input';
import InputError from '../../common/components/inputError/InputError';
import Label from '../../common/components/label/Label';
import Loading from '../../common/services/Loading';
import styles from './RolesScreen.module.scss';
import RolesService from '../../api/roles/RolesService';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import { RoleViewModel } from '../../api/roles/models/RoleViewModel';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    afterSave: () => void;
    roleId?: string;
    isDetails: boolean;
};

type Form = {
    realName: string;
}

enum ScreenType {
    NEW,
    EDIT,
    DETAILS
}

const RolesScreen: React.FC<Props> = ({ roleId, groupId, afterSave, isDetails, userCanRead, userCanWrite }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, setValue } = useForm<Form>();
    const [screenMode, setScreenMode] = useState<ScreenType>(isDetails && !!roleId ? ScreenType.DETAILS : !!roleId ? ScreenType.EDIT : ScreenType.NEW);
    const [itemToRemove, setItemToRemove] = useState<RoleViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [realNameIsAvailable, setRealNameIsAvailable] = useState<boolean>(true);

    const onCancel = () => {
        afterSave();
    }

    const onSubmit = async ({ realName }: Form) => {
        try {

            if (!realName) {
                return;
            }

            Loading.show();
            if (groupId) {

                const result = await RolesService.checkNameAvailability({ roleId, realName, groupId });

                if (result != null && result.available == false) {
                    if (!!result.errors) {
                        if (!!result.errors.find((x: string) => x == 'REALNAME')) {
                            setRealNameIsAvailable(false);
                            addToast(t('roles.real_name_not_available'), { appearance: 'warning' });
                        }
                    }
                    Loading.hide();
                    return;
                }

                await RolesService.save({ id: roleId, realName: realName, groupId });

            }
            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            afterSave();

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getRole = async () => {
        try {

            Loading.show();

            if (roleId) {
                const result = await RolesService.getRoleById(roleId);
                setValue('realName', result?.realName);
            }

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: RoleViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await RolesService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onCancel();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        if (userCanRead) {
            if (!!roleId) {
                getRole();
            }
        }
    }, [roleId]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.name')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Input name="realName" placeholder={t('common.name')} ref={register({ required: true, maxLength: 255 })} disabled={screenMode === ScreenType.DETAILS} maxLength={255} onKeyPress={() => setRealNameIsAvailable(true)} />
                    <InputError error={errors.realName} />
                    {!realNameIsAvailable && <InputError error={{ type: 'real_name_not_available', message: t('roles.real_name_not_available') }} />}
                </FormItem>
            </div>
            {screenMode !== ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={() => screenMode === ScreenType.EDIT && !!isDetails ? setScreenMode(ScreenType.DETAILS) : onCancel()} />
                {userCanWrite && <Button type="submit" text={t('common.save')} size={'small'} />}
            </div>}
            {screenMode === ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.back')} preset={'secondary'} size={'small'} onClick={() => onCancel()} />
                {userCanWrite && <Button type="button" text={t('common.remove')} preset={'danger'} size={'small'} onClick={() => showRemoveItemDialog({ id: roleId } as RoleViewModel)} />}
                {userCanWrite && <QuestionYesNo message={t('common.messages.remove_record')}
                    isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />}
                {userCanWrite &&<Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
            </div>}
        </form>
    );
}

export default RolesScreen;
