import Http from '../../common/services/Http';
import { IdNameWithChildsDto } from '../common/models/IdNameDto';
import { Page } from '../common/types/Page';
import { EstablishmentSearchCriteria } from './models/EstablishmentSearchCriteria';
import { EstablishmentViewModel } from './models/EstablishmentViewModel';

class EstablishmentService {

    public getList(criteria: EstablishmentSearchCriteria) {
        return Http.get<Page<EstablishmentViewModel>>('establishments', criteria);
    }

    public getListSelectItemsWithPoles(groupId: string) {
        return Http.get<IdNameWithChildsDto[]>('establishments/get-establishments-for-select-item-by-group/' + groupId);
    }

    public get(id?: string) {
        return Http.get<EstablishmentViewModel>('establishments/' + id);
    }

    public save(model: EstablishmentViewModel) {
        return !!model.id ? Http.put('establishments', model) : Http.post('establishments', model);
    }

    public remove(model: EstablishmentViewModel) {
        return  Http.put('establishments/deactivate', model);
    }

    public getAllEstablishmentsWithPoles(groupId?: string) {
        if(groupId){
            return Http.get<IdNameWithChildsDto[]>('establishments/get-all-establishments-with-poles/' + groupId);
        }
        return Http.get<IdNameWithChildsDto[]>('establishments/get-all-establishments-with-poles');
    }
}

export default new EstablishmentService();