import React, { ReactChild, useEffect, useState } from 'react';
import styles from './Sidebar.module.scss';
import { BsLayoutThreeColumns } from 'react-icons/bs';
import { NAVBAR_ID } from '../../../Config';
import { useWindowHeight } from '@react-hook/window-size';

type Props = {
    children: ReactChild,
    onClose?: () => void,
    onOpen?: () => void,
}

const Sidebar: React.FC<Props> = ({ children, onClose, onOpen }: Props) => {

    const width = 360;
    const windowHeight = useWindowHeight();
    const [xPosition, setX] = useState(-width);
    const [scrolled, setScrolled] = useState(false)

    const toggleMenu = () => {
        if (xPosition == -width) {
            setX(0);
            onOpen && setTimeout(onOpen);
        } else {
            setX(-width);
            onClose && setTimeout(onClose);
        }
    };

    React.useEffect(() => {
        setX(-width);
    }, []);


    useEffect(() => {
        document.addEventListener('scroll', () => {
            const scrolled = document.scrollingElement?.scrollTop || 0;
            if (scrolled >= 5) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        });
    }, [])

    const navbar = document.getElementById(NAVBAR_ID);
    const navbarHeight = navbar?.getBoundingClientRect().height;
    const sidebarHeight = scrolled ? windowHeight : (windowHeight - (navbarHeight || 0));

    return (
        <div
            className={styles.sideBar}
            style={{
                zIndex: 100,
                right: `${xPosition}px`,
                width: `${width}px`,
                height: `${sidebarHeight}px`,
                top: scrolled ? 0 : undefined,
            }}
        >
            <button
                onClick={() => toggleMenu()}
                className={styles.toggleMenu}
                style={{
                    transform: `translate(-54px, -1px)`
                }}
            >
                <BsLayoutThreeColumns className={styles.toggleMenuIcon}/>
            </button>
            <div className={styles.content}>{children}</div>
        </div>
    );
}

export default Sidebar;
