import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { CheckUsernameAndEmailAvailabilityViewModel } from './models/CheckUsernameAndEmailAvailabilityViewModel';
import { UserSearchCriteria } from './models/UserSearchCriteria';
import { UserViewModel } from './models/UserViewModel';

class UserService {

    public getList(criteria: UserSearchCriteria) {
        return Http.get<Page<UserViewModel>>('users', criteria);
    }

    public getUserById(id: string) {
        return Http.get<UserViewModel>('users/'+ id);
    }

    public save(model: UserViewModel) {
        return !!model.id ? Http.put('users', model) : Http.post('users', model);
    }

    public remove(model: UserViewModel) {
        return Http.put('users/deactivate', model);
    }

    public checkUsernameAndEmailAvailability(model: CheckUsernameAndEmailAvailabilityViewModel) {
        return Http.post<any>('users/check-username-and-email-availability', model);
    }
    
    public getAuthors(criteria: UserSearchCriteria) {
        return Http.get<UserViewModel[]>('users/get-authors', criteria);
    }

    public getAllUsersOfGroup(groupId: string, allData?: boolean) {
        return Http.get<UserViewModel[]>('users/get-all-users-of-group/'+ groupId + '/' + (allData ? allData : false));
    }

    public getAllUsersOfEstablishment(establishmentId: string, allData?: boolean) {
        return Http.get<UserViewModel[]>('users/get-all-users-of-establishment/'+ establishmentId + '/' + (allData ? allData : false));
    }

    public getAllRemoteUsersOfGroup(groupId: string) {
        return Http.get<UserViewModel[]>('users/get-all-remote-users-of-group/'+ groupId);
    }
    
    public getAll() {
        return Http.get<UserViewModel[]>('users/get-all');
    }
}

export default new UserService();