import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProjectInvestmentsSummaryBox.module.scss';
import Input from '../../../../../../common/components/input/Input';
import MoneyFormat from '../../../../../../common/components/moneyFormat/MoneyFormat';
import Popover from '../../../../../../common/components/popover/Popover';
import { FaPen } from 'react-icons/fa';
import FormItem from '../../../../../../common/components/formItem/FormItem';
import Label from '../../../../../../common/components/label/Label';
import { UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../../../models/ProjectForm';
import InputGroupController from '../../../../../../common/components/inputGroup/InputGroupController';

type EditableMoneyPopoverProps = {
    form: UseFormMethods<ProjectForm>;
    editName: string;
};

const EditableMoneyPopover = ({ form, editName }: EditableMoneyPopoverProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.popoverContainer}>
            <FormItem>
                <Label className={styles.bold}>{t('common.value')}</Label>
                <InputGroupController
                    type="number"
                    text="€"
                    name={editName}
                    form={form}
                    disabled={false}
                    format="money"
                    containerClassName={styles.popoverInput}
                />
            </FormItem>
        </div>
    );
};

type Props = {
    form: UseFormMethods<ProjectForm>;
    icon: React.ReactElement | undefined;
    title?: string;
    subtitle?: string;
    total: number;
    commentName?: string;
    canComment?: boolean;
    isDisabled: boolean;
    standout?: boolean;
    canEdit?: boolean;
    editName?: string;
};

const ProjectInvestmentsSummaryBox: React.FC<Props> = ({
    form, icon, title, subtitle, commentName, isDisabled, standout = false, total, canComment = true, canEdit = false, editName,
}: Props) => {
    const { t } = useTranslation();

    return (
        <div className={`${styles.container} ${standout ? styles.standout : ''}`}>
            <div className={styles.headerContainer}>
                <div className={styles.right}>
                    {<div className={styles.shadowBall}></div>}
                    <div className={styles.icon}>{icon}</div>
                    {title && <span className={styles.title}>{title}</span>}
                </div>
                <div className={styles.left}>
                    {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
                    <span className={styles.price}>
                        {(!canEdit || isDisabled) && <MoneyFormat value={total} />}
                        {(canEdit && editName && !isDisabled) && 
                            <Popover content={() => <EditableMoneyPopover form={form} editName={editName} />} contentContainerClassName={styles.popoverContentContainer}>
                                {(isPopoverOpen, setIsPopoverOpen) => (
                                    <div onClick={() => setIsPopoverOpen(!isPopoverOpen)} className={styles.editButtonContainer}>
                                        <MoneyFormat value={total} />
                                        <FaPen color={'#63C9EF'} className={styles.editIcon} />
                                    </div>
                                )}
                            </Popover>
                        }
                    </span>
                </div>
            </div>
            <div className={styles.bodyContainer}>
                {(canComment && commentName) && <Input
                    name={commentName}
                    ref={form.register()}
                    maxLength={255}
                    placeholder={t('project.investments_summary.comment')}
                    disabled={isDisabled}
                    defaultValue={form.getValues(commentName)}
                />}
            </div>
        </div>
    );
}

export default memo(ProjectInvestmentsSummaryBox);
