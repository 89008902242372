import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import styles from './ProjectsTimes.module.scss';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { Reducers } from '../../store/types';
import { UserProfile } from '../../api/account/models/UserProfile';
import Table, { TableCell, TableColumn, TableRow } from '../../common/components/table/Table';
import Pagination, { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from '../../common/components/pagination/Pagination';
import Loading from '../../common/services/Loading';
import { EqualHeight } from 'react-equal-height';
import PaginationTextInfo from '../../common/components/pagination/PaginationTextInfo';
import { SearchType, ColumnField, SearchField } from '../../api/projects/models/ProjectsSearchCriteria';
import DateFormat from '../../common/components/dateFormat/dateFormat';
import { DATE_FORMAT_MONTH_YEAR, POLICIES, SEARCH_HISTORY_PAGES } from '../../Config';
import SearchHistoryService from '../../api/userSearchHistory/SearchHistoryService';
import { SearchHistoryViewModel } from '../../api/userSearchHistory/models/SearchHistoryViewModel';
import StatusService from '../../api/status/StatusService';
import { useHistory } from 'react-router-dom';
import { ProjectsTimesSearchCriteria } from '../../api/projectsTimes/models/ProjectsTimesSearchCriteria';
import { ProjectTimesDto, ProjectTimesPhaseDto } from '../../api/projectsTimes/models/ProjectTimesDto';
import { StatusViewModel } from '../../api/status/models/StatusViewModel';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ProjectAdvancementsTableFilters, { Filters } from '../dasboard/components/ProjectAdvancements/ProjectAdvancementsTableFilters/ProjectAdvancementsTableFilters';
import ProjectsTimesTable from './ProjectTimesTable/ProjectsTimesTable';
import Badge from '../../common/components/badge/Badge';
import ProjectTimesService from '../../api/projectsTimes/ProjectTimesService';
import ProjectResponsiblesDsiService from '../../api/projectResponsiblesDsi/ProjectResponsiblesDsiService';
import { ProjectResponsibleDsiViewModel } from '../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiViewModel';
import stylesTable from '../../common/components/table/Table.module.scss';
import TableUtils from '../../common/components/table/TableUtils';
import Label from '../../common/components/label/Label';
import Utils from '../../common/services/Utils';
import { ProjectTimesTotalsDto } from '../../api/projectsTimes/models/ProjectTimesTotalsDto';
import PlannedRealizedTimes from './PlannedRealizedTimes/PlannedRealizedTimes';
import { ItemsPerPage } from '../../common/components/pagination/numberPerPage/NumberPerPage';
import Toolbar from './toolbar/Toolbar';
import { ProjectTimesSupportDto } from '../../api/projectsTimes/models/ProjectTimesSupportDto';

const ProjectTimes: React.FC = () => {
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();

    const [currentPage, setCurrentPage] = useState(1);
    const [projects, setProjects] = useState<ProjectTimesDto[] | undefined>(undefined);
    const [projectsTotals, setProjectsTotals] = useState<ProjectTimesTotalsDto | undefined>(undefined);
    const [onInit, setOnInit] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [reRenderContent, setReRenderContent] = useState(0);
    const [reRenderContentPhases, setReRenderContentPhases] = useState<number>(0);
    const [filters, setFilters] = useState<Filters>();
    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPage>(DEFAULT_PAGINATION_ITEMS_PER_PAGE);
    const criteria: ProjectsTimesSearchCriteria = {
        page: 1,
        itemsPerPage: itemsPerPage,
        orderBy: 'asc',
        orderColumn: 'designation',
    };
    const [orderBy, setOrderBy] = useState<string | undefined>(criteria.orderBy);
    const [orderColumn, setOrderColumn] = useState<string | undefined>(criteria.orderColumn);
    const [forceOrderBy, setForceOrderBy] = useState(0);
    const [openedRows, setOpenedRows] = useState<string[]>([]);
    const [openedPhases, setOpenedPhases] = useState<string[]>([]);
    const [statusList, setStatusList] = useState<StatusViewModel[]>([]);
    const [responsablesDsiList, setResponsablesDsiList] = useState<ProjectResponsibleDsiViewModel[]>([]);
    const [research, setResearch] = useState(0);
    const userCanWriteTimes = !!loggedUser?.policies.find(x => x == POLICIES.COLLABORATORS_TIMES_WRITE);

    const getData = async () => {
        if (loggedUser) {
            const userPosition = { groupId: loggedUser.groupId, establishmentId: loggedUser.establishmentId };

            const [status, responsableDsi] =
                await Promise.all([
                    StatusService.getList(userPosition),
                    ProjectResponsiblesDsiService.getList(userPosition)
                ]);

            setStatusList(status.items);
            setResponsablesDsiList(responsableDsi.items ?? []);
        }
    }

    const renderCellDates = (cell: TableCell) => (
        <>
            {(cell.row.startDate || cell.row.endDate) &&
                <><DateFormat value={cell.row.startDate} format={DATE_FORMAT_MONTH_YEAR} /> - <DateFormat value={cell.row.endDate} format={DATE_FORMAT_MONTH_YEAR} /></>
            }
        </>
    );

    const renderCellStatus = (cell: TableCell) => (
        <div style={{ textAlign: 'center' }}>
            {cell.row.status && <Badge color={cell.row.statusColor}>{cell.row.status}</Badge>}
        </div>
    );

    const renderCellTotal = () => (
        <span className={styles.labelTotal}>{t('common.total')}</span>
    );

    const renderCellTotals = () => (
        <label className={styles.bold}>
            <PlannedRealizedTimes planned={projectsTotals?.planned} realized={projectsTotals?.realized} />
        </label>
    );

    const renderCellEmpty = () => (
        <></>
    );

    const renderCellDesignation = (cell: TableCell) => (
        <label className={styles.clickable}
            onClick={(e: any) => {
                e.stopPropagation();
                onRowClick(cell.row)
            }}
        >
            {cell.row.designation}
        </label>
    );

    const _tableColumns: TableColumn[] = [
        { field: ColumnField.IDENTIFICATION, searchType: SearchType.TEXT, searchField: SearchField.IDENTIFICATION, order: 'identification', name: t('projectstimes.table.identification'), width: '10%' },
        { field: ColumnField.DESIGNATION, searchType: SearchType.TEXT, searchField: SearchField.DESIGNATION, order: 'designation', name: t('projectstimes.table.designation'), width: '43%', renderCell: renderCellDesignation },
        { field: ColumnField.STATUS, searchType: SearchType.SELECT, searchField: SearchField.STATUS, order: 'status', name: t('projectstimes.table.status'), width: '22%', renderCell: renderCellStatus },
        { field: ColumnField.START_END_DATE, searchType: SearchType.RANGE_DATE, searchField: SearchField.START_END_DATE, order: 'start_date', name: t('projectstimes.table.start_end'), width: '25%', renderCell: renderCellDates, cellAlignment: 'center', columnCellAlignment: 'center' },
    ];

    const _footerLeftTableColumns: TableColumn[] = [
        { field: '', name: '', width: '10%' },
        { field: '', name: '', width: '43%', render: renderCellTotal },
        { field: '', name: '', width: '22%' },
        { field: '', name: '', width: '25%' },
    ];

    const _footerRightTableColumns: TableColumn[] = [
        { field: '', name: '', width: '40%', render: renderCellEmpty },
        { field: '', name: '', width: '80%', render: renderCellTotals },

    ];

    const _rightTableColumns: TableColumn[] = [
        { field: ColumnField.COLAB, searchType: SearchType.SELECT, searchField: SearchField.RESPONSIBLE_DSI, order: 'colab', name: t('projectstimes.table.responsable_dsi'), width: '30%' },
        { field: ColumnField.PLANNED_REALIZED, name: t('projectstimes.table.start_end'), width: '70%', cellAlignment: 'center', columnCellAlignment: 'center' },
    ];

    const _tableSubColumns: TableColumn[] = [
        { field: 'name', name: t('projectstimes.table.name'), width: '100%', cellAlignment: 'right', columnCellAlignment: 'right', cellStyle: { paddingRight: '1rem' } },
    ];

    const getUserSettings = async () => {
        try {
            Loading.show();
            const resultSearchHistory = await SearchHistoryService.getSearchHistoryForPage(SEARCH_HISTORY_PAGES.PROJECTS_TIMES.toString());
            Loading.hide();
            applySearchHistory(resultSearchHistory);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
            setDefaultFilters();
            setResearch(research + 1);
        }
    }

    const applySearchHistory = (result: SearchHistoryViewModel) => {
        if (result && result.json) {
            const auxCriteria = JSON.parse(result.json);
            setItemsPerPage(auxCriteria.itemsPerPage);
            setOrderBy(auxCriteria.orderBy);
            setOrderColumn(auxCriteria.orderColumn);
            setOpenedRows(auxCriteria.openedRows);
            setOpenedPhases(auxCriteria.openedPhases);
            setFilters(auxCriteria);
            search(auxCriteria.page);
        } else {
            setDefaultFilters();
            search();
        }
    }

    const getProjectsAndPhases = async (reRender?: boolean) => {
        try {

            Loading.show();

            getCriteria();

            saveSearchHistory();

            const [result, totals, supports] =
                await Promise.all([
                    ProjectTimesService.getProjectsAndPhasesAndUsers(criteria),
                    ProjectTimesService.getProjectsAndPhasesAndUsersTotals(criteria),
                    ProjectTimesService.getSupportTimes({ ...criteria }),
                ]);

            await generateProjectPhases(result.items, supports);
            setTotalItems(result.totalItems);

            const totalsP = [totals.planned, supports?.planned].filter(x => x != undefined);
            const totalsR = [totals.realized, supports?.realized].filter(x => x != undefined);
            setProjectsTotals({
                planned: totalsP.length > 0 ? totalsP.sum() : undefined,
                realized: totalsR.length > 0 ? totalsR.sum() : undefined
            });
            if (reRender) { setReRenderContent(reRenderContent + 1); }

            Loading.hide();

        } catch (error) {
            console.log(error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const saveSearchHistory = async (customCriteria?: ProjectsTimesSearchCriteria) => {
        criteria.openedRows = openedRows;
        criteria.openedPhases = openedPhases;
        if (customCriteria) {
            customCriteria.openedRows = criteria.openedRows;
            customCriteria.openedPhases = criteria.openedPhases;
        }
        await SearchHistoryService.saveSearchHistory({ page: SEARCH_HISTORY_PAGES.PROJECTS_TIMES.toString(), json: JSON.stringify(customCriteria ? customCriteria : criteria) });
    }

    const isOpenedRow = (rowId?: string) => {
        return !![...openedRows, ...(criteria.openedRows ?? [])].find(z => z == rowId);
    }

    const isOpenedPhase = (phaseGuid?: string) => {
        return !![...openedPhases, ...(criteria.openedPhases ?? [])].find(z => z == phaseGuid);
    }

    const generateProjectPhases = async (result: ProjectTimesDto[], supportResult: ProjectTimesSupportDto) => {
        setProjects([...result.map(x => {
            return {
                ...x,
                showSubRows: isOpenedRow(x.id),
                phases: x.phases.map(p => {
                    return {
                        ...p,
                        showUsers: isOpenedPhase(p.guid)
                    }
                })
            }
        }),
        {
            id: '00000000-0000-0000-0000-000000000000',
            designation: t('projectstimes.table.support'),
            planned: supportResult?.planned,
            realized: supportResult?.realized,
            showSubRows: isOpenedRow('00000000-0000-0000-0000-000000000000'),
            phases: [{
                planned: supportResult?.planned,
                realized: supportResult?.realized,
                users: supportResult?.users.map(u => ({
                    id: u.id,
                    name: u.name,
                    planned: u.planned,
                    realized: u.realized,
                    lastUpdate: u.lastUpdate,
                    lastUpdateUser: u.lastUpdateUser
                })),
                showUsers: isOpenedPhase('00000000-0000-0000-0000-000000000000')
            }]
        } as ProjectTimesDto
        ]);
    }

    const onOrderList = (column: TableColumn) => {
        const _orderBy = orderColumn !== column.order ? 'asc' : orderBy == 'desc' ? 'asc' : 'desc';
        setOrderColumn(column.order);
        setOrderBy(_orderBy);
        setForceOrderBy(forceOrderBy + 1);
        search();
    }

    const getCriteria = async () => {
        criteria.page = currentPage;
        criteria.itemsPerPage = itemsPerPage;
        criteria.groupId = loggedUser?.groupId;
        criteria.establishmentId = loggedUser?.establishmentId;
        criteria.orderBy = orderBy;
        criteria.orderColumn = orderColumn;
        criteria.openedRows = openedRows;
        criteria.openedPhases = openedPhases;

        if (filters) {
            criteria.identification = filters.identification;
            criteria.designation = filters.designation;
            criteria.startDate = filters.startDate;
            criteria.endDate = filters.endDate;
            criteria.statusId = filters.statusId;
            criteria.responsibleDsiId = filters.responsibleDsiId;
        }
    };

    const onPageChange = (page: number) => {
        search(page);
    }

    const setDefaultFilters = () => {
        setFilters({
            //startDate: new Date(new Date().getFullYear(), 0, 1),
            //endDate: new Date(new Date().getFullYear() + 5, 11, 31)
        });
    }

    const onChangeFilters = (filters: Filters) => {
        setCurrentPage(1);
        setFilters(filters);
        search();
    }

    const onItemsPerPageChange = (items: ItemsPerPage) => {
        setItemsPerPage(items);
        search();
    }

    const search = (page?: number) => {
        setCurrentPage(page ?? 1);
        setResearch(research + 1);
    }

    const forceSaveSearchHistory = async () => {
        const resultSearchHistory = await SearchHistoryService.getSearchHistoryForPage(SEARCH_HISTORY_PAGES.PROJECTS_TIMES.toString());
        if (resultSearchHistory && resultSearchHistory.json) {
            const auxCriteria = JSON.parse(resultSearchHistory.json);
            saveSearchHistory(auxCriteria);
        }
    }

    const onRowClick = async (row: TableRow, index?: number) => {
        //if we only do the saveSearchHistory() he loses the scope and lost the criteria and filters!!!
        await forceSaveSearchHistory();
        history.push(`/project/${row.id}/details/projectstimes/null`);
    }

    const onExpandClick = (row: TableRow, index?: number) => {
        row.showSubRows = !row.showSubRows;
        if (projects && (!!index || index == 0)) {
            projects[index].showSubRows = row.showSubRows;
            if (projects[index].showSubRows) {
                setOpenedRows([...openedRows, projects[index].id]);
            } else {
                setOpenedRows([...openedRows.filter(rId => rId !== projects[index].id)]);
            }
        }
        setReRenderContentPhases(reRenderContentPhases + 1);
    }

    const onExpandUsersClick = (projectIndex?: number, phaseIndex?: number) => {
        if (projects && (!!projectIndex || projectIndex == 0) && (!!phaseIndex || phaseIndex == 0)) {
            projects[projectIndex].phases[phaseIndex].showUsers = !projects[projectIndex].phases[phaseIndex].showUsers;
            const guid = projects[projectIndex].phases[phaseIndex].guid ?? '00000000-0000-0000-0000-000000000000';
            if (projects[projectIndex].phases[phaseIndex].showUsers) {
                setOpenedPhases([...openedPhases, guid]);
            } else {
                setOpenedPhases([...openedPhases.filter(rId => rId !== guid)]);
            }
        }
        setReRenderContent(reRenderContent + 1);
    }

    const onClearFilters = () => {
        setItemsPerPage(10);
        setOpenedRows([]);
        setOpenedPhases([]);
    }

    useEffect(() => {
        if (onInit) {
            getData();
            getUserSettings();
            setOnInit(false);
        }
    }, [onInit]);

    useEffect(() => {
        if (research > 0) {
            getProjectsAndPhases(true);
        }
    }, [research]);

    return (
        <ScreenTitle title={t('projectstimes.title')} >
            <ScreenContainer>
                <Toolbar
                    clearSearch={() => {
                        onClearFilters();
                        onChangeFilters({});
                    }}
                    onItemsPerPageChange={onItemsPerPageChange}
                    itemsPerPage={itemsPerPage}
                    key={'contentTableToolbar_' + reRenderContent}
                />
                <EqualHeight>
                    <div key={'contentTable_' + reRenderContent}
                        className={styles.divTablesContent}>
                        <div className={styles.divTablesLeft}>
                            <Table
                                columns={_tableColumns}
                                rows={projects ?? []}
                                allowHover={true}
                                searchComponent={filters && <ProjectAdvancementsTableFilters
                                    filters={filters}
                                    onFilter={filters => { onChangeFilters(filters) }}
                                    onChange={filters => {
                                        //updateFilters
                                    }}
                                    columns={_tableColumns}
                                    status={statusList}
                                    onClearFilters={onClearFilters}
                                />}
                                onOrderList={column => onOrderList(column as TableColumn)}
                                subRowsFieldName={'phases'}
                                subRowsColumns={_tableSubColumns}
                                onRowClick={onExpandClick}
                                onExpandClick={onExpandClick}
                                orderBy={orderBy}
                                orderColumn={orderColumn}
                            />
                            {projects && projects.length > 0 && <div key={'contentTable2_' + reRenderContent}
                                className={styles.divTablesContent}>
                                <div className={styles.divTablesLeft}>
                                    <div className={stylesTable.tables}>
                                        <div className={stylesTable.table}>
                                            <div className={stylesTable.rows}>
                                                <div className={stylesTable.row} style={{ minHeight: '38px' }}>
                                                    {_footerLeftTableColumns.map((col, colIndex) => (
                                                        <div key={`row-cell-${colIndex}`} className={stylesTable.rowCell} style={TableUtils.getRowCellStyle(col)}>
                                                            {col.render && col.render()}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className={styles.divTablesRight} id="divRightTable">
                            {projects && <ProjectsTimesTable projects={projects} reRenderContentPhases={reRenderContentPhases} userCanWriteTimes={userCanWriteTimes}
                                searchComponent={filters && <ProjectAdvancementsTableFilters
                                    filters={filters}
                                    onFilter={filters => { onChangeFilters(filters) }}
                                    onChange={filters => {
                                        //updateFilters
                                    }}
                                    columns={_rightTableColumns}
                                    responsablesDsi={responsablesDsiList}
                                    onClearFilters={onClearFilters}
                                />
                                }
                                onExpandUsersClick={onExpandUsersClick}
                                filters={filters}
                                onRefresh={() => {
                                    getProjectsAndPhases();
                                }}
                            ></ProjectsTimesTable>}
                            {projects && projects.length > 0 && <div key={'contentTable21_' + reRenderContent}
                                className={styles.divTablesContent}>
                                <div className={styles.divTablesLeft}>
                                    <div className={stylesTable.tables}>
                                        <div className={stylesTable.table}>
                                            <div className={stylesTable.rows}>
                                                <div className={stylesTable.row} style={{ minHeight: '38px' }}>
                                                    <div key={`row-cell-0`} className={`${styles.columnHide} ${stylesTable.rowCell}`} style={{ width: '40%' }}>
                                                        {renderCellEmpty()
                                                        }
                                                    </div>
                                                    <div key={`row-cell-1`} className={stylesTable.rowCell} style={{ width: '80%' }}>
                                                        {renderCellTotals()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </EqualHeight>
                {totalItems > 0 && <div className={styles.paginationContainer}>
                    <PaginationTextInfo className={styles.paginationInfo} itemName={t('projectstimes.items')} items={(projects?.length ?? 0) - 1} totalItems={totalItems} />
                    <Pagination currentPage={currentPage} onChange={onPageChange} totalItems={totalItems} itemsPerPage={itemsPerPage} />
                </div>}
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default ProjectTimes;
