import Http from '../../common/services/Http';
import { IdNameDto } from '../common/models/IdNameDto';
import { Page } from '../common/types/Page';
import { DomainSearchCriteria } from './models/DomainSearchCriteria';
import { DomainViewModel } from './models/DomainViewModel';

class DomainService {
    
    public getList(criteria: DomainSearchCriteria) {
        return Http.get<Page<DomainViewModel>>('domainProjects', criteria);
    }

    public getDomainById(id: string) {
        return Http.get<DomainViewModel>('domainProjects/'+ id);
    }

    public save(model: DomainViewModel) {
        return !!model.id ? Http.put('domainProjects', model) : Http.post('domainProjects', model);
    }

    public remove(model: DomainViewModel) {
        return  Http.put('domainProjects/deactivate', model);
    }

    public getDomainsByGroupId(groupId: string) {
        return Http.get<IdNameDto[]>('domainProjects/get-all-domain-projects-for-select-item/ '+ groupId);
    }
}

export default new DomainService();



