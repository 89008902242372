import React from 'react';
import styles from './Tabs.module.scss';

export interface TabItem {
  id: string;
  title: string;
  content: React.ReactElement;
}

type Props = {
  items: TabItem[];
  activeTabId?: string;
  onChange: (tab: TabItem) => void;
}

const Tabs: React.FunctionComponent<Props> = ({ items, activeTabId, onChange }: Props) => {
  const activeTab = items.find((x, i) => !activeTabId && i == 0 || !!activeTabId && x.id === activeTabId);
  return (
    <div className={styles.fullWidth}>
      <ul className={styles.tabs}>
        {items.map((item, i) => {
          const label = item.title;
          return (
            <li onClick={() => onChange(item)}
              className={(!activeTabId && i == 0 || !!activeTabId && activeTabId == item.id) ? styles.current : ''}
              key={`tab-${item.id}`}
            >
              <a className={styles.label}>{label}</a>
            </li>
          )
        })}
      </ul>
      {Boolean(activeTab) && <div key={`tab-content-${activeTab!.id}`} className={styles.content}>
        {activeTab!.content}
      </div>}
    </div>
  );
};

export default Tabs;