import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Button from '../../../common/components/button/Button';
import Loading from '../../../common/services/Loading';
import ListingTable, { ListingTableCell, ListingTableColumn } from '../../../common/components/listingTable/ListingTable';
import Dropdown from '../../../common/components/dropdown/Dropdown';
import DropdownItem from '../../../common/components/dropdown/DropdownItem';
import PaginationTextInfo from '../../../common/components/pagination/PaginationTextInfo';
import Pagination, { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from '../../../common/components/pagination/Pagination';
import { FaEllipsisH, FaPlus } from 'react-icons/fa';
import styles from './ListProjectResponsiblesDsiScreen.module.scss';
import QuestionYesNo from '../../../common/components/questionYesNo/QuestionYesNo';
import { ProjectResponsibleDsiViewModel } from '../../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiViewModel';
import { ProjectResponsibleDsiSearchCriteria } from '../../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiSearchCriteria';
import ProjectResponsiblesDsiService from '../../../api/projectResponsiblesDsi/ProjectResponsiblesDsiService';
import DateFormat from '../../../common/components/dateFormat/dateFormat';
import { DATE_FORMAT_DEFAUT } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    onClickItem: (item: ProjectResponsibleDsiViewModel, isDetails: boolean) => void;
    createNewProjectResponsibleDsi: () => void;
};

const ListProjectResponsiblesDsiScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite, ...props }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const criteria: ProjectResponsibleDsiSearchCriteria = { groupId };
    const [projectResponsiblesDsi, setProjectResponsiblesDsi] = useState<ProjectResponsibleDsiViewModel[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [itemToRemove, setItemToRemove] = useState<ProjectResponsibleDsiViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const getProjectResponsiblesDsi = async () => {
        try {
            Loading.show();
            criteria.page = currentPage;
            criteria.itemsPerPage = DEFAULT_PAGINATION_ITEMS_PER_PAGE;
            criteria.orderColumn = 'anu.real_name';
            criteria.orderBy = 'asc';
            criteria.allData = true;
            
            if (criteria.groupId) {
                const result = await ProjectResponsiblesDsiService.getList(criteria);
                setProjectResponsiblesDsi(result.items);
                setTotalItems(result.totalItems);
            }
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const renderTableActionCell = (cell: ListingTableCell) => {
        return (
            <Dropdown options={<>
                <DropdownItem onClick={() => props.onClickItem(cell.row as ProjectResponsibleDsiViewModel, true)}>{t('common.details')}</DropdownItem>
                {userCanWrite && <DropdownItem onClick={() => props.onClickItem(cell.row as ProjectResponsibleDsiViewModel, false)}>{t('common.edit')}</DropdownItem>}
                {userCanWrite && <DropdownItem onClick={() => showRemoveItemDialog(cell.row as ProjectResponsibleDsiViewModel)}>{t('common.remove')}</DropdownItem>}
            </>}>
                <div><FaEllipsisH /></div>
            </Dropdown>
        );
    }

    const renderTableCell = (cell: ListingTableCell) => {

        return (
            <div onClick={() => props.onClickItem(cell.row as ProjectResponsibleDsiViewModel, true)}>{cell.row.name}</div>
        );
    }

    const renderTableCreatedDateCell = (cell: ListingTableCell) => {
        const createdDate = (cell.row as ProjectResponsibleDsiViewModel).createdDate;

        return (
            <div onClick={() => props.onClickItem(cell.row as ProjectResponsibleDsiViewModel, true)}>
                {createdDate &&<DateFormat value={createdDate} format={DATE_FORMAT_DEFAUT} />}
                <br/><small>{cell.row.createdUser}</small>
            </div>
        );
    }

    const renderTableUpdatedCell = (cell: ListingTableCell) => {
        const updatedDate = (cell.row as ProjectResponsibleDsiViewModel).updatedDate;

        return (
            <div onClick={() => props.onClickItem(cell.row as ProjectResponsibleDsiViewModel, true)}>
                {updatedDate &&<DateFormat value={updatedDate} format={DATE_FORMAT_DEFAUT} />}
                <br/><small>{cell.row.updatedUser}</small>
            </div>
        );
    }
    
    const tableColumns: ListingTableColumn[] = [
        {
            field: 'name',
            name: t('common.name'),
            width: 'fill',
            renderCell: renderTableCell
        },        
        {
            field: 'createdDate',
            name: t('common.creation'),
            width: 4,
            renderCell: renderTableCreatedDateCell
        },
        {
            field: 'updatedDate',
            name: t('common.update'),
            width: 4,
            renderCell: renderTableUpdatedCell
        },        
        {
            field: 'action',
            width: 2,
            cellAlignment: 'center',
            preventClick: true,
            renderCell: renderTableActionCell
        }
    ];

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    }

    const showRemoveItemDialog = (item: ProjectResponsibleDsiViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await ProjectResponsiblesDsiService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                getProjectResponsiblesDsi();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        if (userCanRead) {
            getProjectResponsiblesDsi();
        }
    }, [currentPage]);

    return (
        <div>
            <div style={{ minHeight: '511px', position: 'relative' }}>
                <ListingTable columns={tableColumns} rows={projectResponsiblesDsi} allowHover={true} onRowClick={row => props.onClickItem(row as ProjectResponsibleDsiViewModel, true)} />
                <QuestionYesNo message={t('common.messages.remove_record')}
                    isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />
            </div>
            <div style={{ textAlign: 'right', width:'100%', paddingRight: '1rem', paddingTop: '1.5rem' }}>
                {userCanWrite && <Button type="button" preset={'secondary'} size="small" onClick={() => props.createNewProjectResponsibleDsi()} onlyIcon={true} >
                    <FaPlus color={'#034E92'} />
                </Button>}
            </div>
            {totalItems > 0 && <div className={styles.paginationContainer} style={{ bottom: 0, width: '100%' }}>
                <PaginationTextInfo className={styles.paginationInfo} itemName={t('parameters.project_responsibles_dsi')} items={projectResponsiblesDsi.length} totalItems={totalItems} />
                <Pagination currentPage={currentPage} onChange={onPageChange} totalItems={totalItems} />
            </div>
            }
        </div>
    );
}

export default ListProjectResponsiblesDsiScreen;
