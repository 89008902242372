import React from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import ProjectAdvancements from './components/ProjectAdvancements/ProjectAdvancements';
import UnderConstruction from '../../common/components/underConstruction/UnderConstruction';

const DashboardScreen: React.FC = () => {

    const { t } = useTranslation();

    return (
        <ScreenTitle title={t('dasboards.title')} >
            <ProjectAdvancements></ProjectAdvancements>
            {/* <UnderConstruction/> */}
        </ScreenTitle >
    );
}

export default DashboardScreen;
