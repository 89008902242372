import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TimesTableFilters.module.scss';
import { FaEraser } from 'react-icons/fa';
import Input, { Props as InputProps } from '../../../common/components/input/Input';
import { TableColumn } from '../../../common/components/table/Table';
import DateTimePickerRange from '../../../common/components/dateTimePickerRange/DateTimePickerRange';
import Button from '../../../common/components/button/Button';
import Select from '../../../common/components/select/Select';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import { useDebouncedCallback } from 'use-debounce/lib';
import { SearchType } from '../../../api/projects/models/ProjectsSearchCriteria';
import { Filters } from '../../dasboard/components/ProjectAdvancements/ProjectAdvancementsTableFilters/ProjectAdvancementsTableFilters';

const DebounceInput = ({ ...props }: InputProps) => {
    const debounced = useDebouncedCallback(e => {
        if (props.onChange) {
            props.onChange(e);
        }
    }, 500);

    return (
        <Input
            {...props}
            ref={undefined}
            onChange={e => debounced(e)}
        />
    );
}

type Props = {
    filters: Filters;
    onChange: (filters: Filters) => void;
    columns: TableColumn[];
    enableRightTable?: boolean;
    cellHeight?: string;
}

const TimesTableFilters = ({ filters, onChange, columns, enableRightTable, cellHeight }: Props) => {
    const { t } = useTranslation();

    const clearFilters = () => {
        onChange({});
    }
    const onChangeValue = (col: TableColumn, value: any) => {
        if (col.searchField) {
            const temp: any = { ...filters };
            temp[col.searchField] = value;
            onChange(temp);
        }
    }

    const onChangeValues = (searchFields: string[], values: any) => {
        if (searchFields.length) {
            const temp: any = { ...filters };
            searchFields.forEach((x, i) => {
                temp[x] = values[i]
            });
            onChange(temp);
        }
    }

    const getCellWidthStyle = (col: TableColumn) => {
        if (!col.width) {
            return {};
        }

        if (col.width === 'fill') {
            return { flex: 1 }
        }

        if (typeof col.width === 'string') {
            return { width: col.width };
        }

        return {
            width: ((col.width * 100) / 20) + '%',
        };
    }

    const getColumnCellStyle = (col: TableColumn) => {
        return {
            textAlign: col.columnCellAlignment || 'left',
            ...getCellWidthStyle(col),
            ...(enableRightTable && cellHeight ? { height: cellHeight } : {}),
        }
    }

    return (
        <div className={styles.formContent}>
            <div className={styles.columns}>
                {columns.map((col, colIndex) => {
                    const value = col.searchField ? (filters as any)[col.searchField] : null;
                    const valueStart = col.searchFields && col.searchFields[0] ? (filters as any)[col.searchFields[0]] : null;
                    const valueEnd = col.searchFields && col.searchFields[1] ? (filters as any)[col.searchFields[1]] : null;
                    const options: SelectValueLabel[] = (col?.extraArgs?.options || []);

                    return (
                        <div
                            key={`col-search-${colIndex}`}
                            className={!col.isActionColumn ? styles.columnCell : styles.columnCellAction}
                            style={getColumnCellStyle(col)}
                        >

                            {col.searchType && col.searchType == SearchType.TEXT && (
                                <DebounceInput
                                    className={styles.paddingInputSearch}
                                    maxLength={255}
                                    defaultValue={value || ''}
                                    onChange={e => onChangeValue(col, e.target.value)}
                                    placeholder={col.name}
                                />
                            )}

                            {col.searchType && col.searchType == SearchType.MULTISELECT &&
                                <Select
                                    isMulti={true as any}
                                    showOnlyFirstSelected={true}
                                    isClearable
                                    placeholder={col.name}
                                    isDisabled={false}
                                    isSelectSearch={true}
                                    options={options}
                                    value={
                                        !value ? null :
                                            value && value.find ? options.filter(x => value.find((y: any) => y === x.value)) :
                                                value && !value.find ? options.filter(x => String(x.value || '').toString() === String(value || '').toString())
                                                    : null
                                    }
                                    onChange={(data: SelectValueLabel[]) => {
                                        onChangeValue(col, data.map(x=> x.value));
                                    }}
                                    filterOption={(candidate: any, input: any) => {
                                        if (input) {
                                            return candidate.label.toUpperCase().includes(input.toUpperCase());
                                        }
                                        return true;
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
                                        valueContainer: (base: any) => ({
                                            textTransform: 'none',
                                            fontWeight: 'normal'
                                        })
                                    }}
                                    height={'42.59px'}
                                />
                            }

                            { col.searchType && col.searchType == SearchType.RANGE_DATE && col.searchFields &&
                                <div className={styles.dateRangeCustom}>
                                    <DateTimePickerRange
                                        dateFormat="MM/yyyy"
                                        locale='fr'
                                        start={valueStart}
                                        end={valueEnd}
                                        showMonthYearPicker
                                        placeholderText={t('common.start_end')}
                                        onChange={() => undefined}
                                        onChangeRange={(start: Date | null, end: Date | null) => {
                                            onChangeValues(col.searchFields ?? [], [
                                                start ?? null,
                                                end ? new Date(end.getFullYear(), end.getMonth() + 1, 0, 23, 59, 59) : end,
                                            ]);
                                        }}
                                    />
                                </div>
                            }

                            {col.isActionColumn &&
                                <Button
                                    onClick={clearFilters}
                                    preset="primary"
                                    onlyIcon={true}
                                    className={styles.toolbarButton}
                                >
                                    <FaEraser className={styles.toolbarButtonIcon} />
                                </Button>
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default memo(TimesTableFilters);