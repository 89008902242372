import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ListLoginLogsFilters.module.scss';
import { Controller, useForm } from 'react-hook-form';
import UsersService from '../../../api/users/UsersService';
import GroupService from '../../../api/groups/GroupService';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import { UserViewModel } from '../../../api/users/models/UserViewModel';
import { DATE_TIME_FILTER_FORMAT, LOGIN_LOG_TYPE } from '../../../Config';
import { GroupViewModel } from '../../../api/groups/models/GroupViewModel';
import FormItem from '../../../common/components/formItem/FormItem';
import { Col, Row } from 'react-flexbox-grid';
import Label from '../../../common/components/label/Label';
import Select from '../../../common/components/select/Select';
import RangeInputPicker from '../../../common/components/rangeInputPicker/RangeInputPicker';
import moment from 'moment';
import Button from '../../../common/components/button/Button';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../store/types';
import { UserProfile } from '../../../api/account/models/UserProfile';

export interface Filters {
    userId?: string;
    startDate?: Date;
    endDate?: Date;
    groupId?: string;
    logType?: string;
}

type Props = {
    filters: Filters;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
}

const ListLoginLogsFilters: React.FC<Props> = ({ filters, onChange, onFilter }: Props) => {
    const { t } = useTranslation();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { getValues, setValue, reset, watch, control } = form;

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [usersOriginalDB, setUsersOriginalDB] = useState<UserViewModel[]>([]);
    const [userOptions, setUserOptions] = useState<SelectValueLabel[]>([]);
    const [groupOptions, setGroupOptions] = useState<SelectValueLabel[]>([]);
    const [logTypeOptions, setLogTypeOptions] = useState<SelectValueLabel[]>([]);


    const getData = async () => {
        const [
            userOptionsDB,
            groupOptionsDB
        ] = await Promise.all([
            UsersService.getAll(),
            GroupService.getAll(),
        ]);

        setUsersOriginalDB(userOptionsDB);
        getUsersByGroup(userOptionsDB)
        setGroupOptions((groupOptionsDB || []).map((x: GroupViewModel) => ({ value: x.id || '', label: x.name || '' })));
        setLogTypeOptions([ { value: LOGIN_LOG_TYPE.SUCCESS, label: t('parameters.login_log_types.' + LOGIN_LOG_TYPE.SUCCESS as any) },
                            { value: LOGIN_LOG_TYPE.WRONG_PASSWORD, label: t('parameters.login_log_types.' + LOGIN_LOG_TYPE.WRONG_PASSWORD as any) },
                            { value: LOGIN_LOG_TYPE.WRONG_USER, label: t('parameters.login_log_types.' + LOGIN_LOG_TYPE.WRONG_USER as any) },
                            { value: LOGIN_LOG_TYPE.LDAP_SUCCESS, label: t('parameters.login_log_types.' + LOGIN_LOG_TYPE.LDAP_SUCCESS as any) },
                            { value: LOGIN_LOG_TYPE.LDAP_ERROR, label: t('parameters.login_log_types.' + LOGIN_LOG_TYPE.LDAP_ERROR as any) } ])
    }

    useEffect(() => {
        getData()
    }, []);

    const clearFilters = () => {
        reset({
            userId: undefined,
            startDate: undefined,
            endDate: undefined,
            groupId: undefined,
            logType: undefined,
        });
        onInputChange();
        onSubmit(getValues());
    }

    const onSubmit = (f: Filters) => {
        onFilter(f);
    }

    const onInputChange = () => {
        onChange(getValues());
    }

    const getUsersByGroup = (uoDB:UserViewModel[]) => {       
        console.log('filters.groupId', filters.groupId) 
        const groupId = loggedUser && !loggedUser.groupId ? getValues('groupId') : filters.groupId;
        const userOptionsFiltered = groupId ? uoDB.filter(x => x.groupId == groupId) : uoDB;
        const usersFiltered =  (userOptionsFiltered || []).map((x: UserViewModel) => ({ value: x.id || '', label: `${x.realName} (${x.userName})`  || '' }));
        setUserOptions(usersFiltered);  
    }

    const renderFormItemGroup = () => {
        return (
            <FormItem>
                <Label className={styles.bold}>{t('common.group')}</Label>
                <Controller
                    render={({ onChange: onSelectChange, value }) => {
                        return (
                            <Select
                                options={groupOptions}
                                isClearable
                                placeholder={t('common.group')}
                                onChange={(data: SelectValueLabel) => {
                                    onSelectChange(data?.value);
                                    getUsersByGroup(usersOriginalDB);
                                    onInputChange();
                                }}
                                value={groupOptions.find(x => x.value === value) ? { label: groupOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                menuPortalTarget={document.body}
                            />
                        );
                    }}
                    control={control}
                    name="groupId"
                    defaultValue={getValues('groupId')} />
            </FormItem>
        )
    }

    const renderFormItemType = () => {
        return (
            <FormItem>
                <Label className={styles.bold}>{t('common.log_type')}</Label>
                <Controller
                    render={({ onChange: onSelectChange, value }) => {
                        return (
                            <Select
                                options={logTypeOptions}
                                isClearable
                                placeholder={t('common.log_type')}
                                onChange={(data: SelectValueLabel) => {
                                    onSelectChange(data?.value);                                                        
                                    onInputChange();
                                }}
                                value={logTypeOptions.find(x => x.value === value) ? { label: logTypeOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                menuPortalTarget={document.body}
                            />
                        );
                    }}
                    control={control}
                    name="logType"
                    defaultValue={getValues('logType')} />
            </FormItem>
        )
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col sm={12} md={6}>
                                {loggedUser && !loggedUser.groupId && renderFormItemGroup()}
                                {loggedUser && loggedUser.groupId && renderFormItemType()}
                            </Col>
                            <Col sm={12} md={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.user')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={userOptions}
                                                    isClearable
                                                    placeholder={t('common.user')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        onInputChange();
                                                    }}
                                                    value={userOptions.find(x => x.value === value) ? { label: userOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                    menuPortalTarget={document.body}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="userId"
                                        defaultValue={getValues('userId')} />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={12}>
                                <RangeInputPicker
                                    label={t('common.date')}
                                    onChange={(startValue: any, endValue: any) => {
                                        setValue('startDate', startValue ? moment(startValue).toDate() : undefined);
                                        setValue('endDate', endValue ? moment(endValue).toDate() : undefined);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getValues('startDate')}
                                    defaultEndValue={getValues('endDate')}
                                    dateFormat={DATE_TIME_FILTER_FORMAT}
                                    isDatePicker
                                    showTimeInput
                                />
                            </Col>
                        </Row>

                        {loggedUser && !loggedUser.groupId && <Row>
                            <Col sm={12} md={6}>
                                {renderFormItemType()}
                            </Col>
                        </Row>}

                    </FormItem>
                </div>

                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(ListLoginLogsFilters);