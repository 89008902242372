import React from 'react';
import { GROUP_PARAMS_TYPE } from '../../../Config';
import PoliciesScreen from '../../policies/PoliciesScreen';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';

type Props = {
    userCanRead:boolean;
    userCanWrite:boolean;
    groupId?: string;
};

const ParametersPoliciesScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    return (
            <div>
                <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.POLICIES}/>
                <PoliciesScreen groupId={groupId} userCanRead={userCanRead} userCanWrite={userCanWrite}></PoliciesScreen>
            </div>

    );
}

export default ParametersPoliciesScreen;
