import React, { ReactChild } from 'react';
import Label from '../label/Label';
import styles from './Panel.module.scss';
import { FaChevronLeft, FaEllipsisH } from 'react-icons/fa';

type Props = {
    title?: string;
    children: ReactChild | ReactChild[];
    isOptions: boolean;
    contentClassName?: string;
    onGoBackClick?: () => void;
};

const Panel: React.FC<Props> = ({ title, isOptions, onGoBackClick, children, contentClassName }: Props) => {
    return (
        <div>
            { title && <div className={styles.header}>
                <FaChevronLeft className={styles.buttonReturn} onClick={() => onGoBackClick && onGoBackClick()} />
                <Label className={styles.labelTitle}>
                    {title}
                </Label>
                { isOptions && <FaEllipsisH className={styles.buttonOptions} /> }
            </div>}
            <div className={`${styles.content} ${contentClassName || ''}`}>
                {children}
            </div>
        </div>
    );
}

export default Panel;
