import Http from '../../common/services/Http';
import { SearchHistoryViewModel } from './models/SearchHistoryViewModel';

class SearchHistoryService {

    public getSearchHistoryForPage(page: string) {
        return Http.get<SearchHistoryViewModel>('searchhistory/get-search-history-for-page/' + page);
    }

    public saveSearchHistory(model: SearchHistoryViewModel) {
        return Http.post('searchhistory/save-search-history', model);
    }

}

export default new SearchHistoryService();