import dayjs from 'dayjs';
import { UseFieldArrayMethods } from 'react-hook-form';
import { DateMode, ProjectPhaseDatePeriodItemViewModel, ProjectPhaseDatePeriodProvisionalScheduleViewModel, ProjectPhaseDatePeriodViewModel, ProjectPhaseDateViewModel, ProjectPhaseViewModel, ProjectViewModel } from '../../../api/projects/models/ProjectViewModel';
import Utils from '../../../common/services/Utils';
import { ProjectForm, ProjectFormConcernedEstablishment, ProjectFormConcernedPartner } from '../models/ProjectForm';

class ProjectScreenFormUtil {

    public transformConcernedEstablishments(model: ProjectViewModel, concernedEstablishments: UseFieldArrayMethods<ProjectFormConcernedEstablishment, 'id'>) {
        return concernedEstablishments.fields.map((ce, i) => {
            const poles = (ce.poles || [])?.map((p, ii) => ({
                ...p,
                checked: !!p.checked,
                ...(model.concernedEstablishments[i].poles ?? [])[ii],
            })).filter(p => p.checked);

            return {
                ...ce,
                checked: !!ce.checked,
                ...model.concernedEstablishments[i],
                poles,
            };
        }).filter(ce => ce.checked || ce.poles.length > 0);
    }

    public transformPhases(model: ProjectViewModel) {

        return model.phases.map(phase => {
            const dates: ProjectPhaseDateViewModel[] = phase.dates.map(date => {
                const dateMonths = (date.periodsItems || []).filter(x => x.checked).map(x => x.date);
                const periods: ProjectPhaseDatePeriodViewModel[] = [];

                for (const dateMonth of dateMonths) {
                    const monthBefore = dayjs(dateMonth).subtract(1, 'month')
                    const lastPeriod = periods.length > 0 ? periods[periods.length - 1] : null;
                    const lastMonth = lastPeriod ? dayjs(lastPeriod.dateEnd) : null;

                    const provisional = date.periodsItems?.find(x => x.checked && x.date == dateMonth)?.provisionalSchedules?.filter(x => x.estimatedTime != null)

                    if ((lastMonth && lastPeriod && lastMonth.isSame(monthBefore, 'month'))) {
                        lastPeriod.dateEnd = dayjs(Date.UTC(dateMonth.getFullYear(), dateMonth.getMonth() + 1, 0, 0, 0, 0)).toDate();
                        lastPeriod.provisionalSchedules = [
                            ...(lastPeriod.provisionalSchedules ?? []),
                            ...(this.mapProvisional(provisional??[]))
                        ]
                    } else {

                        periods.push({
                            dateStart: dayjs(Date.UTC(dateMonth.getFullYear(), dateMonth.getMonth(), 1, 0, 0, 0)).toDate(),
                            dateEnd: dayjs(Date.UTC(dateMonth.getFullYear(), dateMonth.getMonth() + 1, 0, 0, 0, 0)).toDate(),
                            provisionalSchedules: this.mapProvisional(provisional??[])
                        });
                    }
                }

                return {
                    ...date,
                    periodsItems: undefined,
                    periods,
                };
            });

            return {
                ...phase,
                dates,
            };
        });
    }

    public mapProvisional = (provisional: ProjectPhaseDatePeriodProvisionalScheduleViewModel[]) =>{
        return provisional.map(x=> {
            console.log(x)
            return {
                ...x,
                date: dayjs(Date.UTC(x.date.getFullYear(), x.date.getMonth(), 1, 0, 0, 0)).toDate(),
            }
        })
    }

    public isInvestmentTypesValid(finalModel: ProjectForm) {
        return finalModel.investmentTypes.filter(it => {
            const investmentTypeTotal = it.dates.map(x => x.total).sum();
            const hasInvalidEstablishmentsPercentages = it.establishments.map(e => e.percentage).sum() !== 100;
            const hasInvalidEstablishmentsTotals = it.establishments.map(e => e.total).sum() !== investmentTypeTotal;
            const hasInvalidEstablishmentsPoles = it.establishments.filter(e => {
                const hasInvalidPolesPercentages = e.poles.map(e => e.percentage).sum() !== 100;
                return hasInvalidPolesPercentages && e.poles.length > 0 && e.total > 0;
            }).length > 0;

            return (hasInvalidEstablishmentsPercentages || hasInvalidEstablishmentsTotals || hasInvalidEstablishmentsPoles) && investmentTypeTotal > 0;
        }).length > 0;
    }

    public isInternalAllocationTypesValid(finalModel: ProjectForm) {
        return finalModel.internalAllocationTypes.filter(it => {
            const investmentTypeTotal = it.dates.map(x => x.total).sum();
            const hasInvalidEstablishmentsPercentages = it.establishments.map(e => e.percentage).sum() !== 100;
            const hasInvalidEstablishmentsTotals = it.establishments.map(e => e.total).sum() !== investmentTypeTotal;
            const hasInvalidEstablishmentsPoles = it.establishments.filter(e => {
                const hasInvalidPolesPercentages = e.poles.map(e => e.percentage).sum() !== 100;
                return hasInvalidPolesPercentages && e.poles.length > 0 && e.total > 0;
            }).length > 0;

            return (hasInvalidEstablishmentsPercentages || hasInvalidEstablishmentsTotals || hasInvalidEstablishmentsPoles) && investmentTypeTotal > 0;
        }).length > 0;
    }

    public createNewPhase(startDate: Date, endDate: Date, dateMode: DateMode, dateFormat: string, order: number): ProjectPhaseViewModel {
        const dates: ProjectPhaseDateViewModel[] = Utils.getYearsInRange(startDate, endDate).map(date => {
            const periodsItems: ProjectPhaseDatePeriodItemViewModel[] = Utils.getMonthsInYear(date).map(monthDate => ({ date: monthDate, checked: false }));
            return {
                date: date,
                dateFormat,
                dateMode,
                periodsItems,
            };
        });
        return { guid: Utils.newGuid(), name: '', order: order, dates, percentageAchievement: 0 };
    }

}

export default new ProjectScreenFormUtil();
