import React, { memo, useState } from 'react';
import styles from './ProjectEvaluationScheduleLine.module.scss';
import { ArrayField, Controller, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../../../models/ProjectForm';
import { ScrollSyncPane } from 'react-scroll-sync';
import { ProjectPhaseDatePeriodItemViewModel, ProjectPhaseViewModel } from '../../../../../../api/projects/models/ProjectViewModel';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import InputGroupController from '../../../../../../common/components/inputGroup/InputGroupController';
import colors from '../../../../../../styles/export/colors.module.scss'
import Popover from '../../../../../../common/components/popover/Popover';
import Label from '../../../../../../common/components/label/Label';
import Input from '../../../../../../common/components/input/Input';
import Button from '../../../../../../common/components/button/Button';
import DateFormat from '../../../../../../common/components/dateFormat/dateFormat';
import { DATE_FORMAT_MONTH_YEAR_2 } from '../../../../../../Config';
import { SelectValueLabel } from '../../../../../../common/types/SelectValueLabel';
import { Col, Row } from 'react-flexbox-grid';
import { IoTrash } from 'react-icons/io5';
import InputHourGroupController from '../../../../../../common/components/inputHourGroup/InputHourGroupController';

type Props = {
    isDetails: boolean;
    form: UseFormMethods<ProjectForm>;
    numberItemsFullWidth: number;
    phase: Partial<ArrayField<ProjectPhaseViewModel, 'id'>>;
    phaseIndex: number;
    projectResponsiblesDsiOptions: SelectValueLabel[];
    userCanReadTimes?: boolean;
    userCanWriteTimes?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectEvaluationScheduleLine: React.FC<Props> = ({
    isDetails, form, numberItemsFullWidth, phase, phaseIndex, projectResponsiblesDsiOptions, userCanReadTimes, userCanWriteTimes, ...props
}: Props) => {

    const { t } = useTranslation();
    const phasesName = `phases[${phaseIndex}]`;
    const dates = phase.dates || [];

    let c = 0;
    const totalAllMonthsChecked = phase && phase.dates && phase.dates.length > 0 ? phase.dates.map(x => x.periodsItems?.filter(z => z.checked).length).sum() || 0 : 0;
    const percByElement = !totalAllMonthsChecked ? 0 : (100).divide(totalAllMonthsChecked || 1);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onMonthDeselectAllClick = (dateIndex: number) => {
        const monthsName = `${phasesName}.dates[${dateIndex}].periodsItems`;
        const monthsWatch = form.watch<string, ProjectPhaseDatePeriodItemViewModel[]>(monthsName);
        monthsWatch.forEach((_, i) => {
            form.setValue(`${monthsName}[${i}].checked`, false);
        });
    }

    const onPercentageAchievementChange = (polePercentageName: string, newValue: string | number) => {
        let value = Number(newValue || 0);
        value = Number(value.toFixed(2));
        return value > 100 ? 100 : value < 0 ? 0 : value;
    }

    return (
        <div {...props} className={styles.container} key={'year' + dates.length}>
            <div className={styles.leftNameContainer} >
                <div className={styles.leftName} data-tip={phase.name} data-for='global'>
                    {t('project.project_organization.phase_number', { value: phaseIndex + 1 })}
                    <ReactTooltip id='global' type='info' getContent={(dataTip: string) => <span>{dataTip}</span>}></ReactTooltip>
                </div>
                <div className={styles.percentageAchievementBox}>
                    <InputGroupController
                        noBorder={true}
                        type="number"
                        name={phasesName + '.percentageAchievement'}
                        form={form}
                        disabled={isDetails}
                        format="percentage"
                        text={t('common.percentage')}
                        max={100}
                        min={0}
                        onValueChange={e => onPercentageAchievementChange(phasesName + '.percentageAchievement', e)}
                    />
                </div>
            </div>
            <ScrollSyncPane>
                <div className={`${dates.length > numberItemsFullWidth ? styles.areaValue : styles.areaValueFixed} ${styles.scrollPane}`}>
                    {dates.map((date, dateIndex) => {
                        const dateName = `${phasesName}.dates[${dateIndex}]`;
                        const dateWatch = phase && phase.dates && phase.dates.length > 0 ? phase.dates[dateIndex] : null;
                        const totalMonthsChecked = (dateWatch?.periodsItems || []).filter(x => x.checked).length;
                        const dateItems = date.periodsItems || [];
                        const percentageAchievement = (phase.percentageAchievement || 0);

                        return (
                            <div
                                key={dateIndex}
                                className={dates.length > numberItemsFullWidth ? styles.boxValues : styles.boxValuesFixed}
                                style={dates.length > numberItemsFullWidth ? {} : { width: (100 / (dates.length)) + '%' }}
                            >
                                <div className={styles.monthsContainer}>
                                    {dateItems.map((month, monthIndex) => {
                                        const monthCheckedName = `${dateName}.periodsItems[${monthIndex}].checked`;
                                        let width = 0;
                                        if (month.checked) {
                                            if (percentageAchievement >= 100) {
                                                width = 100;
                                            }
                                            else if (percentageAchievement <= 0) {
                                                width = 0;
                                            }
                                            else {
                                                c = c.plus(1);
                                                const aux = Number(c.multiply(percByElement).toFixed(2));
                                                width = 100;
                                                if (aux > percentageAchievement) {
                                                    const back = (c - 1) * percByElement;
                                                    if (back <= percentageAchievement) {
                                                        const remaining = percentageAchievement.minus(back);
                                                        width = Number(remaining.multiply(100).divide(percByElement).toFixed(2));
                                                    } else {
                                                        width = 0;
                                                    }
                                                }
                                            }
                                        }
                                        return (
                                            <>
                                                {month.checked &&
                                                    <Controller
                                                        control={form.control}
                                                        name={monthCheckedName}
                                                        render={({ onChange, value }) => {
                                                            return (
                                                                <div className={`${styles.monthItem} ${value ? (isDetails ? styles.checkedDisabled : styles.checked) : ''}`}
                                                                    >
                                                                    <Popover
                                                                        positions={['top', 'left']}
                                                                        contentContainerClassName={styles.popoverWidth}
                                                                        containerClassName={styles.fullWidth}
                                                                        onClickOutside={() => {
                                                                            setIsOpen(false);
                                                                        }}
                                                                        content={setIsPopoverOpen =>
                                                                            <div className={styles.formContent}>
                                                                                <Row>
                                                                                    <Col xs={6}>
                                                                                        <Label className={styles.bold}>{t('project.project_organization.provisional_schedule')} </Label>
                                                                                    </Col>
                                                                                    <Col xs={6}>
                                                                                        <Label className={styles.datePopover}>
                                                                                            <DateFormat value={month.date} format={DATE_FORMAT_MONTH_YEAR_2} />
                                                                                        </Label>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row style={{ marginBottom: '10px' }}>
                                                                                    <Col xs={8}>
                                                                                        <Label>{t('project.project_organization.project_manager_responsibles_dsi')}</Label>
                                                                                    </Col>
                                                                                    <Col xs={4}>
                                                                                        <Label>{t('project.project_organization.hours')}</Label>
                                                                                    </Col>
                                                                                </Row>
                                                                                {(month.provisionalSchedules ?? []).map((item, i) => {
                                                                                    const projectResponsibleDsiId = `${dateName}.periodsItems[${monthIndex}].provisionalSchedules[${i}].projectResponsibleDsiId`;
                                                                                    const time = `${dateName}.periodsItems[${monthIndex}].provisionalSchedules[${i}].estimatedTime`;
                                                                                    return (
                                                                                        <Row key={`provisionalSchedules_${i}`} style={{ marginBottom: '10px' }}>
                                                                                            <Col xs={8} key={i}>
                                                                                                <Input
                                                                                                    type={'text'}
                                                                                                    name={projectResponsibleDsiId}
                                                                                                    disabled={true}
                                                                                                    value={item.projectResponsibleDsiName}
                                                                                                    style={{ backgroundColor: !isDetails ? 'white' : undefined }}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col xs={4}>
                                                                                                <InputHourGroupController
                                                                                                    style={{ backgroundColor: !isDetails ? 'white' : undefined }}
                                                                                                    name={time}
                                                                                                    placeholder={t('project.project_organization.hours')}
                                                                                                    type={'number'}
                                                                                                    form={form}
                                                                                                    disabled={isDetails}
                                                                                                    hideMessageLength={true}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    )
                                                                                })}
                                                                                {month.provisionalSchedules?.length == 0 &&
                                                                                    <Label className={styles.msgWithoutDSIResp}>{t('project.project_organization.without_dsi_responsible_selected')} </Label>
                                                                                }
                                                                                {(!isDetails && userCanWriteTimes) && <div className={styles.buttonsFooter}>
                                                                                    <Button onClick={() => {
                                                                                        setIsOpen(false);
                                                                                        setIsPopoverOpen(false);
                                                                                    }}
                                                                                        text={t('common.save')} size={'small'} />
                                                                                </div>}
                                                                            </div>}>
                                                                        {(isPopoverOpen, setIsPopoverOpen) => (
                                                                            <div
                                                                                className={styles.monthItemChecked}
                                                                                onClick={() => {
                                                                                    if (!isDetails && !isOpen) {
                                                                                        onChange(!value)
                                                                                    }
                                                                                }}
                                                                                onContextMenu={e => {
                                                                                    if (userCanReadTimes) {
                                                                                        e.preventDefault();
                                                                                        e.stopPropagation();
                                                                                        setIsPopoverOpen(true)
                                                                                        setIsOpen(true);
                                                                                    }
                                                                                }}>
                                                                                {t(('common.months.' + (month.date.getMonth() + 1)) as any).charAt(0)}
                                                                                <span style={{ width: !value ? '0%' : width + '%', borderBottomColor: !value || width <= 0 ? colors.gray : colors.magenta, borderRightColor: !value || width <= 0 ? colors.gray : colors.magenta }} className={`${styles.monthBorderBottom} ${monthIndex > 0 && styles.monthBorderBottomRight}`}></span>
                                                                            </div>
                                                                        )}
                                                                    </Popover>
                                                                </div>
                                                            );
                                                        }}
                                                        defaultValue={form.getValues(monthCheckedName)}
                                                        key={monthIndex} />}
                                                {!month.checked &&
                                                    <Controller
                                                        control={form.control}
                                                        name={monthCheckedName}
                                                        render={({ onChange, value }) => {
                                                            return (
                                                                <div
                                                                    className={`${styles.monthItem} ${value ? (isDetails ? styles.checkedDisabled : styles.checked) : ''}`}
                                                                    onClick={() => !isDetails && onChange(!value)}
                                                                >
                                                                    {t(('common.months.' + (month.date.getMonth() + 1)) as any).charAt(0)}
                                                                    <span style={{ width: !value ? '0%' : width + '%', borderBottomColor: !value || width <= 0 ? colors.gray : colors.magenta, borderRightColor: !value || width <= 0 ? colors.gray : colors.magenta }} className={`${styles.monthBorderBottom} ${monthIndex > 0 && styles.monthBorderBottomRight}`}></span>
                                                                </div>
                                                            );
                                                        }}
                                                        defaultValue={form.getValues(monthCheckedName)}
                                                        key={monthIndex}
                                                    />
                                                }
                                            </>
                                        );
                                    })}
                                    {totalMonthsChecked > 0 && !isDetails && <div className={styles.deselectAllButton} onClick={() => onMonthDeselectAllClick(dateIndex)}>
                                        <IoTrash className={styles.icon} />
                                    </div>}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </ScrollSyncPane>
        </div >
    );
}

export default memo(ProjectEvaluationScheduleLine);
