import React from 'react';
import styles from './Text.module.scss';

type TextPreset = 'default' | 'screenHeaderTitle';

type Props = {
    text?: string;
    preset?: TextPreset;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>;

const Text: React.FC<Props> = ({ children, preset = 'default', ...props }: Props) => {

    const presetClassName = preset ? styles[preset] : styles.default;

    return (
        <p
            {...props}
            className={`${presetClassName} ${props.className || ''}`}
        >
            {children}
        </p>
    );
}

export default Text;
