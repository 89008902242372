import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Component } from 'spinners-react';
import { Reducers } from '../../store/types';

type Props = {
    policies: string[];
} & RouteProps;

const PrivateRoute: React.FC<Props> = ({ policies, ...props }: Props) => {

    const userPolicies = useSelector<Reducers, string[]>(state => state.authentication.profile?.policies ?? []);
    const isAutorized = useSelector<Reducers, boolean>(() => userPolicies.length > 0 &&
        //!policies.find(p => userPolicies.find(up=> up == p)) //o user tem todas as politicas
        !!policies.find(p => !!userPolicies.find(up => up == p)) //o user tem pelo menos 1 das politicas
    );

    return (
        isAutorized ? <Route  {...props} render={() => {
            return <Component />
        }} /> :
        <Route render={() => {
            return  <Redirect to="/not-authorized" />
        }} />
    );
}

export default PrivateRoute;
