import React, { memo } from 'react';
import styles from './ProjectAdvancementTablePhaseLine.module.scss';
import { ScrollSyncPane } from 'react-scroll-sync';
import { useTranslation } from 'react-i18next';
import { ProjectAdvancementPhase } from '../ProjectAdvancements';
import colors from '../../../../../styles/export/colors.module.scss'

type Props = {
    phase: ProjectAdvancementPhase;
    phaseIndex: number;
    widthItems: string
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectAdvancementTablePhaseLine: React.FC<Props> = ({
    widthItems, phase, phaseIndex, ...props
}: Props) => {

    const dates = phase.dates || [];
    const periods = dates.map(x => x.periodsItems??[]).flat();

    let c = 0;
    const totalAllMonthsChecked = periods.filter(z => z.checked).length;
    const percByElement = !totalAllMonthsChecked ? 0 : (100).divide(phase.countMonths || 1);

    const phasePercentge = phase.percentage - ( !!periods.find(x => x.filteredStart) ? (percByElement * phase.countMonthsFilteredStart) : 0 );

    return (
        <div {...props} className={styles.container}>
            <ScrollSyncPane>
                <div className={`${styles.areaValue} ${styles.scrollPane}`}>
                    {dates.map((date, dateIndex) => {

                        const dateItems = date.periodsItems || [];
                        return (
                            <div
                                key={dateIndex}
                                className={styles.boxValues}
                                style={{ width: widthItems }}
                            >
                                <div className={styles.monthsContainer}>
                                    {dateItems.map((month, monthIndex) => {

                                        let width = 0;
                                        if (month.checked) {
                                            if (phasePercentge >= 100) {
                                                width = 100;
                                            }
                                            else if (phasePercentge <= 0) {
                                                width = 0;
                                            }
                                            else {
                                                c = c.plus(1);
                                                const aux = Number(c.multiply(percByElement).toFixed(2));
                                                width = 100;
                                                if (aux > phasePercentge) {
                                                    const back = (c - 1) * percByElement;
                                                    if (back <= phasePercentge) {
                                                        const remaining = phasePercentge.minus(back);
                                                        width = Number(remaining.multiply(100).divide(percByElement).toFixed(2));
                                                    } else {
                                                        width = 0;
                                                    }
                                                }
                                            }
                                        }
                                        return (
                                            <div
                                                key={monthIndex}
                                                className={`${styles.monthItem}
                                                            ${month.checked ? styles.checked : ''}
                                                            ${month.filteredStart && styles.alignContinueLeft}
                                                            ${month.filteredEnd && styles.alignContinueRight}
                                                            ${!month.checked && dateIndex == 0 && phase.filteredStart && styles.alignContinueLeft}
                                                            ${!month.checked && dateIndex == (dates.length - 1) && phase.filteredEnd && styles.alignContinueRight}
                                                `}
                                            >
                                                <span className={styles.continue}>
                                                    &nbsp;{(
                                                        month.filteredStart || month.filteredEnd ||
                                                        (!month.checked && dateIndex == 0 && phase.filteredStart) ||
                                                        (!month.checked && dateIndex == (dates.length - 1) && phase.filteredEnd)
                                                    )&& '...'}
                                                </span>
                                                <span style={{ width: !month.checked ? '0%' : width + '%', borderBottomColor: !month.checked || width <= 0 ? colors.gray : colors.magenta, borderRightColor: !month.checked || width <= 0 ? colors.gray : colors.magenta }} className={`${styles.monthBorderBottom} ${monthIndex > 0 && styles.monthBorderBottomRight}`}></span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </ScrollSyncPane>
        </div>
    );
}

export default memo(ProjectAdvancementTablePhaseLine);
