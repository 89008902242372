import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { LdapSearchCriteria } from './models/LdapSearchCriteria';
import { LdapViewModel } from './models/LdapViewModel';

class LdapService {
    
    public get(id?: string) {
        return Http.get<LdapViewModel>('ldaps/' + id);
    }
    
    public getList(criteria: LdapSearchCriteria) {
        return Http.get<Page<LdapViewModel>>('ldaps', criteria);
    }

    public save(model: LdapViewModel) {
        return !!model.id ? Http.put('ldaps', model) : Http.post('ldaps', model);
    }

    public remove(model: LdapViewModel) {
        return  Http.put('ldaps/deactivate', model);
    }

}

export default new LdapService();