import { Action, AuthenticationState, SET_AUTHENTICATION } from './type';

const initialState: AuthenticationState = {
	isAuthenticated: false,
	profile: null,
};

function rootReducer(state: AuthenticationState = initialState, action: Action): AuthenticationState {
	switch (action.type) {
		case SET_AUTHENTICATION:
			return {
				...state,
				isAuthenticated: action.profile ? true : false,
				profile: action.profile || null,
			};
		default:
			return state;
	}
}

export default rootReducer;
