import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Button from '../button/Button';
import Label from '../label/Label';
import styles from './QuestionYesNo.module.scss';

type Props = {
    message: string;
    isVisible: boolean;
    onYes: () => void;
    onNo: () => void;
};

const QuestionYesNo: React.FC<Props> = ({ message, isVisible, onYes, onNo }: Props) => {

    const { t } = useTranslation();

    const customStyles = {
        content: {
            top: '15%',
            left: '35%',
            right: '35%',
            bottom: 'auto',
            backgroundClip: 'padding-box',
            backgroundColor: '#FFFFFF',
            border: '1px solid transparent',
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
            outline: '0 none',

        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
    };

    return (
        <Modal
            isOpen={isVisible}
            style={customStyles}
        >
            <div>
                <div className={styles.titleContainer}>
                    <Label className={styles.textTitle}>{t('common.question')}</Label>
                </div>
                <div className={styles.messageContainer}>
                    <label className={styles.textMessage}>{message}</label>
                </div>
                <div className={styles.buttons_container}>
                    <Button text={t('common.no')}
                        preset={'secondary'}
                        onClick={onNo}
                        size={'small'}
                    />
                    <Button text={t('common.yes')}
                        size={'small'}
                        onClick={onYes}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default QuestionYesNo;
