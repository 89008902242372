import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { IoTrash } from 'react-icons/io5';
import ReactTable, { CellInfo, RowInfo, SortingRule } from 'react-table';
import withFixedColumns, { ColumnFixed } from 'react-table-hoc-fixed-columns';
import { useToasts } from 'react-toast-notifications';
import { ProjectTimesHistoryDto } from '../../../../api/projectsTimes/models/ProjectTimesHistoryDto';
import { ProjectTimesHistorySearchCriteria } from '../../../../api/projectsTimes/models/ProjectTimesHistorySearchCriteria';
import ProjectTimesService from '../../../../api/projectsTimes/ProjectTimesService';
import Button from '../../../../common/components/button/Button';
import DateFormat from '../../../../common/components/dateFormat/dateFormat';
import DateTimePickerRange from '../../../../common/components/dateTimePickerRange/DateTimePickerRange';
import Label from '../../../../common/components/label/Label';
import MoneyFormat from '../../../../common/components/moneyFormat/MoneyFormat';
import Pagination from '../../../../common/components/pagination/Pagination';
import PaginationTextInfo from '../../../../common/components/pagination/PaginationTextInfo';
import QuestionYesNo from '../../../../common/components/questionYesNo/QuestionYesNo';
import { TableCell, TableColumn, TableRow } from '../../../../common/components/table/Table';
import Loading from '../../../../common/services/Loading';
import { DATE_FORMAT_DEFAUT, DATE_TIME_FORMAT_DEFAUT } from '../../../../Config';
import styles from './ProjectTimePopoverHistory.module.scss';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

type Props = {
    editItem: (item: ProjectTimesHistoryDto) => void,
    projectResponsibleDsiId: string,
    projectResponsibleDsiName: string,
    projectPhaseGuid: string,
    startDate?: Date | null;
    endDate?: Date | null;
    setStartDate: (d: Date | null) => void;
    setEndDate: (d: Date | null) => void;
    currentPage: number,
    setCurrentPage: (page: number) => void,
    setRefresh: () => void;
    orderBy: string,
    orderColumn: string,
    setOrderBy: (orderBy: string) => void;
    setOrderColumn: (orderColumn: string) => void;
    userCanWriteTimes?: boolean;
};

const ProjectTimePopoverHistory: React.FC<Props> = ({ editItem, projectResponsibleDsiId, projectResponsibleDsiName, projectPhaseGuid, startDate, setStartDate, endDate, setEndDate, currentPage, setCurrentPage, setRefresh, userCanWriteTimes,
    orderBy,
    orderColumn,
    setOrderBy,
    setOrderColumn
}: Props) => {
    const { addToast } = useToasts();
    const { t } = useTranslation();

    const [histories, setHistories] = useState<(ProjectTimesHistoryDto | { isTotalLine: boolean, hours: number })[]>([]);
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = useState<ProjectTimesHistoryDto | undefined>();
    const criteria: ProjectTimesHistorySearchCriteria = {
        page: currentPage,
        itemsPerPage: 5,
        orderBy: 'desc',
        orderColumn: 'pt.date @ORDER, pt.id',
        projectPhaseGuid: projectPhaseGuid??'00000000-0000-0000-0000-000000000000',
        projectResponsibleDsiId,
        startDate: startDate ?? undefined,
        endDate: endDate ?? undefined,
    };

    const [totalItems, setTotalItems] = useState(0);
    const [totalHours, setTotalHours] = useState(0);
    const [reRender, setReRender] = useState(0);


    const renderEmptyCell = () => {
        return (<span></span>)
    }

    const renderTableActionCell = (cell: TableCell) => {
        if (userCanWriteTimes) {
            return (
                <Button type="button" preset={'secondary'} size="small" onClick={() => {
                    setSelectedItem((cell.row as ProjectTimesHistoryDto));
                    setDialogDeleteItemIsOpen(true);
                }} onlyIcon={true} >
                    <IoTrash className={styles.icon} />
                </Button>
            );
        } else {
            return (<div />);
        }

    }

    const renderLastChangeCell = (cell: TableCell) => {
        return (
            <Label>{(cell.row as ProjectTimesHistoryDto).lastUpdateUser} <br />
                <DateFormat value={(cell.row as ProjectTimesHistoryDto).lastUpdate} format={DATE_TIME_FORMAT_DEFAUT} /></Label>
        );
    }

    const renderDateCell = (cell: TableCell) => {
        return (
            <Label><DateFormat value={(cell.row as ProjectTimesHistoryDto).date} format={DATE_FORMAT_DEFAUT} /></Label>
        );
    }

    const renderHoursCell = (cell: TableCell) => {
        return (
            <Label><MoneyFormat value={cell.row.hours} suffix={'h'} /></Label>
        );
    }

    const tableColumns: TableColumn[] = [
        {
            field: 'date',
            name: t('projectstimes.popover.day'),
            width: 100,
            renderCell: renderDateCell,
            order: 'pt.date @ORDER, pt.id'
        },
        {
            field: 'hours',
            name: t('projectstimes.popover.hours'),
            width: 80,
            renderCell: renderHoursCell,
            order: 'pt.hours'
        },
        {
            field: 'lastUpdate',
            name: t('projectstimes.popover.last_change'),
            renderCell: renderLastChangeCell,
            order: 'coalesce(pt.updated_date, pt.created_date)'
        },
        {
            name: '',
            field: 'action',
            width: 40,
            cellAlignment: 'center',
            preventClick: true,
            isActionColumn: true,
            renderCell: renderTableActionCell
        }
    ];

    const getTimes = async () => {
        try {

            Loading.show();

            criteria.page = currentPage;
            criteria.orderBy = orderBy;
            const aux = orderColumn?.includes('@ORDER') ? orderColumn.replace('@ORDER', orderBy ?? '') : `${orderColumn} ${orderBy}`;
            criteria.orderColumn = aux;

            const [result, total] =
                await Promise.all([
                    ProjectTimesService.getProjectTimesHistory(criteria),
                    ProjectTimesService.getProjectTimesHistoryTotalHours(criteria)
                ]);

            setTotalHours(total);
            setHistories(result.items);
            setTotalItems(result.totalItems);
            setReRender(reRender + 1);

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const removeItem = async () => {
        if (!!selectedItem) {
            try {
                Loading.show();
                await ProjectTimesService.remove({ id: selectedItem.id });
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                setDialogDeleteItemIsOpen(false);
                getTimes();
                Loading.hide();
                setRefresh();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    }

    const rendeHeader = (col: TableColumn) => (
        <div style={!!col.order ? { cursor: 'pointer' } : {}}>
            <span className={styles.headerName}>
                {col.name}&nbsp;</span>
            {!col.isActionColumn && orderColumn == col.order && <span>
                {(!orderBy || orderBy === 'asc') && <FaSortAlphaDown className={styles.iconOrder}></FaSortAlphaDown>}
                {orderBy === 'desc' && <FaSortAlphaUp className={styles.iconOrder}></FaSortAlphaUp>}
            </span>
            }
        </div>
    );

    const renderFooter = (col: TableColumn) => (
        col.field == 'date' ? <Label className={styles.totalLabel}>{t('common.total')}</Label> :
            col.field == 'hours' ? <Label className={styles.bold}><MoneyFormat value={totalHours} suffix={'h'} /></Label> :
                renderEmptyCell()
    );

    const customizeCell = (cellInfo: CellInfo, column: any, tblColumn: TableColumn) => {
        return getRowCellValue(cellInfo.original, tblColumn)
    }

    const getRowCellValue = (row: TableRow, col: TableColumn) => {
        if (col.renderCell) {
            return col.renderCell({ row, column: col });
        }
        return <></>
    }

    const mapColumns: ColumnFixed[] =
        tableColumns.map(x =>
        (
            {
                Header: () => rendeHeader(x),
                headerClassName: styles.header,
                accessor: x.field,
                fixed: x.isActionColumn ? 'right' : 'left',
                width: x.width ? Number(x.width) : undefined,
                resizable: false,
                manualFilters: false,
                Cell: (cellInfo, column) => customizeCell(cellInfo, column, x),
                sortable: !!x.order,
                Footer: () => renderFooter(x)
            } as ColumnFixed)
        );

    const NoDataComponent = () => {
        return (
            <div className={styles.noData}>{t('common.without_elements')}</div>
        )
    }

    const onOrderList = (column: TableColumn) => {
        const _orderBy = orderColumn !== column.order ? 'asc' : orderBy == 'desc' ? 'asc' : 'desc';
        setOrderColumn(column.order ?? '');
        setOrderBy(_orderBy);
    }

    useEffect(() => {
        getTimes();
    }, [startDate, endDate, currentPage, orderBy, orderColumn]);

    return (
        <div className={styles.content} >
            <div className={styles.line}>
                <div className={styles.item50}>
                    <Label className={styles.title}>{t('projectstimes.popover.history')}</Label>
                    <Label>{projectResponsibleDsiName}</Label>
                </div>
                <div className={styles.item50} key={'datepicker' + reRender}>
                    <DateTimePickerRange
                        dateFormat="MM/yyyy"
                        locale='fr'
                        start={startDate ?? undefined}
                        end={endDate ?? undefined}
                        showMonthYearPicker
                        placeholderText={t('common.start_end')}
                        onChange={(dates: [Date, Date]) => undefined}
                        onChangeRange={(start: Date | null, end: Date | null) => {
                            setStartDate(start || null);
                            let dateLastDateOfMonth = end;
                            if (end) {
                                dateLastDateOfMonth = new Date(end.getFullYear(), end.getMonth() + 1, 0, 23, 59, 59);
                            }
                            setEndDate(dateLastDateOfMonth);
                        }}
                    />
                </div>
            </div>
            <div className={styles.tableLine} key={'perdilheorespeito' + reRender}>
                {histories.length > 0 && <ReactTableFixedColumns
                    filterable={false}
                    showPaginationBottom={false}
                    data={histories}
                    columns={mapColumns}
                    defaultPageSize={histories.length}
                    multiSort={false}
                    NoDataComponent={NoDataComponent}
                    className={styles.table}
                    resizable={false}
                    getTdProps={(finalState: any, rowInfo?: RowInfo, column?: any, instance?: any) => ({
                        style: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        },
                        onClick: (e: any, t: any) => {
                            if (tableColumns.find(x => x.field == column?.id && !x.preventClick && !x.isActionColumn) && userCanWriteTimes) {
                                editItem(rowInfo?.original as ProjectTimesHistoryDto)
                            }
                        }
                    })}
                    onSortedChange={(newSorted: SortingRule[], column: any, additive: boolean) => {
                        const columnInList: TableColumn = tableColumns.filter(x => x.field == column.id)[0];
                        onOrderList && onOrderList(columnInList);
                    }}
                    defaultSortMethod={(a: any, b: any, desc: any) => 0}
                />}
                {histories.length <= 0 && NoDataComponent()}
                {totalItems > 0 && <div className={styles.paginationContainer}>
                    <PaginationTextInfo className={styles.paginationInfo} itemName={t('projectstimes.items')} items={histories.length} totalItems={totalItems} />
                    <Pagination currentPage={currentPage} onChange={onPageChange} totalItems={totalItems} itemsPerPage={criteria.itemsPerPage} />
                </div>}
            </div>
            <QuestionYesNo message={t('common.messages.remove_record')}
                isVisible={dialogDeleteItemIsOpen}
                onYes={() => removeItem()}
                onNo={() => setDialogDeleteItemIsOpen(false)} />
        </div >
    );
}

export default ProjectTimePopoverHistory;
