import React from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../common/components/label/Label';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import styles from './NotAuthorizedScreen.module.scss';

const NotAuthorizedScreen: React.FC = () => {

    const { t } = useTranslation();

    return (
        <ScreenTitle title={t('common.errors.page_not_authorized')}>
            <ScreenContainer className={styles.screenContainer}>
                <div className={styles.divTitle}>
                    <h1 className={styles.title}>{t('common.errors.page_not_authorized')}</h1>
                    <Label>{t('common.messages.page_not_authorized')}</Label>
                </div>
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default NotAuthorizedScreen;
