import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../common/components/button/Button';
import styles from './PoliciesScreen.module.scss';
import { FaChevronLeft, FaChevronDown } from 'react-icons/fa';
import CheckBox from '../../common/components/checkBox/CheckBox';
import Loading from '../../common/services/Loading';
import { useToasts } from 'react-toast-notifications';
import PoliciesService from '../../api/policies/PoliciesService';
import { PermissionItem, PolicyItem, PolicyRoleItem, PolicyViewModel } from '../../api/policies/models/PolicyViewModel';
import { useForm } from 'react-hook-form';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
};

enum ScreenType {
    EDIT,
    DETAILS
}

const PoliciesScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { handleSubmit, setValue } = useForm<PolicyViewModel>();

    const [screenMode, setScreenMode] = useState<ScreenType>(ScreenType.DETAILS);

    const [roleItemIndex, setRoleItemIndex] = useState([]);
    const [roleItems, setRoleItems] = useState<PolicyRoleItem[]>([]);

    const [aux, setAux] = useState<boolean>(true);

    const getRoles = async () => {
        try {
            Loading.show();
            setRoleItems([]);
            const result = await PoliciesService.getPoliciesByGroup(groupId);

            if (result) {
                setRoleItems(result);
            }
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const onSubmit = async (model: PolicyViewModel) => {
        try {

            const finalModel = model;
            finalModel.groupId = groupId;
            finalModel.policiesRoles = roleItems;

            Loading.show();
            await PoliciesService.update(finalModel);
            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            setScreenMode(ScreenType.DETAILS);

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const toggleOpen = (r: number, isOpen: boolean) => {
        setRoleItemIndex((e: any) => ({ ...e, [r]: !isOpen }));
    }

    const onPermissionSelect = (role: number, policy: number, permission: number, p: PermissionItem) => {
        setAux(false);
        setValue(`policiesRoles[${role}].policies[${policy}].permissions[${permission}].checked`, p.checked = !p.checked);
        if (p.name == 'WRITE' && p.checked) {
            roleItems[role].policies[policy].permissions.filter(x=> x.name != 'WRITE').forEach((element,i) => {
                element.checked = true;
                setValue(`policiesRoles[${role}].policies[${policy}].permissions[${i}].checked`, true);    
            } );
            setRoleItems(roleItems);
        }
        setTimeout(()=>{
            setAux(true);
        })
    }

    useEffect(() => {
        if (userCanRead) {
            getRoles();
        }
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
            <div className={styles.formLine}>
                {roleItems && roleItems.map((role: PolicyRoleItem, r: number) => {
                    const isOpen = roleItemIndex[r] == undefined ? true : roleItemIndex[r];
                    const isActive = isOpen ? true : false;
                    return (
                        <div key={r} className={styles.content}>
                            <div className={`${styles.buttonParent} ${isActive ? styles.active : ''}`}
                                onClick={() => toggleOpen(r, isOpen)}>
                                <div className={styles.buttonText}>
                                    {role.name}
                                </div>
                                <div className={styles.buttonIcon}>
                                    {!isOpen && <FaChevronLeft className={styles.iconSize} />}
                                    {isOpen && <FaChevronDown className={styles.iconSize} />}
                                </div>
                            </div>
                            { isOpen && <div className={styles.contentRole}>
                                {role.policies && role.policies.sort((a, b) => t(('parameters.group.policies.' + a.name as any)).localeCompare(t(('parameters.group.policies.' + b.name as any))))
                                    .map((policy: PolicyItem, p: number) => {
                                        const policyName = t(('parameters.group.policies.' + policy.name as any));
                                        return (
                                            <div key={p} className={styles.linePolicy}>
                                                <div className={styles.policyTitle}>
                                                    {policyName}
                                                </div>
                                                {aux && policy.permissions && policy.permissions.map((permission: PermissionItem, perm: number) => {
                                                    const disabled = permission.name != 'WRITE' && !!policy.permissions.find(x=> x.name == 'WRITE' && x.checked);
                                                    const permissionName = t(('parameters.group.policies.' + permission.name) as any);
                                                    return (
                                                        <div key={perm} className={styles.policyOption}>
                                                            <CheckBox
                                                                name={`policiesRoles[${r}].policies[${p}].permissions[${perm}].checked`}
                                                                onChange={() => onPermissionSelect(r, p, perm, permission)}
                                                                label={permissionName}
                                                                disabled={disabled || screenMode === ScreenType.DETAILS}
                                                                defaultChecked={permission.checked}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                            </div>}
                        </div>
                    )
                })}
            </div>
            {screenMode !== ScreenType.DETAILS && <div className={styles.buttonsFooter}>
                <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={() => { setScreenMode(ScreenType.DETAILS); getRoles(); }} />
                {userCanWrite && <Button type="submit" text={t('common.save')} size={'small'} />}
            </div>}
            {screenMode === ScreenType.DETAILS && <div className={styles.buttonsFooter}>
                {userCanWrite && <Button type="button" text={t('common.edit')} onClick={() => setScreenMode(ScreenType.EDIT)} size={'small'} />}
            </div>}
        </form>
    );
}

export default PoliciesScreen;
