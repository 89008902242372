import React, { useState } from 'react';

import ListSuccessIndicatorsScreen from '../../successIndicators/list/ListSuccessIndicatorsScreen';
import SuccessIndicatorsScreen from '../../successIndicators/SuccessIndicatorsScreen';
import { SuccessIndicatorViewModel } from '../../../api/successIndicators/models/SuccessIndicatorViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead:boolean;
    userCanWrite:boolean;
    groupId?: string
};

enum ScreenType {
    LIST_SUCCESS_INDICATORS,
    SUCCESS_INDICATOR
}

const ParametersSuccessIndicatorsScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_SUCCESS_INDICATORS);
    const [currentSuccessIndicator, setCurrentSuccessIndicator] = useState<SuccessIndicatorViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_SUCCESS_INDICATORS && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.SUCCESS_INDICATORS}/> }
            { screen === ScreenType.LIST_SUCCESS_INDICATORS && <ListSuccessIndicatorsScreen
            userCanRead={userCanRead}
            userCanWrite={userCanWrite}
                groupId={groupId}
                createNewSuccessIndicator={() => {
                    setIsDetails(false);
                    setCurrentSuccessIndicator(null);
                    setScreen(ScreenType.SUCCESS_INDICATOR)
                }}
                onClickItem={(item: SuccessIndicatorViewModel, isDetails: boolean) => {
                    if(item.active)
                    {
                        setIsDetails(isDetails);
                        setCurrentSuccessIndicator(item);
                        setScreen(ScreenType.SUCCESS_INDICATOR);
                    }
                }}
            ></ListSuccessIndicatorsScreen>}
            { screen === ScreenType.SUCCESS_INDICATOR && <SuccessIndicatorsScreen
            userCanRead={userCanRead}
            userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                successIndicatorId={currentSuccessIndicator?.id}
                afterSave={() => {
                    setCurrentSuccessIndicator(null);
                    setScreen(ScreenType.LIST_SUCCESS_INDICATORS);
                }}></SuccessIndicatorsScreen>}
        </div>

    );
}

export default ParametersSuccessIndicatorsScreen;
