import React, { memo, useState } from 'react';
import styles from './ProjectAdvancementTable.module.scss';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { useTranslation } from 'react-i18next';
import { EqualHeightElement } from 'react-equal-height';
import { ProjectAdvancement, ProjectAdvancementDate } from '../ProjectAdvancements';
import ProjectAdvancementTableLine from '../ProjectAdvancementsTableLine/ProjectAdvancementTableLine';
import dayjs from 'dayjs';
import ProjectAdvancementTablePhaseLine from '../ProjectAdvancementsTablePhaseLine/ProjectAdvancementTablePhaseLine';
import { TABLE_EQUAL_HEIGHT_NAMES } from '../../../../../common/components/table/Table';

type Props = {
    projects: ProjectAdvancement[];
    reRenderContentPhases: number;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectAdvancementTable: React.FC<Props> = ({
    projects, reRenderContentPhases, ...props
}: Props) => {

    const { t } = useTranslation();
    const [initialScrollPosition, setInitialScrollPosition] = useState<number>(0);

    const dates = projects.length > 0 ? (projects[0].dates || []) : [];

    const table = document.getElementById('ProjectAdvancementsTable');
    const tableWidth = table?.getBoundingClientRect().width ?? 0;

    const minWidth = 40;
    const showScrool = dates.length * minWidth > tableWidth;
    const widthItem = showScrool ? minWidth : tableWidth / dates.length; //remove floated values
    const widthItems = widthItem + 'px';

    const getTodayLine = () => {
        let numberColumnFoundedDate = 0;
        const itemsToday = dates.find((item: ProjectAdvancementDate, index: number) => {
            const sameDate = dayjs(item.date).format('YYYY/MM').toString() == dayjs(new Date()).format('YYYY/MM').toString();
            numberColumnFoundedDate = sameDate ? index : numberColumnFoundedDate;
            return sameDate;
        });

        if (!(itemsToday)) {
            return <></>;
        }

        const currentDay = new Date().getDate();
        const leftPosition = ((widthItem * numberColumnFoundedDate) + currentDay) - initialScrollPosition - 2; // -2 por causa dos pixeis que tem na css do .todayLine

        return (
            <>
                <div
                    className={styles.todayCircle}
                    style={{ left: leftPosition - 3 + 'px' }}> {/*-3.5 por ser metade da largura do circulo na css do .todayCircle*/}
                </div>
                <div className={styles.todayLine}
                    style={{ left: leftPosition + 'px' }}>
                </div>
            </>
        )
    }

    const getHeader = () => {
        return (
            <div>
                {dates.map((item, i: number) => {
                    const itemToday = dayjs(item.date).format('YYYY/MM').toString() == dayjs(new Date()).format('YYYY/MM').toString();
                    return (
                        <div
                            key={`tbl_header_${i}`}
                            className={`${styles.boxValues} 
                            ${styles.labelHeader} 
                            ${i == (dates.length -1) || item.date.getMonth() == 11 ? styles.boxValuesHeader : {}}
                            ${itemToday ? styles.bold : ''}`}
                            style={{ width: widthItems }}
                        >
                            {item.label.charAt(0)}
                        </div>
                    );
                })}
            </div>
        )
    }

    const getHeaderParent = () => {
        return (
            <div>
                {dates.map(x => x.date.getFullYear()).filter((v, i, a) => a.indexOf(v) === i).map((item, i: number) => {
                    return (
                        <div
                            key={'tbl_header_parent' + i}
                            className={`${styles.boxValues} ${styles.labelHeader} ${styles.boxValuesHeader}`}
                            style={{ width: widthItem * dates.filter(x => x.date.getFullYear() == item).length + 'px' }}
                        >
                            {item}
                        </div>
                    );
                })
                }
            </div>
        )
    }

    const onSyncScroll = (el: Element) => {
        setInitialScrollPosition(el.scrollLeft);
    }

    return (
        <ScrollSync horizontal={true} vertical={false} onSync={onSyncScroll}>
            <div {...props} id={'ProjectAdvancementsTable'}>
                <EqualHeightElement name={TABLE_EQUAL_HEIGHT_NAMES.ROW_COLUMN_NAMES}>
                    <div></div>
                </EqualHeightElement>

                {projects .length > 0 && <div className={styles.flex}>
                    <EqualHeightElement name={TABLE_EQUAL_HEIGHT_NAMES.ROW_FILTERS}>
                        <ScrollSyncPane>
                            <div className={`${styles.areaValueHeader} ${styles.scrollPane}`}>
                                {getHeaderParent()}
                                {getHeader()}
                            </div>
                        </ScrollSyncPane>
                    </EqualHeightElement>
                </div> }

                {projects .length > 0 && <div className={styles.content}>
                    {projects.map((item, i) => (
                        <div key={`rt-row-${i}`}>
                            {i == 0 && getTodayLine()}
                            <div>
                                <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.ROW_INDEX}${i}`}>
                                    <ProjectAdvancementTableLine
                                        key={`${TABLE_EQUAL_HEIGHT_NAMES.ROW_INDEX}${i}`}
                                        dates={item.dates}
                                        widthItems={widthItems}
                                    />
                                </EqualHeightElement>
                            </div>
                            <div key={'contentPhasesTable_' + reRenderContentPhases}>
                                {item.showPhases && item.phases.map((phase, ix) => (
                                    <EqualHeightElement key={`rt-row-sub-${ix}-${i}`} name={`${TABLE_EQUAL_HEIGHT_NAMES.SUB_ROW_INDEX}${ix}-${i}`}>
                                        <ProjectAdvancementTablePhaseLine
                                            key={`${ix}-${i}`}
                                            phase={phase}
                                            widthItems={widthItems}
                                            phaseIndex={ix}
                                        />
                                    </EqualHeightElement>
                                ))} </div>
                        </div>
                    ))}
                    {/* {projects.length === 0 && (
                        <div className={styles.noLinesText}>
                            {t('common.no_results')}
                        </div>
                    )} */}
                </div> }

                {showScrool && <div className={`${styles.flex} ${styles.footerContainer}`}>
                    <ScrollSyncPane>
                        <div className={`${styles.areaValueFooter}`} id="ProjectAdvancementsTableFooter">
                            {dates && dates.map((item, i: number) => {
                                return (
                                    <div key={i}
                                        className={`${styles.boxValues}`}
                                        style={{ width: widthItems }}>
                                    </div>
                                );
                            })}
                        </div>
                    </ScrollSyncPane>
                </div>}

            </div>
        </ScrollSync>
    );
}

export default memo(ProjectAdvancementTable);
