import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale, ReactDatePickerProps as DateProps } from 'react-datepicker';
import { fr, pt, enGB } from 'date-fns/locale';
import { Portal } from 'react-overlays';
import styles from './DateTimePickerRange.module.scss'
import { FaCalendarAlt } from 'react-icons/fa';
import Input from '../input/Input';
import dayjs from 'dayjs';
import { DATE_FORMAT_DEFAUT } from '../../../Config';

registerLocale('fr', fr)
registerLocale('pt', pt)
registerLocale('enGB', enGB)

type Props = {
  onChangeRange?: (start: Date | null, end: Date | null) => void;
  start?: Date,
  end?: Date,
  onClickOutsideRange?: () => void
} & DateProps;

const CalendarContainer = ({ children }: any) => {
  const el = document.getElementById('calendar-portal');
  return <Portal container={el}>{children}</Portal>;
};

const DateTimePickerRange: React.FC<Props> = ({
  onChangeRange, start, end, onClickOutsideRange, placeholderText, ...props
}: Props) => {


  const [dateRange, setDateRange] = useState<[Date | null | undefined, Date | null | undefined]>([start, end]);
  const [startDate, endDate] = dateRange;

  const format = (props.dateFormat as string ?? DATE_FORMAT_DEFAUT).toUpperCase();

  const onChange = (dates: [Date, Date]) => {
    setDateRange(dates);
    (!dates[0] && !dates[1] || !!dates[0] && !!dates[1]) && onChangeRange && onChangeRange(dates[0], dates[1]);
  };

  const returnValue = () => {
    return !startDate && !endDate ? '' :
      (!!startDate ? dayjs(startDate).format(format) : '')
      + ' - ' +
      (!!endDate ? dayjs(endDate).format(format) : '')
  }

  return (
    <DatePicker
      {...props}
      onChange={onChange}
      shouldCloseOnSelect={false}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      popperContainer={CalendarContainer}
      ref={input => !!input && !!startDate && !!endDate && input.setOpen(false)}
      customInput={
        <div className={styles.inputGroup}>
          <Input value={returnValue()} placeholder={placeholderText} style={{ caretColor: 'white' }} />
          <div className={styles.inputGroupAppend}>
            <span className={` ${styles.inputGroupText} `}><FaCalendarAlt /></span>
          </div>
        </div>
      }
      onClickOutside={() => {
        !!onClickOutsideRange && onClickOutsideRange();
        !!startDate && onChangeRange && onChangeRange(startDate, endDate ?? null);
      }}
      isClearable
      onChangeRaw={(event: any) => {
        event.preventDefault();
        if (event.nativeEvent.inputType == 'deleteContentBackward') {
          onChangeRange && onChangeRange(null, null);
        }
      }}
      onFocus={(e: any) => {
        e.target.select();
      }}
    />
  );

};

export default DateTimePickerRange;