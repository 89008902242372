import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { LoginLogSearchCriteria } from './models/LoginLogSearchCriteria';
import { LoginLogViewModel } from './models/LoginLogViewModel';

class LoginLogsService {

    public getList(criteria: LoginLogSearchCriteria) {
        return Http.get<Page<LoginLogViewModel>>('loginlogs', criteria);
    }
}

export default new LoginLogsService();