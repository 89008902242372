import React, { memo, useState } from 'react';
import { IconType } from 'react-icons';
import Label from '../../../../../../common/components/label/Label';
import styles from './ProjectEvaluationBox.module.scss';
import MoneyFormat from '../../../../../../common/components/moneyFormat/MoneyFormat';
import ProjectEvaluationLine from '../projectEvaluationLine/ProjectEvaluationLine';
import Button from '../../../../../../common/components/button/Button';
import { FaPen } from 'react-icons/fa';
import Popover from '../../../../../../common/components/popover/Popover';
import FormItem from '../../../../../../common/components/formItem/FormItem';
import InputIcon from '../../../../../../common/components/input/InputIcon';
import Input from '../../../../../../common/components/input/Input';
import { useTranslation } from 'react-i18next';
import { ProjectInvestmentTotalsViewModel, ProjectInvestmentTypeViewModel } from '../../../../../../api/projects/models/ProjectViewModel';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../../../models/ProjectForm';
import DateFormat from '../../../../../../common/components/dateFormat/dateFormat';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'

export interface DateFormatModeItem {
   date: Date,
   format: string
}

type Props = {
    isDetails: boolean;
    title: string;
    icon?: React.ReactElement<IconType> | undefined,
    titleFooter: string;
    iconFooter?: React.ReactElement<IconType> | undefined,
    isTotal?: boolean | undefined,
    showColumnsOutsideHeader: boolean;
    showColumnsInsideHeader: boolean;
    showColumnsFooter: boolean;
    investmentTotals: UseFieldArrayMethods<ProjectInvestmentTotalsViewModel, 'id'>;
    form: UseFormMethods<ProjectForm>;
    investmentTypes: UseFieldArrayMethods<ProjectInvestmentTypeViewModel, 'id'>;
    unit: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectEvaluationBox: React.FC<Props> = ({
    isDetails, unit,
    titleFooter, iconFooter, isTotal, showColumnsOutsideHeader, showColumnsInsideHeader, showColumnsFooter, investmentTotals, investmentTypes, form, 
    ...props
}: Props) => {

    const { t } = useTranslation();

    const table = document.getElementById('ProjectEvaluationsTable');
    const tableWidth = table?.getBoundingClientRect().width ?? 0;

    const DEFAULT_NUMBER_ITEMS_FULLWIDTH = parseInt((tableWidth / 300).toString());
    let totalHeader = 0; let totalFooter = 0;

    const columnSize = (100).divide(investmentTotals.fields.length.plus(1)) + '%';
    
    const [buttonPopoverItem, setButtonPopoverItem] = useState<number | null>(null);
    const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);

    const mouseButtonPopover = (index: number, state: boolean) => {
        if(!isOpenPopover){
            setButtonPopoverItem(!state ? null : index);
        }
    }

    const investmentTotalsWatch = form.watch<string, ProjectInvestmentTotalsViewModel[]>('investmentTotals');
    const investmentTypesWatch = form.watch<string, ProjectInvestmentTypeViewModel[]>('investmentTypes');

    const setInvestimentTotals = (i: number, investmentTypeIndex: number) => {
        const investmentTotalsWatch = form.watch<string, ProjectInvestmentTotalsViewModel[]>('investmentTotals');
        const investmentTypesWatch = form.watch<string, ProjectInvestmentTypeViewModel[]>('investmentTypes');
        const investmentTotal = investmentTypesWatch.map(x => Number(x.dates[i].total)).sum();
        form.setValue(`investmentTotals[${i}].total`, investmentTotal);
        const investmentTotalFinal = investmentTypesWatch.map(x => Number(x.dates[i].total)).sum().minus(investmentTotalsWatch[i].report);
        form.setValue(`investmentTotals[${i}].totalFinal`, investmentTotalFinal);

        // Update establishments totals
        const investmentType = investmentTypesWatch[investmentTypeIndex];
        const total = investmentType?.dates?.map(x => Number(x.total)).sum();
        investmentType.establishments.forEach((est, estIndex) => {
            let per = Number(est.percentage.toFixed(2));
            
            if(investmentType.establishments.length == 1){
                per = 100;
            }
            const val = (total.multiply(per)).divide(100);
            form.setValue(`investmentTypes[${investmentTypeIndex}].establishments[${estIndex}].total`, val);
            form.setValue(`investmentTypes[${investmentTypeIndex}].establishments[${estIndex}].percentage`, per);

            if(est?.poles.length == 1){
                form.setValue(`investmentTypes[${investmentTypeIndex}].establishments[${estIndex}].poles[0].percentage`, 100);
            }
        });
    }

    const [reportItemPopover, setReportItemPopover] = useState<number>(0);
    const [commentItemPopover, setCommentItemPopover] = useState<string | undefined>(undefined);

    const savePopover = (i: number) => {
        const investmentTotalsWatch = form.watch<string, ProjectInvestmentTotalsViewModel[]>('investmentTotals');
        form.setValue(`investmentTotals[${i}].report`, reportItemPopover);
        form.setValue(`investmentTotals[${i}].comment`, commentItemPopover);
        const totalFinal = investmentTotalsWatch[i].total + reportItemPopover;
        form.setValue(`investmentTotals[${i}].totalFinal`, totalFinal);
    }

    return (
        <ScrollSync horizontal={true} vertical={false}>
            <div {...props} id={'ProjectEvaluationsTable'}>

                <div className={styles.flex}>
                    <div className={styles.areaDetails}>
                        { showColumnsOutsideHeader && <div className={ `${styles.headerOutside} ${styles.boxDetails}` }></div> }
                    </div>
                    {/* SCROLL */}
                    <ScrollSyncPane>
                        <div className={`${(investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? styles.areaValue : styles.areaValueFixed)} ${styles.scrollPane}`}>
                            { investmentTotals.fields && investmentTotals.fields.map((item: any, i: number) => {
                                return (
                                    <div key={i} className={ 
                                        investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues} ${styles.labelHeaderOutside}` : `${styles.boxValuesFixed} ${styles.labelHeaderOutside}` } style={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>
                                        <DateFormat value={item.date} format={item.dateFormat}></DateFormat>
                                    </div>
                                );
                            }) }
                            { isTotal && <div className={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValuesTotal} ${styles.labelHeaderOutside}` : `${styles.boxValuesTotalFixed} ${styles.labelHeaderOutside}` } style={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>{t('common.total')}</div> }
                        </div>
                    </ScrollSyncPane>
                </div>

                <div className={styles.flex}>
                    <div className={ `${styles.areaDetails}` }>
                        <div className={ `${styles.header} ${styles.boxDetails}` }>
                            <div className={styles.shadowBall}></div>
                            <div className={styles.labelContainer}>
                                { props.icon && <span className={styles.labelHeader && styles.iconHeader}>{props.icon}</span> }
                                <Label className={styles.labelHeaderTitle}>
                                    {props.title}
                                </Label>
                            </div>
                        </div>
                    </div>
                    {/* SCROLL */}
                    <ScrollSyncPane>
                        <div className={`${investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? styles.areaValueHeader : styles.areaValueHeaderFixed} ${styles.scrollPane}`}>
                            { showColumnsInsideHeader && investmentTotals.fields && investmentTotals.fields.map((item, i: number) => {
                                const totalColumnHeader = investmentTypesWatch?.map((x: ProjectInvestmentTypeViewModel) => Number(x.dates[i].total)).sum();
                                totalHeader = totalHeader.plus(totalColumnHeader);
                                return (
                                    <Label 
                                        key={i} 
                                        className={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues} ${styles.labelHeader}` : `${styles.boxValuesFixed} ${styles.labelHeader}` } 
                                        style={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>
                                            <MoneyFormat value={totalColumnHeader} />
                                    </Label> 
                                );
                            }) }
                            { isTotal && <div className={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues} ${styles.labelHeader}` : `${styles.boxValuesFixed} ${styles.labelHeader}` } style={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>
                                <MoneyFormat value={totalHeader} />
                            </div> }
                        </div>
                    </ScrollSyncPane>
                </div>

                <div>
                    <div className={styles.content}>
                        { investmentTypes && investmentTypes.fields.map((item, i) => (
                            <ProjectEvaluationLine
                                isDetails={isDetails}
                                key={i}
                                investmentType={item}
                                showColumns={showColumnsInsideHeader}
                                numberColumns={investmentTotalsWatch.length}
                                isTotalColumn={isTotal}
                                form={form}
                                investmentTypeName={`investmentTypes[${i}]`}
                                investmentTypeIndex={i}
                                numberItemsFullWidth={DEFAULT_NUMBER_ITEMS_FULLWIDTH}
                                setInvestimentTotals={setInvestimentTotals}
                                unit={unit}
                            />
                        )) }
                    </div>
                </div>

                <div className={styles.flex}>
                    <div className={styles.areaDetails}>
                        <div className={ `${styles.footer} ${styles.boxDetails}`}>
                            <div className={styles.shadowBallFooter}></div>
                            <div className={styles.labelContainer}>
                                {iconFooter && <span className={styles.labelFooter && styles.iconFooter}>{iconFooter}</span>}
                                <div className={ `${styles.boxValues} ${styles.labelFooter}` }>
                                    <Label className={styles.labelFooterTitle}>{titleFooter}</Label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SCROLL */}
                    <ScrollSyncPane>
                        <div className={`${investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? styles.areaValueFooter : styles.areaValueFooterFixed}`}>
                            { showColumnsFooter && investmentTotals.fields && investmentTotals.fields.map((item, i: number) => {
                                const totalColumnFooter = investmentTypesWatch?.map((x: ProjectInvestmentTypeViewModel) => Number(x.dates[i].total)).sum();
                                totalFooter = totalFooter.plus(totalColumnFooter).plus(investmentTotalsWatch[i].report);
                                const totalReportColumn = investmentTotalsWatch[i].report < 0 ? (investmentTotalsWatch[i].report).multiply(-1) : investmentTotalsWatch[i].report;
                                const totalFinalColumn = totalColumnFooter.plus(investmentTotalsWatch[i].report);
                                return (
                                    <div key={i} className={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues} ${styles.labelFooter}` : `${styles.boxValuesFixed} ${styles.labelFooter}` } 
                                    style={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }
                                    onMouseOver={() => mouseButtonPopover(i, true)} onMouseLeave={() => mouseButtonPopover(i, false)}>
                                        <Label className={styles.labelFooterNormal}>
                                            <div className={styles.labelFooterTotal}>
                                                <MoneyFormat value={totalFinalColumn} />
                                            </div>
                                            <div>
                                                { buttonPopoverItem == i && <div className={styles.buttonPopover}>
                                                    <Popover onClickOutside={() => { setIsOpenPopover(false); setButtonPopoverItem(null); }} content={() =>
                                                    <div className={styles.formContent}>
                                                        <div >
                                                            <FormItem>
                                                                <Label className={styles.bold}>{t('project.project_evaluation.report_previous_year')} </Label>
                                                                <InputIcon
                                                                    disabled={isDetails}
                                                                    defaultValue={reportItemPopover}
                                                                    type={'number'} 
                                                                    maxLength={255} 
                                                                    icon={t('common.euro')}
                                                            onChange={event => setReportItemPopover(Number(event.target.value))}
                                                                />
                                                            </FormItem>
                                                        </div>
                                                        <div >
                                                            <FormItem>
                                                                <Label className={styles.bold}>{t('common.comment')} </Label>
                                                                <Input 
                                                                    disabled={isDetails}
                                                            defaultValue={commentItemPopover}
                                                                    placeholder={t('common.comment')} 
                                                            onKeyUp={event => setCommentItemPopover(event.currentTarget.value) }
                                                                    maxLength={255} />
                                                            </FormItem>
                                                        </div>
                                                        { !isDetails && <div className={styles.buttonsFooter}>
                                                            <Button onClick={() => { setIsOpenPopover(false); setButtonPopoverItem(null); savePopover(i);} } 
                                                                text={t('common.save')} size={'small'} />
                                                        </div>
                                                        }
                                                    </div> }>
                                                    {(isPopoverOpen, setIsPopoverOpen) => (
                                                        <Button type="button" preset={'popover'} size="extraSmall" 
                                                            onClick={() => { 
                                                                setReportItemPopover(form.watch(`investmentTotals[${i}].report`));
                                                                setCommentItemPopover(form.watch(`investmentTotals[${i}].comment`));
                                                                setIsOpenPopover(true); setIsPopoverOpen(!isPopoverOpen) 
                                                            } } 
                                                            onlyIcon={true} >
                                                            <FaPen color={'#63C9EF'} />
                                                        </Button>
                                                    )}
                                                    </Popover> 
                                                </div> }
                                            </div>
                                        </Label>
                                    <Label className={styles.labelFooterSmall}>
                                        { investmentTotalsWatch[i] && investmentTotalsWatch[i].report != 0 &&
                                            <div><MoneyFormat value={totalColumnFooter} />
                                            { investmentTotalsWatch[i].report < 0 && <span> - </span> }
                                            { investmentTotalsWatch[i].report > 0 && <span> + </span> }
                                             <MoneyFormat value={totalReportColumn} /></div>
                                        }
                                        { investmentTotalsWatch[i] && investmentTotalsWatch[i].report == 0 &&
                                            <div>&nbsp;</div>
                                        }
                                    </Label>
                            </div> 
                            );
                        }) }
                        { isTotal && <div className={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues} ${styles.labelFooter}` : `${styles.boxValuesFixed} ${styles.labelFooter}` } style={ investmentTotals.fields.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: columnSize } }>
                            <Label className={styles.labelFooterNormal}>
                                <div className={styles.labelFooterTotal}>
                                    <MoneyFormat value={totalFooter} />
                                </div>
                            </Label>
                            <Label className={styles.labelFooterSmall}>&nbsp;</Label>
                        </div> }
                    </div>
                    </ScrollSyncPane>
                </div>

            </div>
        </ScrollSync>
    );
}

export default memo(ProjectEvaluationBox);
