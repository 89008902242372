
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { EqualHeightElement } from 'react-equal-height';
import { useTranslation } from 'react-i18next';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { CollaboratorTimesValueDateDto, CollaboratorTimesDto, CollaboratorTimesDateDto } from '../../../api/collaboratorsTimes/models/CollaboratorTimesDto';
import { TABLE_EQUAL_HEIGHT_NAMES } from '../../../common/components/table/Table';
import TimesPlanningTableLine from '../timesPlanningTableLine/TimesPlanningTableLine';
import TimesPlanningTablePhaseLine from '../timesPlanningTablePhaseLine/TimesPlanningTablePhaseLine';
import TimesPlanningTableValueColumn from '../timesPlanningTableValueColumn/TimesPlanningTableValueColumn';
import TimesPlanningTableSingleValueColumn from '../timesPlanningTableSingleValueColumn/TimesPlanningTableSingleValueColumn';
import styles from './TimesPlanningTable.module.scss';
import colors from '../../../styles/export/colors.module.scss';

type Props = {
    collaboratorsTimes: CollaboratorTimesDto[];
    supportDates: CollaboratorTimesDateDto[];
    totalPlannedDates: CollaboratorTimesValueDateDto[];
    totalExecutedDates: CollaboratorTimesValueDateDto[];
    openRowsIds: string[];
}

const TimesPlanningTable = ({ collaboratorsTimes, openRowsIds, supportDates, totalPlannedDates, totalExecutedDates }: Props) => {

    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement | null>(null);

    let aux = [
        ...(collaboratorsTimes.length > 0 ? collaboratorsTimes[0].dates : [])
    ];

    if(supportDates.length > 0){
        aux = [
            ...aux,
            ...supportDates.filter(x=> !aux.find(a=> dayjs(a.date).format('YYYY/MM/DD') == dayjs(x.date).format('YYYY/MM/DD')))
        ];
    }

    const dates = aux.length > 0 ? aux : Array.from({ length: 12 }, (item, i) => {
        return { date: new Date((new Date()).getFullYear(), i) }
    });
    const tableWidth = containerRef?.current?.getBoundingClientRect().width ?? 0;

    const decimalPlacesAux = [...collaboratorsTimes.map(x => x.dates).flat(), ...supportDates].map(x => [x.executedValue ?? 0, x.plannedValue ?? 0]).flat().map(x => {
        return x > 999 ? 8 : x > 99 ? 6 : 5
    });
    const decimalPlaces = decimalPlacesAux.length > 0 ? decimalPlacesAux.reduce(function (prev, current) {
        return (prev > current) ? prev : current
    }) : 1;

    const minWidthDecimalPlaces = decimalPlaces * 18;
    const minCell = 70;
    const minWidth = minWidthDecimalPlaces < minCell ? minCell : minWidthDecimalPlaces;
    const showScroll = dates.length * minWidth > tableWidth;
    const widthItem = showScroll ? minWidth : tableWidth / dates.length;
    const widthItems = widthItem + 'px';

    const getHeaderParent = () => {
        const years = dates.map(x => x.date.getFullYear()).filter((v, i, a) => a.indexOf(v) === i);
        return (
            <div>
                {years.map((item, i: number) => {
                    return (
                        <div
                            key={'tbl_header_parent' + i}
                            className={`
                                ${styles.boxValues} 
                                ${styles.labelHeader} 
                                ${styles.boxValuesHeader}
                            `}
                            style={{ width: widthItem * dates.filter(x => x.date.getFullYear() == item).length + 'px' }}
                        >
                            {item}
                        </div>
                    );
                })}
            </div>
        );
    }

    const getHeader = () => {
        return (
            <div>
                {dates.map((item, i: number) => (
                    <div
                        key={`tbl_header_${i}`}
                        className={`
                            ${styles.boxValues} 
                            ${styles.labelHeader} 
                            ${i == (dates.length - 1) || item.date.getMonth() == 11 ? styles.boxValuesHeader : ''}
                        `}
                        style={{ width: widthItems }}
                    >
                        {dayjs(item.date).format('MMM').charAt(0)}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <ScrollSync horizontal={true} vertical={false}>
            <div ref={containerRef}>

                <EqualHeightElement name={TABLE_EQUAL_HEIGHT_NAMES.ROW_COLUMN_NAMES}>
                    <div className={styles.headerTextContainer}>
                        <div className={styles.headerText}>
                            {t('collaborators_times.table.times_planned_and_executed')}
                        </div>
                    </div>
                </EqualHeightElement>

                <div className={styles.flex}>
                    <EqualHeightElement name={TABLE_EQUAL_HEIGHT_NAMES.ROW_FILTERS}>
                        <ScrollSyncPane>
                            <div className={`${styles.areaValueHeader} ${styles.scrollPane}`}>
                                {getHeaderParent()}
                                {getHeader()}
                            </div>
                        </ScrollSyncPane>
                    </EqualHeightElement>
                </div>

                <div className={styles.content}>
                    {collaboratorsTimes.map((item, i) => (
                        <div key={`rt-row-${i}`}>
                            <div>
                                <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.ROW_INDEX}${i}`}>
                                    <TimesPlanningTableLine
                                        key={`${TABLE_EQUAL_HEIGHT_NAMES.ROW_INDEX}${i}`}
                                        dates={item.dates}
                                        widthItems={widthItems}
                                        renderItem={date => (
                                            <TimesPlanningTableValueColumn
                                                executedValue={date.executedValue}
                                                plannedValue={date.plannedValue}
                                                color={`${item.statusColor}25`}
                                                draw={date.draw}
                                            />
                                        )}
                                    />
                                </EqualHeightElement>
                            </div>
                            <div>
                                {Boolean(openRowsIds.find(id => id === item.id)) && item.phases.map((phase, ix) => (
                                    <EqualHeightElement key={`rt-row-sub-${ix}-${i}`} name={`${TABLE_EQUAL_HEIGHT_NAMES.SUB_ROW_INDEX}${ix}-${i}`}>
                                        <TimesPlanningTablePhaseLine
                                            key={`${TABLE_EQUAL_HEIGHT_NAMES.SUB_ROW_INDEX}${ix}-${i}`}
                                            dates={phase.dates}
                                            widthItems={widthItems}
                                            renderItem={date => (
                                                <TimesPlanningTableValueColumn
                                                    executedValue={date.executedValue}
                                                    plannedValue={date.plannedValue}
                                                    color={colors.catskillWhiteOriginal}
                                                    isSubRow={true}
                                                    draw={date.draw}
                                                />
                                            )}
                                        />
                                    </EqualHeightElement>
                                ))}
                            </div>
                        </div>
                    ))}

                    {supportDates.length > 0 && <div>
                        <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.FOOTER_ROW_INDEX}${0}`}>
                            <TimesPlanningTableLine
                                key={`${TABLE_EQUAL_HEIGHT_NAMES.FOOTER_ROW_INDEX}${0}`}
                                dates={supportDates}
                                widthItems={widthItems}
                                renderItem={date => (
                                    <TimesPlanningTableValueColumn
                                        executedValue={date.executedValue}
                                        plannedValue={date.plannedValue}
                                        color={colors.catskillWhiteOriginal}
                                        draw={date.draw}
                                    />
                                )}
                            />
                        </EqualHeightElement>
                    </div>}
                    {totalPlannedDates.length > 0 && <div>
                        <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.FOOTER_ROW_INDEX}${1}`}>
                            <TimesPlanningTableLine
                                key={`${TABLE_EQUAL_HEIGHT_NAMES.FOOTER_ROW_INDEX}${1}`}
                                dates={totalPlannedDates}
                                widthItems={widthItems}
                                renderItem={date => (
                                    <TimesPlanningTableSingleValueColumn value={date.value} draw={date.draw} />
                                )}
                            />
                        </EqualHeightElement>
                    </div>}
                    {totalExecutedDates.length > 0 && <div>
                        <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.FOOTER_ROW_INDEX}${2}`}>
                            <TimesPlanningTableLine
                                key={`${TABLE_EQUAL_HEIGHT_NAMES.FOOTER_ROW_INDEX}${2}`}
                                dates={totalExecutedDates}
                                widthItems={widthItems}
                                renderItem={date => (
                                    <TimesPlanningTableSingleValueColumn value={date.value} color={colors.catskillWhiteOriginal} draw={date.draw} />
                                )}
                            />
                        </EqualHeightElement>
                    </div>}
                    {collaboratorsTimes.length <= 0 && supportDates.length <= 0  && <div>
                        <EqualHeightElement name={`${TABLE_EQUAL_HEIGHT_NAMES.NO_ELEMENTS}`}>
                            <div className={`${styles.row} ${styles.noResultsText}`}>{t('common.no_results')} </div>
                        </EqualHeightElement>
                    </div>}
                </div>

                {showScroll && <div className={`${styles.flex} ${styles.footerContainer}`}>
                    <ScrollSyncPane>
                        <div className={`${styles.areaValueFooter}`}>
                            {dates && dates.map((item, i: number) => {
                                return (
                                    <div key={i}
                                        className={`${styles.boxValues}`}
                                        style={{ width: widthItems }}>
                                    </div>
                                );
                            })}
                        </div>
                    </ScrollSyncPane>
                </div>}

            </div>
        </ScrollSync>
    );
}

export default TimesPlanningTable;
