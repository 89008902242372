/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React from 'react';
import styles from './Input.module.scss';

type InputPreset = 'default' | 'login';

type Props = {
    preset?: InputPreset;
    icon: string;
    nameValues: string[];
    placeholderValues: string[];
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const InputRange = React.forwardRef<HTMLInputElement, Props>(({ nameValues, placeholderValues, preset, icon, ...props }, ref) => {
    const presetClassName = preset ? styles[preset] : styles.default;

    return (
        <div className={styles.formLine}>
            <input ref={ref}
                type={'number'}
                {...props}
                name={nameValues[0]}
                placeholder={placeholderValues[0]}
                className={`${styles.inputRangeWithIcon} ${presetClassName} ${props.className || ''} ${props.disabled ? styles.disabled : ''} `} />
            <input ref={ref}
                type={'number'}
                {...props}
                name={nameValues[1]}
                placeholder={placeholderValues[1]}
                className={`${styles.inputRangeWithIcon} ${presetClassName} ${props.className || ''} ${props.disabled ? styles.disabled : ''} `} />
            <div className={styles.iconRangeInput}><b>{icon}</b></div>
        </div>
    );
});

export default InputRange;
