import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { CollaboratorsTimesSearchCriteria } from './models/CollaboratorsTimesSearchCriteria';
import { CollaboratorsTimesSupportSearchCriteria } from './models/CollaboratorsTimesSupportSearchCriteria';
import { CollaboratorTimesDto } from './models/CollaboratorTimesDto';
import { CollaboratorTimesSupportDto } from './models/CollaboratorTimesSupportDto';
import { CollaboratorTimesTotalsDto } from './models/CollaboratorTimesTotalsDto';


class CollaboratorsTimesService {
    
    public getProjectsAndPhases(criteria: CollaboratorsTimesSearchCriteria) {
        return Http.get<Page<CollaboratorTimesDto>>('collaboratorsTimes/get-projects-and-phases', criteria);
    }

    public getProjectsAndSupportTotals(criteria: CollaboratorsTimesSearchCriteria) {
        return Http.get<CollaboratorTimesTotalsDto[]>('collaboratorsTimes/get-projects-and-support-totals', criteria);
    }

    public getSupportTimes(criteria: CollaboratorsTimesSupportSearchCriteria) {
        return Http.get<CollaboratorTimesSupportDto[]>('collaboratorsTimes/get-support-times', criteria);
    }
}

export default new CollaboratorsTimesService();