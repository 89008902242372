import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Pagination.module.scss';

type Props = {
    items?: number;
    totalItems: number;
    itemName: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const PaginationTextInfo: React.FunctionComponent<Props> = ({ items, totalItems, itemName, ...props }: Props) => {
    const { t } = useTranslation();
    return (
        <div className={`${styles.paginationInfo} ${props.className || ''}`} {...props}>
            {items} {t('pagination.of')} {totalItems} {itemName}
        </div>
    );
}

export default PaginationTextInfo;