import Http from '../../common/services/Http';
import { GroupParamsInfoViewModel } from './models/GroupParamsInfoViewModel';

class GroupParamsInfoService {

    public get(groupId?: string, type?: string) {
        return Http.get<GroupParamsInfoViewModel>(`groupParamsInfos/${groupId}/${type}`);
    }

    public save(model: GroupParamsInfoViewModel) {
        return !!model.id ? Http.put('groupParamsInfos', model) : Http.post('groupParamsInfos', model);
    }
}

export default new GroupParamsInfoService();