import React, { memo } from 'react';
import styles from './ProjectSubventions.module.scss';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../../../models/ProjectForm';
import { ProjectSubventionViewModel } from '../../../../../../api/projects/models/ProjectViewModel';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from '../../../../../../common/components/formItem/FormItem';
import Label from '../../../../../../common/components/label/Label';
import Button from '../../../../../../common/components/button/Button';
import { IoAddCircleOutline, IoTrash } from 'react-icons/io5';
import InputResizable from '../../../../../../common/components/input/InputResizable';
import InputGroupController from '../../../../../../common/components/inputGroup/InputGroupController';

type Props = {
    isDetails: boolean;
    form: UseFormMethods<ProjectForm>;
    subventions: UseFieldArrayMethods<ProjectSubventionViewModel, 'id'>;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectSubventions: React.FC<Props> = ({
    isDetails, form, subventions
}: Props) => {

    const { t } = useTranslation();
    const { register } = form;

    const addSubvention = () => {
        subventions.append({});
    }
    const removeSubvention = (index: number) => {
        subventions.remove(index);
    }

    return (
        <FormItem>
            {subventions.fields.map((item, i) => {
                const nameInput = `subventions[${i}].name`;
                const typeInput = `subventions[${i}].typeSubvention`;
                const expectedValueInput = `subventions[${i}].expectedValue`;
                const realValueInput = `subventions[${i}].realValue`;
                return (
                    <div className={styles.row} key={i}>
                        <div className={styles.col} style={{ flex: 1 }}>
                            <Label className={styles.bold}>{t('project.project_definition.subvention_nature')}</Label>
                            <InputResizable
                                maxLength={255}
                                name={nameInput}
                                defaultValue={item.name}
                                placeholder={t('project.project_definition.subvention_nature')}
                                ref={register({ maxLength: 255 })}
                                disabled={isDetails}
                            />
                        </div>
                        <div className={styles.col}>
                            <Label className={styles.bold}>{t('project.project_definition.subvention_type')}</Label>
                            <InputResizable
                                maxLength={255}
                                name={typeInput}
                                defaultValue={item.typeSubvention}
                                placeholder={t('project.project_definition.subvention_type')}
                                ref={register({ maxLength: 255 })}
                                disabled={isDetails}
                            />
                        </div>
                        <div className={styles.col}>
                            <Label className={styles.bold}>{t('project.project_definition.amount_envisaged')}</Label>
                            <InputGroupController
                                type="number"
                                name={expectedValueInput}
                                form={form}
                                disabled={isDetails}
                                format="money"
                                text={t('common.euro')}
                            />
                        </div>
                        <div className={styles.col}>
                            <Label className={styles.bold}>{t('project.project_definition.amount_obtained')}</Label>
                            <InputGroupController
                                type="number"
                                name={realValueInput}
                                form={form}
                                disabled={isDetails}
                                format="money"
                                text={t('common.euro')}
                            />
                        </div>
                        {!isDetails && <div className={styles.col} style={{ marginTop: '32px' }}>
                            <Button type="button" preset={'secondary'} size="small" onlyIcon={true} onClick={() => removeSubvention(i)}>
                                <IoTrash />
                            </Button>
                        </div>}
                    </div>
                )
            })}
            {subventions.fields.length == 0 &&
                <Row style={{ marginBottom: '10px' }}>
                    <Col xs>
                        <div className={styles.withoutElements}>{t('common.without_elements')}</div>
                    </Col>
                </Row>
            }
            <Row >
                <Col xs={12}>
                    {!isDetails && <Button size={'small'} className={styles.addButton} onClick={addSubvention}>
                        <IoAddCircleOutline className={styles.addButtonIcon} size={24} />
                        <span className={styles.addButtonText}>{t('common.add')}</span>
                    </Button>
                    }
                </Col>
            </Row>
        </FormItem>
    );
}

export default memo(ProjectSubventions);
