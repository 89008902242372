
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEraser } from 'react-icons/fa';
import Button from '../../../common/components/button/Button';
import NumberPerPage, { ItemsPerPage } from '../../../common/components/pagination/numberPerPage/NumberPerPage';
import Text from '../../../common/components/text/Text';
import styles from './Toolbar.module.scss';

type Props = {
    clearSearch: () => void;
    itemsPerPage: ItemsPerPage;
    onItemsPerPageChange: (items: ItemsPerPage) => void
}

const Toolbar = ({ clearSearch, itemsPerPage, onItemsPerPageChange }: Props) => {
    const { t } = useTranslation();
    const [reRender, setReRender] = useState(1);

    return (
        <div className={styles.container}>
            <div className={styles.toolbar}>
                <Text preset="screenHeaderTitle" className={styles.title}>{t('projectstimes.title')}</Text>
            </div>
            <div className={`${styles.toolbar} ${styles.toolbarItemsPerPage}`}>
                {itemsPerPage > 0 &&
                    <NumberPerPage key={'itemsPerPage_' + reRender} itemsPerPage={itemsPerPage} onChangeItemsPerPage={onItemsPerPageChange}></NumberPerPage>
                }
                <Button onClick={() => {
                    clearSearch();
                    setReRender(reRender + 1);
                }} preset="primary" onlyIcon={true} className={styles.toolbarButton}>
                    <FaEraser className={styles.toolbarButtonIcon} />
                </Button>
            </div>
        </div>
    );
}

export default Toolbar;
