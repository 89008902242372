import React, { ReactElement, useState } from 'react';
import styles from './SubBox.module.scss';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

type Props = {
    title: string;
    open?: boolean | undefined;
    rightHeader?: ReactElement;
    contentClassName?: any;
    headerClassName?: any;
    headerTextClassName?: any;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const SubBox: React.FC<Props> = ({ children, open, title, rightHeader, contentClassName, headerClassName, headerTextClassName, ...props }: Props) => {

    const [showContent, setShowContent] = useState(open?? true); 

    return (
        <div {...props}>
            <div className={`${styles.header} ${headerClassName ? headerClassName : ''}`}>
                <div className={styles.labelContainer} onClick={() => { setShowContent(!showContent) }}>
                    {showContent && <FaChevronDown className={`${styles.icon} ${headerTextClassName ? headerTextClassName : ''}`}/>}
                    {!showContent && <FaChevronRight className={`${styles.icon} ${headerTextClassName ? headerTextClassName : ''}`}/>}
                    <div className={`${styles.title} ${headerTextClassName ? headerTextClassName : ''}`}>
                        {title}
                    </div>
                </div>
                {rightHeader && <div>
                    {rightHeader}
                </div>}
            </div>
            {showContent && <div className={`${styles.content} ${contentClassName ? contentClassName : ''}`}>
                {children}
            </div>}
        </div>
    );
}

export default SubBox;
