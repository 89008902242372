import React from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import Welcome from '../../common/components/welcome/Welcome';

type Props = {

};

const HomeScreen: React.FC<Props> = ({ }: Props) => {

  const { t } = useTranslation();

  return (
    <ScreenTitle title={t('projects.title')}>
      <Welcome/>
    </ScreenTitle>
  );

}

export default HomeScreen;
