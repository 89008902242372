import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Button from '../../common/components/button/Button';
import FormItem from '../../common/components/formItem/FormItem';
import Input from '../../common/components/input/Input';
import InputError from '../../common/components/inputError/InputError';
import Label from '../../common/components/label/Label';
import Loading from '../../common/services/Loading';
import styles from './ProjectResponsibleScreen.module.scss';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import { ProjectResponsibleViewModel } from '../../api/projectResponsibles/models/ProjectResponsibleViewModel';
import ProjectResponsibleService from '../../api/projectResponsibles/ProjectResponsiblesService';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { UserViewModel } from '../../api/users/models/UserViewModel';
import { Reducers } from '../../store/types';
import { UserProfile } from '../../api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import UsersService from '../../api/users/UsersService';
import Select from '../../common/components/select/Select';
import { SelectValueLabel } from '../../common/types/SelectValueLabel';
import SelectController from '../../common/components/select/SelectController';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    afterSave: () => void;
    projectResponsiblesId?: string;
    isDetails: boolean;
};

type Form = {
    userId?: string;
}

enum ScreenType {
    NEW,
    EDIT,
    DETAILS
}

const ProjectResponsibleScreen: React.FC<Props> = ({ projectResponsiblesId, groupId, afterSave, isDetails, userCanRead, userCanWrite }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const form = useForm<Form>({ shouldUnregister: false, shouldFocusError: true });
    //const { register, handleSubmit, errors, setValue, getValues } = useForm<Form>();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [screenMode, setScreenMode] = useState<ScreenType>(isDetails && !!projectResponsiblesId ? ScreenType.DETAILS : !!projectResponsiblesId ? ScreenType.EDIT : ScreenType.NEW);
    const [itemToRemove, setItemToRemove] = useState<ProjectResponsibleViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [description, setDescription] = useState<string>();
    const [users, setUsers] = useState<SelectValueLabel[] | null>(null);

    const onCancel = () => {
        afterSave();
    }

    const onSubmit = async ({ userId }: Form) => {
        try {
            Loading.show();
            if (groupId) {
                await ProjectResponsibleService.save({ id: projectResponsiblesId, groupId, description, userId });
            }
            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            afterSave();

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getDomain = async () => {
        try {

            Loading.show();

            if (projectResponsiblesId) {
                const result = await ProjectResponsibleService.getProjectResponsibleById(projectResponsiblesId);
                form.setValue('userId', result?.userId);
                setDescription(result.description ?? '');
            }

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: ProjectResponsibleViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await ProjectResponsibleService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onCancel();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };


    const onCancelEdit = () => {
        if (screenMode === ScreenType.EDIT && !!isDetails) {
            setScreenMode(ScreenType.DETAILS);
            getDomain();
        } else {
            onCancel()
        }
    }

    const getUsers = async () => {
        try {
            Loading.show();
            const userPosition = { groupId: loggedUser?.groupId, establishmentId: loggedUser?.establishmentId };

            const result = !!userPosition.establishmentId ? await UsersService.getAllUsersOfEstablishment(userPosition.establishmentId, true) : !!userPosition.groupId ? await UsersService.getAllUsersOfGroup(userPosition.groupId, true) : null;
            setUsers((result || []).map(x => ({ value: x.id || '', label: x.realName || '', active: x.active != undefined ? x.active : true })));

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    useEffect(() => {
        if (userCanRead) {
            if (loggedUser) {
                getUsers();
            }
            if (!!projectResponsiblesId) {
                getDomain();
            }
        }
    }, [projectResponsiblesId]);

    return (
        <form onSubmit={form.handleSubmit(onSubmit)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
            <div className={styles.formLine}>
                {users != null && users.filter(x => x.active) && <FormItem className={styles.formItem}>
                        <Label className={styles.bold}>{t('common.user')}</Label>
                        <SelectController
                            name={'userId'}
                            form={form}
                            rules={{ required: true }}
                            options={users.filter(x => x.active)}
                            allOptions={users}
                            isClearable
                            placeholder={t('common.user')}
                            isDisabled={screenMode === ScreenType.DETAILS}
                        />
                        <InputError error={form.errors.userId} />
                    </FormItem>
                }
                <FormItem className={styles.formItem}>
                    <Label className={styles.bold}>{t('common.description')}</Label>
                    <TextareaAutosize
                        style={{ resize: 'none' }}
                        name='description'
                        value={description}
                        disabled={screenMode === ScreenType.DETAILS}
                        className={`${styles.input} ${styles.inputDefault} ${screenMode === ScreenType.DETAILS ? styles.inputDisabled : ''}`}
                        onChange={e => {
                            setDescription(e.currentTarget.value);
                        }}
                        rows={2}
                    />
                </FormItem>
            </div>
            {screenMode !== ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={onCancelEdit} />
                {userCanWrite && <Button type="submit" text={t('common.save')} size={'small'} disabled={!users} />}
            </div>}
            {screenMode === ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.back')} preset={'secondary'} size={'small'} onClick={() => onCancel()} />
                {userCanWrite && <Button type="button" text={t('common.remove')} preset={'danger'} size={'small'} onClick={() => showRemoveItemDialog({ id: projectResponsiblesId } as ProjectResponsibleViewModel)} />}
                <QuestionYesNo message={t('common.messages.remove_record')}
                    isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />
                {userCanWrite && <Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
            </div>}
        </form>
    );
}

export default ProjectResponsibleScreen;
