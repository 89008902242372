import React, { memo, useEffect, useRef, useState } from 'react';
import Input from '../../../../common/components/input/Input';
import styles from './EditableDiv.module.scss';
import { FaPencilAlt } from 'react-icons/fa';

type Props = {
    value?: number;
    onValueChange: (value?: number | undefined) => void;
    editable?: boolean;
};

const EditableDiv: React.FC<Props> = ({ value, onValueChange, editable }: Props) => {
    const [edit, setEdit] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (edit && ref.current && !ref.current.contains(e.target)) {
                setEdit(false)
            }
        }

        document.addEventListener('mousedown', checkIfClickedOutside)

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [edit]);

    useEffect(() => {
        if (!!edit) {
            inputRef && inputRef.current && inputRef.current.focus();
        }
    }, [edit]);

    useEffect(() => {
        if (!editable) {
            setEdit(false);
        }
    }, []);

    const renderEditContainer = (): JSX.Element => {
        return <div className={styles.editContainer}>
            <Input
                ref={inputRef}
                style={{ borderColor: 'transparent', padding: 0, fontSize: '14px', color: '#BEBEBE', fontWeight: 'bold', textAlign: 'right' }}
                className={styles.input}
                type={'number'}
                pattern="[0-9]*"
                defaultValue={(!value && value != 0) ? undefined : value}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    value = !!e.currentTarget.value ? Number(e.currentTarget.value) : undefined;
                    onValueChange(!!e.currentTarget.value ? Number(e.currentTarget.value) : undefined);
                }} />
            {(!!value || value === 0) && <span className={styles.label}>h</span>}
        </div>
    };

    const renderDetailsContainer = (): JSX.Element => {
        return <div
            onClick={() => {
                if (editable) {
                    setEdit(true)
                } else {
                    setEdit(false)
                }
            }
            }
            className={styles.detailsContainer}>
            <span>{value}</span>
            {(!!value || value === 0) && <span className={styles.label}>h</span>}
            {editable && <FaPencilAlt className={styles.edit} />}
        </div>
    }

    return (<div ref={ref} style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
        {edit ? renderEditContainer() : renderDetailsContainer()}
    </div >);
}

export default memo(EditableDiv);
