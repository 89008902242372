import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { DepartmentSearchCriteria } from './models/DepartmentSearchCriteria';
import { DepartmentViewModel } from './models/DepartmentViewModel';

class DepartmentService {
    
    public getList(criteria: DepartmentSearchCriteria) {
        return Http.get<Page<DepartmentViewModel>>('departments', criteria);
    }

    public getDepartmentById(id: string) {
        return Http.get<DepartmentViewModel>('departments/'+ id);
    }

    public save(model: DepartmentViewModel) {
        return !!model.id ? Http.put('departments', model) : Http.post('departments', model);
    }

    public remove(model: DepartmentViewModel) {
        return  Http.put('departments/deactivate', model);
    }

}

export default new DepartmentService();



