import React, { memo } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProjectInvestmentTypeEstablishmentViewModel, ProjectInvestmentTypeViewModel } from '../../../../../../api/projects/models/ProjectViewModel';
import InputGroupController from '../../../../../../common/components/inputGroup/InputGroupController';
import PercentageFormat from '../../../../../../common/components/percentageFormat/PercentageFormat';
import { ProjectForm } from '../../../../models/ProjectForm';
import styles from './ProjectEvaluationLineEstablishment.module.scss';

type Props = {
    isDetails: boolean;
    item: ProjectInvestmentTypeEstablishmentViewModel;
    form: UseFormMethods<ProjectForm>;
    investmentTypeName: string;
    establishmentName: string;
    valueUnit: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectEvaluationLineEstablishment: React.FC<Props> = ({ item, form, investmentTypeName, establishmentName, isDetails, valueUnit, ...props }: Props) => {
    const { t } = useTranslation();

    const establishmentWatch = form.watch<string, ProjectInvestmentTypeEstablishmentViewModel>(establishmentName);
    const investmentTypeWatch = form.watch<string, ProjectInvestmentTypeViewModel>(investmentTypeName);
    const investmentTypeTotal = investmentTypeWatch.dates.map(x => Number(x.total || 0)).sum();

    let establishmentsPercentage = investmentTypeWatch.establishments.map(x => Number(x.percentage || 0)).sum();
    establishmentsPercentage = establishmentsPercentage > 100 ? 100 : (establishmentsPercentage < 0 ? 0 : establishmentsPercentage);

    const establishmentsTotals = investmentTypeWatch.establishments.map(x => Number(x.total || 0)).sum();

    let polesPercentage = establishmentWatch.poles.map(x => Number(x.percentage || 0)).sum();
    polesPercentage = polesPercentage > 100 ? 100 : (polesPercentage < 0 ? 0 : polesPercentage);
    const roundPolesPercentage = Number(polesPercentage.toFixed(2));
   
    const investmentTotal = investmentTypeWatch.dates.map(x => x.total).sum();

    const percentageName = `${establishmentName}.percentage`;
    const totalName = `${establishmentName}.total`;

    const validatePolesPercentage = (establishment?: ProjectInvestmentTypeEstablishmentViewModel, establishmentPath?:string) => {
        if(establishmentPath && establishment?.poles.length == 1){
            form.setValue(`${establishmentPath}.poles[0].percentage`, 100);
        }
    }

    const onPercentageValueChange = (newValue: string | number) => {
        
        const oldValue = form.getValues<string, number>(percentageName) || 0;
        const remainingPercentage = (100).minus(establishmentsPercentage.minus(oldValue));

        let value = Number(newValue || 0);
        value = remainingPercentage.minus(value) < 0 ? remainingPercentage : (value < 0 ? 0 : value);
        value = value > 100 ? 100 : (value < 0 ? 0 : value);
        value = Number(value.toFixed(2)); 

        validatePolesPercentage(item, establishmentName);

        return value;
    }

    const onPercentageChange = () => {
        
        const value = form.getValues<string, number>(percentageName) || 0;

        const total = investmentTotal.multiply(value).divide(100);
        form.setValue(totalName, total);

        const investmentTypeWatch = form.watch<string, ProjectInvestmentTypeViewModel>(investmentTypeName);
        const p = investmentTypeWatch.establishments.map(x => Number((x.percentage || 0).toFixed(2))).sum();
        const m = investmentTypeWatch.establishments.map(x => Number(x.total || 0)).sum();

        if (p == 100 && m < investmentTypeTotal) {
            const remaining = investmentTypeTotal.minus(m);
            form.setValue(totalName, total.plus(remaining));
        }
    }
    
    const onTotalValueChange = (newValue: string | number) => {
        const oldValue = form.getValues<string, number>(totalName) || 0;
        const remainingValue = investmentTotal.minus(establishmentsTotals.minus(oldValue));

        let value = Number(newValue || 0);
        value = remainingValue.minus(value) < 0 ? remainingValue : (value < 0 ? 0 : value);
        value = value > investmentTotal ? investmentTotal : (value < 0 ? 0 : value);

        return value;
    }

    const onTotalChange = () => {
        const value = form.getValues<string, number>(totalName) || 0;

        const percentage = ((value.multiply(100)).divide(investmentTotal)) || 0;
        form.setValue(percentageName, percentage);

        const investmentTypeWatch = form.watch<string, ProjectInvestmentTypeViewModel>(investmentTypeName);
        const p = investmentTypeWatch.establishments.map(x => Number((x.percentage || 0).toFixed(2))).sum();
        const m = investmentTypeWatch.establishments.map(x => Number(x.total || 0)).sum();

        if (m == investmentTypeTotal && p < 100) {
            const remaining = (100).minus(p);
            form.setValue(percentageName, p.plus(remaining));
        }
    }

    const onPolePercentageChange = (polePercentageName: string, newValue: string | number) => {
        const oldValue = form.getValues<string, number>(polePercentageName) || 0;
        const remainingPercentage = (100).minus(roundPolesPercentage.minus(oldValue));

        let value = Number(newValue || 0);
        value = Number(value.toFixed(2));
        value = remainingPercentage.minus(value) < 0 ? remainingPercentage : (value < 0 ? 0 : value);

        return value;
    }


    return (
        <div
            {...props}
            className={`${styles.container} ${props.className || ''}`}
        >
            <div className={styles.headerContainer}>
                <div className={styles.titleContainer}>
                    <span className={styles.title}>{item.establishmentName}</span>
                    {item.poles && item.poles.length > 0 && <span className={`${styles.subtitle} ${(roundPolesPercentage < 100 && establishmentWatch.total > 0) ? styles.subtitleDanger : ''}`}>
                        <PercentageFormat value={roundPolesPercentage} renderAsText={true} />
                        {t('project.investments.total_value_distributed_by_the_poles')}
                    </span>}
                </div>
                <div className={styles.titleInputsContainer}>
                    <InputGroupController
                        type="number"
                        text={t('common.percentage')}
                        name={percentageName}
                        form={form}
                        disabled={isDetails}
                        onValueChange={onPercentageValueChange}
                        onChange={onPercentageChange}
                        format="percentage"
                    />
                    <InputGroupController
                        type="number"
                        text={valueUnit}
                        name={totalName}
                        form={form}
                        disabled={isDetails}
                        onValueChange={onTotalValueChange}
                        onChange={onTotalChange}
                        format="money"
                    />
                </div>
            </div>
            <div className={styles.progressBarContainer}>
                <div className={styles.progressBar} style={{ width: `${roundPolesPercentage || 0}%` }}></div>
            </div>
            <div className={styles.bodyContainer}>
                {item.poles.map((pole, i) => {
                    const polePercentageName = `${establishmentName}.poles[${i}].percentage`;
                    return (
                        <div key={`pole-${i}-${pole.poleId}`} className={styles.poleItem}>
                            <InputGroupController
                                type="number"
                                text={t('common.percentage')}
                                name={polePercentageName}
                                nameText={pole.poleName}
                                form={form}
                                disabled={isDetails}
                                onValueChange={e => onPolePercentageChange(polePercentageName, e)}
                                format="percentage"
                            />
                        </div>
                    );
                })}
                {item.poles.length === 0 && <div className={styles.noPolesText}>{t('project.investments.no_poles')}</div>}
            </div>
        </div>
    );
}

export default memo(ProjectEvaluationLineEstablishment);
