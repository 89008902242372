import Http from '../../common/services/Http';
import { InvestmentTypeDto } from './models/InvestmentTypeDto';
class InvestmentTypesService {

    public getByProjectType(projectType: string) {
        return Http.get<InvestmentTypeDto[]>('investmentTypes/get-by-project-type/' + projectType);
    }
    
}

export default new InvestmentTypesService();