import React, { ReactChild, ReactElement, useState } from 'react';
import styles from './Dropdown.module.scss';
import DropdownWrapper from 'react-dropdown-wrapper';
import { DropdownWrapperChildFunc } from '../../types/ReactDropdownWrapper';
import { Portal } from 'react-overlays';

type Props = {
    children: ReactChild | ReactChild[];
    options: any; // TODO: cb
    useLocationOfItemClicked?: boolean;
    idDropdown?: string;
    position?: 'top' | 'bottom',
    alignment?: 'center' | 'left' | 'right'
};

const Dropdown: React.FC<Props> = ({ children, options, useLocationOfItemClicked, idDropdown, position, alignment }: Props) => {
    let clientX = 0;
    let clientY = 0;
    let elWidth = 0;
    
    return (
        <DropdownWrapper>
            {({ changeStatus, isShow }: DropdownWrapperChildFunc) => (
                <>
                    <div className={styles.icon} onClick={e => {  
                         document.body.click();     
                         e.preventDefault();     
                         e.stopPropagation();  
                         
                         const ellipsisObject = idDropdown ? document.getElementById(idDropdown): undefined;
                         clientX = ellipsisObject ? ellipsisObject.getBoundingClientRect().x + document.body.scrollLeft + document.documentElement.scrollLeft - 10: 0;
                         clientY = ellipsisObject ? ellipsisObject.getBoundingClientRect().y + document.body.scrollTop + document.documentElement.scrollTop - 18 : 0;
                         elWidth = ellipsisObject ? ellipsisObject.offsetWidth ?? 10 : 10;
                         //clientX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                         //clientY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;

                        changeStatus(!isShow);
                    }}>{children}</div>
                    {isShow && useLocationOfItemClicked && (
                        <Portal container={document.body}>
                            <div style={{ position: 'absolute', maxWidth: 250, width: '100%', 
                                            left: clientX + (alignment == 'left' ? -90 : alignment == 'right' ? elWidth : -(elWidth/4)), 
                                            top: clientY + (position == 'top' ? -90 : position == 'bottom' ? 50 : 0) }}>
                                <div className={styles.dropdownContainer}>
                                    {options}
                                </div>
                            </div>
                        </Portal>
                    )}

                    {isShow && !useLocationOfItemClicked && (
                        <div className={`${styles.dropdownContainer}`}>
                            {options}
                        </div>
                    )}
                </>
            )}
        </DropdownWrapper>
    );
}

export default Dropdown;
