import React, { memo, useState } from 'react';
import { IconType } from 'react-icons';
import Label from '../../../../../../common/components/label/Label';
import styles from './ProjectEvaluationScheduleBox.module.scss';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { ProjectForm } from '../../../../models/ProjectForm';
import { ProjectPhaseViewModel, ProjectSuccessIndicatorViewModel } from '../../../../../../api/projects/models/ProjectViewModel';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import ProjectEvaluationScheduleLine from '../projectEvaluationScheduleLine/ProjectEvaluationScheduleLine';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '@react-hook/window-size';
import { SelectValueLabel } from '../../../../../../common/types/SelectValueLabel';

type Props = {
    title: string;
    icon?: React.ReactElement<IconType> | undefined;
    form: UseFormMethods<ProjectForm>;
    phases: UseFieldArrayMethods<ProjectPhaseViewModel, 'id'>;
    isDetails: boolean;
    projectResponsiblesDsiOptions: SelectValueLabel[];
    userCanReadTimes?: boolean;
    userCanWriteTimes?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectEvaluationScheduleBox: React.FC<Props> = ({
    form, title, icon, isDetails, projectResponsiblesDsiOptions, userCanReadTimes, userCanWriteTimes, ...props
}: Props) => {

    const { t } = useTranslation();

    const table = document.getElementById('ProjectMonthsTable');
    const tableWidth = table?.getBoundingClientRect().width ?? 0;

    const DEFAULT_NUMBER_ITEMS_FULLWIDTH = parseInt((tableWidth / 300).toString());

    const phasesFields = form.watch<string, ProjectPhaseViewModel[]>('phases')?.filter(x => x.name && x.name.length > 0) || [];
    const dates = phasesFields.length > 0 ? (phasesFields[0].dates || []) : [];

    return (
        <ScrollSync horizontal={true} vertical={false}>
            <div {...props} id={'ProjectMonthsTable'}>

                <div className={styles.flex}>
                    <div className={`${styles.areaDetails}`}>
                        <div className={`${styles.header} ${styles.boxDetails}`}>
                            <div className={styles.shadowBall}></div>
                            <div className={styles.labelContainer}>
                                {icon && <span className={styles.labelHeader && styles.iconHeader}>{icon}</span>}
                                <Label className={styles.labelHeaderTitle}>
                                    {title}
                                </Label>
                            </div>
                        </div>
                    </div>
                    <ScrollSyncPane>
                        <div className={`${dates.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? styles.areaValueHeader : styles.areaValueHeaderFixed} ${styles.scrollPane}`}>
                            {dates.map((item, i: number) => {
                                return (
                                    <Label
                                        key={i}
                                        className={dates.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues} ${styles.labelHeader}` : `${styles.boxValuesFixed} ${styles.labelHeader}`}
                                        style={dates.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: (100 / (dates.length)) + '%' }}>
                                        {item.date.getFullYear()}
                                    </Label>
                                );
                            })}
                        </div>
                    </ScrollSyncPane>
                </div>

                <div className={styles.content}>
                    {phasesFields.map((item, i) => (
                        <ProjectEvaluationScheduleLine
                            isDetails={isDetails}
                            key={i}
                            phase={item}
                            form={form}
                            numberItemsFullWidth={DEFAULT_NUMBER_ITEMS_FULLWIDTH}
                            phaseIndex={i}
                            projectResponsiblesDsiOptions={projectResponsiblesDsiOptions}
                            userCanReadTimes={userCanReadTimes}
                            userCanWriteTimes={userCanWriteTimes}
                        />
                    ))}
                    {phasesFields.length === 0 && (
                        <div className={styles.noLinesText}>
                            {t('common.no_results')}
                        </div>
                    )}
                </div>

                {dates.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH && phasesFields.length > 0 && <div className={`${styles.flex} ${styles.footerContainer}`}>
                    <div className={styles.areaDetails}>
                        <div className={`${styles.footer} ${styles.boxDetails}`}></div>
                    </div>
                    <ScrollSyncPane>
                        <div className={`${dates.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? styles.areaValueFooter : styles.areaValueFooterFixed}`}>
                            {dates && dates.map((item, i: number) => {
                                return (
                                    <div key={i} className={dates.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? `${styles.boxValues}` : `${styles.boxValuesFixed} `}
                                        style={dates.length > DEFAULT_NUMBER_ITEMS_FULLWIDTH ? {} : { width: (100 / (dates.length)) + '%' }}>
                                    </div>
                                );
                            })}
                        </div>
                    </ScrollSyncPane>
                </div>}

            </div>
        </ScrollSync>
    );
}

export default memo(ProjectEvaluationScheduleBox);
