import React, { memo } from 'react';
import styles from './TimesPlanningTablePhaseLine.module.scss';
import { ScrollSyncPane } from 'react-scroll-sync';

interface DateItem {
    date: Date;
    [key: string]: any;
}

interface Props<TItem> {
    dates: TItem[];
    renderItem: (item: TItem) => React.ReactNode;
    widthItems: string
}

const TimesPlanningTablePhaseLine = <TItem extends DateItem>({ widthItems, dates, renderItem }: Props<TItem>) => {
    return (
        <div className={styles.container}>
            <ScrollSyncPane>
                <div className={`${styles.items} ${styles.scrollPane}`}>
                    {dates.map((date, dateIndex) => {
                        const isLastYear = dateIndex === dates.length - 1 || dates[dateIndex + 1].date.getFullYear() !== date.date.getFullYear();
                        return (
                            <div
                                key={dateIndex}
                                className={`${styles.item} ${isLastYear ? styles.lastYearItem : ''}`}
                                style={{ width: widthItems }}
                            >
                                {renderItem(date)}
                            </div>
                        );
                    })}
                </div>
            </ScrollSyncPane>
        </div>
    );
}

export default memo(TimesPlanningTablePhaseLine);
