import React from 'react';
import styles from './Badge.module.scss';

type BadgePreset = 'primary' | 'primaryDark' | 'success' | 'danger';
type BadgeSize = 'normal' | 'big';

type Props = {
    text?: string;
    preset?: BadgePreset;
    size?: BadgeSize;
    color?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Badge: React.FC<Props> = ({ children, text, preset = 'primary', size = 'normal', color, ...props }: Props) => {

    const presetClassName = preset ? styles[preset] : !color ? styles.primary : '';
    const sizeClassName = size ? (styles as any)['size' + size[0].toUpperCase() + size.substr(1, size.length)] : styles.sizeNormal;

    const colorStyle =  color ? { color: { backgroundColor: `${color}25`, color: `${color}` } } : { color: {} };

    return (
        <span
            {...props}
            className={`${styles.badge} ${presetClassName} ${sizeClassName} ${props.className || ''}`} style={colorStyle.color}
        >
            {Boolean(text) && text}
            {children}
        </span>
    );
}

export default Badge;
