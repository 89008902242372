import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Button from '../../common/components/button/Button';
import FormItem from '../../common/components/formItem/FormItem';
import Input from '../../common/components/input/Input';
import InputError from '../../common/components/inputError/InputError';
import Label from '../../common/components/label/Label';
import Loading from '../../common/services/Loading';
import styles from './PriorityLevelsScreen.module.scss';
import PriorityLevelsService from '../../api/priorityLevels/PriorityLevelsService';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import { PriorityLevelViewModel } from '../../api/priorityLevels/models/PriorityLevelViewModel';
import TextareaAutosize from 'react-autosize-textarea/lib';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    afterSave: () => void;
    priorityLevelId?: string;
    isDetails: boolean;
};

type Form = {
    name: string;
    index?: number;
}

enum ScreenType {
    NEW,
    EDIT,
    DETAILS
}

const PriorityLevelsScreen: React.FC<Props> = ({ priorityLevelId, groupId, afterSave, isDetails, userCanRead, userCanWrite }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, setValue } = useForm<Form>();
    const [screenMode, setScreenMode] = useState<ScreenType>(isDetails && !!priorityLevelId ? ScreenType.DETAILS : !!priorityLevelId ? ScreenType.EDIT : ScreenType.NEW);
    const [itemToRemove, setItemToRemove] = useState<PriorityLevelViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [description, setDescription] = useState<string>();


    const onCancel = () => {
        afterSave();
    }

    const onSubmit = async ({ name, index }: Form) => {
        try {

            if (!name) {
                return;
            }

            Loading.show();
            if (groupId) {
                await PriorityLevelsService.save({ id: priorityLevelId, name: name, groupId, index, description });
            }
            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            afterSave();

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getPriorityLevel = async () => {
        try {

            Loading.show();

            if (priorityLevelId) {
                const result = await PriorityLevelsService.getPriorityLevelById(priorityLevelId);
                setValue('name', result?.name);
                setValue('index', result?.index);
                setDescription(result.description ?? '')
            }

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: PriorityLevelViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await PriorityLevelsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onCancel();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onCancelEdit = () => {
        if (screenMode === ScreenType.EDIT && !!isDetails) {
            setScreenMode(ScreenType.DETAILS);
            getPriorityLevel();
        } else {
            onCancel()
        }
    }

    useEffect(() => {
        if (userCanRead) {
            if (!!priorityLevelId) {
                getPriorityLevel();
            }
        }
    }, [priorityLevelId]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.name')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Input name="name" placeholder={t('common.name')} ref={register({ required: true, maxLength: 255 })} disabled={screenMode === ScreenType.DETAILS} maxLength={255} />
                    <InputError error={errors.name} />
                </FormItem>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.index')}</Label>
                    <Input name="index" placeholder={t('common.index')} type={'number'} ref={register({})} disabled={screenMode === ScreenType.DETAILS} pattern="[0-9]*" />
                    <InputError error={errors.index} />
                </FormItem>
            </div>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.description')}</Label>
                    <TextareaAutosize
                        style={{ resize: 'none' }}
                        name='description'
                        value={description}
                        disabled={screenMode === ScreenType.DETAILS}
                        className={`${styles.input} ${styles.inputDefault} ${screenMode === ScreenType.DETAILS ? styles.inputDisabled : ''}`}
                        onChange={e => {
                            setDescription(e.currentTarget.value);
                        }}
                        rows={2}
                    />
                </FormItem>
            </div>
            {screenMode !== ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={onCancelEdit} />
                {userCanWrite && <Button type="submit" text={t('common.save')} size={'small'} />}
            </div>}
            {screenMode === ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.back')} preset={'secondary'} size={'small'} onClick={() => onCancel()} />
                {userCanWrite && <Button type="button" text={t('common.remove')} preset={'danger'} size={'small'} onClick={() => showRemoveItemDialog({ id: priorityLevelId } as PriorityLevelViewModel)} />}
                <QuestionYesNo message={t('common.messages.remove_record')}
                    isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />
                {userCanWrite && <Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
            </div>}
        </form>
    );
}

export default PriorityLevelsScreen;
