import React, { memo } from 'react';
import { ProjectTimesDto } from '../../../api/projectsTimes/models/ProjectTimesDto';
import Utils from '../../../common/services/Utils';
import PlannedRealizedTimes from '../PlannedRealizedTimes/PlannedRealizedTimes';
import styles from './ProjectsTimesTableLine.module.scss';

type Props = {
    project?: ProjectTimesDto;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ProjectsTimesTableLine: React.FC<Props> = ({
    project,
    ...props
}: Props) => {

    return (
        <div {...props} className={`${styles.container} ${styles.containerBorder}`}>
            <div className={`${styles.column} ${styles.columnHide}`} style={Utils.calculateCellWidthStyle(40)}></div>
            <div className={`${styles.column} ${styles.columnValues}`} style={Utils.calculateCellWidthStyle(80)}>
                {project && <PlannedRealizedTimes planned={project.planned} realized={project.realized}/>}
                {!project && <label className={styles.bold}>&nbsp;</label>}
            </div>
        </div>
    );
}

export default memo(ProjectsTimesTableLine);
