import React from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './InputError.module.scss';

type Props = {
    error: FieldError | undefined;
};

const InputError: React.FC<Props> = ({ error }: Props) => {
    const { t } = useTranslation();

    const renderMessage = () => {
        switch (error?.type) {
            case 'required':
                return t('common.errors.required');
            case 'email_not_available':
                return t('common.errors.email_not_available');
            default:
                return error?.message;
        }
    }

    return error && error.type ? (
        <div className={styles.message}>
            {renderMessage()}
        </div>
    ) : null;
}

export default InputError;
