import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEllipsisH, FaPlus } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';
import EstablishmentService from '../../../api/establishments/EstablishmentService';
import { EstablishmentSearchCriteria } from '../../../api/establishments/models/EstablishmentSearchCriteria';
import { EstablishmentViewModel } from '../../../api/establishments/models/EstablishmentViewModel';
import Button from '../../../common/components/button/Button';
import Loading from '../../../common/services/Loading';
import ListingTable, { ListingTableCell, ListingTableColumn } from '../../../common/components/listingTable/ListingTable';
import Dropdown from '../../../common/components/dropdown/Dropdown';
import DropdownItem from '../../../common/components/dropdown/DropdownItem';
import PaginationTextInfo from '../../../common/components/pagination/PaginationTextInfo';
import Pagination, { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from '../../../common/components/pagination/Pagination';
import styles from './ListEstablishmentsScreen.module.scss';
import QuestionYesNo from '../../../common/components/questionYesNo/QuestionYesNo';
import { DATE_FORMAT_DEFAUT } from '../../../Config';
import DateFormat from '../../../common/components/dateFormat/dateFormat';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    onClickItem: (item: EstablishmentViewModel, isDetails: boolean) => void;
    createNewEstablishment: () => void;
    onRefreshAccordion?: () => void;
};

const ListEstablishmentsScreen: React.FC<Props> = ({ groupId, onRefreshAccordion, userCanRead, userCanWrite, ...props }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const criteria: EstablishmentSearchCriteria = { groupId: groupId };
    const [establishments, setEstablishments] = useState<EstablishmentViewModel[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [itemToRemove, setItemToRemove] = useState<EstablishmentViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const getEstablishments = async () => {
        try {
            Loading.show();
            criteria.page = currentPage;
            criteria.itemsPerPage = DEFAULT_PAGINATION_ITEMS_PER_PAGE;
            criteria.orderColumn = 'index, name';
            criteria.orderBy = 'asc';

            if (criteria.groupId) {
                const result = await EstablishmentService.getList(criteria);
                setEstablishments(result.items);
                setTotalItems(result.totalItems);
            }
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const renderTableActionCell = (cell: ListingTableCell) => {
        return (
            <Dropdown options={<>
                <DropdownItem onClick={() => props.onClickItem(cell.row as EstablishmentViewModel, true)}>{t('common.details')}</DropdownItem>
                {userCanWrite && <DropdownItem onClick={() => props.onClickItem(cell.row as EstablishmentViewModel, false)}>{t('common.edit')}</DropdownItem>}
                {userCanWrite && <DropdownItem onClick={() => showRemoveItemDialog(cell.row as EstablishmentViewModel)}>{t('common.remove')}</DropdownItem>}
            </>}>
                <div><FaEllipsisH /></div>
            </Dropdown>
        );
    }

    const renderTableCreatedDateCell = (cell: ListingTableCell) => {
        const createdDate = (cell.row as EstablishmentViewModel).createdDate;

        return (
            <div onClick={() => props.onClickItem(cell.row as EstablishmentViewModel, true)}>
                {createdDate &&<DateFormat value={createdDate} format={DATE_FORMAT_DEFAUT} />}
                <br/><small>{cell.row.createdUser}</small>
            </div>
        );
    }

    const renderTableUpdatedCell = (cell: ListingTableCell) => {
        const updatedDate = (cell.row as EstablishmentViewModel).updatedDate;

        return (
            <div onClick={() => props.onClickItem(cell.row as EstablishmentViewModel, true)}>
                {updatedDate &&<DateFormat value={updatedDate} format={DATE_FORMAT_DEFAUT} />}
                <br/><small>{cell.row.updatedUser}</small>
            </div>
        );
    }

    
    const tableColumns: ListingTableColumn[] = [
        {
            field: 'index',
            name: t('common.index'),
            width: 2
        },
        {
            field: 'name',
            name: t('common.name'),
            width: 'fill'
        },        
        {
            field: 'createdDate',
            name: t('common.creation'),
            width: 4,
            renderCell: renderTableCreatedDateCell
        },
        {
            field: 'updatedDate',
            name: t('common.update'),
            width: 4,
            renderCell: renderTableUpdatedCell
        },        
        {
            field: 'action',
            width: 2,
            cellAlignment: 'center',
            preventClick: true,
            renderCell: renderTableActionCell,
        }
    ];

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    }

    const showRemoveItemDialog = (item: EstablishmentViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await EstablishmentService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onRefreshAccordion && onRefreshAccordion();
                getEstablishments();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getEstablishments();
    }, [currentPage]);

    return (
        <div>
            <div style={{ minHeight: '511px', position: 'relative' }}>
                <ListingTable columns={tableColumns} rows={establishments} allowHover={true} onRowClick={row => props.onClickItem(row as EstablishmentViewModel, true)} />
                <QuestionYesNo message={t('common.messages.remove_record')}
                    isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />
            </div>
            <div style={{ textAlign: 'right', width:'100%', paddingRight: '1rem', paddingTop: '1.5rem' }}>
                {userCanWrite && <Button type="button" preset={'secondary'} size="small" onClick={() => props.createNewEstablishment()} onlyIcon={true} >
                    <FaPlus color={'#034E92'} />
                </Button>}
            </div>
            { totalItems > 0 && <div className={styles.paginationContainer} style={{ bottom: 0, width: '100%' }}>
                <PaginationTextInfo className={styles.paginationInfo} itemName={t('parameters.establishments')} items={establishments.length} totalItems={totalItems} />
                <Pagination currentPage={currentPage} onChange={onPageChange} totalItems={totalItems} />
            </div>}
        </div>
    );
}

export default ListEstablishmentsScreen;
