import { Dispatch } from 'redux';
import Storage from '../../common/services/Storage';
import { STORAGE } from '../../Config';
import { UserProfile } from '../../api/account/models/UserProfile';
import { SET_AUTHENTICATION } from './type';

export const initAuthentication = (cb: (rt: boolean) => void) => async (dispatch: Dispatch) => {
	try {

		const profile = Storage.getObject(STORAGE.AUTH_USER);
		if (!profile) {
			cb(false);
		}

		dispatch(setAuthentication(profile));

		cb(true);
	} catch (ex) {
		dispatch(logout() as any);
		cb(false);
	}
}

export const authenticateUser = (profile: UserProfile) => async (dispatch: Dispatch) => {
	Storage.setObject(STORAGE.AUTH_USER, profile);

	dispatch(setAuthentication(profile));
}

export const logout = () => async (dispatch: Dispatch) => {
	Storage.remove(STORAGE.AUTH_USER);

	dispatch(setAuthentication(null));
}

export const setAuthentication = (profile: UserProfile | null) => ({
	type: SET_AUTHENTICATION,
	profile,
});
