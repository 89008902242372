import Http from '../../common/services/Http';
import { IdNameDto } from '../common/models/IdNameDto';
import { Page } from '../common/types/Page';
import { QualityQuestionSearchCriteria } from './models/QualityQuestionSearchCriteria';
import { QualityQuestionViewModel } from './models/QualityQuestionViewModel';

class QualityQuestionsService {
    
    public getList(criteria: QualityQuestionSearchCriteria) {
        return Http.get<Page<QualityQuestionViewModel>>('qualityQuestions', criteria);
    }

    public getQualityQuestionById(id: string) {
        return Http.get<QualityQuestionViewModel>('qualityQuestions/'+ id);
    }

    public save(model: QualityQuestionViewModel) {
        return !!model.id ? Http.put('qualityQuestions', model) : Http.post('qualityQuestions', model);
    }

    public remove(model: QualityQuestionViewModel) {
        return  Http.put('qualityQuestions/deactivate', model);
    }

    public getQualityQuestions(groupId?: string) {
        return Http.get<IdNameDto[]>('qualityQuestions/get-all-quality-questions-for-select-item/' + groupId);
    }
}

export default new QualityQuestionsService();



