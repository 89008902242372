import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserProfile } from '../../../api/account/models/UserProfile';
import { POLICIES } from '../../../Config';
import { Reducers } from '../../../store/types';
import Navbar, { NavbarItem } from '../navbar/Navbar';

type Props = {
    children: React.ReactNode;
}

const AuthenticatedLayout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const { t } = useTranslation();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const seeDashboards = !!loggedUser?.policies.find(x => x == POLICIES.PROJECT_READ || x == POLICIES.PROJECT_WRITE);
    const seeProjects = !!loggedUser?.policies.find(x => x == POLICIES.PROJECT_READ || x == POLICIES.PROJECT_WRITE);
    const seeParameters = !!loggedUser?.policies.find(x => x == POLICIES.PARAMETERS_READ || x == POLICIES.PARAMETERS_WRITE || x == POLICIES.GROUP_WRITE || x == POLICIES.STATUS_WRITE);
    const seeCollaboratorsTimes = !!loggedUser?.policies.find(x => x == POLICIES.COLLABORATORS_TIMES_WRITE || x == POLICIES.COLLABORATORS_TIMES_READ);
    const seeProjectsTimes = !!loggedUser?.policies.find(x => x == POLICIES.COLLABORATORS_TIMES_READ || x == POLICIES.COLLABORATORS_TIMES_WRITE);//TODO: POLICIES
 

    const navbarItems: NavbarItem[] = [];

    if (seeDashboards) {
        navbarItems.push({
            text: t('menu.dashboard'),
            url: '/dashboards',
        })
    }

    if (seeProjects) {
        navbarItems.push({
            text: t('menu.projects'),
            url: '/projects',
        })
    }
    
    if (seeProjectsTimes) {
        navbarItems.push({
            text: t('menu.projecttimes'),
            url: '/projectstimes',
        })
    }

    if (seeCollaboratorsTimes) {
        navbarItems.push({
            text: t('menu.collaborators_times'),
            url: '/collaboratorstimes',
        })
    }

    if (seeParameters) {
        navbarItems.push({
            text: t('menu.parameters'),
            url: '/parameters',
        })
    }

    return (
        <div>
            <Navbar items={navbarItems} />
            {children}
        </div>
    );
}

export default AuthenticatedLayout;
