import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Button from '../../common/components/button/Button';
import FormItem from '../../common/components/formItem/FormItem';
import Input from '../../common/components/input/Input';
import InputError from '../../common/components/inputError/InputError';
import Label from '../../common/components/label/Label';
import Loading from '../../common/services/Loading';
import styles from './UsersScreen.module.scss';
import UsersService from '../../api/users/UsersService';
import { UserViewModel } from '../../api/users/models/UserViewModel';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import RolesService from '../../api/roles/RolesService';
import { SelectValueLabel } from '../../common/types/SelectValueLabel';
import Select from '../../common/components/select/Select';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    establishmentId?: string;
    afterSave: () => void;
    userId?: string;
    isDetails: boolean;
};

type Form = {
    realName: string;
    userName: string;
    email: string;
    roleName: string;
}

enum ScreenType {
    NEW,
    EDIT,
    DETAILS
}

const UsersScreen: React.FC<Props> = ({ userId, groupId, establishmentId, afterSave, isDetails, userCanRead, userCanWrite }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, setValue, control } = useForm<Form>();
    const [screenMode, setScreenMode] = useState<ScreenType>(isDetails && !!userId ? ScreenType.DETAILS : !!userId ? ScreenType.EDIT : ScreenType.NEW);
    const [userNameIsAvailable, setUserNameIsAvailable] = useState<boolean>(true);
    const [emailIsAvailable, setEmailIsAvailable] = useState<boolean>(true);
    const [itemToRemove, setItemToRemove] = useState<UserViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [rolesOptions, setRolesOptions] = useState<SelectValueLabel[]>([]);
    const [remoteUsersOptions, setRemoteUsersOptions] = useState<SelectValueLabel[]>([]);
    const [remoteUsers, setRemoteUsers] = useState<UserViewModel[]>([]);
    const [remoteUsersAvailable, setRemoteUsersAvailable] = useState<boolean>(false);
    const [isRemoteUser, setIsRemoteUser] = useState<boolean>(false);
    const onCancel = () => {
        afterSave();
    }

    const onSubmit = async ({ realName, userName, email, roleName }: Form) => {
        try {

            if (!realName || !userName || !email || !roleName) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();

            const result = await UsersService.checkUsernameAndEmailAvailability({ userId, userName, email });

            if (result != null && result.available == false) {
                if (!!result.errors) {
                    if (!!result.errors.find((x: string) => x == 'USERNAME')) {
                        setUserNameIsAvailable(false);
                        addToast(t('users.username_not_available'), { appearance: 'warning' });
                    }
                    if (!!result.errors.find((x: string) => x == 'EMAIL')) {
                        setEmailIsAvailable(false);
                        addToast(t('common.errors.email_not_available'), { appearance: 'warning' });
                    }
                }
                Loading.hide();
                return;
            }

            if (groupId || establishmentId) {
                const role = rolesOptions.find(x => x.value == roleName);
                await UsersService.save({ id: userId, realName, userName, email, groupId, establishmentId, roles: [{ name: role?.value, realName: role?.label }], isRemote: isRemoteUser });
            }

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            afterSave();

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getData = async () => {
        try {

            Loading.show();

            const [roles, user] = await Promise.all([
                RolesService.getList({ groupId }),
                userId ? UsersService.getUserById(userId) : null
            ]);

            setRolesOptions(roles.items.map(x => ({ value: x.name, label: x.realName })));

            if (userId) {
                setValue('realName', user?.realName);
                setValue('userName', user?.userName);
                setValue('email', user?.email);
                if (user && user.roles && user.roles[0]) {
                    setValue('roleName', user.roles[0].name);
                }
            }
            else {
                const [remoteUsers] = await Promise.all([
                    UsersService.getAllRemoteUsersOfGroup(groupId ?? '')
                ]);

                setRemoteUsers(remoteUsers);

                setRemoteUsersOptions(remoteUsers.map(x => ({ value: x.userName, label: x.realName })));

                if (remoteUsers && remoteUsers.length >0 ) {
                    setRemoteUsersAvailable(true);
                }
            }

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }

    }

    const showRemoveItemDialog = (item: UserViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await UsersService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onCancel();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        if (userCanRead) {
            if (userCanRead) {
                getData();
            }
        }
    }, [userId]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent}>

            <div className={styles.formLine}>
                {remoteUsersAvailable && <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('users.remote_user')}</Label>
                    <Controller
                        render={({ onChange, value }) => {
                            return (
                                <Select
                                    menuPortalTarget={document.querySelector('body')}
                                    isDisabled={screenMode === ScreenType.DETAILS}
                                    options={remoteUsersOptions}
                                    placeholder={t('users.remote_user')}
                                    isClearable={true}
                                    onChange={(data: SelectValueLabel) => {
                                            onChange(data?.value);                                       
                                            const user = remoteUsers.find(x => x.userName == data?.value);

                                            if (user) {
                                                setIsRemoteUser(true);
                                                setValue('realName', user?.realName);
                                                setValue('userName', user?.userName);
                                                setValue('email', user?.email);
                                            }
                                            else {
                                                setIsRemoteUser(false);
                                            }
                                    }}
                                    value={remoteUsersOptions.find(x => x.value === value)}
                                    filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                />
                            );
                        }}
                        control={control}
                        name="remoteUserName"
                        defaultValue={''}
                        rules={{ required: false }}
                    />
                </FormItem>}


                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.name')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Input name="realName" placeholder={t('common.name')} maxLength={255} ref={register({ required: true, maxLength: 255 })} disabled={screenMode === ScreenType.DETAILS} />
                    <InputError error={errors.realName} />
                </FormItem>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.username')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Input name="userName" placeholder={t('common.username')} maxLength={255} onKeyPress={() => setUserNameIsAvailable(true)} ref={register({
                        required: true,
                        maxLength: 255,
                        // pattern: {
                        //     value: /^[a-zA-Z0-9\.]+$/,
                        //     message: t('users.username_not_valid')
                        // }
                    })} disabled={screenMode === ScreenType.DETAILS} />
                    <InputError error={errors.userName} />
                    {!userNameIsAvailable && <InputError error={{ type: 'username_not_available', message: t('users.username_not_available') }} />}
                </FormItem>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.email')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Input name="email" placeholder={t('common.email')} type="email" maxLength={255} onKeyPress={() => setEmailIsAvailable(true)} ref={register({ required: true, maxLength: 255 })} disabled={screenMode === ScreenType.DETAILS} />
                    <InputError error={errors.email} />
                    {!emailIsAvailable && <InputError error={{ type: 'email_not_available' }} />}
                </FormItem>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.role')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Controller
                        render={({ onChange, value }) => {
                            return (
                                <Select
                                    menuPortalTarget={document.querySelector('body')}
                                    isDisabled={screenMode === ScreenType.DETAILS}
                                    options={rolesOptions}
                                    placeholder={t('common.role')}
                                    onChange={(data: SelectValueLabel) => {
                                        onChange(data.value);
                                    }}
                                    value={rolesOptions.find(x => x.value === value)}
                                    filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                />
                            );
                        }}
                        control={control}
                        name="roleName"
                        defaultValue={''}
                        rules={{ required: true }}
                    />
                    <InputError error={errors.roleName} />
                </FormItem>
            </div>
            {screenMode !== ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={() => screenMode === ScreenType.EDIT && !!isDetails ? setScreenMode(ScreenType.DETAILS) : onCancel()} />
                {userCanWrite && <Button type="submit" text={t('common.save')} size={'small'} />}
            </div>}
            {screenMode === ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                <Button type="button" text={t('common.back')} preset={'secondary'} size={'small'} onClick={() => onCancel()} />
                {userCanWrite && <Button type="button" text={t('common.remove')} preset={'danger'} size={'small'} onClick={() => showRemoveItemDialog({ id: userId } as UserViewModel)} />}
                {userCanWrite && <QuestionYesNo message={t('common.messages.remove_record')}
                    isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />}
                {userCanWrite && <Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
            </div>}
        </form>
    );
}

export default UsersScreen;
