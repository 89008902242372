import React from 'react';
import ListLoginLogsScreen from '../../loginLogs/ListLoginLogsScreen';

type Props = {
    userCanRead: boolean;
};


const ParametersLoginLogsScreen: React.FC<Props> = ({ userCanRead }: Props) => {
    return (
        <div>
            <ListLoginLogsScreen
                userCanRead={userCanRead}
            />
        </div>

    );
}

export default ParametersLoginLogsScreen;
