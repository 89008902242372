import React, { memo } from 'react';
import styles from './Box.module.scss';

type Props = {
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const BoxContent = ({ children, ...props }: Props) => (
    <div
        {...props}
        className={`${styles.content} ${props.className || ''}`}
    >
        {children}
    </div>
);

export default memo(BoxContent);
