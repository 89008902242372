import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from '../../common/components/select/Select';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Button from '../../common/components/button/Button';
import FormItem from '../../common/components/formItem/FormItem';
import Input from '../../common/components/input/Input';
import InputError from '../../common/components/inputError/InputError';
import Label from '../../common/components/label/Label';
import Loading from '../../common/services/Loading';
import styles from './ProductsScreen.module.scss';
import ProductsService from '../../api/products/ProductsService';
import { SelectValueLabel } from '../../common/types/SelectValueLabel';
import { ProductViewModel } from '../../api/products/models/ProductViewModel';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import DomainsService from '../../api/domains/DomainsService';
import TextareaAutosize from 'react-autosize-textarea/lib';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId: string;
    afterSave: () => void;
    productId?: string;
    isDetails: boolean;
};

type Form = {
    name: string;
    domainProjectId: string;
    index?: number;
}

enum ScreenType {
    NEW,
    EDIT,
    DETAILS
}

const ProductsScreen: React.FC<Props> = ({ productId, groupId, afterSave, isDetails, userCanRead, userCanWrite }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, setValue, control } = useForm<Form>();
    const [itemToRemove, setItemToRemove] = useState<ProductViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [screenMode, setScreenMode] = useState<ScreenType>(isDetails && !!productId ? ScreenType.DETAILS : !!productId ? ScreenType.EDIT : ScreenType.NEW);
    const [domainsOptions, setDomainsOptions] = useState<SelectValueLabel[]>([]);
    const [description, setDescription] = useState<string>();

    const onCancel = () => {
        afterSave();
    }

    const onSubmit = async ({ name, domainProjectId, index }: Form) => {
        try {

            if (!name) {
                return;
            }

            Loading.show();
            if (groupId) {
                await ProductsService.save({ id: productId, name: name, groupId, domainProjectId: domainProjectId, index, description });
            }
            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            afterSave();

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getData = async () => {
        try {

            Loading.show();

            const [domains, product] = await Promise.all([
                DomainsService.getDomainsByGroupId(groupId),
                productId ? ProductsService.getProductById(productId) : null
            ]);

            setDomainsOptions(domains.map(x => ({ value: x.id, label: x.name })));

            if (productId) {
                setValue('name', product?.name);
                setValue('domainProjectId', product?.domainProjectId);
                setValue('index', product?.index);
                setDescription(product && product.description ? product.description : '');
            }

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }

    }

    const showRemoveItemDialog = (item: ProductViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await ProductsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onCancel();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onCancelEdit = () => {
        if (screenMode === ScreenType.EDIT && !!isDetails) {
            setScreenMode(ScreenType.DETAILS);
            getData();
        } else {
            onCancel()
        }
    }

    useEffect(() => {
        if (userCanRead) {
            getData();
        }
    }, [productId])

    return (

        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('products.product')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Input name="name" placeholder={t('products.product')} ref={register({ required: true, maxLength: 255 })} disabled={screenMode === ScreenType.DETAILS} maxLength={255} />
                    <InputError error={errors.name} />
                </FormItem>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`} >
                    <Label className={styles.bold}>{t('products.domain')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Controller
                        render={({ onChange, value }) => {
                            return (
                                <Select
                                    menuPortalTarget={document.querySelector('body')}
                                    isDisabled={screenMode === ScreenType.DETAILS}
                                    options={domainsOptions}
                                    placeholder={t('products.domain')}
                                    onChange={(data: SelectValueLabel) => {
                                        onChange(data.value);
                                    }}
                                    value={domainsOptions.find(x => x.value === value)}
                                    filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                />
                            );
                        }}
                        control={control}
                        name="domainProjectId"
                        defaultValue={''}
                        rules={{ required: true }}
                    />
                    <InputError error={errors.domainProjectId} />
                </FormItem>
            </div>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.index')}</Label>
                    <Input name="index" placeholder={t('common.index')} type={'number'} ref={register({})} disabled={screenMode === ScreenType.DETAILS} pattern="[0-9]*" />
                    <InputError error={errors.index} />
                </FormItem>

                <FormItem className={styles.formItem}>
                    <Label className={styles.bold}>{t('common.description')}</Label>
                    <TextareaAutosize
                        style={{ resize: 'none' }}
                        name='description'
                        value={description}
                        disabled={screenMode === ScreenType.DETAILS}
                        className={`${styles.input} ${styles.inputDefault} ${screenMode === ScreenType.DETAILS ? styles.inputDisabled : ''}`}
                        onChange={e => {
                            setDescription(e.currentTarget.value);
                        }}
                        rows={2}
                    />
                </FormItem>
            </div>
            {
                screenMode !== ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                    <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={onCancelEdit} />
                    {userCanWrite && <Button type="submit" text={t('common.save')} size={'small'} />}
                </div>
            }
            {
                screenMode === ScreenType.DETAILS && <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                    <Button type="button" text={t('common.back')} preset={'secondary'} size={'small'} onClick={() => onCancel()} />
                    {userCanWrite && <Button type="button" text={t('common.remove')} preset={'danger'} size={'small'} onClick={() => showRemoveItemDialog({ id: productId } as ProductViewModel)} />}
                    <QuestionYesNo message={t('common.messages.remove_record')}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                    {userCanWrite && <Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
                </div>
            }
        </form >
    );
}

export default ProductsScreen;
