
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEraser } from 'react-icons/fa';
import Button from '../../../common/components/button/Button';
import NumberPerPage, { ItemsPerPage } from '../../../common/components/pagination/numberPerPage/NumberPerPage';
import Select from '../../../common/components/select/Select';
import Text from '../../../common/components/text/Text';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import styles from './Toolbar.module.scss';

type Props = {
    collaborators: SelectValueLabel[];
    collaboratorId?: string;
    onChangeCollaborator: (collaboratorId?: string) => void;
    clearSearch: () => void;
    itemsPerPage: ItemsPerPage;
    onItemsPerPageChange: (items: ItemsPerPage) => void
}

const Toolbar = ({ collaborators, collaboratorId, onChangeCollaborator, clearSearch, itemsPerPage, onItemsPerPageChange }: Props) => {
    const { t } = useTranslation();
    const [reRender, setReRender] = useState(1);

    return (
        <div className={styles.container}>
            <div className={styles.toolbar}>
                <Text preset="screenHeaderTitle" className={styles.title}>{t('collaborators_times.title')}</Text>
            </div>
            <div className={`${styles.toolbar} ${styles.toolbarItemsPerPage}`}>
                {itemsPerPage > 0 && <NumberPerPage key={'itemsPerPage_' + reRender} onChangeItemsPerPage={onItemsPerPageChange}></NumberPerPage>}
                <Select
                    options={collaborators}
                    isClearable
                    placeholder={t('collaborators_times.collaborator_select_placeholder')}
                    onChange={(data: SelectValueLabel) => {
                        onChangeCollaborator(data?.value);
                    }}
                    value={collaboratorId ? collaborators.find(x => x.value === collaboratorId) : null}
                    isDisabled={false}
                    filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                    roundCorners
                    menuPortalTarget={document.body}
                    width={300}
                />
                <Button onClick={() => {
                    clearSearch();
                    setReRender(reRender + 1);
                }} preset="primary" onlyIcon={true} className={styles.toolbarButton}>
                    <FaEraser className={styles.toolbarButtonIcon} />
                </Button>
            </div>
        </div>
    );
}

export default Toolbar;
