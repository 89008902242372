import React from 'react';
import InputGroup, { Props as InputGroupProps } from './InputGroup';
import { Controller } from 'react-hook-form';

type Props = InputGroupProps & {
    name: string;
    form: any;
    hideMessageLength?: boolean;
};

const InputGroupController = ({ name, form, hideMessageLength, onValueChange, ...props }: Props, ref: any) => {
    return (
        <Controller
            control={form.control}
            name={name}
            render={({ onChange: controllerOnChange, value }) => {
                return (
                    <InputGroup
                        hideMessageLength={hideMessageLength}
                        value={value}
                        ref={ref}
                        {...props}
                        onValueChange={value => {
                            const newValue = props.type === 'number' ? Number(value || 0) : value;
                            controllerOnChange(onValueChange ? onValueChange(newValue) : newValue);
                        }}
                    />
                );
            }}
            defaultValue={form.getValues(name)}
        />
    );
};

export default React.forwardRef<HTMLInputElement, Props>(InputGroupController);
