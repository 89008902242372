import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PopoverFilters.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import { useForm } from 'react-hook-form';
import Button from '../button/Button';
import { SelectValueLabel } from '../../types/SelectValueLabel';
import FormItem from '../formItem/FormItem';
import Label from '../label/Label';
import SelectController from '../select/SelectController';
import Input from '../input/Input';

export interface Filters {
    statusId?: string[];
    projectManageId?: string[];
    domainId?: string[];
    typologyId?: string[];
}

export interface FiltersSetup {
    key: string;
    value?: any;
    isInput?: boolean;
    typeInput?: 'number' | 'text';
    isSelect?: boolean;
    selectOptions?: SelectValueLabel[];
    placeholder?: string;
    columnWidth?: number;
    childs?: FiltersSetup[];
    isDisabled?: boolean;
    maxLength?: number;

    label?: string;
    labelClassname?: string;
}

type Props = {
    filtersSetup: FiltersSetup[];
    filters: Filters;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
    onClearFilter: () => void;
}

const PopoverFilters: React.FC<Props> = ({ filtersSetup, filters, onChange, onFilter, onClearFilter }: Props) => {
    const { t } = useTranslation();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });

    const clearFilters = () => {
        form.reset();
        Object.keys(filters).map(x => form.setValue(x as keyof Filters, null));
        onClearFilter();
    }

    const onSubmit = (filters: Filters) => {
        onFilter({ ...filters });
    }

    const onInputChange = () => {
        onChange(form.getValues());
    }

    const getColumn = (x: FiltersSetup) => (
        <Col key={'col_' + x.key}
            xs={x.columnWidth ? x.columnWidth : 12}>
            {typeFilterToRender(x)}
        </Col>
    );

    const typeFilterToRender = (x: FiltersSetup) => {
        if (x.isSelect) {
            return renderSelect(x);
        }
        else if (x.isInput) {
            return renderInput(x);
        }
        else {
            return <></>
        }
    };

    const renderSelect = (x: FiltersSetup) => (
        <FormItem>
            <Label className={x.labelClassname}>{x.label}</Label>
            <SelectController
                isMulti={true as any}
                showOnlyFirstSelected={true}
                name={x.key}
                form={form}
                options={x.selectOptions as SelectValueLabel[]}
                isClearable
                isDisabled={x.isDisabled as boolean}
                placeholder={x.placeholder}
                onChange={() => {
                    onInputChange();
                }}
            />
        </FormItem>
    );


    const renderInput = (x: FiltersSetup) => (
        <FormItem>
            <Label className={x.labelClassname}>{x.label}</Label>
            <Input
                name={x.key}
                placeholder={x.placeholder}
                maxLength={x.maxLength}
                onChange={() => {
                    onInputChange();
                }}
            />
        </FormItem>
    );

    return (
        <form onSubmit={form.handleSubmit(onSubmit)} style={{ minWidth: '500px' }}>
            <div className={styles.formContent}>
                <FormItem>
                    {
                        filtersSetup.map(x => (
                            <Row key={x.key}>
                                {
                                    x.childs && x.childs.length
                                        ? x.childs && x.childs.map(z => getColumn(z))
                                        : getColumn(x)
                                }
                            </Row>
                        ))
                    }
                </FormItem>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.clear')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset'
                            className={styles.cleanButton} />

                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                            className={styles.filterButton}
                        />

                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(PopoverFilters);